/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { Ring } from '@uiball/loaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Scrollbar from '@/shared/components/ScrollBar';
import { CurrentUserProps, ContactsProps } from '@/shared/prop-types/ChatProps';
import {
  colorAdditional, 
  colorBackground, 
  colorBackgroundBody,
  colorFieldsBorder,
  colorAccent,
  colorAccentHover,
  colorBubbleActive,
  colorText,
  colorBubble,
} from '@/utils/palette';
import { 
  paddingRight,
  paddingLeft,
  borderRight,
  left,
  right,
  marginRight,
} from '@/utils/directions';
import ChatContact from './ChatContact';
import ChatBubble from './ChatBubble';
import ChatSearch from './ChatSearch';
import ChatField from './ChatField';
import ChatTopbar from './ChatTopbar';
import { getTickets, getTicketMessages } from '../../../../utils/services/user.service';

const Chat = ({
  currentUser, contacts, onUpdateTickets, c, displayLimit, loadMoreContacts,
 }) => {
   const [FlashProductData, setFlashProductData] = useState([]);
   const [ChatId, setChatId] = useState();
   const [Status, setStatus] = useState();   
   const [isLoading, setIsLoading] = useState(false);
   const [Tmessage, setTmessage] = useState([]);
   const [messagesArray, setMessagesArray] = useState([]);
   const iconStyle = { color: 'rgb(69, 175, 149)' };
  //  console.log('contacts messagesArray', messagesArray);
   useEffect(() => {
     if (Tmessage.length > 0) {
       const updatedMessages = [...messagesArray, ...Tmessage]; // Append Tmessage to messagesArray
       setMessagesArray(updatedMessages);
      localStorage.setItem('chatMessages', JSON.stringify(updatedMessages));
     }
   }, [Tmessage]);
//  console.log('contacts ChatId', ChatId);
//  console.log('contacts FlashProductData', FlashProductData);
//  console.log('contacts Tmessage', Tmessage);
//  console.log('contacts Chatsdddd', contacts);
//  console.log(JSON.parse(localStorage.getItem('user')).data.user.firstName);
//  console.log(JSON.parse(localStorage.getItem('user')).data.user.lastName);
     const fetchChats = async (ChatId) => {
      //  console.log('cccccc', ChatId);
       setIsLoading(true);
       try {
         const requestData = {
         ticket_id: ChatId,
       };
         const response = await getTicketMessages(requestData);
         if (response.data.tickets) {
           const flashProductsData = response.data.tickets;
           setFlashProductData(flashProductsData);
           setIsLoading(false);
         } else {
           console.error('Error fetching tickets:', response.statusText);
         }
       } catch (error) {
         setFlashProductData([]);
         setIsLoading(false);
         console.error('Error fetching tickets:', error);
       }
     };
   const [currentChat, setCurrentChat] = useState();
  //  console.log('currentChat', currentChat);
   const [openContacts, setOpenContacts] = useState(false);
   const currentTime = new Date();
   const formattedTime = currentTime.toLocaleTimeString([], {
     hour: '2-digit',
     minute: '2-digit',
     hour12: true,
   });
   const onOpenChat = (item) => {
    //  console.log('Open chat console', item);
     setChatId(item._id);
     setStatus(item.status);
     fetchChats(item._id);
     setCurrentChat(item._id);
   };
   const onOpenContacts = () => {
     setOpenContacts(state => !state);
   };
   const handleContactSelection = (newChatId) => {
     // setChatId(newChatId); // Update the ChatId state with the new ID
   };
   const handleChatMessageChange = (message) => {
     setTmessage(message);
    //  console.log('Received message:', message);
   };
   return (
     <>
       <div
         className="loader-overlay"
         style={{
     display: isLoading ? 'flex' : 'none',
     justifyContent: 'center',
     alignItems: 'center',
     position: 'fixed',
     top: 0,
     left: 0,
     width: '100%',
     height: '100%',
     // background: 'rgba(255, 255, 255, 0.8)',
     zIndex: 999,
   }}
       >
         <Ring size={65} color="#00897B" />
       </div>
       <ChatContainer
         open={openContacts}
         onClick={openContacts ? onOpenContacts : null}
         role="presentation"
       >
         <ChatContactList open={openContacts}>
           <ChatSearch onUpdateTickets={onUpdateTickets} />
           <ChatContacts>
             <ChatContactsScroll alwaysShowTracks>
               {contacts.map(item => (
                 <ChatContactButton
                   key={item._id}
                   type="button"
                   onClick={e => onOpenChat(item)}
                 >
                   <ChatContact active={item._id === currentChat} contact={item} />
                 </ChatContactButton>
             ))}
             </ChatContactsScroll>
           </ChatContacts>
         </ChatContactList>
         {currentChat === null ? (
           <ChatDialog>
             <ChatTopbar onClick={onOpenContacts} />
             <ChatEmptyMessages>
               <p>Select a chat to start messaging</p>
             </ChatEmptyMessages>
           </ChatDialog>
       ) : (
         <ChatDialog>
           <ChatTopbar
             contact={contacts.find(item => item._id === currentChat)}
             onClick={onOpenContacts}
           />
           <ChatScroll alwaysShowTracks>
             <ChatMessagesWrap>
               {FlashProductData === null || FlashProductData.length === 0 ? (
                 <ChatMessages>
                   <ChatEmptyMessages>
                     <p>No messages</p>
                   </ChatEmptyMessages>
                 </ChatMessages>
               ) : (
                 <ChatMessages>
                   {FlashProductData.map(item => (
                     <ChatBubble
                       key={item.id}
                       contact={
                      item.user_id === currentUser.user_id ? currentUser
                      : contacts.find(c => c.user_id === item.user_id)
                    }
                       message={item}
                       Tmessage={Tmessage}
                       active={item.user_id === currentUser.user_id}
                     />
                   ))}
                   {messagesArray.map((message, index) => (
                     <ChatBubbleMessageWrap
                       key={index} // You can use a unique key if you have multiple messages
                       style={{
                   marginBottom: '5px',
                   background: 'rgba(45,49,57,1)',
                   marginLeft: 'auto',
                   borderRadius: '15px 0 0 15px',
                   padding: '15px 10px 10px 15px',
                   paddingTop: '10px',
                   paddingBottom: '5px',
                 }}
                     >
                       <ChatBubbleContactName style={{ textTransform: 'capitalize' }}>
                         {JSON.parse(localStorage.getItem('user')).data.user.firstName}{' '}
                         {JSON.parse(localStorage.getItem('user')).data.user.lastName}
                       </ChatBubbleContactName>
                       <ChatBubbleMessage style={{ margin: '0 0 5px 0' }}>{message.message}</ChatBubbleMessage>
                       {message.Files && message.Files.length > 0 && (
                       <div>
                         {message.Files.map((file, index) => (
                           <a key={index} href={file} target="_blank" className="mr-1" rel="noopener noreferrer" download>
                             <FontAwesomeIcon icon={faDownload} size="1x" style={iconStyle} className="my-icon" />
                           </a>
            ))}
                       </div>
        )}
                       <ChatBubbleDate>{formattedTime}</ChatBubbleDate>
                     </ChatBubbleMessageWrap>
                 ))}
                 </ChatMessages>
                   )}
             </ChatMessagesWrap>
           </ChatScroll>
           <ChatField chatId={ChatId} status={Status} getIsChatVal1={handleChatMessageChange} fetchChats={fetchChats} />
         </ChatDialog>
       )}
       </ChatContainer>
     </>
   );
 };

Chat.propTypes = {
  currentUser: CurrentUserProps.isRequired,
  contacts: ContactsProps.isRequired,
};

export default Chat;

// region STYLES

const ChatBubbleMessageWrap = styled.div`
  max-width: 270px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0 40px 40px 0;
 
  ${paddingRight}: 40px;
  ${paddingLeft}: 20px;
  background-color: ${colorBubble};
  ${props => props.file && 'padding: 10px 20px 20px 20px'};



  img {
    max-width: 215px;
  }
`;

const ChatBubbleContactName = styled.p`
  margin-top: 0;
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 500;
  color: ${colorAccent};
  ${paddingRight}: 50px;
`;

const ChatBubbleMessage = styled.p`
  margin: 0 0 10px 0;
  font-size: 12px;
  color: ${colorText};
`;

const ChatBubbleDate = styled.p`
  color: ${colorAdditional};
  font-size: 10px;
  margin: 0;
  text-align: end;
  ${right}: 36px;

  @media screen and (min-width: 1366px) {
    ${right}: 0;
  }
`;

const ChatContainer = styled.div`
  height: calc(100vh - 185px);
  min-height: 400px;
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  background-color: ${colorBackground};

  @media screen and (max-width: 1023px) {

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      ${right}: 0;
      left: 0;
      opacity: 0;
      z-index: 0;
      transition: opacity 0.3s, z-index 0.3s 0.3s;
      background-color: ${colorAdditional};
    }
    
    ${props => props.open && `
      &:before {
        opacity: 0.2;
        z-index: 1;
        transition: opacity 0.3s, z-index 0s;
        pointer-events: none;
      }

      .chat__scroll, .chat__text-field {
        pointer-events: none;
      }
    `}
  }
`;

const ChatContactList = styled.div`
  width: 335px;
  height: 100%;
  overflow: hidden;
  transition: transform 0.3s;
  background-color: ${colorBackground};
  ${borderRight}: 1px solid ${colorFieldsBorder};

  @media screen and (max-width: 1023px) {
    max-width: 335px;
    width: 100%;
    transform: translateX(calc(-100% - 1px));
    position: absolute;
    z-index: 1;

    ${props => props.open && `
      transform: translateX(0);
      ${borderRight(props)}: none;
    `}
  }
`;

const ChatContacts = styled.div`
  overflow: auto;
  height: calc(100% - 55px);
`;

const ChatContactButton = styled.button`
  width: 100%;
  padding: 0;
  border: none;
  background: transparent;
  text-align: ${left};
`;

const ChatDialog = styled.div`
  width: calc(100% - 335px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const ChatMessagesWrap = styled.div`
  width: 100%;
  display: flex;
`;

const ChatMessages = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
`;

const ChatEmptyMessages = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;

  p {
    margin: auto;
    border-radius: 15px;
    padding: 5px 20px;
    color: ${colorAdditional};
    background-color: ${colorBackgroundBody};
  }
`;

const ChatScroll = styled(Scrollbar)`
  text-align: ${left};

  .scrollbar-track {

    &.scrollbar-track-y {
      width: 2px;
      ${marginRight}: 3px;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
`;

const ChatContactsScroll = styled(Scrollbar)`
  height: 100%;

  .scroll-content {

    &, & > div {
      height: 100%;
    }
  }

  .scrollbar-track {

    &.scrollbar-track-y {
      width: 2px;
      ${marginRight}: 3px;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
`;

// endregion
