/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { right, left, marginRight } from "@/utils/directions";
import Panel from "@/shared/components/Panel";
import { Table } from "@/shared/components/TableElements";
import ErrorAlert from "@/shared/components/ErrorAlert";
import { fetchTopTenCurrencies } from "@/redux/actions/topTenActions";
import {
  // createMarketPlace,
  getBTC,
  getETH,
  getBNB,
  getUSDT,
  getUSDC,
  getPAX,
  getBUSD,
  getGBPT,
  getJPYC,
  getEUROC,
  getMATIC,
  getCTY,
} from "../../../../utils/services/user.service";

const colors = [
  "#d383ff",
  "#27a17c",
  "#2775ca",
  "#5db944",
  "#fffff",
  "#f5c754",
  "#7b7b7b",
  "#14459c",
  "#16449a",
  "#21a2ff",
  "#0b1746",
  "#f7931b",
];

const TradeHistory = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const [getBTCPrice, setBTCPrice] = useState("");
  const [getETHPrice, setETHPrice] = useState("");
  const [getBNBPrice, setBNBPrice] = useState("");
  const [getUSDTPrice, setUSDTPrice] = useState("");
  const [getUSDCrice, setUSDCrice] = useState("");
  const [getPAXPrice, setPAXPrice] = useState("");
  const [getBUSDPrice, setBUSDPrice] = useState("");
  const [getGBPTPrice, setGBPTPrice] = useState("");
  const [getJPYCPrice, setJPYCPrice] = useState("");
  const [getEUROCPrice, setEUROCPrice] = useState("");
  const [getMATICPrice, setMATICPrice] = useState("");
  const [getCTYPrice, setCTYPrice] = useState("");

  const bnbb = `${process.env.PUBLIC_URL}/img/logo/bnbb.png`;
  const CustodyCoin = `${process.env.PUBLIC_URL}/img/logo/CustodyCoin.png`;
  const downloadmodified = `${process.env.PUBLIC_URL}/img/logo/download-modified.png`;
  const ethereum = `${process.env.PUBLIC_URL}/img/logo/ethereum.png`;
  const euroc = `${process.env.PUBLIC_URL}/img/logo/euroc.png`;
  const gbpt = `${process.env.PUBLIC_URL}/img/logo/gbpt.png`;
  const jpyc = `${process.env.PUBLIC_URL}/img/logo/jpyc.png`;
  const paxos = `${process.env.PUBLIC_URL}/img/logo/paxos.png`;
  const usdc = `${process.env.PUBLIC_URL}/img/logo/usdc.png`;
  const USDCoin = `${process.env.PUBLIC_URL}/img/logo/USD-Coin.png`;
  const usdt = `${process.env.PUBLIC_URL}/img/logo/usdt.png`;
  const Bitcoin = `${process.env.PUBLIC_URL}/img/logo/Bitcoin.png`;

  const { topTen, errorTopTen, isTopTenLoading } = useSelector((state) => ({
    topTen: state.topTen.data,
    errorTopTen: state.topTen.error,
    isTopTenLoading: state.topTen.isFetching,
  }));
  const topSix = topTen?.slice(0, 6);

  const refreshData = () => {
    dispatch(fetchTopTenCurrencies());
  };

  useEffect(() => {
    const fetchBTC = async () => {
      try {
        const response = await getBTC();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setBTCPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchETH = async () => {
      try {
        const response = await getETH();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('setETHPrice', formattedData);
          setETHPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchBNB = async () => {
      try {
        const response = await getBNB();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setBNBPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchUSDT = async () => {
      try {
        const response = await getUSDT();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setUSDTPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchUSDC = async () => {
      try {
        const response = await getUSDC();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('setUSDCrice', formattedData);
          setUSDCrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchPAX = async () => {
      try {
        const response = await getPAX();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setPAXPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchBUSD = async () => {
      try {
        const response = await getBUSD();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setBUSDPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchGBPT = async () => {
      try {
        const response = await getGBPT();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setGBPTPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchJPYC = async () => {
      try {
        const response = await getJPYC();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setJPYCPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchEUROC = async () => {
      try {
        const response = await getEUROC();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setEUROCPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchMATIC = async () => {
      try {
        const response = await getMATIC();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setMATICPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchCTY = async () => {
      try {
        const response = await getCTY();
        if (response.data.status === "success") {
          const Data = response.data.data.price;
          const formattedData = Number(Data).toFixed(2);
          // console.log('getBTC', formattedData);
          setCTYPrice(formattedData);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };

    fetchBTC();
    fetchETH();
    fetchBNB();
    fetchUSDT();
    fetchUSDC();
    fetchPAX();
    fetchBUSD();
    fetchGBPT();
    fetchJPYC();
    fetchEUROC();
    fetchMATIC();
    fetchCTY();
  }, []);

  return (
    <Panel
      xl={12}
      xs={12}
      className="mt-3"
      title="Coins"
      subhead="Current Prices"
      isLoading={isTopTenLoading}
      refreshRequest={refreshData}
    >
      {/* <ErrorAlert subTitle="Quotes" error={errorTopTen} /> */}
      <Table responsive striped>
        <thead>
          <tr>
            <TradeHistoryTh style={{ paddingLeft: "10%" }}>
              Coins
            </TradeHistoryTh>
            <TradeHistoryTh style={{ paddingLeft: "10%" }}>
              Symbol
            </TradeHistoryTh>
            <TradeHistoryTh style={{ paddingLeft: "10%" }}>
              Price
            </TradeHistoryTh>
          </tr>
        </thead>
        <tbody style={{ cursor: "pointer" }}>
          <tr>
            <td>
              <DashboardCoin style={{ paddingLeft: "30%" }} color={colors[0]}>
                CTY Coin
              </DashboardCoin>
            </td>
            <td style={{ paddingLeft: "10%" }}>CTY</td>
            <td style={{ paddingLeft: "10%" }}>
              <b>$</b> {getCTYPrice}
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ paddingLeft: "30%" }} color={colors[11]}>
                Bitcoin
              </DashboardCoin>
            </td>
            <td style={{ paddingLeft: "10%" }}>BTC</td>
            <td style={{ paddingLeft: "10%" }}>
              <b>$</b> {getBTCPrice}
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ paddingLeft: "30%" }} color={colors[6]}>
                Ethereum
              </DashboardCoin>
            </td>
            <td style={{ paddingLeft: "10%" }}>ETH</td>
            <td style={{ paddingLeft: "10%" }}>
              <b>$</b> {getETHPrice}
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ paddingLeft: "30%" }} color={colors[5]}>
                BNB Coin
              </DashboardCoin>
            </td>
            <td style={{ paddingLeft: "10%" }}>BNB</td>
            <td style={{ paddingLeft: "10%" }}>
              <b>$</b> {getBNBPrice}
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ paddingLeft: "30%" }} color={colors[1]}>
                USDT Coin
              </DashboardCoin>
            </td>
            <td style={{ paddingLeft: "10%" }}>USDT</td>
            <td style={{ paddingLeft: "10%" }}>
              <b>$</b> {getUSDTPrice}
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ paddingLeft: "30%" }} color={colors[2]}>
                USDC Coin
              </DashboardCoin>
            </td>
            <td style={{ paddingLeft: "10%" }}>USDC</td>
            <td style={{ paddingLeft: "10%" }}>
              <b>$</b> {getUSDCrice}
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ paddingLeft: "30%" }} color={colors[3]}>
                Paxos
              </DashboardCoin>
            </td>
            <td style={{ paddingLeft: "10%" }}>PAX</td>
            <td style={{ paddingLeft: "10%" }}>
              <b>$</b> {getPAXPrice}
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ paddingLeft: "30%" }} color={colors[4]}>
                BUSD Coin
              </DashboardCoin>
            </td>
            <td style={{ paddingLeft: "10%" }}>BUSD</td>
            <td style={{ paddingLeft: "10%" }}>
              <b>$</b> {getBUSDPrice}
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ paddingLeft: "30%" }} color={colors[9]}>
                GBPT
              </DashboardCoin>
            </td>
            <td style={{ paddingLeft: "10%" }}>GBPT</td>
            <td style={{ paddingLeft: "10%" }}>
              <b>$</b> {getGBPTPrice}
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ paddingLeft: "30%" }} color={colors[9]}>
                JPYC Coin
              </DashboardCoin>
            </td>
            <td style={{ paddingLeft: "10%" }}>JPYC</td>
            <td style={{ paddingLeft: "10%" }}>
              <b>$</b> {getJPYCPrice}
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ paddingLeft: "30%" }} color={colors[9]}>
                EUROC
              </DashboardCoin>
            </td>
            <td style={{ paddingLeft: "10%" }}>EUROC</td>
            <td style={{ paddingLeft: "10%" }}>
              <b>$</b> {getEUROCPrice}
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ paddingLeft: "30%" }} color={colors[9]}>
                Matic
              </DashboardCoin>
            </td>
            <td style={{ paddingLeft: "10%" }}>POLYGON</td>
            <td style={{ paddingLeft: "10%" }}>
              <b>$</b> {getMATICPrice}
            </td>
          </tr>
          <tr>
            <td>
              <DashboardCoin style={{ paddingLeft: "30%" }} color={colors[1]}>
                USDT Coin
              </DashboardCoin>
            </td>
            <td style={{ paddingLeft: "10%" }}>USDT</td>
            <td style={{ paddingLeft: "10%" }}>
              <b>$</b> {getUSDTPrice}
            </td>
          </tr>
        </tbody>
      </Table>
    </Panel>
  );
};

export default TradeHistory;

// region STYLES

const DashboardCoin = styled.p`
  color: ${(props) => props.color};
  font-weight: 700;
`;

const TradeHistoryTh = styled.th``;

// endregion

const ProductItemLabel = styled.img`
  ${right}: 5px;
  width: 20px;
`;
