/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import { React, useState, useEffect } from 'react';
import Web3 from 'web3';
import { Formik } from 'formik';
import styled from 'styled-components';
import { useAccount, useBalance } from 'wagmi';
import { useDispatch } from 'react-redux';
import Abi from './Abi/tokenAbi.json';
import ContractAbi from './Abi/contractAbi.json';
import { Button } from '../../../../shared/components/Button';
import { CustomSelect } from '../../../../shared/components/CustomSelect';
import { alertMsg, clearMsg } from '../../../../redux/actions/authActions';
import { getTotalBalance } from '../../../../redux/actions/sidebarActions';



function WalletResponse({ setstatusCode }) {
  const options = [
    { value: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', label: 'USDC' },
    { value: '0x8E870D67F660D95d5be530380D0eC0bd388289E1', label: 'PAX' },
    { value: '0xdAC17F958D2ee523a2206206994597C13D831ec7', label: 'USDT' },
    { value: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', label: 'BUSD' },
    { value: '0xBa08Da6b46E3dd153DD8b66A6E4cfd37A6359559', label: 'CTY' },
    { value: '0x1aBaEA1f7C830bD89Acc67eC4af516284b1bC33c', label: 'EUROC' },
    { value: '0x86B4dBE5D203e634a12364C0e428fa242A3FbA98', label: 'GBPT' },
    { value: '0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB', label: 'JPYC' },
    // { value: '0x6f9CbE318a6BD9a199BD947451F0F26609ccC4F6', label: 'GULF' },
  ];
  const account = useAccount();
  const dispatch = useDispatch();
  const [isWallet, setisWallet] = useState(false);
  useEffect(() => {
    if (account.address !== undefined) return setisWallet(true);
    return setisWallet(false);
  }, [account]);

  const [loading, setloading] = useState(false);
  const { data, isError, isLoading } = useBalance({
    address: account.address,
  });
  // Use the connected account and contract here
  async function TransferFee(values, { resetForm }) {
    setloading(true);
    try {
      const web3 = new Web3(window.ethereum);
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3.utils.toHex(97) }],
      });

      const tokenAddress = values.token;
      const tokenContract = new web3.eth.Contract(Abi, tokenAddress);
      const contractAddress = '0x5eE120c4E0613fAe5116517ec4dF323aF45dB251';
      const contractCustodiy = new web3.eth.Contract(ContractAbi, contractAddress);
      const ownerAddress = await contractCustodiy.methods.owner().call();
      const approvers = ownerAddress;
      const currentAddress = account.address; // External wallet address
      const user = JSON.parse(localStorage.getItem('user'));
      const RecipientAddress = user.data.user.address; // Internal wallet address
      // console.log('adding ownerAddress as a approval ====>>>>>', ownerAddress);
      // console.log(tokenContract, contractCustodiy);
      try {
        // eslint-disable-next-line radix
        const value = parseInt(web3.utils.toWei(values.amount, 'ether'));
        // const totalamount = (BigInt(value) + BigInt(21000000000)).toString();
        // check user balance for lock token if not avaliable then revert trasaction
        const tokenbalance = await tokenContract.methods.balanceOf(currentAddress).call();
        const tokenConvertBalance = web3.utils.fromWei(tokenbalance, 'ether');
        // console.log(currentAddress);
        // console.log(tokenConvertBalance, tokenbalance);
        if (values.amount > 0) {
          if (tokenConvertBalance > values.amount) {
            setloading(false);
            // console.log('Input value in wei ====>>>>>', value);
            // eslint-disable-next-line max-len
            const tokenContractResult = await tokenContract.methods.increaseAllowance(contractAddress, value.toString()).send({ from: currentAddress });
            dispatch(alertMsg('Please Confirm Transaction Approval'));
            const contractCustodiyResult = await contractCustodiy.methods.lockTokens(RecipientAddress, tokenAddress, value.toString(), approvers).send({ from: currentAddress });
            dispatch(alertMsg(`Transaction Successfully Done!!! Here is Address,\n ${contractCustodiyResult.blockHash}`));
            dispatch(getTotalBalance());
            setstatusCode(200);
            setTimeout(() => {
              dispatch(clearMsg());
            }, 3000);
            resetForm({ values: '' });
          } else {
            setstatusCode(400);
            setloading(false);
            dispatch(alertMsg('You dont have sufficient token balance'));
            setTimeout(() => {
              dispatch(clearMsg());
            }, 3000);
            resetForm({ values: '' });
          }
        } else {
          setloading(false);
          setstatusCode(400);
          dispatch(alertMsg('Please Enter Correct Amount'));
          setTimeout(() => {
            dispatch(clearMsg());
          }, 3000);
          resetForm({ values: '' });
        }
      } catch (error) {
        setloading(false);
        setstatusCode(400);
        dispatch(alertMsg(`Error approving token:,\n ${error}`));
        setTimeout(() => {
          dispatch(clearMsg());
        }, 3000);
        resetForm({ values: '' });
      }
    } catch (error) {
      setloading(false);
      setstatusCode(400);
      dispatch(alertMsg(`Async function encountered an error:,\n ${error}`));
      setTimeout(() => {
        dispatch(clearMsg());
      }, 6000);
      resetForm({ values: '' });
    }
  }
  return (
    <>
      {
        isWallet ? (
          <>
            <Formik
              initialValues={{ amount: '', token: '' }}
              onSubmit={(values, { resetForm }) => {
                // console.log(values);
                TransferFee(values, { resetForm });
              }}
              validate={(values) => {
                const errors = {};
                const regex = /^[0-9\b]+$/;
                if (!values.amount) {
                  errors.amount = 'Amount is Required !!';
                } else if (!regex.test(values.amount)) {
                  errors.amount = 'Amount must be in digit e.g 10';
                }
                if (!values.token) {
                  errors.token = 'Token is Required !!';
                }
                return errors;
              }}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <InputDiv>
                      <label htmlFor="email">Amount</label>
                      <input
                        name="amount"
                        type="text"
                        placeholder="Enter your Amount"
                        value={values.amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.amount && touched.amount && 'error'}
                      />
                      {errors.amount && touched.amount && (
                        <Error className="input feedback">{errors.amount}</Error>
                      )}

                    </InputDiv>

                    <SelectDiv>
                      <label style={{ color: 'white' }} htmlFor="email">Token *</label>
                      <CustomSelect
                        value={
                          values.token
                        }
                        onChange={(
                          value,
                        ) => {
                          setFieldValue(
                            'token',
                            value.value,
                          );
                        }}
                        options={
                          options
                        }
                        isDisabled={values.amount === ''}
                      />
                      {errors.token && touched.token && (
                        <Error className="input feedback">{errors.token}</Error>
                      )}
                    </SelectDiv>
                    <FormButton type="submit" variant={loading ? 'secondary' : 'primary'}>
                      {loading ? 'Transfering......' : 'Transfer'}
                    </FormButton>

                  </form>
                );
              }}
            </Formik>
            <div>
              <BalanceTitle>Connected Account: <Balance>{account.address}</Balance></BalanceTitle>
              {/* <BalanceTitle>Balance: <Balance>{data?.formatted} {data?.symbol}</Balance></BalanceTitle> */}
            </div>
          </>
        ) : null
      }
    </>
  );
}

export default WalletResponse;
const InputDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
  input {
    background-color: transparent;
    border: none;
    color:white;
    border-bottom:1px solid #555555;
  }
`;
const Error = styled.div`
  color: #e66e6e;
  padding: 2px 0px;
  font-size: 12px;
`;

const FormButton = styled(Button)`
margin-top:5px
 `;
export const BalanceTitle = styled.p`
 font-size: 14px;
 text-align: left;
 font-weight: 600;
`;
export const Balance = styled.span`
font-size: 16px;
text-align: left;
font-weight: 700;
color:#4ce1b6
`;
const SelectDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
  font-size: 14px;
`;




