/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LogInFormNew from '@/shared/components/account/loginForm/LogInFormNew';
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountTitle,
  AccountWrap,
} from '@/shared/components/account/AccountElements';

import Logo from '../../../utils/img/custodiy.png';

import {
  clearMsg, login, logout, adminSwitchProfile,
} from '../../../redux/actions/authActions';
import { toast } from 'react-toastify';

const LogInNew = () => {
  const [loading, setLoading] = useState(false);
  const [tfaCode, setTfaCode] = useState("");
  const [isTFA, setIsTFA] = useState(false);

  const { isRegister } = useSelector(state => state.user);

  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();
  const history = useHistory();
  const rmCheck = document.getElementById('rememberMe');
  const emailInput = document.getElementById('email');
  const urlSearchParams = new URLSearchParams(window.location.search);
  const emailVal = urlSearchParams.get('email');
  const user = JSON.parse(localStorage?.getItem('user'))

  const submitForm = async (values) => {
    if (!values.tfa) {
      toast.error('please input TFA Code')
      return false
    }
    setLoading(true);
    dispatch(clearMsg());
    const payload = {
      email: values.email,
      tfaCode: values.tfa
    }

    dispatch(adminSwitchProfile(
      payload
    ))
      .then(
        () => {
          dispatch(clearMsg());
          history.push('/dashboard');
          window.location.reload();

          setInterval(() => {
            dispatch(logout());
            history.push('/login');
          }, 1800000);
        },
      )
      .catch(() => {
        setTimeout(() => {
          dispatch(clearMsg());
        }, 3000);
        setLoading(false);
      });
  };
  return (
    <AccountWrap>
      <AccountContent>
        <AccountCard>
          <AccountHead>
            <AccountTitle>Welcome to
              <AccountLogo>
                <img style={{ width: '68%' }} src={Logo} alt="logo" />
              </AccountLogo>
            </AccountTitle>
          </AccountHead>
          <h4 className="subhead" style={{ fontSize: '18px', fontWeight: '700', textAlign: 'center' }}>Log In</h4>
          <LogInFormNew
            onSubmit={submitForm}
            form="log_in_form"
            loading={loading}
            errorMsg={message}
            isRegister={isRegister}


          />
        </AccountCard>
      </AccountContent>
    </AccountWrap>
  );
};

export default LogInNew;
