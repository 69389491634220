import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SafeBoxForm from './component/SafeboxForm';

const AddSafeBoxPage = () => {
  const { message } = useSelector(state => state.message);
  return (
    <Container className="dashboard">
      <Row hidden>
        <Col md={12}>
          <h3 className="page-title">Create SafeBox</h3>
        </Col>
      </Row>
      <Row>
        <SafeBoxForm errorMsg={message} />
      </Row>

    </Container>
  );
  };
export default AddSafeBoxPage;
