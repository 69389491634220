/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';
import '../style/Rating.css';
import {
  Carousel, Col, Container, Row,
} from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { Button } from '@/shared/components/Button';
import { CardBody } from '@/shared/components/Card';
import { colorAccent, colorAdditional, colorBackground } from '@/utils/palette';
import { right, left, marginRight } from '@/utils/directions';
import { deleteMyProduct, getMyProduct, getProductData } from '../../../utils/services/user.service';
// import '../style/product.css';
import StarRating from '../home/StarRating';
import { toast } from 'react-toastify';
import { Ring } from '@uiball/loaders';


const Product = ({ storeId, onDeleteProduct }) => {
  const [products, setProducts] = useState([]);
  const [refetch, setRefetch] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { productId } = useParams();

  const Sale = `${process.env.PUBLIC_URL}/img/for_store/catalog/sale_lable.png`;
  const New = `${process.env.PUBLIC_URL}/img/for_store/catalog/new_lable.png`;
  const NoImg = `${process.env.PUBLIC_URL}/img/no-img.png`;
  const NoImg1 = `${process.env.PUBLIC_URL}/img/No-img1.png`;

  function removeDuplicateColors(product) {
    const seenColors = new Set();
    // eslint-disable-next-line no-param-reassign
    product.product_sizes_quantity = product.product_sizes_quantity.filter((item) => {
      if (!seenColors.has(item.color)) {
        seenColors.add(item.color);
        return true;
      }
      return false;
    });
  }

  // Iterate through each product and remove duplicate colors
  if (products.length > 0) {
    products.forEach((product) => {
      removeDuplicateColors(product);
    });
  }

  const getProductDetail = async () => {
    try {
      const response = await getProductData({ productId }); // Pass productId to the API call
      if (response.data && response.data) {
        // Handle the received product details as needed
      } else {
        console.error('Not Found');
      }
    } catch (error) {
      console.error('Error fetching store data:', error);
    }
  };
  useEffect(() => {
    const fetchStoreData = async () => {
      const payload = {
        limit: 20,
        pageNumber: 1,
        store_id: storeId,
      };
      try {
        const response = await getMyProduct(payload);
        if (response.data && response.data.products.length > 0) {
          setProducts(response.data.products);
        } else {
          console.error('Not Found');
        }
      } catch (error) {
        console.error('Error fetching store data:', error);
      }
    };
    fetchStoreData();
  }, [storeId, refetch]);
  const handleDelete = async (id) => {
    setIsLoading(true)
    try {
      const payload = {
        product_id: id
      };
      const res = await deleteMyProduct(payload);
      setRefetch(!refetch)
      toast.success(res?.data?.Message);
      setIsLoading(false)

      onDeleteProduct();
    } catch (error) {
      console.error('Error deleting product:', error);
      setIsLoading(false)
      toast.error(error?.response?.data.Message);

    }
  };

  return (
    <div className="container mt-4">
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? 'flex' : 'none', // Use flex display when loading
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <div className="row px-4">
        {products.length > 0 ? (
          products.map(product => (
            <div
              className="col-lg-3 col-md-6 col-sm-12"
              style={{ textTransform: 'capitalize' }}
              key={product._id}
            >
              <div className="card productCard" style={{ background: '#232329' }}>
                <Link to={`/productdetails/${product._id}`}>

                  <div className="square-img-wrapper mt-4">
                    <img
                      src={product.pictures.length > 0 ? product.pictures[0] : NoImg1}
                      className="card-img-top square-img p-3 border-radius zoom-in-on-hover"
                      alt={product.storeName}
                    />
                  </div>
                </Link>
                <div className="card-body">
                  <Row>

                    <Col xs={12} md={7} lg={7}>
                      <h5 className="card-title producttitle mt-0 mb-0">{product.title}</h5>
                    </Col>
                    <Col xs={12} md={5} lg={5}>
                      <p className="card-title productPrice mb-0 float-right">
                        {product.product_sizes_quantity[0].price} {product.symbol}
                      </p>
                    </Col>
                  </Row>
                  <span className="d-none">
                    <span style={{ width: '100%' }}>
                      <h5 className="card-title producttitle mt-0 mb-1">{product.title}</h5>
                      {(() => {
                        const newVal = 1;
                        // eslint-disable-next-line max-len
                        return <h6 className="card-title producttitle mt-0 mb-0">{product.brand}</h6>;
                      })()}
                    </span>
                    <span style={{ float: 'right', width: '40%' }}>
                      <p
                        className="card-title productPrice mb-0 float-right"
                      >
                        {product.product_sizes_quantity[0].price} {product.symbol}
                      </p>
                    </span>
                  </span>
                  <span>
                    {product.product_sizes_quantity.map(color => (
                      <ProductItemColor
                        style={{ background: color.color }}
                      />
                    ))}
                  </span>
                  <div className="Rating mb-4" style={{ float: 'right', marginTop: '7%' }}>
                    {product.ProductRatting?.length > 0 ? (
                      <StarRating rating={product.ProductRatting[0]?.averageRating} />
                    ) : (
                      <StarRating rating={0} />
                    )}
                  </div>

                  <Link to={`/ProductEdit/${product._id}`}>
                    <div className="overlay-text">
                      <p><span> &#9998; </span></p>
                    </div>
                  </Link>
                  <div className="overlay-text-delete " onClick={() => handleDelete(product?._id)}>
                    <i className="fa fa-trash-o text-white px-1" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </div>
          ))
        ) : (
          <p className="text-center mb-5">No Products available.</p>
        )}
      </div>
    </div>
  );
};
export default Product;

// region STYLES

const ProductItemsWrap = styled.div`
  overflow: hidden;
  width: 100%;
`;

const ProductItemsList = styled.div`
  width: calc(100% + 20px);
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
`;

const ProductCard = styled(Card)`
  padding: 0;
  width: 25%;
  background: red;

  @media screen and (max-width: 1500px) {
    width: 25%;
    background: red;
  }

  @media screen and (max-width: 1200px) {
    width: 50%;
    background: red;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    height: auto;
    background: red;
  }
`;

const ProductItem = styled(CardBody)`
  margin-bottom: 30px;
  height: 440px;
  ${marginRight}: 30px;
  position: relative;
  background-color: ${colorBackground};
`;

const ProductItemImageWrap = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  overflow: hidden;
`;

const ProductItemImage = styled.img`
  height: 100%;
  width: auto;
`;

const ProductItemInfo = styled.div`
  text-align: ${left};
  position: relative;
  width: calc(100% - 90px);
`;

const ProductItemTitle = styled.h4`
  font-weight: 500;
  transition: all 0.3s;
`;

const ProductItemLink = styled(Link)`
  padding: 40px 30px;
  display: block;

  &:hover {
    text-decoration: none;

    ${ProductItemTitle} {
      color: ${colorAccent};
    }
  }
`;

const ProductItemDescription = styled.p`
  margin: 0;
  color: ${colorAdditional};
  line-height: 17px;
`;

const ProductItemPrice = styled.h1`
  position: absolute;
  top: 0;
  ${right}: -90px;
  line-height: 36px;
`;

const ProductItemOldPrice = styled.p`
  position: absolute;
  ${right}: -90px;
  top: 36px;
  line-height: 28px;
  color: ${colorAdditional};
  margin: 0;
  text-decoration: line-through;
`;

const ProductItemColor = styled.span`
  height: 15px;
  width: 15px;
  ${marginRight}: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-top: 10px;
`;

const ProductItemLabel = styled.img`
  position: absolute;
  top: -2px;
  ${right}: 20px;
  width: 50px;
`;
