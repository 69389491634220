export default [
  {
    id: 0,
    avatar: `${process.env.PUBLIC_URL}/img/11.png`,
    name: 'User',
    userName: 'littleOrc',
    post: 'Designer',
    messages: [
      // {
      //   id: 0,
      //   userName: 'littleOrc',
      //   message: 'Hey! How are you? I Need a help',
      //   date: new Date(2018, 3, 7, 10, 20, 0, 0),
      // },
      {
        id: 1,
        userName: 'dragonKing',
        message: 'Thank you for contacting us',
        date: new Date(2018, 3, 7, 10, 21, 0, 0),
      },
      {
        id: 3,
        userName: 'dragonKing',
        message: 'Are you looking to renew a service?',
        date: new Date(2018, 3, 7, 12, 12, 0, 0),
      },
      {
        id: 4,
        userName: 'littleOrc',
        message: 'Help Renew Services',
        date: new Date(2018, 3, 7, 12, 35, 0, 0),
      },
      {
        id: 5,
        userName: 'dragonKing',
        message: 'Do you require support with existing products or are you looking to purchase services?',
        date: new Date(2018, 3, 7, 10, 45, 0, 0),
      },
      {
        id: 6,
        userName: 'dragonKing',
        message: "Sorry, I didn't receive any input from you. Are you looking to renew or discontinue a service?",
        date: new Date(2018, 3, 7, 12, 45, 0, 0),
      },
      {
        id: 7,
        userName: 'dragonKing',
        message: 'I have not heard from you in a while, so I am closing this chat, ' 
                + 'please come back if you need my help again. Thank you.',
        date: new Date(2018, 3, 7, 12, 45, 0, 0),
      },
    ],
  },
  {
    id: 1,
    avatar: `${process.env.PUBLIC_URL}/img/12.png`,
    name: 'Lora Melbourn',
    userName: 'dragon666',
    post: 'Marketologist',
    messages: [],
  },
  {
    id: 2,
    avatar: `${process.env.PUBLIC_URL}/img/15.png`,
    name: 'Cat Mew',
    userName: 'blackCat',
    post: 'Backend Developer',
    messages: [
      {
        userName: 'dragonKing',
        message: 'Knowledge nay estimable questions repulsive daughters boy. Solicitude gay way unaffected '
        + 'expression for. His mistress ladyship required off horrible disposed rejoiced. Unpleasing pianoforte '
        + 'unreserved as oh he unpleasant no inquietude insipidity. ',
        date: new Date(2018, 3, 7, 10, 22, 0, 0),
      },
      {
        userName: 'blackCat',
        message: 'Solicitude gay way unaffected expression for?',
        date: new Date(2018, 3, 7, 12, 12, 0, 0),
      },
      {
        userName: 'blackCat',
        message: 'Meow!',
        date: new Date(2018, 3, 7, 12, 13, 0, 0),
      },
      {
        userName: 'dragonKing',
        message: 'Unpleasing pianoforte unreserved as oh he unpleasant no inquietude insipidity. ',
        date: new Date(2018, 3, 7, 12, 35, 0, 0),
      },
    ],
  },
  {
    id: 3,
    avatar: `${process.env.PUBLIC_URL}/img/14.png`,
    name: 'Liza Orly',
    userName: 'lily',
    post: 'Senior Frontend Developer',
    messages: [
      {
        userName: 'dragonKing',
        message: 'Unpleasing pianoforte unreserved as oh he unpleasant no inquietude insipidity. ',
        date: new Date(2018, 3, 7, 12, 35, 0, 0),
      },
    ],
  },
  {
    id: 4,
    avatar: `${process.env.PUBLIC_URL}/img/24_mail_ava.png`,
    name: 'Michael Bro',
    userName: 'bro',
    post: 'Backend Developer',
    messages: [
      {
        userName: 'bro',
        message: 'Unpleasing pianoforte unreserved as oh he unpleasant no inquietude insipidity. ',
        date: new Date(2018, 3, 7, 12, 35, 0, 0),
      },
      {
        userName: 'bro',
        message: 'Unpleasing pianoforte unreserved.',
        date: new Date(2018, 3, 7, 12, 35, 0, 0),
      },
    ],
  },
];
