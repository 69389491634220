export default {
  projectId: "81071c472fca374201396407d4cad368",
  // REACT_APP_ETHERIUM_CHAIN: 11155111,

  // REACT_APP_USDC_CONTRACT_ADDRESS: '0x0EFBfbD47d429bcad372930a50328BE847Bfeb76',
  // REACT_APP_PAX_CONTRACT_ADDRESS: '0xa8f9c88808bbaCE8db49675F63F7f4AE43b6fA28',
  // REACT_APP_USDT_CONTRACT_ADDRESS: '0x32B56a0Fd9164808132C691f7A15C7Ef04BaCD79',
  // REACT_APP_BUSD_CONTRACT_ADDRESS: '0xd9F494E4a782B61857DCb6B11Ff2cEbD81Cf4d54',
  // REACT_APP_CTY_CONTRACT_ADDRESS: '0x592Ed02b382C7d682851Ff241c016c4550E11E5a',
  // REACT_APP_EUROC_CONTRACT_ADDRESS: '0x99d6C18402009ed9945E2DA8D58B93E87e318c4b',
  // REACT_APP_GBPT_CONTRACT_ADDRESS: '0x78190373FD5CB87EB028d476253E494bc6C2a96E',
  // REACT_APP_JPYC_CONTRACT_ADDRESS: '0x07EE19796F68BdB86DCBEC96C6162D829DdEda2B',

  // testnet chain id
  // REACT_APP_SMART_CHAIN: 97,
  // REACT_APP_POLYGON_MATIC: 80001,
  // REACT_APP_BTC: 321,
  // REACT_APP_SOLANA: 123,
  // REACT_APP_ETHER: 11155111,
  // REACT_APP_TRON: 111,

  // mainnet chain id
  REACT_APP_TRON: 111,
  REACT_APP_SMART_CHAIN: 56,
  REACT_APP_POLYGON_MATIC: 137,
  REACT_APP_BTC: 321,
  REACT_APP_SOLANA: 123,
  REACT_APP_ETHER: 1,

  //  testnet contract addresses
  REACT_APP_BNB_CTY_COMISSION_ADDRESS:
    "0x592Ed02b382C7d682851Ff241c016c4550E11E5a",
  // REACT_APP_BNB_USDT_COMISSION_ADDRESS: '0x32B56a0Fd9164808132C691f7A15C7Ef04BaCD79',
  REACT_APP_BNB_USDT_COMISSION_ADDRESS:
    "0x526BDb9879eCEC5C1afBb4575a28E1a51bbA6621",
  REACT_APP_MATIC_CTY_COMISSION_ADDRESS:
    "0x24a6550CEf019ee86b669297C052942985B3d37c",
  REACT_APP_MATIC_USDT_COMISSION_ADDRES:
    "0xaaE9BcA71Eb2B03E36E9579C1C263042869d84A9",
  REACT_APP_ETH_CTY_COMISSION_ADDRESS:
    "0xa45F502576184D0A25A71A5deF18eabB82453E73",
  REACT_APP_ETH_USDT_COMISSION_ADDRESS:
    "0x03F583D56dbd5934C620DeB8CCDd59eFBfEe8CAb",

  //  mainnet contract address
  // REACT_APP_BNB_CTY_COMISSION_ADDRESS: '0xBa08Da6b46E3dd153DD8b66A6E4cfd37A6359559',
  // REACT_APP_BNB_USDT_COMISSION_ADDRESS: '0x55d398326f99059fF775485246999027B3197955',
  // REACT_APP_MATIC_CTY_COMISSION_ADDRESS: 'client sa lena ha ',
  // REACT_APP_MATIC_USDT_COMISSION_ADDRES: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  // REACT_APP_ETH_CTY_COMISSION_ADDRESS: 'client sa lena ha ',
  // REACT_APP_ETH_USDT_COMISSION_ADDRESS: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  // mainnet chain id
  // REACT_APP_ETHERIUM_CHAIN: 1,
  // REACT_APP_SMART_CHAIN: 56,
  // REACT_APP_POLYGON_MATIC: 137,

  // REACT_APP_BSC_DEFAULT_TOKEN: '0x32B56a0Fd9164808132C691f7A15C7Ef04BaCD79',
  // REACT_APP_ETH_DEFAULT_TOKEN_ADDRESS: '0x03F583D56dbd5934C620DeB8CCDd59eFBfEe8CAb',
  // REACT_APP_MATIC__DEFAULT_TOKEN_ADDRESS: '0xaaE9BcA71Eb2B03E36E9579C1C263042869d84A9',
  // REACT_APP_ETH_CONTRACT_ADDRESS: '0xf32074e01d972Ec6417308327CB6F9edbB2dD26c',
  // REACT_APP_MATIC_CONTRACT_ADDRESS: '0x4fe7BF40b8DeA7F8270dB2B9d9f0F3f13340B934',
  // REACT_APP_BSC_CONTRACT_ADDRESS: '0x048e583C9ED4c2E2835628d91b64a5Cc7E34A046',
  // REACT_APP_ADMIN_WALLET_ADDRESS_FOR_COMMISION: '0x710dcda140962b487e6934e7fa8fa6be62a5c61e',

  REACT_APP_BSC_DEFAULT_TOKEN: "0x55d398326f99059fF775485246999027B3197955",
  REACT_APP_ETH_DEFAULT_TOKEN_ADDRESS:
    "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  REACT_APP_MATIC__DEFAULT_TOKEN_ADDRESS:
    "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",

  REACT_APP_ETH_CONTRACT_ADDRESS: "0xF04531768dE244DDc3D3F8FcC565c587AA95Eb0D",
  REACT_APP_MATIC_CONTRACT_ADDRESS:
    "0xf413D45BcEc91Ed296Ad9A75257ACC43b3d812F1",
  REACT_APP_BSC_CONTRACT_ADDRESS: "0x9306bDb6f1C8F05a89B04D563A53705fe0d008F5",

  // REACT_APP_ADMIN_WALLET_ADDRESS_FOR_COMMISION: '0x970D9aE269ab5F9d9E2674De0aa826802B7Cba4d',
  REACT_APP_ADMIN_WALLET_ADDRESS_FOR_COMMISION:
    "0xD18dC3279FB8F26cEe009A46A87C217124634b49",

  REACT_APP_OPY_BSC_CONTRACT_ADDRESS:
    "0x7a656f418afc09eaF4AE8b75EAe74fE09E7A7706",
  REACT_APP_FTY_BSC_CONTRACT_ADDRESS:
    "0x7a656f418afc09eaF4AE8b75EAe74fE09E7A7706",

  REACT_APP_STORE_CREATE_FEE: "0",
  REACT_APP_MARKET_PLACE_CONTRACT_ADDRESS_BSC:
    "0x014a087b646Bd90E7DCEad3993F49EB1F4B5f30a",
  REACT_APP_NULL_WALLET_ADDRESS: "0x0000000000000000000000000000000000000000",

  REACT_APP_USDC_CONTRACT_ADDRESS: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  REACT_APP_PAX_CONTRACT_ADDRESS: "0x8E870D67F660D95d5be530380D0eC0bd388289E1",
  REACT_APP_USDT_CONTRACT_ADDRESS: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  REACT_APP_BUSD_CONTRACT_ADDRESS: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  REACT_APP_CTY_CONTRACT_ADDRESS: "0xBa08Da6b46E3dd153DD8b66A6E4cfd37A6359559",
  REACT_APP_EUROC_CONTRACT_ADDRESS:
    "0x1aBaEA1f7C830bD89Acc67eC4af516284b1bC33c",
  REACT_APP_GBPT_CONTRACT_ADDRESS: "0x86B4dBE5D203e634a12364C0e428fa242A3FbA98",
  REACT_APP_JPYC_CONTRACT_ADDRESS: "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB",
};
