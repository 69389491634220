/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */

import React from "react";
import Select from "react-select";

export const CustomSelect = ({
  isLoading,
  onChange,
  options,
  value,
  name,
  onClick,
  handleBlur,
  isDisabled,
  isSearchable,
  onInputChange,
  placeholder,
}) => {
  const style = {
    option: (provided, state) => {
      const styles = {
        ...provided,
        cursor: "pointer",
      };

      if (state.isSelected) {
        styles.color = "#fff";
        styles.backgroundColor = "#4ce1b6";
      } else if (state.isDisabled) {
        styles.color = "#A9A9A9";
        styles.backgroundColor = "#857f7f";
      } else {
        styles.color = "black";
        styles.backgroundColor = "#fff";
      }

      styles["&:hover"] = {
        backgroundColor: "#4ce1b6",
      };

      return styles;
    },
    control: (base) => ({
      ...base,
      width: "100%",
      border: "1px solid #b1b1b1 !important",
      backgroundColor: "transparent !important",
      "&:hover": {
        border: "1px solid #4ce1b6 !important",
        backgroundColor: "transparent !important",
      },
      ".isDisabled": {
        border: "1px solid #4ce1b6 !important",
        backgroundColor: "transparent !important",
      },
    }),
    input: (provided, state) => ({
      ...provided,
      color: "#b1b1b1", // Change this to the color you want
    }),
    singleValue: (provided, state) => {
      const styles = {
        ...provided,
        color: "#b1b1b1", // Change this to the color you want
      };

      return styles;
    },
  };

  // eslint-disable-next-line max-len
  const defaultValue = (selectOptions, selectValue) =>
  selectOptions
    ? selectOptions.find(
        (option) =>  option.value === selectValue
      )
    : null;
      
  return (
    <Select
      key={`my_unique_select_key__${value}`}
      isLoading={isLoading}
      value={defaultValue(options, value) || ""}
      // eslint-disable-next-line no-shadow
      onChange={onChange}
      name={name}
      options={options}
      className="selectDropdpenReact"
      onClick={onClick}
      width="100%"
      styles={style}
      minMenuHeight={100}
      maxMenuHeight={150}
      menuPlacement="bottom"
      onBlur={handleBlur}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      onInputChange={onInputChange}
      placeholder={placeholder}
    />
  );
};
export const CustomMultiSelect = ({
  defualtValue,
  onChange,
  options,
  value,
  disableField,
  onInputChange,
}) => {
  const customStyles3 = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#fff" : "black",
      backgroundColor: state.isSelected ? "#4ce1b6" : "#fff",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#4ce1b6",
        color: "#fff",
      },
    }),
    control: () => ({
      display: "flex",
      marginBottom: "-5px",
      paddingBottom: "4px",
      backgroundColor: "transparent",
      borderBottom: "1px solid #555555",
      cursor: "pointer",
    }),
    menuList: (base) => ({
      ...base,
      marginBottom: "-10px",
      marginTop: "-7px",
      height: "190px",
      overflowY: "scroll",
      "::-webkit-scrollbar": {
        width: "6px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "white",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#4ce1b6",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    input: (provided, state) => ({
      ...provided,
      color: "#b1b1b1", // Change this to the color you want
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
    multiValueLabel: (base) => ({
      ...base,
      whiteSpace: "break-spaces",
      overflow: "hidden",
      flexWrap: "break-spaces",
    }),
  };

  const filterConfig = { matchFrom: "start" };

  return (
    <Select
      defaultValue={defualtValue}
      value={value}
      onChange={(value) => onChange(value)}
      onInputChange={onInputChange}
      options={options}
      className="selectDropdpenReactMulti"
      isMulti
      styles={customStyles3}
      minMenuHeight={100}
      maxMenuHeight={150}
      isSearchable={!disableField}
      isClearable={!disableField}
      components={
        disableField
          ? {
            Menu: () => null,
            MenuList: () => null,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            MultiValueRemove: () => null,
          }
          : {}
      }
    />
  );
};
