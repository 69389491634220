/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-len */
// eslint-disable-file arrow-parens
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Brush, Legend,
} from 'recharts';
import TradingViewWidget from "./TradingViewWidget"
import Panel from '@/shared/components/Panel';
import getTooltipStyles from '@/shared/helpers';
import ErrorAlert from '@/shared/components/ErrorAlert';
import { fetchCryptoHistory } from '@/redux/actions/cryptoHistoryActions';
import { CRYPTOCURRENCIES_NAMES_COINCAP } from '@/shared/constants/cryptocurrencies';
import { DashboardAreaChartContainer } from '../../BasicDashboardComponents';
import { getDatesFromYesterdayArr } from '../../../../utils/dates';
import {
  getTransactionReport
} from '../../../../utils/services/user.service';
import { Ring } from "@uiball/loaders";
import moment from 'moment';


const brush = (theme) => {
  if (theme === 'light') {
    return '#f2f4f7';
  }
  return '#38373f';
};

const getBtcEthChartData = (cryptoHistory) => {
  if (!cryptoHistory) {
    return [];
  }
  const BTCHistory = cryptoHistory;
  // const ETHHistory = cryptoHistory?.find(item => item.currency === 'ETH');
  const showDays = cryptoHistory.length;
  const dates = getDatesFromYesterdayArr(showDays);

  const res = [];
  if (!BTCHistory) return [];

  // console.log('BTCHistory >>>>>>>>>>>>>>>>>>>> ', BTCHistory);
  // console.log('dates >>>>>>>>>>>>>>>>>>>> ', dates);
  // console.log('showDays >>>>>>>>>>>>>>>>>>>> ', showDays);

  for (let i = 0; i < showDays; i += 1) {
    const BTCHistoryPriceUsd = BTCHistory[i]?.priceUsd || 0;
    const BTCHistoryDate = BTCHistory[i]?.data || 0;
    // console.log('BTCHistoryPriceUsd >>>>>>>>>>>>>>>>>>>> ', BTCHistoryPriceUsd);
    // const ETCHistoryPriceUsd = ETHHistory?.history[i]?.priceUsd || 0;
    res.push({
      name: BTCHistoryDate,
      btc: (+BTCHistoryPriceUsd),
      // eth: (+ETCHistoryPriceUsd).toFixed(2),
    });
  }

  return res;
};

const BtcEth = ({ dir }) => {
  const dispatch = useDispatch();
  const {
    isFetching, theme, cryptoHistory, error,
  } = useSelector(state => ({
    isFetching: state.cryptoHistory.isFetching,
    theme: state.theme,
    cryptoHistory: state.cryptoHistory.data.cryptoHistory,
    error: state.cryptoHistory.error,
  }));

  const refreshData = () => {
    dispatch(fetchCryptoHistory({ currencies: CRYPTOCURRENCIES_NAMES_COINCAP }));
  };

  const [selectedItem, setSelectedItem] = useState('CTY');
  const [chartSelectedItems, setChartSelectedItem] = useState('CTY');
  const [getWeekGraphData, setWeekGraphData] = useState([]);
  const [getChartPricingData, setChartPricingData] = useState([]);
  const [priceLoading, setPriceLoading] = useState(false);
  const [lineInterval, setLineInterval] = useState(1);



  useEffect(() => {
    let isMounted = true; // track whether the component is mounted
  
    const fetchWeekGraph = async (eventKey) => {
      try {
        const requestData = {
          symbol: eventKey,
        };
        const response = await getTransactionReport(requestData);
        if (response && isMounted) {
          const Data = response.data;
          setWeekGraphData(Data.result);
        } else {
          console.error('Error fetching fetchWeekGraph: ', response);
        }
      } catch (e) {
        if (isMounted) {
          console.error('Error fetching fetchWeekGraph: ', e);
        }
      }
    };
  
    fetchWeekGraph('CTY');
  
    return () => {
      isMounted = false; // cleanup function to set isMounted to false
    };
  }, [selectedItem]);
  


  const Graphdata = [
    { date: '2023-10-02T00:00:00.000Z', priceUsd: '0', time: 1696204800000 },
    { date: '2023-10-03T00:00:00.000Z', priceUsd: '0', time: 1696204800000 },
    { date: '2023-10-04T00:00:00.000Z', priceUsd: '0', time: 1696204800000 },
    { date: '2023-10-05T00:00:00.000Z', priceUsd: '0', time: 1696204800000 },
    { date: '2023-10-06T00:00:00.000Z', priceUsd: '0', time: 1696204800000 },
    { date: '2023-10-07T00:00:00.000Z', priceUsd: '0', time: 1696204800000 },
    { date: '2023-10-08T00:00:00.000Z', priceUsd: '0', time: 1696204800000 },
    { date: '2023-10-09T00:00:00.000Z', priceUsd: '0', time: 1696204800000 },
    { date: '2023-10-10T00:00:00.000Z', priceUsd: '0', time: 1696204800000 },
    { date: '2023-10-11T00:00:00.000Z', priceUsd: '0', time: 1696204800000 },
    { date: '2023-10-12T00:00:00.000Z', priceUsd: '0', time: 1696204800000 },
    { date: '2023-10-13T00:00:00.000Z', priceUsd: '0', time: 1696204800000 },
    { date: '2023-10-14T00:00:00.000Z', priceUsd: '0', time: 1696204800000 },
    { date: '2023-10-15T00:00:00.000Z', priceUsd: '0', time: 1696204800000 },
  ];

  // Helper function to get month name based on the month number
  const getMonthName = (monthNumber) => {
    switch (monthNumber) {
      case 1:
        return 'January';
      case 2:
        return 'February';
      case 3:
        return 'March';
      case 4:
        return 'April';
      case 5:
        return 'May';
      case 6:
        return 'June';
      case 7:
        return 'July';
      case 8:
        return 'August';
      case 9:
        return 'September';
      case 10:
        return 'October';
      case 11:
        return 'November';
      case 12:
        return 'December';
      default:
        return '';
    }
  };

  let modifiedArray = [];
  if (getWeekGraphData.length > 0) {
    modifiedArray = getWeekGraphData.map(item => ({
      data: `${getMonthName(item.month)} ${item.year}`,
      priceUsd: item.transactions,
      time: 1696204800000,
    }));
  }
  let modifiedChartPricingArray = [];
  if (getChartPricingData.length > 0) {
    modifiedChartPricingArray = getChartPricingData.map(item => ({
      data: moment(item.createdAt
      ).format("DD-MM-YY"),
      priceUsd: item?.usdPrice,
      time: item?.time,
    }));
  }

  useEffect(() => {
    refreshData();
    loadCoinGeckoWidget();
  }, []);

  const loadCoinGeckoWidget = () => {
    const script = document.createElement('script');
    script.src = 'https://widgets.coingecko.com/coingecko-coin-compare-chart-widget.js';
    script.async = true;
    script.onload = () => {
      renderCoinGeckoWidget();
    };
    document.body.appendChild(script);
  };

  const renderCoinGeckoWidget = () => {
    if (window.coingeckoCoinPriceChartWidget) {
      window.coingeckoCoinPriceChartWidget.initialize({
        coinId: 'etherium',
        currency: 'usd',
        height: 300,
        locale: 'en',
      });
    }
  };

  return (
    <>

      {/* CHart for Pricing  */}
      <Panel
        isLoading={isFetching}
        xs={12}
        subhead="Trading View"
        refreshRequest={refreshData}
        className='h-[50vh] xl:h-[70vh]'
      >
        <div style={{ height: '70vh' }} dir="ltr">
          {priceLoading ? <Ring color="#00897B" /> : (

            <DashboardAreaChartContainer height="100%">
              {error ? (
                <ErrorAlert error={error} />
              ) : (
                <><TradingViewWidget /><AreaChart data={modifiedChartPricingArray.length > 0 ? getBtcEthChartData(modifiedChartPricingArray) : getBtcEthChartData(Graphdata)} margin={{ top: 20, left: -15, bottom: 20 }}>
                    <XAxis dataKey="name" tickLine={false} reversed={dir === 'rtl'} interval={lineInterval} />
                    <YAxis tickLine={false} orientation={dir === 'rtl' ? 'right' : 'left'} />
                    <Tooltip {...getTooltipStyles(theme, 'defaultItems')} />
                    <Legend />
                    <CartesianGrid />
                    <Brush dataKey="name" height={12} stroke={brush(theme)} fill={brush(theme)} />
                    <Area
                      name={chartSelectedItems}
                      type="monotone"
                      dataKey="btc"
                      fill="#4ce1b6"
                      stroke="#4ce1b6"
                      fillOpacity={0.2} />
                    {/* <Area name="ETH" type="monotone" dataKey="eth" fill="#70bbfd" stroke="#70bbfd" fillOpacity={0.2} /> */}
                  </AreaChart></>
              )}
            </DashboardAreaChartContainer>
          )}
        </div>
      </Panel>
    </>
  );
};

BtcEth.propTypes = {
  dir: PropTypes.string.isRequired,
};

export default BtcEth;
