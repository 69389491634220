import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import MetaMaskForm from '../../../containers/Dashboards/NewContract/component/MetaMaskForm';

const storeWallet = () => (
  <Container className="dashboard">
    <Row hidden>
      <Col md={12}>
        <h3 className="page-title">New Store</h3>
      </Col>
    </Row>
    <Row>
      <div className="col-12 px-5">
        <MetaMaskForm from="store" />
      </div>
      <div className="col-12">
        {/* <ContractForm /> */}
      </div>
    </Row>

  </Container>
    );

export default storeWallet;
