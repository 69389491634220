import { Modal, Spinner } from "react-bootstrap";
import { useState } from "react";
import { colorAccent } from "../../../utils/palette";
import Badge from "react-bootstrap/Badge";

import styled from "styled-components";

const OfferModal = ({ modalIsOpen, modalIsClose, data }) => {

  const [isLoad, setIsLoad] = useState(false);

  return (
    <>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalIsOpen}
        onHide={modalIsClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: "large" }}>
            Custom Offer Details
          </Modal.Title>
        </Modal.Header>
        {isLoad ? (
          <Modal.Body>
            <SpinnerDiv>
              <Spinner style={{ color: colorAccent }} />
            </SpinnerDiv>
          </Modal.Body>
        ) : (
          <Modal.Body>
            {data ? (
              <>
                <div
                  className="container mt-0 containerDiv pt-0"
                  style={{ overflowX: "hidden" }}
                >
                  <div
                    className="row mt-0 mb-2 mx-1"
                    style={{ border: "2px solid #7b7b7b", borderRadius: "5px" }}
                  >
                    <div className="col-lg-2 col-md-2 col-sm-12 text-center">
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-1"
                      >
                        <b>Category Id</b>
                      </p>
                      {data.category_id ? (
                        <p
                          style={{
                            textTransform: "capitalize",
                            color: "#7b7b7b",
                          }}
                          className="pb-1"
                        >
                          {data?.category_id}
                        </p>
                      ) : (
                        <p
                          style={{
                            textTransform: "capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          -
                        </p>
                      )}
                    </div>

                    <div className="col-lg-2 col-md-2 col-sm-12 text-center">
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-1"
                      >
                        <b>Status</b>
                      </p>
                      <Badge
                        bg={data?.offer_status === "active" ? "dark" : "danger"}
                        className="mb-2"
                        style={{
                          paddingBottom: "6px",
                          fontSize: "small",
                          fontWeight: "400",
                        }}
                      >
                        {data?.offer_status}
                      </Badge>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 text-center">
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-1"
                      >
                        <b>Symbol </b>
                      </p>
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="pb-1"
                      >
                        {data?.symbol}
                      </p>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 text-center">
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-1"
                      >
                        <b>Chain Id </b>
                      </p>
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="pb-1"
                      >
                        {data?.chain_id}
                      </p>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 text-center">
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-1"
                      >
                        <b>Delivery Charges </b>
                      </p>
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="pb-1"
                      >
                        {data?.total_delivery_charges}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-4 mb-1">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-0"
                    >
                      <b>Buyer Detail</b>
                    </p>
                  </div>
                  {data?.buyer_detail ? (
                    <div
                      className="row mt-0 mb-2 mx-1"
                      style={{
                        border: "2px solid #7b7b7b",
                        borderRadius: "5px",
                      }}
                    >
                      <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                        <p
                          style={{
                            textTransform: "capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          <b>Buyer Name </b>
                        </p>

                        <p
                          style={{
                            textTransform: "capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          {data?.buyer_detail[0]?.name || "-"}
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                        <p style={{ color: "#7b7b7b" }} className="py-1">
                          <b>Buyer Email </b>
                        </p>

                        <p style={{ color: "#7b7b7b" }} className="py-1">
                          {data?.buyer_detail[0]?.email || "-"}
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                        <p
                          style={{
                            textTransform: "capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          <b>Buyer Id</b>
                        </p>

                        <p
                          style={{
                            textTransform: "capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          {data?.buyer_detail[0]?._id || "-"}
                        </p>
                      </div>
                    </div>
                  ) : null}
                  <div className="row mt-4 mb-1">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-0"
                    >
                      <b>Seller Detail</b>
                    </p>
                  </div>
                  {data?.seller_wallet ? (
                    <div
                      className="row mt-0 mb-2 mx-1"
                      style={{
                        border: "2px solid #7b7b7b",
                        borderRadius: "5px",
                      }}
                    >
                      <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                        <p
                          style={{
                            textTransform: "capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          <b>Seller Name </b>
                        </p>

                        <p
                          style={{
                            textTransform: "capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          {data?.seller_wallet[0]?.name || "-"}
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                        <p style={{ color: "#7b7b7b" }} className="py-1">
                          <b>Seller Email Address </b>
                        </p>

                        <p style={{ color: "#7b7b7b" }} className="py-1">
                          {data?.seller_wallet[0]?.email || "-"}
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                        <p style={{ color: "#7b7b7b" }} className="py-1">
                          <b>Seller ID </b>
                        </p>

                        <p style={{ color: "#7b7b7b" }} className="py-1">
                          {data?.seller_wallet[0]?._id || "-"}
                        </p>
                      </div>
                    </div>
                  ) : null}
                  {data.product_details ? (
                    <>
                      <div className="row mt-4 mb-1">
                        <p
                          style={{
                            textTransform: "capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-0"
                        >
                          <b>Approver Detail</b>
                        </p>
                      </div>
                      {data.product_details?.map((data1, index) => (
                        <div
                          className="row mt-0 mb-2 mx-1"
                          style={{
                            border: "2px solid #7b7b7b",
                            borderRadius: "5px",
                          }}
                        >
                          <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="py-1"
                            >
                              <b>Detail Id </b>
                            </p>

                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="pb-1"
                            >
                              {data1?.detail_objectId || "-"}
                            </p>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                            <p style={{ color: "#7b7b7b" }} className="py-1">
                              <b> Title </b>
                            </p>

                            <p style={{ color: "#7b7b7b" }} className="pb-1">
                              {data1?.title}
                            </p>
                          </div>

                          <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="py-1"
                            >
                              <b> Price </b>
                            </p>

                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="py-1"
                            >
                              {data1?.price}
                            </p>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="py-1"
                            >
                              <b> Quantity </b>
                            </p>

                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="py-1"
                            >
                              {data1?.quantity}
                            </p>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}
                  {data.store_detail ? (
                    <>
                      <div className="row mt-4 mb-1">
                        <p
                          style={{
                            textTransform: "capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-0"
                        >
                          <b>Store Detail</b>
                        </p>
                      </div>
                      {data.store_detail?.map((data1, index) => (
                        <div
                          className="row mt-0 mb-2 mx-1"
                          style={{
                            border: "2px solid #7b7b7b",
                            borderRadius: "5px",
                          }}
                        >
                          <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="py-1"
                            >
                              <b>Store Id </b>
                            </p>

                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="pb-1"
                            >
                              {data1?._id || "-"}
                            </p>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                            <p style={{ color: "#7b7b7b" }} className="py-1">
                              <b> Store Name </b>
                            </p>

                            <p style={{ color: "#7b7b7b" }} className="pb-1">
                              {data1?.storeName || "-"}
                            </p>
                          </div>

                          <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="py-1"
                            >
                              <b> Store Address </b>
                            </p>

                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="py-1"
                            >
                              {data1?.storeAddress || "-"}
                            </p>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}

                  {data.buyer_approver_detail ? (
                    <>
                      <div className="row mt-4 mb-1">
                        <p
                          style={{
                            textTransform: "capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-0"
                        >
                          <b>Buyer Approver Details</b>
                        </p>
                      </div>
                      {data.buyer_approver_detail?.map((data1, index) => (
                        <div
                          className="row mt-0 mb-2 mx-1"
                          style={{
                            border: "2px solid #7b7b7b",
                            borderRadius: "5px",
                          }}
                        >
                          <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="py-1"
                            >
                              <b>Store Id </b>
                            </p>

                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="pb-1"
                            >
                              {data1?._id || "-"}
                            </p>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                            <p style={{ color: "#7b7b7b" }} className="py-1">
                              <b> Store Name </b>
                            </p>

                            <p style={{ color: "#7b7b7b" }} className="pb-1">
                              {data1?.storeName || "-"}
                            </p>
                          </div>

                          <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="py-1"
                            >
                              <b> Store Address </b>
                            </p>

                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="py-1"
                            >
                              {data1?.storeAddress || "-"}
                            </p>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}

                  <hr
                    hidden
                    style={{ border: "1px solid #b3b3b3", opacity: "1.1" }}
                  />
                </div>
                <button type="button" hidden>
                  Submit Ratings
                </button>
              </>
            ) : (
              <div className="row mt-0 mb-2 mx-1">
                <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                  <p
                    style={{
                      textTransform: "capitalize",
                      color: "#7b7b7b",
                      fontSize: "x-large",
                      fontWeight: "500",
                    }}
                    className="py-1"
                  >
                    Detail Not Found
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 text-center" />

                <div className="col-lg-4 col-md-4 col-sm-12 text-center" />
              </div>
            )}
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};
export default OfferModal;
export const SpinnerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margintop: 0.5rem;
  height: 9rem;
`;
