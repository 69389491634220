import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ContractForm from './component/ContractForm';
import MetaMaskForm from './component/MetaMaskForm';

const NewContractPage = () => (
  <Container className="dashboard">
    <Row hidden>
      <Col md={12}>
        <h3 className="page-title">New Contract</h3>
      </Col>
    </Row>
    <Row>
      <div className="col-12">
        <MetaMaskForm accessType="contract" />
      </div>
      <div className="col-12">
        {/* <ContractForm /> */}
      </div>
    </Row>

  </Container>
);

export default NewContractPage;
