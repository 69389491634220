/* eslint-disable no-plusplus */
import React from 'react';

const ReviewStarRating = ({ rating }) => {
  const starStyle = {
    color: 'gold', // Set the star color to gold
    fontSize: '35px', // Adjust the font size as needed
  };
  let totalHalfStarts = 0;

  // If rating is zero or undefined, display "Not rated yet"
  if (rating === 0 || rating === undefined) {
    const stars1 = [];
    // return <div>Not rated yet</div>;
    for (let i = 0; i < 5; i++) {
      stars1.push(<span key={i} style={starStyle}>&#9734;</span>); // Half star (☆★)
    }
    return <div style={{ display: 'inline-block' }}>{stars1}</div>;
  }

  // Calculate the number of full stars and half stars
  const fullStars = Math.floor(rating);
  const halfStars = Math.ceil(rating - fullStars);
  totalHalfStarts = fullStars - halfStars;
  if (totalHalfStarts !== 0) {
    // console.log('If');
    // eslint-disable-next-line no-const-assign
    totalHalfStarts = 5 - fullStars;
  } else {
    totalHalfStarts = fullStars - halfStars;
  }

  // // Define inline styles for golden stars
  // const starStyle = {
  //   color: 'gold', // Set the star color to gold
  //   fontSize: '18px', // Adjust the font size as needed
  // };

  // Create an array of stars with inline styles
  const stars = [];
  for (let i = 0; i < fullStars; i++) {
    stars.push(<span key={i} style={starStyle}>&#9733;</span>); // Full star (★)
  }
  for (let j = 0; j < totalHalfStarts; j++) {
    stars.push(<span key={j} style={starStyle}>&#9734;</span>); // Half star (☆★)
  }
  // if (halfStars === 1) {
  //   stars.push(<span key="half" style={starStyle}>&#9734;</span>); // Half star (☆★)
  // }

  return <div style={{ display: 'inline-block' }}>{stars}</div>;
};

export default ReviewStarRating;

