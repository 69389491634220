import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { right, left, marginRight } from '@/utils/directions';
import { CRYPTOCURRENCIES_NAMES_COINCAP } from '@/shared/constants/cryptocurrencies';
import { fetchTopTenCurrencies } from '@/redux/actions/topTenActions';

const Token = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const whitepaper = `${process.env.PUBLIC_URL}/img/logo/whitepaper.png`;

  useEffect(() => {
    dispatch(fetchTopTenCurrencies());
  }, [dispatch]);


  return (
    <Container>
      <Row>
        <Col xs={12} md={12} lg={12} className="text-center">
          <h3 className="mx-3 mb-4">Token</h3>
        </Col>
      </Row>
      <Row className="mx-0">
        <Col xs={12} md={4} lg={4} className="text-center">
          <div className="card productCard py-5 px-5" style={{ background: '#232329' }}>
            <h3>Contract Token (V1)</h3>
            <br />
            <br />
            <p>Contract 0x188173379ac8963048afe01c5d3d5998fee67254</p>
            <a 
              href="https://bscscan.com/address/0x188173379ac8963048afe01c5d3d5998fee67254"
              target="_blank" 
              rel="noreferrer" 
              className="btn btn-primary purple-button btn-sm shadow-none mb-2 mt-2 py-2 text-white"
            >
              Contract
            </a>
          </div>
        </Col>
        <Col xs={12} md={4} lg={4} className="text-center">
          <div className="card productCard py-5 px-5" style={{ background: '#232329' }}>
            <h3>Contract Token (V2)</h3>
            <br />
            <br />
            <p>Contract 0x5cf1661a741b7f051214b097f84f2e5b11845018</p>
            <a 
              href="https://bscscan.com/token/0x5cf1661a741b7f051214b097f84f2e5b11845018#balances"
              target="_blank" 
              rel="noreferrer" 
              className="btn btn-primary purple-button btn-sm shadow-none mb-2 mt-2 py-2 text-white"
            >
              Contract
            </a>
          </div>
        </Col>
        <Col xs={12} md={4} lg={4} className="text-center">
          <div className="card productCard py-5 px-5" style={{ background: '#232329' }}>
            <h3>Contract Token (V3)</h3>
            <br />
            <br />
            <p>Contract 0xba08da6b46e3dd153dd8b66a6e4cfd37a6359559</p>
            <a 
              href="https://bscscan.com/address/0xba08da6b46e3dd153dd8b66a6e4cfd37a6359559"
              target="_blank" 
              rel="noreferrer" 
              className="btn btn-primary purple-button btn-sm shadow-none mb-2 mt-2 py-2 text-white"
            >
              Contract
            </a>
          </div>
        </Col>
        <Col xs={12} md={4} lg={4} className="text-center">
          <div className="card productCard py-5 px-5" style={{ background: '#232329' }}>
            <h3>Migration Details</h3>
            <br />
            <a 
              href="https://dashboard.custodiy.com/assets/file/migration_v3.pdf"
              target="_blank" 
              rel="noreferrer" 
              className="btn btn-primary purple-button btn-sm shadow-none mb-2 mt-2 py-2 text-white"
            >
              Show
            </a>
          </div>
        </Col>
        <Col xs={12} md={4} lg={4} className="text-center">
          <div className="card productCard py-5 px-5" style={{ background: '#232329' }}>
            <h3>Audit Contract</h3>
            <br />
            <a 
              href="https://github.com/blocksafu111/audit/blob/main/CUstodiy-Audit-by-BlockSAFU.pdf"
              target="_blank" 
              rel="noreferrer" 
              className="btn btn-primary purple-button btn-sm shadow-none mb-2 mt-2 py-2 text-white"
            >
              Show
            </a>
          </div>
        </Col>
        <Col xs={12} md={4} lg={4} className="text-center">
          <div className="card productCard py-5 px-5" style={{ background: '#232329' }}>
            <h3>Holders</h3>
            <br />
            <a 
              href="https://bscscan.com/token/0x5cf1661a741b7f051214b097f84f2e5b11845018#balances"
              target="_blank" 
              rel="noreferrer" 
              className="btn btn-primary purple-button btn-sm shadow-none mb-2 mt-2 py-2 text-white"
            >
              Holders
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Token;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const Error = styled.div`
 
color: #e66e6e;
padding: 4px 0px;
font-size: 14px;
cursor:none;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;

const ProductItemLabel = styled.img`
  border-radius: '10%'
`;

const ProductItemLabel1 = styled.img`
  position: absolute;
  top: -10px;
  ${right}: 5px;
  width: 65px;
`;
