import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import Calendar from '@/shared/components/Calendar';
import {
  Card, CardBody,
} from '@/shared/components/Card';
import { fetchCalendarEvents } from '../../../DefaultPage/Calendar/components/events';
import { useDispatch, useSelector } from 'react-redux';

const BigCalendar = ({ dir }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCalendarEvents());
  }, []);

  const { calendarEvents } = useSelector(state => ({
    calendarEvents: state.calendarEvents.data,
  }));

  const MyCalendar = useMemo(() => {
    return <Calendar events={calendarEvents} dir={dir} />;
  }, [calendarEvents]);


  return (
    <Col md={12} lg={12} xl={9}>
      <Card>
        <CardBody>
          {MyCalendar} 
        </CardBody>
      </Card>
    </Col>
  );
};

BigCalendar.propTypes = {
  dir: PropTypes.string.isRequired,
};

export default BigCalendar;
