/* eslint-disable no-inner-declarations */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react';
import { PDFDocument, rgb } from 'pdf-lib';
import SignaturePad from './SignaturePad';

const PdfSignature = ({ pdfFile, signatureImage, getPDf }) => {
  const [signedPdf, setSignedPdf] = useState(null);
  const [signedImg, setSignedImg] = useState(null);
  // console.log('signatureImage', signatureImage);
  // setSignedImg(signatureImage);
  const Img = signatureImage;

  const addSignatureToPdf = async () => {
    try {
      const pdfArrayBuffer = await readFileAsArrayBuffer(pdfFile);
      const signatureArrayBuffer = await readFileAsArrayBuffer(Img);

      const pdfDoc = await PDFDocument.load(pdfArrayBuffer);
      const signatureImage = await pdfDoc.embedPng(signatureArrayBuffer);

      const pages = pdfDoc.getPages();
      const firstPage = pages[0];

      const { width, height } = firstPage.getSize();
      const signatureImageDims = signatureImage.scale(0.2);

      const { width: imgWidth, height: imgHeight } = signatureImageDims;
      const position = { x: width - imgWidth - 50, y: 50 };

      firstPage.drawImage(signatureImage, {
        ...position,
        width: imgWidth,
        height: imgHeight,
        opacity: 0.8,
      });

      const modifiedPdfBytes = await pdfDoc.save();

      // Create a new File object with the modified PDF bytes
      const modifiedPdfFile = new File([modifiedPdfBytes], 'modified.pdf', { type: 'application/pdf' });


      const jsonString1 = JSON.stringify(modifiedPdfBytes);

      function bufferArrayToBase64(bufferArray) {
        let binary = '';
        const bytes = new Uint8Array(bufferArray);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
      }
      const base64String = bufferArrayToBase64(modifiedPdfBytes);
      const jsonObject = {
        pdfData: base64String,
      };
      const jsonString = JSON.stringify(jsonObject);

      getPDf(modifiedPdfFile);
      setSignedPdf(URL.createObjectURL(modifiedPdfFile));
    } catch (error) {
      console.error('Error adding signature to PDF:', error);
    }
  };


  const readFileAsArrayBuffer = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(new Uint8Array(reader.result));
    reader.onerror = error => reject(error);
    reader.readAsArrayBuffer(file);
  });

  return (
    <div>
      <div className="row">
        <div className="col-12 my-1 px-3 text-center">
          <a
            onClick={addSignatureToPdf}
            style={{ fontWeight: '500' }}
            className="btn btn-success btn-sm text-white btn-block w-100 ms-auto shadow-none text-capitalize"
          >
            Add Signature
          </a>
        </div>
        {signedPdf
          && (
            <div className="col-12 my-1 px-3 text-center">
              <a
                href={signedPdf}
                target="_blank"
                rel="noreferrer"
                style={{ fontWeight: '500' }}
                className="btn purple-button text-white btn-block w-100 btn-sm ms-auto shadow-none text-capitalize"
              >
                View Signatured Document <i style={{ marginLeft: '4px', marginRight: '-1px', color: 'white' }} className="fas fa-file-pdf" />
              </a>
              {/* <p className="text-dark">If you want change Signature then click on <b>Clear Signature</b></p> */}
            </div>
          )}
      </div>
      {/* {signedPdf && <iframe title="Signed PDF" src={signedPdf} width="100%" height="600px" />} */}
    </div>
  );
};

export default PdfSignature;
