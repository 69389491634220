import React from "react";

const InfoItem = ({ label, value }) => (
  <div className="col-lg-3 col-md-12 col-sm-4">
    <p className="py-1">
      <b style={{ fontWeight: "600" }}>{label}</b>
    </p>
    <h5
      style={{
        textTransform: "capitalize",
        fontWeight: "400",
      }}
      className="currentPrice py-2 mb-2"
    >
      {value}
    </h5>
  </div>
);
export default InfoItem;
