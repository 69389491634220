/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { 
  Spinner, Modal as BootstrapModal, Col, Container, Row,
} from 'react-bootstrap';
import { RxCross2 } from 'react-icons/rx';

import EyeIcon from 'mdi-react/EyeIcon';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import {
  flexFlow, paddingLeft, paddingRight, 
} from '@/utils/directions';
import { ButtonToolbar } from '@/shared/components/Button';
import Panel from '@/shared/components/Panel';
import {
getSafebox, getSafeboxDetail, safeboxApprove, safeboxReject, 
} from '../../../../../utils/services/user.service';
import {
 colorAccent,
  colorBackground,
  colorIcon,
  colorText,
  colorWhite,
} from '../../../../../utils/palette';
import { NoResult } from '../../Contract/component/ContractTable';
import { TwoFactorSubTitle } from '../../../../Account/MyAccount';
import { CustomSelect, CustomMultiSelect } from '../../../../../shared/components/CustomSelect';
import ReactTableBase from '../../../../../shared/components/table/ReactTableBase';

const header = [
  { accessor: 'name', Header: 'Name' },
  { accessor: 'date', Header: 'Date', width: 40 },
  { accessor: 'type_of_contract', Header: 'Type of Contract' },
  { accessor: 'status', Header: 'Status' },
  { accessor: 'approver', Header: 'Approver' },
  { accessor: 'hash', Header: 'Hash', disableGlobalFilter: true },
  {
 accessor: 'action', Header: 'Action', disableGlobalFilter: true, width: 140, 
},

];


const SafeBoxTable = ({ title, viewAll, detailShow }) => {
  const [safeboxData, setsafeboxData] = useState([]);
  const [safeboxData1, setsafeboxData1] = useState([]);
  const [loading, setLoading] = useState(false);
const getSafeDetail = () => {
  setLoading(true);
  getSafebox().then((res) => {
    setsafeboxData(res.data.data);
    setsafeboxData1(res.data.data);
    setLoading(false);
  }).catch(() => {
    setsafeboxData([]);
    setLoading(false);
  });
};
  useEffect(() => {
    setLoading(true);
    getSafeDetail();
  }, []);
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  const [modal, setModal] = useState(false);
  const [detail, setDetail] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [otpModal, setotpModal] = useState(false);
  const [otpLoad, setOtpLoad] = useState(false);
  const [Msg, setMsg] = useState(undefined);
  const [status, setstatus] = useState(400);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState('');
  const [req, setReq] = useState('');
  const handleOrderStatusChange = (value) => {
    if (value.value === 'All') {
      setSelectedOrderStatus(value.value);
      setsafeboxData(safeboxData1);
    } else {
      const completedTransactions = safeboxData1.filter(transaction => transaction.status === value.value);
      setSelectedOrderStatus(value.value);
      setsafeboxData(completedTransactions);
    }
  };
  const optionOrderStatus = [
    { value: 'All', label: 'All' },
    { value: 'Completed', label: 'Completed' },
    { value: 'pending', label: 'Pending' },
    { value: 'Rejected', label: 'Rejected' },
    { value: 'Waiting', label: 'Waiting' },
    { value: 'Declined', label: 'Declined' },
    { value: 'Failed', label: 'Failed' },
  ];
  const toggle = () => {
    setModal(prevState => !prevState);
  };
  const toggleOtp = () => {
    setotpModal(prevState => !prevState);
  };
  const getDetail = (id) => {
    setIsLoad(true);
    toggle();
    getSafeboxDetail(id).then((res) => {
      setIsLoad(false);
      setDetail(res.data.data);
    }).catch((error) => {
      setstatus(400);
      setIsLoad(false);
      setDetail(error.response.data.message);
    });
  };
  const getOtp = (id) => {
    setOtpLoad(true);
    toggleOtp();
   safeboxApprove(id)
      .then(
        (res) => {
          setstatus(200);
          setOtpLoad(false);
          setMsg(res.data.message);
          getSafeDetail();
          setTimeout(() => {
            setMsg('');
            toggleOtp();
          }, 3000);
        },
        (error) => {
          const message = (error.response
            && error.response.data
            && error.response.data.message)
            || error.message
            || error.toString();
          setstatus(400);
          if (error.response.data.status === 400) {
            setOtpLoad(false);
            setMsg(error.response.data.Error);
            setTimeout(() => {
              setMsg('');
              toggleOtp();
            }, 3000);
          } else {
            setOtpLoad(false);
            setMsg(message);
            setTimeout(() => {
              setMsg('');
              toggleOtp();
            }, 3000);
          }

          // return Promise.reject();
        },
      )
      .catch((err) => {
        setstatus(400);
        setOtpLoad(false);
        setMsg(err.data.message);
      });
  };
  const getReject = (id) => {
    setOtpLoad(true);
    toggleOtp();
   safeboxReject(id)
      .then(
        (res) => {
          setstatus(200);
          setOtpLoad(false);
          setMsg(res.data.message);
          getSafeDetail();
          setTimeout(() => {
            setMsg('');
            toggleOtp();
          }, 3000);
        },
        (error) => {
          const message = (error.response
            && error.response.data
            && error.response.data.message)
            || error.message
            || error.toString();
          setstatus(400);
          if (error.response.data.status === 400) {
            setOtpLoad(false);
            setMsg(error.response.data.Error);
            setTimeout(() => {
              setMsg('');
              toggleOtp();
            }, 3000);
          } else {
            setOtpLoad(false);
            setMsg(message);
            setTimeout(() => {
              setMsg('');
              toggleOtp();
            }, 3000);
          }

          // return Promise.reject();
        },
      )
      .catch((err) => {
        setstatus(400);
        setOtpLoad(false);
        setMsg(err.data.message);
      });
  };
  const user = JSON.parse(localStorage.getItem('user'));
  const tableConfig = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    isPagination: true,
  };
  const tableConfi2 = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    isPagination: false,
  };
  const data = [];
  safeboxData.map(item => data.push({
       name: item.name,
       date: item.date,
       hash: (<HashLink
         role="link"
         onClick={() => openInNewTab(`https://bscscan.com/tx/${item.hash[0]}`)}
       > https://bscscan.com/tx/{item.hash[0]?.substring(0, 4)}...
       </HashLink>
             ),
       type_of_contract: 'Safebox',
       status: item.status,
       approver: item.approvers[0]?.approver?.name,
       action: (
        item.status === 'Waiting'
       ? (
         item.current_user_role === 'approver'
         ? (
           item.approvers.filter(i => i.approver.address === user.data.user.address).some(i => i.status === 'Waiting')

           ? (
             <>
               {item.type !== 'external_wallet' || item.type !== 'external_wallet_test' ? (
                 <HashLink
                   onClick={() => getDetail(item._id)}
                 ><EyeIcon style={{ padding: '2px' }} />
                 </HashLink>
               ) : ('')}
               <HashLink onClick={() => { getOtp(item._id); setReq('Accept'); }}><AiOutlineCheckCircle style={{ padding: '2px', fontSize: '20px' }} />
               </HashLink>
               <HashLink onClick={() => { getReject(item._id); setReq('Reject'); }}>
                 <RxCross2 style={{ padding: '2px', fontSize: '15px', color: 'red' }} />
               </HashLink>
             </>
)
            : (
              item.type !== 'external_wallet' || item.type !== 'external_wallet_test' ? (
                <HashLink onClick={() => getDetail(item._id)}><EyeIcon style={{ padding: '2px' }} />
                </HashLink>
              ) : ('')
)

         ) : (
          item.type !== 'external_wallet' || item.type !== 'external_wallet_test' ? (
            <HashLink onClick={() => getDetail(item._id)}><EyeIcon style={{ padding: '2px' }} />
            </HashLink>
          ) : ('')
         )
       ) : (
        item.type !== 'external_wallet' || item.type !== 'external_wallet_test' ? (
          <HashLink onClick={() => getDetail(item._id)}><EyeIcon style={{ padding: '2px' }} />
          </HashLink>
        ) : ('')
       )
       ),
      }));
  return (
    <>
      <Panel lg={12} title={title} closeBtn minusBtn viewAll={viewAll} refreshRequest={() => getSafeDetail()} refresh={loading}>
        <>
          <Row className="mb-0">
            <Col xs={12} md={4} lg={4} />
            <Col xs={12} md={4} lg={4} />
            <Col xs={12} md={4} lg={4}>
              <label htmlFor="features" className="form-label text-white mb-0">Status</label>
              <InputDiv className="px-0">
                <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                  <CustomSelect
                    name="size"
                    id="size"
                    className="w-25 px-0"
                    value={selectedOrderStatus}
                    onChange={handleOrderStatusChange}
                    options={optionOrderStatus}
                  />  
                </DropdownDiv>
              </InputDiv>
            </Col>
          </Row>
          <ReactTableBase
            columns={header}
            data={detailShow ? data.slice(0, 3) : data}
            tableConfig={detailShow ? tableConfi2 : tableConfig}
          />
          {data.length === 0 && (
          <NoResult>(0) Results! Data Not Found
          </NoResult>
          )}
        </>
      </Panel>
      <StyledModal
        show={modal}
        onHide={toggle}
        
      >
        <ModalHeader>
          <ModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={toggle}
          />
          <ModalTitle>SafeBox Detail</ModalTitle>
        </ModalHeader>
        {
          isLoad ? (
            <ModalBody>
              <SpinnerDiv>
                <Spinner style={{ color: colorAccent }} />
              </SpinnerDiv>
            </ModalBody>
          )
          : (
                typeof detail === 'string' ? (
                  <NoResult>{detail}
                  </NoResult>
                 ) : detail?.length === 0 ? (
                   <ModalBody>
                     <div style={{
                      display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '.5rem',
                    }}
                     >No Data Found
                     </div>
                   </ModalBody>
                    ) : (
                      <ModalBody>
                        <ModalMainDiv>
                        
                          <ModalSubTitle>Name:</ModalSubTitle>
                          <ModalDetail>{detail?.name}</ModalDetail>
                        </ModalMainDiv>
                        <ModalMainDiv>
                          <ModalSubTitle>Owner Name:</ModalSubTitle>
                          <ModalDetail>{detail.owner?.name}</ModalDetail>
                        </ModalMainDiv>
                        <ModalMainDiv> <ModalSubTitle>Status:</ModalSubTitle>
                          <ModalDetail>{detail?.status}</ModalDetail>
                        </ModalMainDiv>

                        
                        
                        <ModalMainDiv>
                          <ModalSubTitle>Date:</ModalSubTitle>
                          <ModalDetail>{detail?.date}</ModalDetail>
                        </ModalMainDiv>
                        <ModalMainDiv>
                          <ModalSubTitle>Role</ModalSubTitle>
                          <ModalDetail>{detail.current_user_role}</ModalDetail>
                        </ModalMainDiv>                        
              
                        <ModalMainDiv>
                          <ModalSubTitle>Hash:</ModalSubTitle>
                          <div style={{ 
    wordWrap: 'break-word',
    width: '305px',
    fontSize: '13px',
    // marginRight: '1rem', 
}}
                          >
                            {detail.hash.map(i => (
                              <HashLink
                                role="link"
                                onClick={() => {
                   openInNewTab(`https://bscscan.com/tx/${i}`);
                }}
                              >
                                <div style={{
                                wordWrap: 'break-word',
                                width: '288px',
                                fontSize: '13px',
                              }}
                                >
                                  {`https://bscscan.com/tx/${i.substring(0, 20)}...\n`}

                                </div>
                              </HashLink>
                ))}
            
                          </div>
                        </ModalMainDiv>
                        <ModalMainDiv>
                          <ModalSubTitle>Approver</ModalSubTitle>
                  
                          {detail.approvers.map(i => (
                            // <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
                            <ModalDetail style={{ fontSize: '14px' }}>{i.approver?.name ? i.approver?.name : '  -  '}</ModalDetail>
                            // </div>
))}
                        </ModalMainDiv>
                        <ModalMainDiv>
                          <ModalSubTitle>Files:</ModalSubTitle>
                          
                          {detail.files.map(i => (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <HashLink
                                style={{ padding: '0px' }}
                                role="link"
                                onClick={() => {
openInNewTab(i);
}}
                              >
                                Click here to view 
                              </HashLink>
                            </div>
                          
))}
                         
                 
                        </ModalMainDiv>
                  
             


                      </ModalBody>
                 )  
)
}
       
      </StyledModal>
      <StyledModal
        show={otpModal}
        onHide={toggleOtp}
      >
        <ModalHeader>
          {/* <ModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={() => { toggleOtp(); setMsg(undefined); }}
          /> */}
          <ModalTitle>Safebox {req}</ModalTitle>
        </ModalHeader>
        {
          otpLoad ? (
            <ModalBody>
              <SpinnerDiv>
                <Spinner style={{ color: colorAccent }} />
              </SpinnerDiv>
            </ModalBody>
          )
            : (
              <ModalDivs>
                {status === 200
               ? <span className="lnr lnr-checkmark-circle" style={{ color: colorAccent, fontSize: '55px' }} />
               : (
                 <span className="lnr lnr-circle-minus" style={{ color: 'red', fontSize: '55px' }} />
               )}
                <TwoFactorSubTitle style={{ color: status === 200 ? '#c1f9c1' : 'red' }}>
                  {Msg}
                </TwoFactorSubTitle>
              </ModalDivs>
            )
        }


      </StyledModal>
    </>
  );
};
export default SafeBoxTable;
const HashLink = styled.button`
 border: none; 
 background: transparent; 
 color: #4ce1b6;
 `;
 const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }
  
  flex-flow: ${flexFlow} !important;
`;

const ModalTitle = styled.h4`
  background: #4ce1b6;
  padding: 5px;
  color: #232329;
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size:20px;
  &:first-child {
    margin-top: 0;
  }
`;
export const ModalMainDiv = styled.div`
display: flex;
justify-content: left;
width: 100%;
overflow-wrap: break-word;
align-items: baseline;
`;
export const ModalDetail = styled.h5`
width:300px;
margin-bottom: 10px;
font-size:16px;
font-weight: 700;
text-align:left;
word-wrap: break-word;
&:first-child {
  margin - top: 0;
}
`;
export const ModalSubTitle = styled.h4`
width: 150px;
margin-top: 10px;
font-weight: 600;
font-size:16px;
text-align:left;
word-wrap: break-word;
color:${colorAccent};
&:first-child {
  margin - top: 0;
}
`;
const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`;

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`;

const ModalHeader = styled.div``;
const ModalBody = styled.div``;

const getColor = (color) => {
  switch (color) {
    case 'success': 
    return '#22bb33';
      case 'danger': 
      return '#ff4861';
    default:
      return '#f88e7d';
  }
};
 const StyledModal = styled(BootstrapModal).withConfig({
  shouldForwardProp: prop => !['colored', 'header'].includes(prop),
})`
  
  .modal-dialog {
    max-width: 510px;
  }

  .modal-content {
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }
  
  ${ModalTitleIcon} {
    color: ${props => getColor(props.color)};
  }

  ${props => props.colored && `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${getColor(props.color)};
    }
  `}

  ${props => props.header && `
    
    .modal-dialog {
      max-width: 520px;
    }

    .modal-content {
      padding: 0;
      text-align: left};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 40px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${getColor(props.color)};
    }
  `}
`;
const SpinnerDiv = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
marginTop: .5rem;
height: 9rem;
`;
const ModalDivs = styled.div`
height: 10rem;
justify-content: space-between;
display: flex;
align-items: center;
flex-direction: column;
`;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;  
  }
`;
