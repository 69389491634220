import React, { useState } from "react";
import { Badge, Col } from "react-bootstrap";
import styled from "styled-components";
import MessageTextOutlineIcon from "mdi-react/MessageTextOutlineIcon";
import { Card } from "@/shared/components/Card";
import { Button } from "@/shared/components/Button";
import { colorAdditional, colorBlue, colorBorder } from "@/utils/palette";
import { paddingLeft, left } from "@/utils/directions";
import { ProfileCard } from "./ProfileBasicComponents";
import BuyPackageModal from "./BuyPackageModal";
import numeral from "numeral";

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;

const PackageCard = ({ cardData, onClick, isLoading, hide, isDisbale }) => {
  let priceSymbol, secondSymbol;

  if (cardData?.pool?.length > 0 && cardData.pool[0]?.pool) {
      [priceSymbol, secondSymbol] = cardData.pool[0].pool.split("-");
  } else if (cardData?.new_pool) {
      [priceSymbol, secondSymbol] = cardData.new_pool.split("-");
  }
    const [show, setShow] = useState(false);

  return (
    <>
      <Card>
        <ProfileCard>
          <ProfileInformation>
            <ProfileData>
              <ProfileName>{cardData?.package_name}</ProfileName>
              <ProfileWork className="py-1">{cardData?.package_description}</ProfileWork>
              <ProfileContact>
                <div className="d-lg-flex  text-uppercase justify-content-between align-items-center ">
                  <h5 className="d-flex gap-1">Price <span className="d-flex" style={{
                    fontSize:'8px'
                  }}>(you pay)</span>:</h5>
                  <h5>{`${numeral(cardData.price).format('0,0.000')} ${priceSymbol === "SOLANA" ? "SOL" : priceSymbol}`}</h5>

                </div>
              </ProfileContact>
              {cardData.unlock_days && <ProfileContact>
              <div className="d-flex text-uppercase justify-content-between align-items-center ">
                <h5>Unlock Days:</h5>
                <h5>{`${cardData.unlock_days}`}</h5>
              </div>
            </ProfileContact>}
              <ProfileContact dir="ltr">
                <div className="d-lg-flex text-uppercase justify-content-between align-items-center ">
                  <h5 className="d-flex gap-1">token <span className="d-flex" style={{
                    fontSize:'8px'
                  }}>(you get)</span>:</h5>
                  <h5>{`${numeral(cardData.token).format('0,0.000')} ${secondSymbol}`}</h5>
                </div>
              </ProfileContact>
              <ProfileContact dir="ltr">
                <div className="d-lg-flex text-uppercase justify-content-between align-items-center ">
                  <h5> package status:</h5>
                  <h5
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "400",
                    }}
                    className="currentPrice"
                  >
                    <Badge
                      bg={
                        cardData.package_status === "pending"
                          ? "warning"
                          : cardData.package_status === "active"
                          ? "success"
                          : cardData.package_status === "inactive"
                          ? "danger"
                          : "primary"
                      }
                    >
                      {cardData.package_status}{" "}
                    </Badge>
                  </h5>
                </div>
              </ProfileContact>
              <FormButton
                type="submit"
                variant="outline-success"
                className="py-2 mt-2 w-100"
                onClick={() => setShow(true)}
                disabled={isDisbale == "inactive"}
              >
                Buy Package
              </FormButton>
            </ProfileData>
          </ProfileInformation>
          <ProfileStats></ProfileStats>
        </ProfileCard>
      </Card>

      {show && (
        <BuyPackageModal
          show={show}
          hide={() => {
            setShow(false);
          }}
          data={cardData}
          onClick={() => {
            onClick();
            setShow(false);
          }}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default PackageCard;
// region STYLES
const ProfileInformation = styled.div`
  padding: 30px 0px;
  display: flex;
  //   text-align: ${left};
  border-bottom: 1px solid ${colorBorder};
  display: flex; /* Add display flex */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */

  @media (max-width: 1345px) and (min-width: 1200px) {
    padding: 30px 0px;
  }

  @media screen and (max-width: 360px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;

const ProfileData = styled.div`
  width: calc(100% - 80px);
  @media screen and (max-width: 360px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0;
  }
`;

const ProfileName = styled.p`
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
  line-height: 18px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ProfileWork = styled.p`
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
  opacity: 0.6;
  line-height: 18px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ProfileContact = styled.span`
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 18px;
`;

const ProfileStats = styled.div`
  display: flex;
  justify-content: space-around;
`;

// endregion
