/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ContractTable from './component/ContractTable';

const ContractOperations = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Contract Operations</h3>
      </Col>
    </Row>
    <Row>
      <ContractTable />
    </Row>

  </Container>
  );
export default ContractOperations;
