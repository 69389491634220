import { get } from './base/index';
import { apiUrl } from './base/axios';

// Available api doc: "https://coinmarketcap.com/api/documentation/v1/#section/Quick-Start-Guide"
export default {
  getListingsLatest: (limit = 10, sort = 'market_cap') => {
    const params = {
      limit,
      sort,
    };
    return 0;
  },
  getQuotesBySymbol: (symbol) => {
    const params = { symbol };

    return 0;
  },
  getGlobalQuotes: () => {
    const params = [0];
    return 0;
  },
};
