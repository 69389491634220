/* eslint-disable func-names */

import { CONNECTED, DISCONNECTED } from '../actions/walletActions';

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CONNECTED:
      return { wallets: payload };

    case DISCONNECTED:
      return { };

    default:
      return state;
  }
}
