/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Ring } from '@uiball/loaders';
import { useTranslation } from 'react-i18next';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
 faEye, faEnvelope, faUser, faMap, 
} 
  from '@fortawesome/free-regular-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Panel from '@/shared/components/Panel';
import { Table } from '@/shared/components/TableElements';
import { getWeekChartData } from '@/utils/helpers';
import ErrorAlert from '@/shared/components/ErrorAlert';
import { fetchTopTenCurrencies } from '@/redux/actions/topTenActions';
import TopTenRow from './TopTenRow';
import {
  // createMarketPlace,
  getPublicTransactions,  
  getdetailSearch,
  getWalletActivities,
  walletSendReceiveActivities,
} from '../../../../utils/services/user.service';

const TopTen = () => {
  const {
    topTen, cryptoHistory, isHistoryLoading, isTopTenLoading,
  } = useSelector(state => ({
    topTen: state.topTen.data,
    cryptoHistory: state.cryptoHistory.data.cryptoHistory,
    errorTopTen: state.topTen.error,
    errorHistory: state.cryptoHistory.error,
    isHistoryLoading: state.cryptoHistory.isFetching,
    isTopTenLoading: state.topTen.isFetching,
  }));
  // console.log('isHistoryLoading', isHistoryLoading);
  // console.log('isTopTenLoading', isTopTenLoading);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const [getTransactions, setTransactions] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const REACT_APP_BSC_TRANSACTION_EXPLORER_BASE_URL = 'https://testnet.bscscan.com/tx/';

  const refreshRequest = () => {
    dispatch(fetchTopTenCurrencies());
  };

  const openProductDetails = async (id) => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      const response = await getdetailSearch(id);
      if (response.data.status === 200) {
        const Data = response.data.result[0];
        // console.log('getdetailSearch >>>>>>>>>>', Data);
        setSelectedOrder(Data);
        setIsLoading(false);
      } else {
        console.error('Error fetching getdetailSearch:', response.statusText);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching getdetailSearch:', error);
    }
    setModalIsOpen(true);
  };
  
  const closeProductDetails = () => {
    setSelectedOrder(null);
    setModalIsOpen(false);
  };

  useEffect(() => {
    const fetchWallet = async () => {
      try {
        const response = await getWalletActivities();
        if (response.data) {
          const walletActivities = response.data;
          // console.log('getWalletActivities >>>>>>>>>>>>>>>>> ', walletActivities);
          // setTransactions(walletActivities);
        } else {
          console.error('Error fetching getWalletActivities:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching getWalletActivities:', error);
      }
    };

    const fetchWalletSendReceiveActivities = async () => {
      try {
        const requestData = {
          limit: 10,
          page_number: 1,
        };
        const response = await walletSendReceiveActivities(requestData);
        if (response.data) {
          const walletActivities = response.data.data;
          // console.log('Wallet Send Receive Activities >>>>>>>>>>>>>>>>> ', walletActivities);
          // setTransactions(walletActivities);
        } else {
          console.error('Error fetching Wallet Send Receive Activities:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching Wallet Send Receive Activities:', error);
      }
    };
  
    fetchWallet();
    fetchWalletSendReceiveActivities();
  }, []);

  return (
    <>
      <div
        className="loader-overlay"
        style={{
    display: isLoading ? 'flex' : 'none', // Use flex display when loading
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    zIndex: 999,
  }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Panel
        lg={12}
        title="Receive Fee"
        isLoading={isHistoryLoading || isTopTenLoading}
        refreshRequest={refreshRequest}
      >
        <DashboardTableCrypto responsive bordered>
          <thead style={{ textAlign: 'center' }}>
            <tr>
              <th>Name</th>
              <th>Contract</th>
              <th>Hash</th>
              <th>Approver</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody style={{ cursor: 'pointer' }}>
            { getTransactions.map((item, index) => (
              <tr>
                <td style={{ textAlign: 'center' }}>
                  {item.Name.charAt(0).toUpperCase() + item.Name.slice(1)}
                </td>
                <td style={{ textAlign: 'center' }}>
                  <span className="text-secondary text-xs font-weight-bold">
                    {item.type === 'contract'
    ? `Contract@ ${item.Name.substring(0, 12)}...`
    : 'Direct Transaction'}
                  </span>
                </td>
                {item.hash ? (
                  <td className="align-middle text-center">
                    <a target="_blank" href={`https://bscscan.com/tx/${item.hash}`} rel="noreferrer">
                      <span className="text-purple text-xs font-weight-bold">
                        {item.type === 'transaction' || item.type === 'contract'
          ? `${item.hash.substring(0, 10)}...`
          : '-'}
                      </span>
                    </a>
                  </td>
) : (
  <td className="align-middle text-center">-</td>
)}
                {item.type === 'contract' ? (
                  <td className="align-middle text-center">
                    <span className="text-secondary text-xs font-weight-bold">*****</span>
                  </td>
) : (
  <td className="align-middle text-center">
    <span className="text-secondary text-xs font-weight-bold">-</span>
  </td>
)}
                <td style={{ textAlign: 'center' }}>{item.Amount} {item.Symbol}</td>
                <td style={{ textAlign: 'center' }}>{item.status}</td>
                <td style={{ textAlign: 'center' }}>{item.Date}</td>
                <td style={{ textAlign: 'center' }}>
                  <button 
                    className="btn btn-primary purple-button btn-sm ml-2 px-2 shadow-none"   
                    type="button" 
                    style={{ marginTop: '0px' }}
                    onClick={() => openProductDetails(item.hash)}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </DashboardTableCrypto>
        <Modal       
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered 
          show={modalIsOpen} 
          onHide={closeProductDetails}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center" style={{ fontSize: 'large' }}>Transaction Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedOrder && (
            <>
              <div className="container mt-0 containerDiv pt-0" style={{ overflowX: 'hidden' }}>
                {/* <div className="row">
                <div className="col-12 text-center">
                  <h3 style={{ color: '#7b7b7b' }} className="mb-2"> Order Details</h3>
                </div>
              </div> */}
                <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1"
                    ><b>Name</b>
                    </p> 
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-0"
                    >{selectedOrder.Name}
                    </p> 
                  </div> 
                  <div className="col-lg-9 col-md-9 col-sm-12 text-center">
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1"
                    ><b>Transaction Hash</b>
                    </p> 
                    <a target="_blank" style={{ wordWrap: 'break-word' }} href={`https://bscscan.com/tx/${selectedOrder.hash}`} rel="noreferrer">
                      <span className="text-purple text-xs font-weight-bold">
                        {selectedOrder.hash}
                      </span>
                    </a>
                  </div> 
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1"
                    ><b>Status</b>
                    </p> 
                    <Badge bg="dark" className="mb-1" style={{ fontSize: 'small', fontWeight: '400' }}>{selectedOrder.status}</Badge>
                  </div> 
                  <div className="col-lg-9 col-md-9 col-sm-12 text-center">
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1"
                    ><b>Amount</b>
                    </p> 
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="pb-1 mb-2"
                    >{selectedOrder.Amount} {selectedOrder.Symbol}
                    </p> 
                  </div> 
                </div>
                <div className="row mt-4 mb-1">
                  <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                    <b>Transfer Detail</b>
                  </p> 
                </div>
                <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                  <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1"
                    ><b>Transfer Name </b>
                    </p> 
                    {selectedOrder.Transfer ? (
                      <p  
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                        className="py-1"
                      >*****
                      </p> 
                  ) : (
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1"
                    >-
                    </p> 
                  )}
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-12 text-center">
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1"
                    ><b>Transfer Address </b>
                    </p> 
                    {selectedOrder.Transfer ? (
                      <p  
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b', wordWrap: 'break-word' }}   
                        className="py-1"
                      >{selectedOrder.Transfer.address}
                      </p> 
                  ) : (
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1"
                    >-
                    </p> 
                  )}
                  </div>
                </div>
                {selectedOrder.Approver ? (
                  <>
                    <div className="row mt-4 mb-1">
                      <p style={{ textTransform: 'capitalize', color: '#7b7b7b' }} className="py-0">
                        <b>Approver Detail</b>
                      </p>
                    </div>
                    <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                      <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                        <p style={{ textTransform: 'capitalize', color: '#7b7b7b' }} className="py-1">
                          <b>Approver Name </b>
                        </p>
                        {selectedOrder.Approver ? (
                          <p style={{ textTransform: 'capitalize', color: '#7b7b7b' }} className="py-1">
                            *****
                          </p>
                      ) : (
                        <p style={{ textTransform: 'capitalize', color: '#7b7b7b' }} className="py-1">
                          -
                        </p>
                      )}
                      </div>
                      <div className="col-lg-9 col-md-9 col-sm-12 text-center">
                        <p style={{ textTransform: 'capitalize', color: '#7b7b7b' }} className="py-1">
                          <b>Approver Address </b>
                        </p>
                        {selectedOrder.Approver ? (
                          <p style={{ textTransform: 'capitalize', color: '#7b7b7b', wordWrap: 'break-word' }} className="py-1">
                            {selectedOrder.Approver.address}
                          </p>
                      ) : (
                        <p style={{ textTransform: 'capitalize', color: '#7b7b7b' }} className="py-1">
                          -
                        </p>
                      )}
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="row mt-4 mb-1">
                  <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                    <b>Beneficiary  Detail</b>
                  </p> 
                </div>
                <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                  <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1"
                    ><b>Beneficiary Name</b> 
                    </p> 
                    {selectedOrder.Beneficiary ? (
                      <p  
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                        className="py-1"
                      >*****
                      </p> 
                  ) : (
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1"
                    >-
                    </p> 
                  )}
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-12 text-center">
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1"
                    ><b>Beneficiary Address</b> 
                    </p> 
                    {selectedOrder ? (
                      <p  
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b', wordWrap: 'break-word' }}   
                        className="py-1"
                      >{selectedOrder.Beneficiary.address}
                      </p> 
                  ) : (
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1"
                    >-
                    </p> 
                  )}
                  </div>
                </div>
                <hr hidden style={{ border: '1px solid #b3b3b3', opacity: '1.1' }} />
              </div>
              <button type="button" hidden>
                Submit Ratings
              </button>
            </>
        )}
          </Modal.Body>
        </Modal>
      </Panel>
    </>
  );
};

export default TopTen;

// region STYLES

const DashboardTableCrypto = styled(Table)`

  tbody td {
    padding: 10px;
    white-space: nowrap;
  }
`;

// endregion
