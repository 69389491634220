import React from "react";

const PartnerGrid = ({ getPartnerData }) => {
  return (
    <div className="container py-5 align-self-center  ">
      <div className="row row-cols-1 row-cols-md-3 d-flex justify-content-center align-items-center  row-cols-lg-4 g-4">
        {getPartnerData.map((partner) => (
          <div className="col d-flex  align-items-center justify-content-center align-self-center  " key={partner._id}>
            <img
              src={partner.logo}
              className="card-img-top "
              alt={partner.name}
              style={{
                maxWidth: "192px",
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PartnerGrid;
