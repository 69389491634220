import React from 'react';
import { useSelector } from 'react-redux';
import {
  Route,
  Redirect, useHistory,
} from 'react-router-dom';

function PublicRoute({ children, ...rest }) {
  const { isLoggedIn } = useSelector(state => state.user);
  const user = JSON.parse(localStorage.getItem('user'))
  const adimLogin = user?.token && user?.data?.user.email === "asim92578@gmail.com"
  const history = useHistory();
  if (rest.path === "/Admin_login" && user) {
    history.push('/dashboard')
    return false

  }
  return (
    <Route
      {...rest}
      render={
        ({ location }) => (
          !isLoggedIn
            ? (
              children
            ) : (
              //   <Redirect
              //     to={{
              //       pathname: '/dashboard',
              //       state: { from: location },
              //     }}
              //   />
              history.push('/dashboard')
            ))
      }
    />
  );
}

export default PublicRoute;

