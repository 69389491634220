/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { ContactProps } from '@/shared/prop-types/ChatProps';
import {
  colorAccent, 
  colorAdditional,
  colorFieldsBorder,
  colorHover,
} from '@/utils/palette';
import {
  marginRight,
  marginLeft,
} from '@/utils/directions';
import { getTickets } from '../../../../utils/services/user.service';

const ChatUser = ({ contact, active, v }) => {
  // console.log("ContactProps",ContactProps)
//   const [Tickets, setTickets] = useState([]);
// console.log("Tickets", Tickets)
// console.log('contact ChatContact', contact);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const payload = {
  //         status: 'all',
  //         limit: 10,
  //         page_number: 1,
  //       };

  //       const response = await getTickets(payload);
  //       console.log('response tickets contact', response);

  //       if (response.data.tickets) {
  //         // Assuming that the response contains ticket data in an array
  //         setTickets(response.data.tickets);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching tickets:', error);
  //     }
  //   };

  //   // Call the fetchData function
  //   fetchData();
  // }, []);
  // let lastMessage = '';
  // if (contact.messages.length) {
  //   lastMessage = contact.messages.slice(-1)[0].message;
  // } else {
  //   lastMessage = 'new contact';
  // }
 
  return (
    // eslint-disable-next-line no-underscore-dangle
    <ChatContactWrap key={contact._id} active={active} className={`chat-contact ${active ? 'active' : ''}`}>
      <ChatContactAvatar>
        <img src={`${process.env.PUBLIC_URL}/img/24_mail_ava.png`} alt="ava" />
      </ChatContactAvatar>
      <ChatContactPreview style={{ textTransform: 'capitalize' }}>
        <ChatContactName>{contact.subject}</ChatContactName>
        <ChatContactLastMessage>  { moment(contact.createdAt).format('DD MMMM YYYY')}</ChatContactLastMessage>
      </ChatContactPreview>
    </ChatContactWrap>

  );
};

ChatUser.propTypes = {
  contact: ContactProps.isRequired,
  active: PropTypes.bool,
};

ChatUser.defaultProps = {
  active: false,
};

export default ChatUser;

// region STYLES

const ChatContactWrap = styled.div`
  height: 72px;
  display: flex;
  cursor: pointer;
  transition: all 0.3s;
  border-bottom: 1px solid ${colorFieldsBorder};

  &:hover {
    background-color: ${colorHover};
  }
  
  ${props => props.active && `
    background-color: ${colorHover(props)};

    &:hover {
      background-color: ${colorHover(props)};
    }
  `}
`;

const ChatContactAvatar = styled.div`
  display: flex;
  flex-flow: column;
  align-self: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 40px;
  ${marginRight}: 10px;
  ${marginLeft}: 15px;

  img {
    height: 100%;
  }
`;

const ChatContactPreview = styled.div`
  display: flex;
  flex-flow: column;
  align-self: center;
  width: calc(100% - 80px);
  ${marginRight}: 20px;
  ${marginLeft}: 0;
`;

const ChatContactName = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  color: ${colorAccent};
`;

const ChatContactPost = styled.p`
  margin-top: 0;
  margin-bottom: 3px;
  font-size: 10px;
  line-height: 1.2;
  color: ${colorAdditional};
`;

const ChatContactLastMessage = styled.p`
  margin: 4px 0 0 0;
  font-size: 12px;
  line-height: 1.33;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

// endregion
