/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons'; 
import { toast } from 'react-toastify';
import { Ring } from '@uiball/loaders';
import { Carousel } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import TreeItem from '@material-ui/lab/TreeItem';
import styled from 'styled-components';
import { CardBody } from '@/shared/components/Card';
import { colorAccent, colorAdditional, colorBackground } from '@/utils/palette';
import { right, left, marginRight } from '@/utils/directions';
import {
  getMyStore,
  searchAllStore,
  getCategory,
  getMyProduct,
} from '../../../utils/services/user.service';
import UserProduct from '../product/UserProduct';
import StarRating from '../home/StarRating';
import '../style/storedetail.css';
import '../style/storedetailuser.css';





const StoreDetailUser = () => {
  // const classes = useStyles();
  const { storeId } = useParams(); // Get the store ID from URL parameter
  const [storeData, setStoreData] = useState(null);
  const [SelectedSubcategoryName, setSelectedSubcategoryName] = useState('');
  const [categories, setCategories] = useState([]);
  const [matchingProductsFoundSearch, setMatchingProductsFoundSearch] = useState([]);
  const [matchingProductsFoundCategory, setMatchingProductsFoundCategory] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // console.log('categories', categories);
  // console.log('searchTerm', searchTerm);
  // console.log('matchingProductsFoundSearch data ==>', matchingProductsFoundSearch);
  // console.log('matchingProductsFoundCategory data ==>', matchingProductsFoundCategory);
  const Sale = `${process.env.PUBLIC_URL}/img/for_store/catalog/sale_lable.png`;
  const New = `${process.env.PUBLIC_URL}/img/for_store/catalog/new_lable.png`;

  useEffect(() => {
    const fetchStoreById = async () => {
      try {
        const response = await getMyStore(storeId); // Replace with your API call
        if (response.data) {
          setStoreData(response.data); // Set the detailed store data
        } else {
          console.error('Error fetching store details:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching store details:', error);
      }
    };

    fetchStoreById();

      const fetchStoreData = async () => {
        const requestData = {
          limit: 10, // Set your limit
          pageNumber: 1, // Set your initial page number
          store_id: storeId, // Use the storeId prop
        };
        try {
          // setIsLoading(true);
          const response = await getMyProduct(requestData);
          if (response.data && response.data.products.length > 0) {
            // console.log('Products ? ?? ?', response.data.products);
            setMatchingProductsFoundCategory(response.data.products);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            console.error('Not Found');
          }
        } catch (error) {
          setIsLoading(false);
          console.error('Error fetching store data:', error);
        }
      };
      fetchStoreData();
  }, [storeId]);



  useEffect(() => {
    // Fetch category data from your API
    const fetchCategories = async () => {
      try {
        const response = await getCategory();

        if (response.data.status) { 
          setCategories(response.data.category);
        } else {
          console.error('No category data received');
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);
  
  const fetchAllData = async (page) => {
    const requestData = {
      limit: 10, // Set your limit
      pageNumber: 1, // Use the current page
      store_id: storeId, // Use the storeId prop
    };
    try {
      setIsLoading(true);
      const response = await getMyProduct(requestData);
      if (response.data && response.data.products.length > 0) {
        // console.log('Products ? ?? ?', response.data.products);
        setMatchingProductsFoundCategory(response.data.products);
        setTotalPages(response.data.totalPages); // Set total pages here
        setIsLoading(false);
      } else {
        console.error('Not Found');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching store data:', error);
    }
  };
  
 
  const handleSearch = async () => {
    try {
      const requestData = {
        name: searchTerm, // Send the search query to the API
        sub_category_id: '',
        pageNumber: 1,
        limit: 10,
      };
  
      const response = await searchAllStore(requestData);
      // console.log('response', response);
      // console.log('response.data.result', response.data.result);
  
      if (response.data.result) {
        setMatchingProductsFoundCategory(response.data.result);
      } else if (searchTerm.trim() === '') {
          fetchAllData(1); // Call fetchAllData when searchTerm is empty
        } else {
          setMatchingProductsFoundCategory([]); // No matching products found
        }
    } catch (error) {
      fetchAllData(1);
      console.error('Error fetching search results:', error);
    }
  };
      // Trigger the search when searchQuery changes
      
    

    const handleInputChange = (e) => {
      setSearchTerm(e.target.value);
    };
    const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
      fetchAllData(newPage);
    };
    // Handle category click
    const handleSubcategoryClick = async (subcategoryID) => {
      // console.log('handleCategoryClickhandleCategoryClickhandleCategoryClickhandleCategoryClick');
      try {
        const requestData = {
          name: '',
          sub_category_id: subcategoryID, // Send the selected category ID to the API
          pageNumber: 1,
          limit: 10,
        };
        setIsLoading(true);
        const response = await searchAllStore(requestData);
        // console.log('response data result >>>>>> 11', response.data);
        // console.error('Error fetching stores >>>>>>> ', response.data.Message);
        if (response.data.result) {
          const data = response.data.result;
          // console.log('data>>>>>>>>>', data);
          // console.log('11111');
          setMatchingProductsFoundCategory(data);
          setSelectedSubcategoryName(subcategoryID); // Store the selected category ID
          setIsLoading(false); 
          // console.log('matchingProductsFoundCategory>>>>>>>>>', matchingProductsFoundCategory);
        } else {
          // console.log('22222');
          setMatchingProductsFoundCategory([]); // No matching products found
        }
      } catch (error) {
        setIsLoading(false); 
        console.error('Error fetching stores >>>>>>> ', error);
        console.error('Error fetching stores >>>>>>> ', error.response);
        // console.error('Error fetching stores >>>>>>> ', error.response.data.Message);
        if (error.response.data.Message === 'Product not found') {
          // console.log('33333');
          setMatchingProductsFoundCategory([]);
        // console.log(error.response.data.Message);
        // toast.error(`${error.response.data.Message}`, {
        //   position: toast.POSITION.TOP_RIGHT,
        //   autoClose: 5000, // Adjust as needed
        // });
        console.error('Error setMatchingProductsFoundCategory stores >>>>>>> 11 ', matchingProductsFoundCategory);
        }
      }
    };
 
 
    

  return (
    <>
      <div
        className="loader-overlay"
        style={{
    display: isLoading ? 'flex' : 'none', // Use flex display when loading
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    zIndex: 999,
  }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <div className="pmenu">
        <nav className="navbar navbar-expand-lg ">
          <div className="container-fluid">
            <ul className="navbar-nav ms-auto">
             
              <li className="nav-item dropdown">
                <button
                  className="nav-link btn btn-link dropdown-toggle catbtn"
                  type="button"
                  id="navbarDropdownMenuLinkRight"
                  data-mdb-toggle="dropdown"
                  aria-expanded="false"
                >
                  Cateogories
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLinkRight"
                >
                  <button type="button" className="allbtn" onClick={() => fetchAllData()}>All</button>
                  {categories.map(category => (
                    <li key={category._id}>
                      <button
                        className="dropdown-item btn btn-link"
                        type="button"
                        onClick={() => setSelectedSubcategory({
                  id: category._id,
                  name: category.category_name,
                })}
                      >
                        {category.category_name}
                      </button>
                      {category.sub_categories.length > 0 && (
                      <ul className="dropdown-menu dropdown-submenu dropdown-submenu-right">
                        {category.sub_categories.map(subcategory => (
                          <li key={subcategory._id}>
                            <button
                              className="dropdown-item btn btn-link"
                              type="button"
                              onClick={() => handleSubcategoryClick(subcategory._id)}
                            >
                              {subcategory.sub_category_name}
                            </button>
                            
                          </li>
                      ))}
                      </ul>
                  )}
                    </li>
              ))}
                </ul>
              </li>
            </ul>
          </div>
        </nav>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search products..."
            value={searchTerm}
            onChange={(e) => {
              handleInputChange(e);
              handleSearch(); // Call the handleSearch function here
            }}
          />
        </div>
      </div>
      <div className="container p py-3">
      
      
        <div className="container mt-3 py-3">
          {matchingProductsFoundCategory.length > 0 ? (
            <div className="row">
              {matchingProductsFoundCategory.map(product => (
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  style={{ textTransform: 'capitalize' }}
                  key={product._id}
                >
                  <Link to={product._id ? `/productdetails/${product._id}` : ''}>
                    <div className="card productCard" style={{ background: '#232329' }}>
                      <ProductItemLabel src={Sale} alt="new" />
                      <div className="square-img-wrapper mt-4">
                        <img
                          src={product.pictures[0]}
                          className="card-img-top square-img p-3 border-radius zoom-in-on-hover"
                          alt={product.storeName}
                        />
                      </div>
                      <div className="card-body">
                        <span className="d-flex">
                          <span style={{ width: '100%' }}>
                            <h4 className="card-title producttitle mt-0 mb-1">{product.title}</h4>
                            {(() => {
               const newVal = 1;
               // eslint-disable-next-line max-len
               return <h5 className="card-title producttitle mt-0 mb-0">{product.brand}</h5>;
             })()}
                          </span>
                          <span style={{ float: 'right', width: '40%' }}>
                            <p  
                              className="card-title productPrice mb-0 float-right"
                            >
                              {product.product_sizes_quantity[0].price} {product.symbol}
                            </p>
                          </span>
                        </span> 
                        <span>
                          {product.product_sizes_quantity.map(color => (
                            <ProductItemColor
                              style={{ background: color.color }}
                            />
           ))}             
                        </span>                          
                        <div className="Rating mb-4" style={{ float: 'right', marginTop: '7%' }}>
                          {product.ProductRatting?.length > 0 ? (
                            <StarRating rating={product.ProductRatting[0]?.averageRating} />
           ) : (
             <StarRating rating={0} />
           )}
                        </div> 
                      </div>
                    </div>
                  </Link>
                </div>
            ))}
            </div>
      ) : (
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 text-center">
            <h3>No Products Found</h3>
          </div>
        </div>
      )}
        </div>
      </div>
      {totalPages > 1 && (
      <ul className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <li
            key={index}
            className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
          >
            <button
              className="page-link"
              type="button"
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          </li>
    ))}
      </ul>
)}
    </>
);
};

export default StoreDetailUser;

// region STYLES

const ProductItemsWrap = styled.div`
  overflow: hidden;
  width: 100%;
`;

const ProductItemsList = styled.div`
  width: calc(100% + 20px);
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
`;

const ProductCard = styled(Card)`
  padding: 0;
  width: 25%;
  background: red;

  @media screen and (max-width: 1500px) {
    width: 25%;
    background: red;
  }

  @media screen and (max-width: 1200px) {
    width: 50%;
    background: red;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    height: auto;
    background: red;
  }
`;

const ProductItem = styled(CardBody)`
  margin-bottom: 30px;
  height: 440px;
  ${marginRight}: 30px;
  position: relative;
  background-color: ${colorBackground};
`;

const ProductItemImageWrap = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  overflow: hidden;
`;

const ProductItemImage = styled.img`
  height: 100%;
  width: auto;
`;

const ProductItemInfo = styled.div`
  text-align: ${left};
  position: relative;
  width: calc(100% - 90px);
`;

const ProductItemTitle = styled.h4`
  font-weight: 500;
  transition: all 0.3s;
`;

const ProductItemLink = styled(Link)`
  padding: 40px 30px;
  display: block;

  &:hover {
    text-decoration: none;

    ${ProductItemTitle} {
      color: ${colorAccent};
    }
  }
`;

const ProductItemDescription = styled.p`
  margin: 0;
  color: ${colorAdditional};
  line-height: 17px;
`;

const ProductItemPrice = styled.h1`
  position: absolute;
  top: 0;
  ${right}: -90px;
  line-height: 36px;
`;

const ProductItemOldPrice = styled.p`
  position: absolute;
  ${right}: -90px;
  top: 36px;
  line-height: 28px;
  color: ${colorAdditional};
  margin: 0;
  text-decoration: line-through;
`;

const ProductItemColor = styled.span`
  height: 18px;
  width: 18px;
  ${marginRight}: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-top: 10px;
`;

const ProductItemLabel = styled.img`
  position: absolute;
  top: -2px;
  ${right}: 20px;
  width: 50px;
`;

