
import React, { useState, useEffect } from 'react';
import '../style/createservice.css';

const CreateServices = () => {
    const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [attachment, setAttachment] = useState('');
  const [approver, setApprover] = useState('');
  const [price, setPrice] = useState('');
  const [homeServiceCharges, setHomeServiceCharges] = useState('');
  const [lane1, setLane1] = useState('');
  const [lane2, setLane2] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [features, setFeatures] = useState([]);
  const [newFeature, setNewFeature] = useState('');


  // Load features from local storage on component mount
  useEffect(() => {
    const savedFeatures = localStorage.getItem('features');
    if (savedFeatures) {
      setFeatures(JSON.parse(savedFeatures));
    }
  }, []);

  // Save features to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('features', JSON.stringify(features));
  }, [features]);

  // Handler for adding new features
  const handleAddFeature = () => {
    if (newFeature.trim() !== '') {
      setFeatures([...features, newFeature]);
      setNewFeature('');
    }
  };

  // Handler for removing a feature
  const handleRemoveFeature = (index) => {
    const updatedFeatures = [...features];
    updatedFeatures.splice(index, 1);
    setFeatures(updatedFeatures);
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-4 ">
          <label htmlFor="category" className="text-white">Category</label>
          <input
            type="text"
            id="category"
            className="form-control"
            value={category}
            onChange={e => setCategory(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="subCategory" className="text-white">Sub Category</label>
          <input
            type="text"
            id="subCategory"
            className="form-control"
            value={subCategory}
            onChange={e => setSubCategory(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="title" className="text-white">Title</label>
          <input
            type="text"
            id="title"
            className="form-control"
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-4">
          <label htmlFor="description" className="text-white">Description</label>
          <textarea
            id="description"
            className="form-control"
            value={description}
            onChange={e => setDescription(e.target.value)}
            rows={3}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="attachment" className="text-white">Attachment</label>
          <input
            type="file"
            id="attachment"
            className="form-control"
            value={attachment}
            onChange={e => setAttachment(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="approver" className="text-white">Approver</label>
          <input
            type="text"
            id="approver"
            className="form-control"
            value={approver}
            onChange={e => setApprover(e.target.value)}
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-4">
          <label htmlFor="price" className="text-white">Price</label>
          <input
            type="text"
            id="price"
            className="form-control"
            value={price}
            onChange={e => setPrice(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="homeServiceCharges " className="text-white">Home Service Charges</label>
          <input
            type="text"
            id="homeServiceCharges"
            className="form-control"
            value={homeServiceCharges}
            onChange={e => setHomeServiceCharges(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="lane1" className="text-white">Lane 1</label>
          <input
            type="text"
            id="lane1"
            className="form-control"
            value={lane1}
            onChange={e => setLane1(e.target.value)}
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-4">
          <label htmlFor="lane2" className="text-white">Lane 2</label>
          <input
            type="text"
            id="lane2"
            className="form-control"
            value={lane2}
            onChange={e => setLane2(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="country" className="text-white">Country</label>
          <input
            type="text"
            id="country"
            className="form-control"
            value={country}
            onChange={e => setCountry(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="state" className="text-white">State</label>
          <input
            type="text"
            id="state"
            className="form-control"
            value={state}
            onChange={e => setState(e.target.value)}
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-4">
          <label htmlFor="city" className="text-white">City</label>
          <input
            type="text"
            id="city"
            className="form-control"
            value={city}
            onChange={e => setCity(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="zipCode" className="text-white">Zip Code</label>
          <input
            type="text"
            id="zipCode"
            className="form-control"
            value={zipCode}
            onChange={e => setZipCode(e.target.value)}
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-8">
          <label htmlFor="features" className="text-white">Features</label>
          <input
            type="text"
            id="features"
            className="form-control"
            value={newFeature}
            onChange={e => setNewFeature(e.target.value)}
          />
        </div>
        <div className="col-md-4 d-flex align-items-end">
          <button type="button" className="btn btn-primary" onClick={handleAddFeature}>
            Add Feature
          </button>
        </div>
      </div>

      <ul className="list-group">
        {features.map((feature, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
            {feature}
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => handleRemoveFeature(index)}
            >
              Remove
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};

export default CreateServices;
