/* eslint-disable no-inner-declarations */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ContactProps } from '@/shared/prop-types/ChatProps';
import {
  colorAccent,
  colorAdditional,
  colorFieldsBorder,
  colorHover,
} from '@/utils/palette';
import {
  marginRight,
  marginLeft,
} from '@/utils/directions';

const ChatUser = ({ contact,chatIsOpen, active, readStatus }) => {
  let formattedDate = null;
  if (contact.updatedAt) {
    const timestamp = contact.updatedAt;
    let parsedDate = new Date(timestamp);
    const currentDate = new Date();
    const timeDifference = currentDate - parsedDate;
    function formatTimeDifference(Difference) {
      const minutes = Math.floor(Difference / 60000);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      if (minutes < 60) {
        return `${minutes} min ago`;
      } if (hours < 24) {
        const formattedHours = parsedDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        return `${formattedHours}`;
      } if (hours < 48) {
        return 'Yesterday';
      } if (hours > 48) {
        parsedDate = new Date(); // Replace this with your date value
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        formattedDate = parsedDate.toLocaleDateString('en-US', options);
        return `${formattedDate}`;
      }
      formattedDate = parsedDate.toLocaleDateString('en-US', {
        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
      });
      return `On ${formattedDate}`;
    }
    formattedDate = formatTimeDifference(timeDifference);
  }

  // Format and display the date
  const NoImg = `${process.env.PUBLIC_URL}/img/avatar_4.png`;
  const NoImg1 = `${process.env.PUBLIC_URL}/img/avat.png`;
  const NoImg2 = `${process.env.PUBLIC_URL}/img/avatgroup.png`;
  const hasUnreadMessage = readStatus.some(status => status._id === contact._id && !status.status);

  return (
    <ChatContactWrap active={active}>
      <ChatContactAvatar>
        {(contact.type === 'room' && contact?.support_user_id === '64d200a15a0ec71ba4278c9f' && contact.approver_user_id.length > 0) ? (
          <img
            src={NoImg2}
            alt="Group Chat"
          />
        ) : contact.type === 'otc-room' ? (
          <img
            src={NoImg2}
            alt="Group Chat"
          />
        ) : (
          <img
            src={(contact?.user_detail && contact?.user_detail[0]?.image) || NoImg1}
            alt="Group Chat"
          />
        )
        }

        {contact.type === 'private' && contact?.online_status[0]?.online_status === 'true' && <OnlineIndicator />}
        {contact.support_user_id === '64d200a15a0ec71ba4278c9f' && contact.status === 'open' && <OnlineIndicator />}
      </ChatContactAvatar>
      <ChatContactPreview>

        {hasUnreadMessage && !chatIsOpen && <UnreadBadge></UnreadBadge>}
        <ChatContactName>
          {
            contact.type === 'private' ? (
              contact.support_user_id === '64d200a15a0ec71ba4278c9f' ? 'Support' :
                (contact.user_detail && contact.user_detail.length > 0 ? contact.user_detail[0].name : '')
            ) : contact.type === 'otc-room' ? contact.subject : (
              contact.support_user_id === '64d200a15a0ec71ba4278c9f' ? contact.subject :
                (contact?.name ? contact?.name : (contact?.user_detail[0] ? contact?.user_detail[0]?.name : contact.subject))
            )
          }
        </ChatContactName>

        {formattedDate !== null && (
          <ChatContactData>
            {formattedDate}
          </ChatContactData>
        )}
      </ChatContactPreview>
    </ChatContactWrap>


  );
};

ChatUser.propTypes = {
  contact: ContactProps.isRequired,
  active: PropTypes.bool,
};

ChatUser.defaultProps = {
  active: false,
};

export default ChatUser;

// region STYLES
const UnreadBadge = styled.div`
  position: absolute;
  // top: 0;
  right: 6px;
  background-color: rgb(23,204,95); // You can use any color you prefer for the badge
  color: white;
  border-radius: 50%;
  padding: 5px 5px;
  font-size: 10px;
`;

const ChatContactWrap = styled.div`
  position: relative; /* Added relative positioning */
  height: 72px;
  display: flex;
  cursor: pointer;
  transition: all 0.3s;
  border-bottom: 1px solid ${colorFieldsBorder};

  &:hover {
    background-color: ${colorHover};
  }
  
  ${props => props.active && `
    background-color: ${colorHover(props)};

    &:hover {
      background-color: ${colorHover(props)};
    }
  `}
`;

const OnlineIndicator = styled.div`
  position: absolute;
  // bottom: 0;
  // right: 0;
  width: 12px;
  height: 12px;
  background-color: green; // You can use any color you prefer
  border-radius: 50%;
  border: 2px solid #fff;
`;
// const ChatContactWrap = styled.div`
//   height: 72px;
//   display: flex;
//   cursor: pointer;
//   transition: all 0.3s;
//   border-bottom: 1px solid ${colorFieldsBorder};

//   &:hover {
//     background-color: ${colorHover};
//   }

//   ${props => props.active && `
//     background-color: ${colorHover(props)};

//     &:hover {
//       background-color: ${colorHover(props)};
//     }
//   `}
// `;

const ChatContactAvatar = styled.div`
  display: flex;
  flex-flow: column;
  align-self: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 40px;
  ${marginRight}: 10px;
  ${marginLeft}: 15px;

  img {
    height: 100%;
  }
`;


const ChatContactPreview = styled.div`
  display: flex;
  flex-flow: column;
  align-self: center;
  width: calc(100% - 80px);
  ${marginRight}: 20px;
  ${marginLeft}: 0;
`;

const ChatContactName = styled.p`
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0;
  color: ${colorAccent};
`;
const ChatContactData = styled.p`
  font-size: 12px;
  margin-left: auto;
  margin-bottom: -10px;
  color: ${colorAccent};
`;

const ChatContactPost = styled.p`
  margin-top: 0;
  margin-bottom: 3px;
  font-size: 10px;
  line-height: 1.2;
  color: ${colorAdditional};
`;

const ChatContactLastMessage = styled.p`
  margin: 4px 0 0 0;
  font-size: 12px;
  line-height: 1.33;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

// endregion
