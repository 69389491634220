/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import { withRouter, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAppConfig } from '@/redux/actions/appConfigActions';
import Loading from '@/shared/components/Loading';
import Scrollbar from '@/shared/components/ScrollBar';
import './chat.css';
import {
    colorAdditional, 
    colorBackgroundBody,
    colorAccent, 
    colorFieldsBorder, 
    colorText,
} from '@/utils/palette';
import { 
    left,
    right,
    marginRight,
} from '@/utils/directions';

const direction = (location, rtl) => (location?.pathname === '/' ? 'ltr' : rtl.direction);

const MainWrapper = ({ children, location }) => {
  const { rtl, isFetching } = useSelector(state => ({
    rtl: state.rtl,
    isFetching: state.appConfig.isFetching,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAppConfig());
  }, [dispatch]);

  return isFetching ? (
    <Loading loading={isFetching} />
      ) : (
        <div
          className={`${direction(location, rtl)}-support`}
          dir={direction(location, rtl)}
        >
          <div className="wrapper" style={{ marginBottom: '-22px' }}>
            {children}
          </div>
        </div>
      );
};

MainWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default withRouter(MainWrapper);

const ChatTextField = styled.div`
  height: auto;
  width: 100%;
  background-color: #e7e7e7;
  border-top: 1px solid ${colorFieldsBorder};
`;

const ChatSubjectField = styled.div`
  height: auto;
  width: 100%;
  background-color: #e7e7e7;
  border-top: 1px solid ${colorFieldsBorder};
`;

const ChatContainer = styled.div`
  height: calc(100vh - 185px);
  min-height: 400px;
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  // background-color: blue;

  @media screen and (max-width: 1023px) {

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      ${right}: 0;
      left: 0;
      opacity: 0;
      z-index: 0;
      transition: opacity 0.3s, z-index 0.3s 0.3s;
      background-color: ${colorAdditional};
    }
    
    ${props => props.open && `
      &:before {
        opacity: 0.2;
        z-index: 1;
        transition: opacity 0.3s, z-index 0s;
        pointer-events: none;
      }

      .chat__scroll, .chat__text-field {
        pointer-events: none;
      }
    `}
  }
`;

const ChatDialog = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: #e7e7e7;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  width: 100%;

  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const ChatMessagesWrap = styled.div`
  width: 100%;
  display: flex;
`;

const ChatMessages = styled.div`
  padding: 10px;
  width: 100%;
  height: 100%;
`;

const ChatEmptyMessages = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;

  p {
    margin: auto;
    border-radius: 15px;
    padding: 5px 20px;
    color: #ffffffe6;
    background-color: ${colorBackgroundBody};
  }
`;

const ChatScroll = styled(Scrollbar)`
  text-align: ${left};
  // background-color: yellow;
  width: 100%;

  .scrollbar-track {

    &.scrollbar-track-y {
      width: 2px;
      ${marginRight}: 3px;
    }
  }

  .scrollbar-thumb {
    opacity: 0.9;
    width: 5px;
  }
`;
