/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import Scrollbar from '@/shared/components/ScrollBar';
import {
    colorAdditional, 
    colorBackgroundBody,
} from '@/utils/palette';
import { 
    left,
    right,
    marginRight,
} from '@/utils/directions';

import myContacts from './contacts';
import ChatBubble from './ChatBubble';
import ChatField from './ChatField';
import ChatTopbar from './ChatTopbar';

function ChatBox() {
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const handleShow = (order) => {
        setIsChatOpen(true);
    };

    const [currentMessages, setCurrentMessages] = useState(myContacts[0].messages);
    const [currentChat, setCurrentChat] = useState(myContacts[0].userName);
    const [openContacts, setOpenContacts] = useState(false);

    const onOpenContacts = () => {
        setIsChatOpen(false);
        setOpenContacts(state => !state);
    };

    const currentUser = {
        avatar: `${process.env.PUBLIC_URL}/img/ava.png`,
        name: 'Roman Johanson',
        userName: 'dragonKing',
    };

    useEffect(() => {
        // Scroll to the latest message when new messages are added
        // const chatWindow = document.getElementById('chat-window');
        // chatWindow.scrollTop = chatWindow.scrollHeight;
    }, [messages]);

  return (
    isChatOpen ? (
      <div className="bg-transparent chat-box open">        
        {/* <Chat
          style={{ background: 'red' }}
          contacts={myContacts}
          onClick={onOpenContactss}
          currentUser={{
            avatar: `${process.env.PUBLIC_URL}/img/ava.png`,
            name: 'Roman Johanson',
            userName: 'dragonKing',
          }}
        /> */}
        <ChatContainer
          open={openContacts}
          role="presentation"
        >
          {currentChat === null ? (
            <ChatDialog>
              {/* <ChatTopbar onClick={onOpenContacts} /> */}
              <ChatEmptyMessages>
                <p>Select a chat to start messaging</p>
              </ChatEmptyMessages>
            </ChatDialog>
      ) : (
        <ChatDialog>
          <ChatTopbar
            contact={myContacts.find(item => item.userName === currentChat)}
            onClick={onOpenContacts}
          />
          <ChatScroll alwaysShowTracks>
            <ChatMessagesWrap>
              {currentMessages === null || currentMessages.length === 0 ? (
                <ChatMessages>
                  <ChatEmptyMessages>
                    <p>No messages</p>
                  </ChatEmptyMessages>
                </ChatMessages>
              ) : (
                <ChatMessages>
                  {currentMessages.map(item => (
                    <ChatBubble
                      key={item.id}
                      contact={item.userName === currentUser.userName ? currentUser
                        : myContacts.find(c => c.userName === item.userName)}
                      message={item}
                      date={item}
                      active={item.userName === currentUser.userName}
                    />
                  ))}
                </ChatMessages>
              )}
            </ChatMessagesWrap>
          </ChatScroll>
          <ChatField />
        </ChatDialog>
      )}
        </ChatContainer>
      </div>
    ) : (
      <div className="bg-transparent chat-box1 open">        
        <Button   
          variant="primary" 
          className="btn btn-info mt-1 py-3 px-3 shadow-none rounded-circle button-hover"
          onClick={() => handleShow(1)}   
        >
          <i style={{ fontSize: 'x-large', color: 'white' }} className="fa fa-comments" />
        </Button>
      </div>
    )
  );  
}

export default ChatBox;

const ChatContainer = styled.div`
  height: calc(100vh - 185px);
  min-height: 400px;
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  // background-color: blue;

  @media screen and (max-width: 1023px) {

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      ${right}: 0;
      left: 0;
      opacity: 0;
      z-index: 0;
      transition: opacity 0.3s, z-index 0.3s 0.3s;
      background-color: ${colorAdditional};
    }
    
    ${props => props.open && `
      &:before {
        opacity: 0.2;
        z-index: 1;
        transition: opacity 0.3s, z-index 0s;
        pointer-events: none;
      }

      .chat__scroll, .chat__text-field {
        pointer-events: none;
      }
    `}
  }
`;

const ChatDialog = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: #232329;
  width: 100%;

  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const ChatMessagesWrap = styled.div`
  width: 100%;
  display: flex;
`;

const ChatMessages = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
`;

const ChatEmptyMessages = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;

  p {
    margin: auto;
    border-radius: 15px;
    padding: 5px 20px;
    color: ${colorAdditional};
    background-color: ${colorBackgroundBody};
  }
`;

const ChatScroll = styled(Scrollbar)`
  text-align: ${left};
  // background-color: yellow;
  width: 100%;

  .scrollbar-track {

    &.scrollbar-track-y {
      width: 2px;
      ${marginRight}: 3px;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
`;
