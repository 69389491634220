/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Route,
  Redirect, useHistory,
} from 'react-router-dom';
import styled from 'styled-components';
import { colorBackgroundBody } from '@/utils/palette';
import { paddingLeft } from '@/utils/directions';
import LayoutNew from '../LayoutNew';
import Layout from '../Layout';

function ProtectedRoute({ children, isAuthenticated, ...rest }) {

  const { isLoggedIn } = useSelector(state => state.user);
  const user = JSON.parse(localStorage.getItem('user'));
  // console.log('das', isLoggedIn);
  const history = useHistory();
  // console.log('isLoggedInisLoggedInisLoggedInisLoggedInisLoggedInisLoggedInisLoggedInisLoggedIn', isLoggedIn);
  return (
    <>
      <div>
        {isLoggedIn ? <Layout /> : <LayoutNew />}
        <ContainerWrap>
          <Route
            {...rest}
            render={
        ({ location }) => (
          
              children
            
            )
      }
          />
        </ContainerWrap>
      </div>

    </>

   
  );
}

export default ProtectedRoute;
const ContainerWrap = styled.div`
  padding-top: 90px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  ${paddingLeft}: 0;

  background: ${colorBackgroundBody};

  @media screen and (min-width: 576px) {
    ${paddingLeft}: 250px;
  }

  @media screen and (max-width: 576px) {
    padding-top: 92px;
  }
`;