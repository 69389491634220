/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import Badge from 'react-bootstrap/Badge';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { Switch, Stack } from '@mui/material';
import { Modal as BootstrapModal, Spinner } from 'react-bootstrap';
import { ButtonToolbar, Button } from '@/shared/components/Button';

import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorIcon,
  colorRed,
  colorText,
  colorWhite,
  colorYellow,
  colorLightAccent,
} from '@/utils/palette';
import {
  flexFlow, left, paddingLeft, paddingRight, right,
} from '@/utils/directions';
import {
  AccountButton,
} from '@/shared/components/account/AccountElements';
import TopbarMenuLink from '../../Layout/components/topbar/TopbarMenuLink';
import SimpleModal from '../../../shared/components/Modal/SimpleModal';
import {
  SendTwoFactor, getUserInfo, getBecomeApprover, create, verifyTFA, tfaEnable, tfaDisable,
} from '../../../utils/services/user.service';
import { SpinnerDiv } from '../../Dashboards/Operations/Contract/component/ContractTable';


const label = { inputProps: { 'aria-label': 'Switch demo' } };
const MyAccount = ({
  color, title, colored, header,
}) => {
  const [twoFactorModal, settwoFactorModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [qrCode, setQrCode] = useState(false);
  const [userInfo, setuserInfo] = useState([]);
  const [loading, setloading] = useState(false);
  const [tfaUrl, setTfaUrl] = useState('');
  const [tfaUrl1, setTfaUrl1] = useState('');
  const [rejectionReason, setRejectionReason] = useState('');
  const [rejectionReason1, setRejectionReason1] = useState('');
  const [acceptReason, setAcceptReason] = useState('');
  const [approveFile, setFile1] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [isModalOpen1, setIsModalOpen1] = useState(false); // State to control modal visibility
  const [load, setload] = useState(false);
  const [twoFactors, setTwoFactor] = React.useState(false);
  const [twoFactors2, setTwoFactor2] = useState(false);
  const [Msg, setMsg] = useState('');

  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const closeContractsDetails = () => {
    setModal(prevState => !prevState);
    setTfaUrl();
    setTfaUrl1();
  };

  const getUsers = () => {
    setload(true);
    getUserInfo().then((res) => {
      // console.log('getUsers >>>>>', res);
      if (res.status === 200) {
        // console.log('res', res);
        setload(false);
        setuserInfo(res.data.User);
        setTwoFactor(res.data.User?.status_2fa);
        setTwoFactor2(res.data.User?.TFA === true);
      } else {
        setload(false);
        setuserInfo(JSON.parse(localStorage.getItem('user')));
        setTwoFactor(userInfo?.data?.user?.status_2fa);
      }
    }).catch(() => {
      setuserInfo(JSON.parse(localStorage.getItem('user')));
      setload(false);
    });
  };
  React.useEffect(() => {
    getUsers();
  }, []);


  const handleRejectOrder = async () => {
    const requestData = {
      token: rejectionReason,
    };
    try {
      setload(true);
      const responseReject = await verifyTFA(requestData);
      // console.log('responseReject', responseReject);
      if (responseReject.data) {
        // console.log('verifyTFA Update', responseReject.data);
        if (responseReject.data.verified === true) {
          const tfaEnableResponse = await tfaEnable();
          // console.log('TFA_response', tfaEnableResponse);
          if (tfaEnableResponse.data) {
            setTfaUrl('');
            setTwoFactor2(true);
            toast.success(tfaEnableResponse.data.Message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setload(false);
          } else {
            setload(false);
            toast.error('TFA Enable failed', {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          setload(false);
          toast.error('Verify TFA failed', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        setload(false);
        console.error('verifyTFA failed:', responseReject);
      }
    } catch (error) {
      setload(false);
      console.error('Error verifyTFA:', error);
      console.error('verifyTFA Response data:', error.response.data);
      console.error('verifyTFA Response status:', error.response.status);
      console.error('verifyTFA Response headers:', error.response.headers);
      toast.error(error.response.data.Message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleRejectOrder1 = async () => {
    const requestData = {
      token: rejectionReason1,
    };
    try {
      setload(true);
      const responseReject = await verifyTFA(requestData);
      // console.log('responseReject', responseReject);
      if (responseReject.data) {
        // console.log('verifyTFA Update', responseReject.data);
        if (responseReject.data.verified === true) {
          const tfaEnableResponse = await tfaDisable();
          // console.log('tfaDisable', tfaEnableResponse);
          if (tfaEnableResponse.data) {
            setTwoFactor2(false);
            setTfaUrl1(false);
            toast.success(tfaEnableResponse.data.Message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setload(false);
          } else {
            setload(false);
            toast.error('TFA Disable failed', {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          setload(false);
          toast.error('Verify TFA failed', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        setload(false);
        console.error('verifyTFA failed:', responseReject);
      }
    } catch (error) {
      setload(false);
      console.error('Error verifyTFA:', error);
      console.error('verifyTFA Response data:', error.response.data);
      console.error('verifyTFA Response status:', error.response.status);
      console.error('verifyTFA Response headers:', error.response.headers);
      toast.error(error.response.data.Message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleFileChange1 = (e) => {
    const selectedFile1 = e.target.files[0];
    // console.log('selectedFile 2', selectedFile1);
    setFile1(selectedFile1);
  };
  const handleTwoFactorChange = async (e) => {
    const value = e.target.checked;
    setQrCode(true);
    // console.log('handleTwoFactorChange', value);
    if (value === false) {
      try {
        setload(true);
        setTfaUrl1(true);
        setload(false);
        // const responseReject = await verifyTFA(requestData);
        // console.log('responseReject', responseReject);
        // if (responseReject.data) {   
        //   console.log('verifyTFA Update', responseReject.data);
        //   if (responseReject.data.verified === true) { 
        //     const tfaEnableResponse = await tfaDisable();
        //     console.log('tfaDisable', tfaEnableResponse);
        //     if (tfaEnableResponse.data) {
        //       setTwoFactor2(false);
        //       toast.success(tfaEnableResponse.data.Message, {
        //         position: toast.POSITION.TOP_RIGHT,
        //       });
        //       setload(false);
        //     } else {
        //       setload(false);
        //       toast.error('TFA Disable failed', {
        //         position: toast.POSITION.TOP_RIGHT,
        //       });
        //     }
        //   } else {
        //     setload(false);
        //       toast.error('Verify TFA failed', {
        //         position: toast.POSITION.TOP_RIGHT,
        //       });
        //   }
        // } else {
        //   setload(false);
        //   console.error('verifyTFA failed:', responseReject);
        // }
      } catch (error) {
        setload(false);
        console.error('Error verifyTFA:', error);
        console.error('verifyTFA Response data:', error.response.data);
        console.error('verifyTFA Response status:', error.response.status);
        console.error('verifyTFA Response headers:', error.response.headers);
        toast.error(error.response.data.Message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      try {
        setload(true);
        const response = await create();
        if (response.data) {
          // console.log('create Response >>>>>>> ', response.data);
          setTfaUrl(response.data.tfaURL);
          setload(false);
        } else {
          console.error('Create Not Found');
        }
      } catch (error) {
        setload(false);
        console.error('Error create data:', error);
      }
    }
  };

  const handleSubcategoryClick = async () => {
    setModal(false);
    setIsModalOpen1(true);
  };

  const closeAcceptModal = () => {
    setAcceptReason('');
    setIsModalOpen1(false); // Close the modal when "Cancel" is clicked
  };

  const handleApprove = async (orderId) => {
    const formData = new FormData();
    formData.append('approver_comment', acceptReason);
    formData.append('File', approveFile);

    setload(true);
    setIsModalOpen1(false);
    try {
      if (!acceptReason && !approveFile) {
        setload(false);
        toast.error('Approve reason & document is required', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      if (!acceptReason) {
        setload(false);
        toast.error('Approve reason is required', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      if (!approveFile) {
        setload(false);
        toast.error('Approve document is required', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      const response = await getBecomeApprover(formData);
      // console.log('approveOrder response', response);

      if (response) {
        setload(false);
        // Order approved successfully, you can handle this as needed
        toast.success('Your request is accespted successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        // Reload orders or update state if needed
      } else {
        setload(false);
        toast.success('Something went wrong', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      setload(false);
      console.error('Error approving order:', error);
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
      toast.error(error.response.data.Message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setIsModalOpen1(false);
  };

  const toggleProfile = () => {
    setIsCollapsed(!isCollapsed);
  };
  let Icon;
  switch (color) {
    case 'primary':
      Icon = <ModalTitleIcon className="lnr lnr-pushpin" />;
      break;
    case 'success':
      Icon = <ModalTitleIcon className="lnr lnr-thumbs-up" />;
      break;
    case 'warning':
      Icon = <ModalTitleIcon className="lnr lnr-flag" />;
      break;
    case 'danger':
      Icon = <ModalTitleIcon className="lnr lnr-cross-circle" />;
      break;
    default:
      break;
  }
  const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: colorAccent,
      '&:hover': {
        backgroundColor: '#4ce1b65e',
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: colorAccent,
    },
  }));
  // console.log('uset', userInfo);
 localStorage.setItem('userInfo', userInfo.user_id);
  return (
    <>
      <div>
        <TopbarMenuLink
          title="My Account"
          icon="user"
          onClick={toggle}
        />
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modal}
          onHide={closeContractsDetails}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center" style={{ fontSize: 'large' }}>My Account</Modal.Title>
          </Modal.Header>
          {
            load ? (
              <Modal.Body>
                <SpinnerDiv>
                  <Spinner style={{ color: colorAccent }} />
                </SpinnerDiv>
              </Modal.Body>
            ) : (
              <Modal.Body>
                <div className="container mt-0 containerDiv pt-0" style={{ overflowX: 'hidden' }}>
                  <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                    <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      ><b>Name</b>
                      </p>
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="pb-1 mb-2"
                      >{userInfo.firstName || userInfo?.data?.user?.firstName} {userInfo.lastName || userInfo?.data?.user?.lastName}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                      <p
                        style={{ color: '#7b7b7b' }}
                        className="py-1"
                      ><b>Email</b>
                      </p>
                      <p
                        style={{ color: '#7b7b7b' }}
                        className="pb-1 mb-2"
                      >{userInfo.email || userInfo?.data?.user?.email}
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                      <p
                        style={{ color: '#7b7b7b' }}
                        className="py-1"
                      ><b>Address</b>
                      </p>
                      <p
                        style={{ color: '#7b7b7b', wordWrap: 'break-word' }}
                        className="py-1"
                      >{userInfo.address || userInfo?.data?.user?.address}
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      ><b>Approver Status</b>
                      </p>
                      {userInfo.approver_status === '' && (
                        <Button onClick={() => handleSubcategoryClick()} size="md" className="py-2 mt-2 btn-block transform-lowercase" variant="success">Become Approver</Button>
                      )}
                      {userInfo.approver_status === 'approved' && (
                        <Badge
                          bg="success"
                          className="mb-1"
                          style={{
                            backgroundColor: '#4ce1b6', paddingBottom: '6px', fontSize: 'small', fontWeight: '400', textTransform: 'Capitalize',
                          }}
                        >{userInfo.approver_status || userInfo?.approver_status}
                        </Badge>
                      )}
                      {userInfo.approver_status === 'waiting' && (
                        <Badge
                          bg="warning"
                          className="mb-1"
                          style={{
                            backgroundColor: '#f6da6e', paddingBottom: '6px', fontSize: 'small', fontWeight: '400', textTransform: 'Capitalize',
                          }}
                        >{userInfo.approver_status || userInfo?.approver_status}
                        </Badge>
                      )}
                      {userInfo.approver_status === 'pending' && (
                        <Badge
                          bg="warning"
                          className="mb-1"
                          style={{
                            backgroundColor: '#f6da6e', paddingBottom: '6px', fontSize: 'small', fontWeight: '400', textTransform: 'Capitalize',
                          }}
                        >{userInfo.approver_status || userInfo?.approver_status}
                        </Badge>
                      )}
                      {userInfo.approver_status === 'rejected' && (
                        <Badge
                          bg="danger"
                          className="mb-1"
                          style={{
                            backgroundColor: '#ff4861', paddingBottom: '6px', fontSize: 'small', fontWeight: '400', textTransform: 'Capitalize',
                          }}
                        >{userInfo.approver_status || userInfo?.approver_status}
                        </Badge>
                      )}
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                      <p
                        style={{ color: '#7b7b7b' }}
                        className="py-1"
                      ><b>Two Factor</b>
                      </p>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <div className="mx-auto">Disable</div>
                        <CustomSwitch
                          {...label}
                          value={twoFactors}
                          checked={twoFactors}
                          onChange={(e) => {
                            setloading(true);
                            setTwoFactor(e.target.checked);
                            SendTwoFactor()
                              .then(() => {
                                setloading(false);
                                getUsers();
                              }).catch((err) => {
                                // console.log('===2fa', err, err?.message);
                                setMsg(err?.message);
                                setTwoFactor(null);
                                setloading(false);
                              });
                          }}
                          onClick={() => settwoFactorModal(true)}
                          color="default"
                        />
                        <div className="mx-auto">Enable</div>
                      </Stack>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                      <p
                        style={{ color: '#7b7b7b' }}
                        className="py-1"
                      ><b>2FA Authenticator </b>
                      </p>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <div className="mx-auto">Disable</div>
                        <CustomSwitch
                          {...label}
                          value={twoFactors2}
                          checked={twoFactors2}
                          onChange={(e) => {
                            handleTwoFactorChange(e);
                          }}
                          color="default"
                        />
                        <div className="mx-auto">Enable</div>
                      </Stack>
                    </div>
                    {tfaUrl && (
                      <div className="col-lg-12 col-md-12 col-sm-12 my-2 text-center mx-auto">
                        <hr className="mt-2 mx-0" style={{ border: '1px solid #b3b3b3', opacity: '1.1' }} />
                        <span className="d-flex">
                          <span className="ms-auto">
                            <p
                              style={{ color: '#7b7b7b', fontSize: 'medium' }}
                              className="py-1"
                            ><b>Scan the QR code on your Authenticator App</b>
                            </p>
                          </span>
                          <span className="ms-auto">
                            <i
                              onClick={() => setTfaUrl('')}
                              style={{
                                marginLeft: '6px', marginRight: '5px', color: '#dc3545', marginTop: '6px', fontSize: '20px', cursor: 'pointer',
                              }}
                              className="fa fa-close float-right"
                            />
                          </span>
                        </span>
                      </div>
                    )}
                    {tfaUrl1 && (
                      <div className="col-lg-12 col-md-12 col-sm-12 my-2 text-center mx-auto">
                        <hr className="mt-2 mx-0" style={{ border: '1px solid #b3b3b3', opacity: '1.1' }} />
                        <span className="d-flex">
                          <span className="ms-auto">
                            <p
                              style={{ color: '#7b7b7b', fontSize: 'medium' }}
                              className="py-1"
                            ><b>Open your Authenticator App for code</b>
                            </p>
                          </span>
                          <span className="ms-auto">
                            <i
                              onClick={() => setTfaUrl1('')}
                              style={{
                                marginLeft: '6px', marginRight: '5px', color: '#dc3545', marginTop: '6px', fontSize: '20px', cursor: 'pointer',
                              }}
                              className="fa fa-close float-right"
                            />
                          </span>
                        </span>
                      </div>
                    )}
                    {tfaUrl && (
                      <div className="col-lg-4 col-md-4 col-sm-12 mb-2 text-center mx-auto">
                        {/* <div className="d-flex bg-white w-100 p-4"> */}
                          <QRCode size="190" level="H" value={tfaUrl} style={{ width: '100%' }} renderAs="canvas" />
                        {/* </div> */}
                        <input
                          type="text"
                          name="title"
                          id="title"
                          placeholder="Enter Code"
                          value={rejectionReason}
                          onChange={e => setRejectionReason(e.target.value)}
                          className="form-control mt-2"
                        />
                        <Button onClick={handleRejectOrder} size="sm" className="btn w-50 mb-2 px-2 mt-2 w-100" variant="success">Verify</Button>
                      </div>
                    )}
                    {tfaUrl1 && (
                      <div className="col-lg-4 col-md-4 col-sm-12 mb-2 text-center mx-auto">
                        <input
                          type="text"
                          name="title"
                          id="title"
                          placeholder="Enter Code"
                          value={rejectionReason1}
                          onChange={e => setRejectionReason1(e.target.value)}
                          className="form-control mt-2"
                        />
                        <Button onClick={handleRejectOrder1} size="sm" className="btn w-50 mb-2 px-2 mt-2 w-100" variant="success">Verify</Button>
                      </div>
                    )}
                  </div>
                </div>

              </Modal.Body>
            )
          }
        </Modal>
        <StyledModal
          // show={modal}
          onHide={toggle}
          color={color}
          colored={colored}
          header={header}
        >
          {load ? (
            <ModalBody>
              <SpinnerDiv>
                <Spinner style={{ color: colorAccent }} />
              </SpinnerDiv>
            </ModalBody>
          ) : (
            <>
              <ModalHeader>
                <ModalCloseButton
                  className="lnr lnr-cross"
                  aria-label="close-btn"
                  type="button"
                  onClick={toggle}
                />
                {header ? '' : Icon}
                <ModalTitle>{title}</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <ModalSubTitle>User Address</ModalSubTitle>
                <ModalDetail>{userInfo.address || userInfo?.data?.user?.address}</ModalDetail>

                <ModalSubTitle>User Name</ModalSubTitle>
                <ModalDetail>{`${userInfo.firstName || userInfo?.data?.user?.firstName} ${userInfo.lastName || userInfo?.data?.user?.lastName}`}</ModalDetail>

                <ModalSubTitle>User Email</ModalSubTitle>
                <ModalDetail>{userInfo.email || userInfo?.data?.user?.email}</ModalDetail>

                <ModalSubTitle>Approver Status</ModalSubTitle>
                {userInfo.approver_status === '' && (
                  <Button onClick={() => handleSubcategoryClick()} size="md" className="py-2 mt-2 btn-block transform-lowercase" variant="success">Become Approver</Button>
                )}
                {userInfo.approver_status === 'approved' && (
                  <ModalDetail>
                    <span
                      className="badge px-2 py-1 text-capitalize"
                      style={{ backgroundColor: '#4ce1b6' }}
                    >
                      <h6 className="text-white"><b>{userInfo.approver_status}</b></h6>
                    </span>
                  </ModalDetail>
                )}
                {userInfo.approver_status === 'waiting' && (
                  <ModalDetail>
                    <span
                      className="badge px-2 py-1 text-capitalize"
                      style={{ backgroundColor: '#f6da6e' }}
                    >
                      <h6 className="text-white"><b>{userInfo.approver_status}</b></h6>
                    </span>
                  </ModalDetail>
                )}
                {userInfo.approver_status === 'pending' && (
                  <ModalDetail>
                    <span
                      className="badge px-2 py-1 text-capitalize"
                      style={{ backgroundColor: '#f6da6e' }}
                    >
                      <h6 className="text-white"><b>{userInfo.approver_status}</b></h6>
                    </span>
                  </ModalDetail>
                )}
                {userInfo.approver_status === 'rejected' && (
                  <ModalDetail>
                    <span
                      className="badge px-2 py-1 text-capitalize"
                      style={{ backgroundColor: '#ff4861' }}
                    >
                      <h6 className="text-white"><b>{userInfo.approver_status}</b></h6>
                    </span>
                  </ModalDetail>
                )}

                <ModalSubTitle>Two Factor</ModalSubTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                  <div>Off</div>
                  <CustomSwitch
                    {...label}
                    value={twoFactors}
                    checked={twoFactors}
                    onChange={(e) => {
                      setloading(true);
                      setTwoFactor(e.target.checked);
                      SendTwoFactor()
                        .then(() => {
                          setloading(false);
                          getUsers();
                        }).catch((err) => {
                          // console.log('===2fa', err, err?.message);
                          setMsg(err?.message);
                          setTwoFactor(null);
                          setloading(false);
                        });
                    }}
                    onClick={() => settwoFactorModal(true)}
                    color="default"
                  />
                  <div>On</div>
                </Stack>
                {tfaUrl && (
                  <>
                    <QRCode size="120" level="H" value={tfaUrl} renderAs="canvas" />
                    <input
                      type="text"
                      name="title"
                      id="title"
                      placeholder="Enter Code"
                      value={rejectionReason}
                      onChange={e => setRejectionReason(e.target.value)}
                      className="form-control mt-2"
                    />
                    <h1 onClick={handleRejectOrder}>Submit</h1>
                  </>
                )}
                <ModalSubTitle>Two Factor 2</ModalSubTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                  <div>Off</div>
                  <CustomSwitch
                    {...label}
                    value={twoFactors2}
                    checked={twoFactors2}
                    onChange={(e) => {
                      handleTwoFactorChange(e);
                    }}
                    color="default"
                  />
                  <div>On</div>
                </Stack>
              </ModalBody>

            </>
          )}

        </StyledModal>
      </div>
      <SimpleModal isActive={twoFactorModal} onClose={() => settwoFactorModal(false)}>
        {
          loading ? (
            <ModalBody>
              <SpinnerDiv>
                <Spinner style={{ color: colorAccent }} />
              </SpinnerDiv>
            </ModalBody>
          ) : (
            <ModalDiv>
              {twoFactors
                ? <span className="lnr lnr-checkmark-circle" style={{ color: colorAccent, fontSize: '55px' }} />
                : twoFactors === null ? (
                  <span className="lnr lnr-warning" style={{ color: 'red', fontSize: '55px' }} />
                ) : (
                  <span className="lnr lnr-circle-minus" style={{ color: 'red', fontSize: '55px' }} />
                )}
              { }
              <TwoFactorTitle style={{ color: twoFactors ? colorAccent : '#ff000096' }}>
                {twoFactors ? 'Two Factor Enabled' : twoFactors === null
                  ? 'Something Went Wrong !!'
                  : 'Two Factor Disabled'}
                { }
              </TwoFactorTitle>
              <TwoFactorSubTitle>
                {
                  twoFactors === null ? Msg
                    : (' Status Changed Successfully!!')
                }
              </TwoFactorSubTitle>
              <div>
                <AccountButton
                  variant="outline-success"
                  onClick={() => {
                    settwoFactorModal(false);
                  }}
                  style={{
                    padding: '10px 20px',
                  }}
                >
                  ok
                </AccountButton>
              </div>

            </ModalDiv>
          )
        }

      </SimpleModal>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isModalOpen1}
        onHide={closeAcceptModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: 'large' }}>Become Approver</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mt-0 containerDiv" style={{ overflowX: 'hidden' }}>
            <div>
              <div className="row mt-0 mb-2">
                <div className="col-12">
                  <label
                    htmlFor="description"
                    style={{ color: '#7b7b7b' }}
                    className="form-label mb-1"
                  > <b>Approve Comment</b>
                  </label>
                  <textarea
                    value={acceptReason}
                    onChange={e => setAcceptReason(e.target.value)}
                    className="form-control"
                    rows={2}
                    cols={50}
                    placeholder="Enter Approve Comment"
                  />
                  <label
                    htmlFor="description"
                    style={{ color: '#7b7b7b' }}
                    className="form-label mb-1 mt-2"
                  > <b>Approve Doc</b>
                  </label>
                  <input
                    type="file"
                    className="form-control mt-0"
                    onChange={handleFileChange1}
                    id="File"
                    name="File"
                    accept=".pdf"
                  />
                </div>
                <div className="col-12 my-2">
                  <button
                    type="button"
                    className="btn purple-button btn-block w-100 shadow-none text-capitalize"
                    onClick={handleApprove}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

MyAccount.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
};

MyAccount.defaultProps = {
  title: '',
  message: '',
  colored: false,
  header: false,
};

export default MyAccount;

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }
  
  flex-flow: ${flexFlow} !important;
`;

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size:20px;
  &:first-child {
    margin-top: 0;
  }
`;
const ModalDetail = styled.h5`
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 5700;
text-align:left;
  &:first-child {
    margin-top: 0;
  }
`;
const ModalSubTitle = styled.h4`
  margin-top: 10px;
  font-weight: 600;
font-size:16px;
text-align:left;
color:${colorAccent};
  &:first-child {
    margin-top: 0;
  }
`;
const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`;

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`;

const ModalHeader = styled.div``;
const ModalBody = styled.div``;

const getColor = (color) => {
  switch (color) {
    case 'primary':
      return colorBlue;
    case 'success':
      return colorAccent;
    case 'warning':
      return colorYellow;
    case 'danger':
      return colorRed;

    default:
      return colorText;
  }
};

const StyledModal = styled(BootstrapModal).withConfig({
  shouldForwardProp: prop => !['colored', 'header'].includes(prop),
})`
  
  .modal-dialog {
    max-width: 450px;
  }

  .modal-content {
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }
  
  ${ModalTitleIcon} {
    color: ${props => getColor(props.color)};
  }

  ${props => props.colored && `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${getColor(props.color)};
    }
  `}

  ${props => props.header && `
    
    .modal-dialog {
      max-width: 520px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 40px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${getColor(props.color)};
    }
  `}
`;
export const ModalDiv = styled.div`
height: 16rem;
justify-content: space-between;
display: flex;
align-items: center;
flex-direction: column;
`;
export const TwoFactorTitle = styled.h4`
   padding: 5px; 
   font-size: 18px;
    font-weight: 700;
     text-align: center;
      margin-bottom: 1rem;
       margin-top: 1rem;
       color:${colorAccent};
`;
export const TwoFactorSubTitle = styled.h6`
   padding: 5px; 
   font-size: 16px;
    font-weight: 500;
     text-align: center;
      margin-bottom: 1rem;
`;
// endregion
