import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Formik } from "formik";
import Panel from "@/shared/components/Panel";
import "react-toastify/dist/ReactToastify.css";
import "../../../store/Layout/style/product.css";
import {
  getApprover,
  getApprovers,
  getFee,
  sendCustomOffer,
} from "../../../utils/services/user.service";
import { CustomSelect } from "../CustomSelect";
import { toast } from "react-toastify";
import { Ring } from "@uiball/loaders";

const CustomOfferModal = ({
  showModal,
  closeModal,
  product,
  SelectedColor,
}) => {

  const history = useHistory();
  const [cartData, setCartData] = useState([]);
  const [customOfferPrice, setCustomOfferPrice] = useState(null);
  const [Countries, setCountries] = useState([]);
  const [setDeliveryCharges, setDeliveryChargesTotal] = useState(0);
  const [cartSybmol, setCartSybmol] = useState("");
  const [saleTaxFee, setSaleTaxFee] = useState(0);
  const [Approvers, setApprovers] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [showError1, setShowError1] = useState(false);
  const [getApproverID, setApproverID] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let countriesArray = [];
  let dataArray = [];
  let total1 = 0;

  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        // Extract specific data and store it in the countriesArray
        // eslint-disable-next-line react-hooks/exhaustive-deps
        countriesArray = data.map((country) => ({
          label: country.name.common,
          value: country.name.common,
        }));

        // Now you have the data in the countriesArray
        setCountries(countriesArray);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    const userAdress = JSON.parse(localStorage.getItem("user"));
    let storedCustomArray = [];
    let parsedCustomArray = [];
    if (localStorage.getItem("customArray") != null) {
      storedCustomArray = localStorage.getItem("customArray");
      parsedCustomArray = JSON.parse(storedCustomArray);
    }
    parsedCustomArray = parsedCustomArray.filter(
      (item) => item.userId === userAdress.data.user.user_id
    );
    setCartData(parsedCustomArray);
    if (parsedCustomArray.length > 0) {
      setCartSybmol(parsedCustomArray[0].symbol);
    }

    // const calculateUpdatedPrice = (price, quantity) => price * quantity;
    // console.log('calculateUpdatedPrice', calculateUpdatedPrice);

    if (parsedCustomArray != null) {
      parsedCustomArray.forEach((data) => {
        const itemTotal = data.deliveryCharges;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        total1 += itemTotal;
      });
      setDeliveryChargesTotal(total1);
    }
  }, []);

  const approverOptions = Approvers.map((approver) => ({
    value: approver.address,
    label: approver.name,
  }));
  const handleCountryChange = (value) => {
    setSelectedCountry(value.value);
    setShowError1(false);
  };
  const handleApproverChange = (event) => {
    const selectedID = event.value;
    setApproverID(selectedID);
  };
  const handleDeliveryAddressChange = (e) => {
    setDeliveryAddress(e.target.value);
  };

  const fetchgetFee = async () => {
    try {
      const requestData = {
        type: "sale_tax",
        categoryId: product?.category_id,
      };
      const response = await getFee(requestData);

      if (response.data) {
        const saleTaxFee = response?.data?.data?.fee;
        setSaleTaxFee(saleTaxFee);
      } else {
        console.error("getFee:", response.statusText);
      }
    } catch (error) {
      setSaleTaxFee(0);
    }
  };

  useEffect(() => {
    if (product) {
      fetchgetFee();
    }
  }, [product]);

  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        // Extract specific data and store it in the countriesArray
        // eslint-disable-next-line react-hooks/exhaustive-deps
        countriesArray = data.map((country) => ({
          label: country.name.common,
          value: country.name.common,
        }));

        // Now you have the data in the countriesArray
        setCountries(countriesArray);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    const fetchgetApprovers = async () => {
      try {
        const response = await getApprovers(); // Replace with your API call for approvers
        if (response.data && response.data.user.length > 0) {
          const approverInfo = response.data.user; // Extract the first approver
          approverInfo.sort((a, b) => a.name.localeCompare(b.name));
          setApprovers(approverInfo);
        } else {
          console.error("Error fetching approver data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching approver data:", error);
      }
    };

    fetchgetApprovers();
  }, []);

  const originalPrice = product?.product_sizes_quantity[0]?.price;
  const discountPercentage = product?.product_sizes_quantity[0]?.discount || 0
  const discountedPrice =
    originalPrice - originalPrice * (discountPercentage / 100);

  const handleSendCustomOffer = async () => {
    try {
      setIsLoading(true);
      const payload = {
        symbol: product.symbol,
        total_delivery_charges: product.deliveryCharges,
        chain_id: product?.chainId,
        store_id: product?.store_id,
        category_id: product?.category_id,
        custom_offer_amount: customOfferPrice,
        orignal_price: product?.product_sizes_quantity[0]?.price,
        total_quantity: 1,
        delivery_country: selectedCountry,
        delivery_address: deliveryAddress,
        seller_user_id: product?.userId,
        product_details: product?.product_sizes_quantity,
        approver_wallet_Address: product?.approver_wallet_Address,
        buyer_approver_address: getApproverID,
        product_id: product?._id,
        sale_tax: saleTaxFee,
        seller_wallet: product?.seller_name[0]?.address,
        agency_percentage: product?.agency_percentage,
      };
      const res = await sendCustomOffer(payload);
      if (res) {
        toast.success(res?.data?.Message);
        setIsLoading(false);
        closeModal();
      }
    } catch (error) {
      toast.error(error?.response?.data.Message);
      setIsLoading(false);
      // You can handle the error here, e.g., show a message to the user
    }
  };

  if (!product) {
    return (
      <div className="container mt-4 containerDiv">
        <div className="row">
          <div className="col-12 text-center">
            {" "}
            <h1 className="text-white"> No Product Found</h1>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: "large" }}>
            <b>Send Custom Offer</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#232329",
          }}
        >
          <div
            className="loader-overlay"
            style={{
              display: isLoading ? "flex" : "none", // Use flex display when loading
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(255, 255, 255, 0.8)",
              zIndex: 999,
            }}
          >
            <Ring size={65} color="#00897B" />
          </div>
          <Panel
            lg={12}
            title="Send Custom Offer"
            closeBtn
            minusBtn
            refreshLoad
          >
            <Formik
              enableReinitialize
              initialValues={{
                quantity: 1,
                pickupAddress: "",
                price: "",
              }}
              onSubmit={(values) => {
                handleSendCustomOffer();
              }}
              //   validate={(values) => {
              //     const errors = {};
              //     if (!values.quantity) {
              //     //   errors.quantity = "Quantity is Required!";
              //     }
              //     return errors;
              //   }}
            >
              {(props) => {
                const { handleSubmit } = props;

                return (
                  <form onSubmit={handleSubmit}>
                    <div className="container mt-4 containerDiv">
                      <div className="row">
                        <div className="col-12">
                          <div style={{ overflowY: "auto" }}>
                            <table className="table text-center table-hover">
                              <thead className="thead-light">
                                <tr>
                                  <th style={{ fontWeight: "bold" }}>Image</th>
                                  <th style={{ fontWeight: "bold" }}>Name</th>
                                  <th style={{ fontWeight: "bold" }}>Color</th>
                                  {/* <th style={{ fontWeight: 'bold' }}>Size</th> */}
                                  <th style={{ fontWeight: "bold" }}>
                                    Quantity
                                  </th>
                                  <th style={{ fontWeight: "bold" }}>Price</th>
                                  <th style={{ fontWeight: "bold" }}>
                                    Discount
                                  </th>
                                  <th style={{ fontWeight: "bold" }}>
                                    After Discount
                                  </th>
                                  <th style={{ fontWeight: "bold" }}>
                                    Shipping
                                  </th>
                                  {/* <th style={{ fontWeight: "bold" }}>Total</th> */}
                                  {/* <th style={{ fontWeight: "bold" }}>Action</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {product.length === 0 ? (
                                  <tr>
                                    <td colSpan="11">
                                      No Product Avaiable in Cart
                                    </td>
                                  </tr>
                                ) : (
                                  // eslint-disable-next-line no-underscore-dangle
                                  <tr>
                                    <td
                                      style={{
                                        width: "8%",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <div className="square-img-wrapper1">
                                        <img
                                          src={product?.pictures[0]}
                                          className="d-block w-100 card-img-top square-img1"
                                          alt="img"
                                        />
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        verticalAlign: "middle",
                                        textTransform: "Capitalize",
                                      }}
                                    >
                                      {product.title}
                                    </td>
                                    {SelectedColor ? (
                                      <td style={{ verticalAlign: "middle" }}>
                                        {SelectedColor}
                                      </td>
                                    ) : (
                                      <td style={{ verticalAlign: "middle" }}>
                                        -
                                      </td>
                                    )}
                                    <td
                                      style={{
                                        width: "15%",
                                        verticalAlign: "middle",
                                      }}
                                      className="text-center"
                                    >
                                      <b
                                        style={{
                                          color: "gray",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {"1"}
                                      </b>
                                    </td>
                                    <td style={{ verticalAlign: "middle" }}>
                                      {
                                        originalPrice
                                      }{" "}
                                      {product.symbol}
                                    </td>
                                    <td style={{ verticalAlign: "middle" }}>
                                      {
                                        discountPercentage
                                      }{" "}
                                      %
                                    </td>
                                    <td style={{ verticalAlign: "middle" }}>
                                      {discountedPrice}
                                      <b
                                        style={{
                                          color: "gray",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {" "}
                                        {product.symbol}
                                      </b>
                                    </td>
                                    <td style={{ verticalAlign: "middle" }}>
                                      {product.deliveryCharges}{" "}
                                      <b
                                        style={{
                                          color: "gray",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {" "}
                                        {product.symbol}
                                      </b>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="row pb-4">
                          <div className="col-4">
                            <InputDiv>
                              <DropdownDiv className="mb-0">
                                <label
                                  htmlFor="chain"
                                  className="form-label text-white"
                                >
                                  Delivery Country *
                                </label>
                                <CustomSelect
                                  name="deliveryCountry"
                                  id="deliveryCountry"
                                  value={selectedCountry}
                                  onChange={handleCountryChange}
                                  options={Countries}
                                />
                              </DropdownDiv>
                            </InputDiv>
                          </div>
                          <div className="col-4">
                            <InputDiv>
                              <DropdownDiv className="">
                                <label
                                  htmlFor="subcategory_id"
                                  className="form-label text-white"
                                >
                                  Buyer Approver
                                </label>
                                <CustomSelect
                                  name="subcategory_id"
                                  id="subcategory_id"
                                  className={`form-control w-25`}
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      textTransform: "capitalize",
                                    }),
                                  }}
                                  value={getApproverID}
                                  options={approverOptions}
                                  onChange={handleApproverChange}
                                />
                              </DropdownDiv>
                            </InputDiv>
                          </div>
                          <div className="col-4 mt-1">
                            <label
                              htmlFor="brand"
                              className="form-label text-white"
                              style={{ fontSize: "14px" }}
                            >
                              Delivery Address *
                            </label>
                            <input
                              type="text"
                              className="form-control w-100 bg-transparent "
                              placeholder="Enter Delivery Address"
                              name="deliveryAddress"
                              style={{ color: "#b1b1b1" }}
                              id="deliveryAddress"
                              value={deliveryAddress}
                              onChange={handleDeliveryAddressChange}
                            />
                            {/* <ChatSearchInput
                              type="text"
                              value={deliveryAddress}
                              name="deliveryAddress"
                              id="deliveryAddress"
                              placeholder="Enter Delivery Address"
                              onChange={handleDeliveryAddressChange}
                              className={`form-control`}
                            /> */}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-8 float-right" />
                        <div className="col-lg-4 float-right">
                          <div className="row text-start py-2">
                            <div className="col-6">
                              <label
                                style={{ fontSize: "small" }}
                                htmlFor="features"
                                className="form-label text-white mt-4"
                              >
                                Custom Offer
                              </label>
                            </div>
                            <div className="col-6">
                              <InputDiv>
                                <ChatSearchInput
                                  type="number"
                                  value={customOfferPrice}
                                  className="form-control mt-2"
                                  placeholder="Custom offer Price"
                                  onChange={(e) =>
                                    setCustomOfferPrice(e.target.value)
                                  }
                                />
                              </InputDiv>
                            </div>

                            <div className="col-6">
                              <h5 className="currentPrice mt-3">
                                Total Quantity
                              </h5>
                            </div>
                            <div className="col-6">
                              <h5 className="currentPrice mt-3">1</h5>
                            </div>
                            <div className="col-6">
                              <h5 className="currentPrice mt-3">
                                Shipping Charges
                              </h5>
                            </div>
                            <div className="col-6">
                              <h5 className="currentPrice mt-3">
                                {product.deliveryCharges}
                                <b style={{ color: "gray", fontWeight: "500" }}>
                                  {" "}
                                  {product.symbol}
                                </b>
                              </h5>
                            </div>

                            <div className="col-6">
                              <h5 className="currentPrice mt-3">Sales Tax</h5>
                            </div>
                            <div className="col-6">
                              <h5 className="currentPrice mt-3">
                                {saleTaxFee}
                                <b style={{ color: "gray", fontWeight: "500" }}>
                                  {" "}
                                  {product.symbol}
                                </b>
                              </h5>
                            </div>

                            <div className="col-6">
                              <h5 className="currentPrice mt-3">
                                {" "}
                                Grand Total
                              </h5>
                            </div>
                            <div className="col-6">
                              <h5 className="currentPrice mt-3">
                                {Number(customOfferPrice) +
                                  Number(product.deliveryCharges)}
                                <b style={{ color: "gray", fontWeight: "500" }}>
                                  {" "}
                                  {product.symbol}
                                </b>
                              </h5>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12">
                              {product && (
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-block w-40 purple-button mt-3 shadow-none text-capitalize"
                                  disabled={
                                    customOfferPrice <= 0 ||
                                    !selectedCountry ||
                                    !deliveryAddress
                                  }
                                >
                                  Send Custom Offer
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </Panel>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CustomOfferModal;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;