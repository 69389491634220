import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import { useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { MdOutlineNumbers } from 'react-icons/md';
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';


import FormField from '../../form/FormField';
import { AccountButton } from '../AccountElements';
import { clearMsg } from '../../../../redux/actions/authActions';

const ResetOtpForm = ({
  onSubmit, fieldUser, name, errorMsg, successMsg, isRegister, loading,
}) => {
  const dispatch = useDispatch();
  return (
    <Form
      onSubmit={onSubmit} 
      validate={(values) => {
    const errors = {};
      const otpRegex = /^[0-9\b]+$/;
      if (!values.otp) {
        errors.otp = 'Otp is Required!!';
      } else if (!otpRegex.test(values.otp)) {
        errors.otp = 'Invalid Otp!! Must be in digits';
      }
    return errors;
  }}
    >
      {({ handleSubmit }) => (
        <FormContainer onSubmit={handleSubmit}>
          {/* <Alert
            variant="danger"
            show={!!isRegister}
            style={{
 marginTop: '1rem', color: 'red', width: '100%', textAlign: 'center', 
}}
            onClose={() => dispatch(clearMsg())}
            dismissible
          >
            {errorMsg}
          </Alert> */}
          <Alert
            variant="success"
            show={!!isRegister}
            style={{
 marginTop: '1rem', color: 'black', width: '100%', textAlign: 'center', 
}}
            onClose={() => dispatch(clearMsg())}
            dismissible
          >
            {successMsg}
          </Alert>
          <FormGroup>
            <div>
              <FormGroupLabel>{fieldUser}</FormGroupLabel>
            </div>
            <FormGroupField>
              <FormGroupIcon>
                <AlternateEmailIcon />
              </FormGroupIcon>
              <Field
                name="email"
                id="email"
                component={FormField}
                type="email"
                placeholder={JSON.parse(localStorage.getItem('newUser'))}
                value={JSON.parse(localStorage.getItem('newUser'))}
                disabled
                className="input-without-border-radius"
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <div>
              <FormGroupLabel>{fieldUser}</FormGroupLabel>
            </div>
            <FormGroupField>
              <FormGroupIcon>
                {name === 'email' 
              ? <AlternateEmailIcon />
              : <MdOutlineNumbers />}
              </FormGroupIcon>
              <Field
                name={name === 'email' ? 'email' : 'otp'}
                id="email"
                component={FormField}
                type={name === 'email' ? 'email' : 'text'}
                placeholder={name === 'email' ? 'example@mail.com' : 'Enter OTP'}
                className="input-without-border-radius"
              />
            </FormGroupField>
          </FormGroup>
          <AccountButton variant={loading ? 'secondary' : 'primary'} type="submit">
            {loading ? 'Loading...' : ' Verify Otp'}
          </AccountButton>
        </FormContainer>
    )}
    </Form>
); 
};

ResetOtpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fieldUser: PropTypes.shape(),
};

ResetOtpForm.defaultProps = {
  fieldUser: null,
};

export default ResetOtpForm;
