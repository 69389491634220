/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable max-len */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MenuIcon from "mdi-react/MenuIcon";
import { toast } from "react-toastify";
import Badge from "react-bootstrap/Badge";
import MoreVertIcon from "mdi-react/MoreVertIcon";
import VideoIcon from "mdi-react/VideoIcon";
import PhoneIcon from "mdi-react/PhoneIcon";
import StarIcon from "mdi-react/StarIcon";
import moment from "moment";
import io from "socket.io-client";
import Dropdown from "react-bootstrap/Dropdown";
import { ContactProps } from "@/shared/prop-types/ChatProps";
import {
  colorAccent,
  colorAdditional,
  colorBackground,
  colorFieldsBorder,
  colorIcon,
} from "@/utils/palette";
import { marginLeft, marginRight, left } from "@/utils/directions";
import config from "../../../../config/config";
import {
  blockUser,
  unblockUser,
} from "../../../../utils/services/user.service";
import { useChainContext } from "../../../context/context";

const ChatTopbar = ({
  ChatId,
  onClick,
  contact,
  getChatDataNew,
  getChatDataNewblock,
  getChatDataNewUNblock,
  typingStatus
}) => {
  const [status, setStatus] = useState();

  // const socket = io(config.baseURL);
  const {socket}=useChainContext()
  const [dropdownValue, setDropdownValue] = useState("");
  // if (contact && contact.online_status && contact.online_status.length > 0) {
  //   setStatus(contact.online_status)
  // }
  // console.log("contact topbar", contact);
  const CloseChat = () => {
    socket.emit(
      "close_chat",
      {
        chat_id: ChatId,
        user_id: JSON.parse(localStorage.getItem("user")).data.user.user_id,
        // message: Option,
      },
      (response) => {
        // console.log("CloseChat, server response:", response);
      }
    );
    getChatDataNew();
  };
  const getBlockUser = () => {
    const payload = {
      user_id: contact?.user_detail ? contact?.user_detail[0]?._id : null,
      blocker_id: JSON.parse(localStorage.getItem("user")).data.user.user_id,
    };
    blockUser(payload)
      .then((data) => {
        toast.success(data.data.Message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        getChatDataNewblock();
      })
      .catch((error) => {
        console.error("Error blockUser: ", error);
      });
  };
  const getUnBlockUser = () => {
    const payload = {
      user_id: contact?.user_detail ? contact?.user_detail[0]?._id : null,
      blocker_id: JSON.parse(localStorage.getItem("user")).data.user.user_id,
    };
    unblockUser(payload)
      .then((data) => {
        toast.success(data.data.Message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        getChatDataNewUNblock();
      })
      .catch((error) => {
        console.error("Error blockUser: ", error);
      });
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </span>
  ));
  // Function to handle dropdown value changes
  const handleDropdownChange = (e) => {
    const statusVal = e.currentTarget.getAttribute("data-status");
    // console.log('event', statusVal);
    setDropdownValue(statusVal);
  };
  // const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  //   <span
  //     ref={ref}
  //     onClick={(e) => {
  //       e.preventDefault();
  //       onClick(e);
  //     }}
  //   >
  //     {children}
  //   </span>
  // ));


  const getStatus = () => {
    if (contact && contact.online_status && contact.online_status.length > 0) {
      const latestStatus =
        contact.online_status[contact.online_status.length - 1]; // Assuming the latest status is at the end of the array
      // console.log('latestStatus contact>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', latestStatus);
      if (latestStatus.online_status) {
        return "Online";
      }
      const lastSeenTime = moment(latestStatus.updatedAt).format("lll");
      return `Last seen ${lastSeenTime}`;
    }
    return "Offline"; // Handling the case when online status array is empty or unavailable
  };

  return (
    <ChatTopbarWrap>
      <ChatTopbarButtonMenu type="button" onClick={onClick}>
        <MenuIcon />
      </ChatTopbarButtonMenu>
      {contact && (
        <>
          <ChatTopbarContact>
            <ChatTopBbarContactName>
              {
                contact.type === "room" || contact.type === "otc-room" ? (
                  contact.type === "otc-room" ? contact.subject : contact.subject
                ) : (
                  contact.user_detail
                    ? contact.user_detail[0]?.name
                    : contact?.name
                )
              }
            </ChatTopBbarContactName>

            <ChatTopbarContactStatus>
              {typingStatus && <span className="text-success">{typingStatus}</span>}
              {!typingStatus && getStatus()}
              {getStatus() === "Online" && !typingStatus && (
                <Badge
                  pill
                  className="ml-1 mt-1"
                  bg="success"
                  style={{
                    width: "13px",
                    height: "13px",
                  }}
                >
                  <span className="text-success">.</span>
                </Badge>
              )}
            </ChatTopbarContactStatus>
          </ChatTopbarContact>
          {contact.type === "room" && contact.buyer[0] && (
            <ChatTopbarContact className="text-center float-right">
              <ChatTopBbarContactName>Buyer</ChatTopBbarContactName>
              <ChatTopbarContactStatus>
                <span className="text-capitalize">{contact.buyer[0]?.name}</span>
              </ChatTopbarContactStatus>
            </ChatTopbarContact>
          )}
          {contact.type === "room" && contact.approver[0] && (
            <ChatTopbarContact className="text-center float-right">
              <ChatTopBbarContactName>Approver</ChatTopBbarContactName>
              <ChatTopbarContactStatus>
                <span className="text-capitalize">
                  {contact.approver[0].name}
                </span>
              </ChatTopbarContactStatus>
            </ChatTopbarContact>
          )}
          {contact.type === "room" && contact?.seller[0] && (
            <ChatTopbarContact className="text-center float-right">
              <ChatTopBbarContactName>Seller</ChatTopBbarContactName>
              <ChatTopbarContactStatus>
                <span className="text-capitalize">
                  {contact.seller[0]?.name}
                </span>
              </ChatTopbarContactStatus>
            </ChatTopbarContact>
          )}
        </>
      )}

      <ChatTopbarRight hidden={contact && contact.status === "close"}>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
            <MoreVertIcon style={{ color: "white" }} />
          </Dropdown.Toggle>

          {contact ? (
            <Dropdown.Menu className="py-1">
              {
                contact.status === "open" && contact.type !== "user" ? (
                  <Dropdown.Item
                    className="hover:bg-gray-200 hover:text-gray-800"
                    data-status="close"
                    onClick={CloseChat}
                  >
                    Close
                  </Dropdown.Item>
                ) : null /* You can add other items here as needed */
              }
              {contact.type !== "support" &&
                contact.block_user &&
                contact.block_user.length === 0 ? (
                <Dropdown.Item
                  className="hover:bg-gray-200 hover:text-gray-800"
                  data-status="close"
                  onClick={getBlockUser}
                >
                  Block User
                </Dropdown.Item>
              ) : null}
              {contact.type !== "support" &&
                contact.block_user &&
                contact.block_user.length > 0 &&
                contact.block_user[0].blocker_user_id ===
                JSON.parse(localStorage.getItem("user")).data.user.user_id ? (
                <Dropdown.Item
                  className="hover:bg-gray-200 hover:text-gray-800"
                  data-status="close"
                  onClick={getUnBlockUser}
                >
                  Unblock User
                </Dropdown.Item>
              ) : null}
            </Dropdown.Menu>
          ) : (
            <Dropdown.Menu className="py-1">
              {/* If contact is null or has no data, you can render some default content */}
              <Dropdown.Item disabled>No options available</Dropdown.Item>
            </Dropdown.Menu>
          )}
        </Dropdown>
      </ChatTopbarRight>
    </ChatTopbarWrap>
  );
};

ChatTopbar.propTypes = {
  onClick: PropTypes.func.isRequired,
  contact: ContactProps,
};

ChatTopbar.defaultProps = {
  contact: null,
};

export default ChatTopbar;

// region STYLES
// const Dropdown = styled.div`
//   position: relative;
//   display: inline-block;
// `;

const DropdownButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 50px; /* Adjust the width as needed */
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 260px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;

  ${Dropdown}:hover & {
    display: block;
  }
`;

const DropdownButtonOption = styled.button`
  background-color: #f9f9f9;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 200px;
  text-align: left;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;
const ChatTopbarContactStatus = styled.p`
  margin-bottom: 10px;
  color: ${colorAdditional};
  font-size: 11px;
  line-height: 1.45;
`;
const ChatTopbarWrap = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  border-bottom: 1px solid ${colorFieldsBorder};
  background-color: ${colorBackground};
`;

const ChatTopbarButton = styled.button`
  background-color: transparent;
  border: none;
  transition: all 0.3s;
  cursor: pointer;
  width: 50px;
  height: 50px;

  svg {
    height: 18px;
    width: 18px;
    transition: 0.3s;
    fill: ${colorIcon};
  }

  &:hover svg {
    fill: ${colorAccent};
  }
`;

const ChatTopbarButtonMenu = styled(ChatTopbarButton)`
  ${marginRight}: -20px;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const ChatTopbarControls = styled.div`
  display: none;

  @media screen and (min-width: 698px) {
    display: flex;
  }
`;

const ChatTopbarRight = styled.div`
  ${marginLeft}: auto;
  display: flex;
`;

const ChatTopbarContact = styled.div`
  text-align: ${left};
  ${marginLeft}: 20px;
`;

const ChatTopBbarContactName = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  text-transform: capitalize;
`;

const ChatTopbarContactPost = styled.p`
  margin: 0;
  color: ${colorAdditional};
  font-size: 11px;
  line-height: 1.45;
`;

// endregion
