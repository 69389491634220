import React, { useEffect, useState } from "react";
// import Modal from 'react-modal';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Badge, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

import { toast } from "react-toastify";
import { Ring } from "@uiball/loaders";
import Panel from "@/shared/components/Panel";
import "react-toastify/dist/ReactToastify.css";

import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import { getFees } from "../../../utils/services/user.service";
import moment from "moment";

const CustomOffer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allFees, setAllFees] = useState([]);
  const [reFetch, setRefetch] = useState(false);



  
  useEffect(() => {
    let isMounted = true; // track whether the component is mounted
  
    const fetchFees = async () => {
      try {
        setIsLoading(true);
        const res = await getFees();
        if (isMounted) {
          setAllFees(res?.data?.data);
        }
      } catch (error) {
        // Handle any errors here
        console.error("Error fetching fees:", error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
  
    fetchFees();
  
    return () => {
      isMounted = false; // cleanup function to set isMounted to false
    };
  }, [reFetch]);
  
  const tableConfig = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search...",
    isPagination: true,
  };
  const header = [
    { accessor: "Fee_id", Header: "#" },
    { accessor: "updateAt", Header: "Update At" },
    { accessor: "fee", Header: "Fee" },
    { accessor: "percentage", Header: "Percentage" },
    { accessor: "symbol", Header: "symbol" },
    { accessor: "type", Header: "Type" },
    { accessor: "sub_type", Header: "Sub Type" },
  ];

  const data = [];
  allFees.forEach((item) => {
    data.push({
      Fee_id: item?._id,
      updateAt: (<span>
        {moment(item?.updatedAt).format("MMMM Do YYYY")}
      </span>),
      fee: (<span>{item?.fee}</span>),
      percentage: (<span>{item?.percentage}%</span>),
      type: item?.type,
      sub_type: item?.sub_type,
      symbol: item.symbol,
    });
  });

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <span className="mt-4"></span>
      <Panel
        lg={12}
        title="Fees"
          refreshRequest={() => setRefetch(!reFetch)}
      >
        <ReactTableBase
          columns={header}
          data={data}
          tableConfig={tableConfig}
        />
      </Panel>
    </>
  );
};

export default CustomOffer;
