import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReceiveTable from './component/ReceiveTable';

const ReceivePage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Receive</h3>
      </Col>
    </Row>
    <Row>
      <ReceiveTable />
    </Row>

  </Container>
  );

export default ReceivePage;
