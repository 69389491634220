import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ServiceDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
// console.log(product);
  useEffect(() => {
    fetch(`https://jsonplaceholder.typicode.com/photos/${id}`)
      .then(response => response.json())
      .then(data => setProduct(data))
      .catch(error => console.error('Error fetching data:', error));
  }, [id]);

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4">Service Details</h2>
      <div className="card">
        <img src={product.thumbnailUrl} className="card-img-top" alt={product.title} />
        <div className="card-body">
          <h5 className="card-title text-white">{product.title}</h5>
          <p className="card-text">Product ID: {product.id}</p>
          {/* Display other properties here */}
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
