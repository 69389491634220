import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { CustomSelect } from "../CustomSelect";
import { useState, useEffect } from "react";
import {
  getToken,
  swapOtcMarketPlacePackge,
  swapOtcMarketPlacePackgeSol,
  swapOverviewMarketPlacePackge,
  swapOverviewMarketPlacePackge_general,
  verifyTFAEmail,
} from "../../../utils/services/user.service";
import { Button } from "@/shared/components/Button";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import { pool2Options } from "../../../utils/constants/contants";

const SwapModal = ({
  marketplaceData,
  show,
  hide,
  reFetch,
  swapData,
  swapPair,
}) => {
  const [selectedChainId, setSelectedChainId] = useState(null);
  const [selectedPoolLabel, setSelectedPoolLabel] = useState(null);
  const [toCoinValue, setToCoinValue] = useState("");
  const [toCoinLabel, setToCoinLabel] = useState("");
  const [swapAmount, setSwapAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [matchedTokens, setMatchedTokens] = useState([]);

  const [allTokens, setAllTokens] = useState([]);
  const [isTfaVarified, setIsTfaVarified] = useState(false);
  const userSessionData = JSON.parse(localStorage.getItem("user"));
  const [tfaCode, setTfaCode] = useState("");

  const handleTfaCode = async () => {
    const requestData = {
      token: tfaCode,
      email: userSessionData.data.user.email,
    };

    try {
      setIsLoading(true);
      const res = await verifyTFAEmail(requestData);

      if (res) {
        setIsLoading(false);
        if (res.data.verified === false) {
          setTfaCode("");
          toast.error("Incorrect TFA Code", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (res.data.verified === true) {
          setIsTfaVarified(true);
          toast.success("Verify TFA Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setIsLoading(false);
          setTfaCode("");
          toast.error("Verify TFA failed", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        setIsLoading(false);
        console.error("verifyTFA failed:", res);
      }
    } catch (error) {

      setIsLoading(false);
      toast.error(error.response.data.Message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const chainIds = [
    { value: 1, label: "ETHEREUM" },
    { value: 56, label: "BSC" },
    { value: 137, label: "MATIC" },
    { value: 97, label: "BSC Smart Chain" },
  ];
  const fetchTokens = async () => {
    setIsLoading(true);

    const payload = {
      chain_id: swapData.chain_id,
    };
    const res = await getToken(payload);

    setAllTokens(res.data.data);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchTokens();
  }, []);
  useEffect(() => {
    const selectedChain = chainIds.find(
      (chain) => chain.value === swapData.chain_id
    );
    if (selectedChain) {
      setSelectedChainId(selectedChain.value);
    }
    const selectedPool = pool2Options.find(
      (pool) => pool.label === swapData.pool.split("-")[1]
    );
    if (selectedPool) {
      setSelectedPoolLabel(selectedPool.label);
    }
  }, [swapData, chainIds]);
  useEffect(() => {
    const matchTokens = () => {
      const newArray = swapPair.map((pair) => {
        const token = pool2Options?.find(
          (token) => token?.label === pair?.symbol
        );
        return token ? { label: token?.label, value: token?.value } : null;
      });
      setMatchedTokens(newArray);
    };

    if (allTokens.length > 0) {
      matchTokens();
    }
  }, [allTokens]);

  const handleChange = async (value) => {
    if (value) {
      setIsLoading(true);
      setToCoinValue(value?.value);
      setToCoinLabel(value?.label);
      try {
        const payload = {
          user_package_id: swapData?._id,
          from_symbol: selectedPoolLabel,
          to_symbol: value?.label,
        };

        if (marketplaceData[0]?.otc_type == "general") {
          const res = await swapOverviewMarketPlacePackge_general(payload);

          setSwapAmount(res.data.data);
        } else {
          const res = await swapOverviewMarketPlacePackge(payload);

          setSwapAmount(res.data.data);
        }
      } catch (error) {
        toast.error("An error occurred while fetching swap overview");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleAcceptSwap = async () => {
    setIsLoading(true);
    try {
      const selectedPool = allTokens.find(
        (pool) => pool.label === selectedPoolLabel
      );
      if (!selectedPool) {
        throw new Error("Selected pool not found!");
      }
      const payload = {
        user_package_id: swapData?._id,
        fromSymbol: selectedPoolLabel,
        toSymbol: toCoinLabel,
      };

      let res;
      // commenting for now
      // if (selectedPoolLabel === "SOLANA" || selectedPoolLabel === "WEN") {
      //   res = await swapOtcMarketPlacePackgeSol(payload);
      // } else {
      //   res = await swapOtcMarketPlacePackge(payload);
      // }
      res = await swapOtcMarketPlacePackge(payload);

      toast.success(res.data.Message);
      reFetch();
      hide();
    } catch (error) {
      toast.error(error?.response?.data.Message);
    } finally {
      setIsLoading(false); // Make sure to stop loading regardless of success or failure
    }
  };

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 99999,
        }}
      >
        <Ring size={65} color="#00897B" />

        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={hide}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <>
              {swapAmount && (
                <div
                  className="container mt-0 containerDiv pt-0"
                  style={{ overflowX: "hidden" }}
                >
                  <div className="row mt-2 mb-1">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-0"
                    >
                      <b>Overview</b>
                    </p>
                  </div>
                  <div
                    className="row mt-0 mb-2 mx-1"
                    style={{ border: "2px solid #7b7b7b", borderRadius: "5px" }}
                  >
                    {[
                      { label: "From Symbol", value: swapAmount.from_symbol },
                      { label: "To Symbol", value: swapAmount.to_symbol },
                      { label: "Quantity", value: swapData.receive_amount },
                      {
                        label: "Price",
                        value: swapAmount.to_price.toFixed(4),
                      },
                      {
                        label: "You will get approximately",
                        value: `${swapAmount.conversion_quantity.toFixed(
                          4
                        )} ${toCoinLabel}`,
                      },
                    ].map((item, index) => (
                      <div
                        key={index}
                        className="col-lg-3 col-md-3 col-sm-6 text-center"
                      >
                        <p
                          style={{
                            textTransform: "Capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          <b>{item.label}</b>
                        </p>
                        <p
                          style={{
                            textTransform: "Capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          {item.value}
                        </p>
                      </div>
                    ))}
                  </div>

                  <hr
                    hidden
                    style={{ border: "1px solid #b3b3b3", opacity: "1.1" }}
                  />
                </div>
              )}
              {/* admin detail section  */}
              <div
                className="container mt-0 containerDiv pt-0"
                style={{ overflowX: "hidden" }}
              >
                <div className="row mt-2 mb-1">
                  <p
                    style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                    className="py-0"
                  >
                    <b>Swap Detail</b>
                  </p>
                </div>
                <div
                  className="row mx-1 py-4"
                  style={{ border: "2px solid #7b7b7b", borderRadius: "5px" }}
                >
                  <div className="col-md-4 col-lg-4 col-sm-12 ">
                    <InputDiv>
                      <Label htmlFor="email" className=" text-black ">
                        From
                      </Label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <CustomSelect
                          name="receiver"
                          value={selectedPoolLabel}
                          placeholder={selectedPoolLabel}
                          options={allTokens.filter(
                            (pool) => pool?.label == selectedPoolLabel
                          )}
                          isSearchable={false}
                          isDisabled
                        />
                      </div>
                    </InputDiv>
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-12 ">
                    <InputDiv>
                      <Label htmlFor="email" className=" text-black ">
                        To
                      </Label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <CustomSelect
                          name="receiver"
                          value={toCoinValue}
                          onChange={(value) => {
                            handleChange(value);
                          }}
                          options={matchedTokens}
                          isSearchable
                        />
                      </div>
                    </InputDiv>
                  </div>
                  <div className="col-md-12 col-lg-6 col-sm-12 ">
                    {userSessionData.data.user.TFA && (
                      <div className=" d-flex col-lg-12 col-md-12 col-sm-12 mt-1 gap-2  justify-content-between ">
                        <InputDiv>
                          <label htmlFor="email" className="text-black mb-3">
                            Verify TFA *
                          </label>
                          <input
                            name="tfa"
                            type="text"
                            placeholder="Enter Code"
                            className="text-black"
                            value={tfaCode}
                            onChange={(e) => {
                              setTfaCode(e.target.value);
                            }}
                          />
                        </InputDiv>

                        <InputDiv>
                          <FormButton
                            variant="contain-success"
                            className="py-2 mt-4 w-75 btn-sm"
                            onClick={handleTfaCode}
                            disabled={isTfaVarified}
                          >
                            {isTfaVarified ? "Verified" : "Verify"}
                          </FormButton>
                        </InputDiv>
                      </div>
                    )}
                  </div>
                  <div className="md:flex">
                    <FormButton
                      variant="outline-success"
                      className="py-2 mt-4 btn-sm"
                      onClick={hide}
                    >
                      {/* {loading ? "Loading...." : "Max"} */}
                      Cancel
                    </FormButton>
                    <FormButton
                      variant="contain-success"
                      className="py-2 mt-4 btn-sm"
                      onClick={handleAcceptSwap}
                      disabled={
                        !swapAmount ||
                        (userSessionData.data.user.TFA && !isTfaVarified)
                      }
                    >
                      {/* {loading ? "Loading...." : "Max"} */}
                      Accept
                    </FormButton>
                  </div>
                </div>

                <hr
                  hidden
                  style={{ border: "1px solid #b3b3b3", opacity: "1.1" }}
                />
              </div>
            </>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export default SwapModal;

const Label = styled.label`
  @media screen and (max-width: 876px) {
    margin-bottom: 0px;
    margin-top: 8px;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
  input {
    background-color: transparent;
    border: none;
    color: white;
    border-bottom: 1px solid #555555;
  }
  input.disabled {
    cursor: not-allowed !important;
    color: #d9d9d9;
  }
`;
