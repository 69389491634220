import React, { useState, useRef } from 'react'; // Import useState if not already imported
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
// import EmojiPicker from 'emoji-picker-react';
import PaperclipIcon from 'mdi-react/PaperclipIcon';
import EmoticonIcon from 'mdi-react/EmoticonIcon';
import SendIcon from 'mdi-react/SendIcon'; // Import the SendIcon
// import config from "../../../../config/config";
import config from '../../../../config/config';
import io from "socket.io-client";

import {
  colorAccent,
  colorAdditional,
  colorBackgroundBody,
  colorFieldsBorder,
  colorText,
} from '@/utils/palette';
import {
  right,
  marginLeft,
  paddingRight,
  paddingLeft,
} from '@/utils/directions';
import { useChainContext } from '../../../context/context';

const ChatField = ({ onSendMessage,ChatId }) => { // Receive the onSendMessage prop
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const [NewMessage, setNewMessage] = useState('');
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [selectedFileNames, setSelectedFileNames] = useState([]);
  const {socket}=useChainContext()
  // const socket = io(config.baseURL);

  const fileInputRef = useRef(null);
  // console.log('NewMessage', NewMessage);
  // console.log('selectedEmoji', selectedEmoji);
  const handleSendMessage = () => {
    if (NewMessage.trim() !== '' || selectedEmoji) {
      const messageToSend = selectedEmoji
        ? `${NewMessage} ${selectedEmoji}`
        : NewMessage;
      onSendMessage(messageToSend);
      setNewMessage('');
      setSelectedEmoji(null);
    }
  };
  const handleFileInputChange = (e) => {
    const { files } = e.target;
    if (files.length > 0) {
      const fileArray = Array.from(files);
      setSelectedFileNames(fileArray.map(file => file.name)); // Store selected file names
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendMessage();
    } else {
      handleTyping();
    }
  };

  const handleTyping = () => {
    // Emit typingStatus event to the server
    socket.emit('typingStatus', {
      chatId: ChatId, 
      userId: JSON.parse(localStorage.getItem('user')).data.user.user_id,
    });
  };

  //   const onEmojiClick = (event, emojiObject) => {
  // console.log('emojiObject',emojiObject)
  // const selectedEmojiText = (selectedEmoji || '') + event.emoji;
  // setSelectedEmoji(selectedEmojiText);// Store the selected emoji
  //     setEmojiPickerVisible(false);
  //   };

  // const handleBackspace = (e) => {
  //   if (e.keyCode === 8 && NewMessage === '' && selectedEmoji.length > 0) {
  //     // Remove the last emoji when backspace is pressed and NewMessage is empty
  //     setSelectedEmoji((prevEmojis) => prevEmojis.slice(0, prevEmojis.length - 1));
  //   }
  // };

  return (
    <ChatTextField>
      <ChatForm>
        <ChatFieldInput
          type="text"
          name="chatField"
          placeholder="Type here…"
          value={
            NewMessage
            + (selectedEmoji ? ` ${selectedEmoji}` : '')
            + (selectedFileNames.length > 0
              ? ` ${selectedFileNames.join(', ')}`
              : '')
          }
          onChange={e => setNewMessage(e.target.value)}
          onFocus={() => setEmojiPickerVisible(false)}
          // onKeyDown={handleBackspace}
          onKeyPress={handleKeyPress}
        />
        <ChatFieldControls>

          {/* <ChatIconButton type="button" onClick={(e) => setEmojiPickerVisible(!emojiPickerVisible)}>
            <EmoticonIcon />
          </ChatIconButton> */}
          {/* <ChatIconButton
          type="button"
          onClick={() => fileInputRef.current.click()}
        >
          <input
  type="file"
  accept="image/*"  
  onChange={handleFileInputChange}
  multiple  
  style={{ display: 'none' }}
  ref={fileInputRef}
/>
          <PaperclipIcon />
        </ChatIconButton> */}

          <ChatIconButton
            type="button"
            onClick={handleSendMessage}
          >
            <SendIcon />
          </ChatIconButton>
        </ChatFieldControls>
      </ChatForm>
      {/* {emojiPickerVisible && (
         <StyledEmojiPicke>
    <EmojiPicker
      onEmojiClick={onEmojiClick}
      skinTonesDisabled={true}
      native={true}
    />
    </StyledEmojiPicke>
  )} */}
    </ChatTextField>
  );
};

export default ChatField;


// region STYLES
const StyledEmojiPicke = styled.div`
  position: absolute;
  top: -15px; /* Adjust the top position as needed */
  right: 0; /* Position it at the top right corner */
`;
// const StyledEmojiPicker = styled(EmojiPicker)`
//   width: 250px; /* Set your desired width */
//   height: 350px; /* Set your desired height */


// `;
const ChatTextField = styled.div`
  height: 64px;
  width: 100%;
  border-top: 1px solid ${colorFieldsBorder};
`;

const ChatForm = styled.form`
  width: 100%;
  position: relative;
  padding: 12px 20px;
`;

const ChatFieldInput = styled(Form.Control)`
  width: 100%;
  height: 36px;
  border-radius: 18px;
  font-size: 12px;
  line-height: 1.33;
  transition: 0.3s;
  ${paddingLeft}: 15px;
  ${paddingRight}: 65px;
  background-color: ${colorBackgroundBody};
  border: 1px solid ${colorBackgroundBody};
  color: ${colorText};

  &:focus, &:hover, &:active {
    border-color: ${colorAccent};
    outline: none;
    box-shadow: none;
  }
`;

const ChatFieldControls = styled.div`
  position: absolute;
  top: 12px;
  height: 36px;
  display: flex;
  ${right}: 35px;
`;

const ChatIconButton = styled.button`
  background: transparent;
  border: none;
  height: 36px;
  cursor: pointer;
  padding: 0 5px;
  ${marginLeft}: 5px;

  svg {
    height: 16px;
    width: 16px;
    fill: ${colorAdditional};
    transition: 0.3s;
  }

  &:hover {

    svg {
      fill: ${colorAccent};
    }
  }
`;

// endregion
