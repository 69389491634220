import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { Button } from "@/shared/components/Button";
import { Ring } from "@uiball/loaders";
import { countries, countryCodes } from "../../../utils/constants/contants";
import { CustomSelect } from "../../../shared/components/CustomSelect";
import { createUserOnStriga } from "../../../utils/services/user.service";
import { toast } from "react-toastify";

const CreateStrigaAccountModal = ({ show, hide, reFetch }) => {
    const [isLoading, setIsLoading] = useState(false);
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const [countryCode, setCountryCode] = useState({
        label: "",
        value: "",
    });
    const [countryName, setCountryName] = useState({
        label: "",
        value: "",
    });
    const [phone, setPhone] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [dob, setDob] = useState("2005-01-01");
    const [year, setYear] = useState(0);
    const [month, setMonth] = useState(0);
    const [day, setDay] = useState(0);
    useEffect(() => {
        if (dob) {
            const [yearStr, monthStr, dayStr] = dob.split("-");
            const yearNum = parseInt(yearStr, 10);
            const monthNum = parseInt(monthStr, 10);
            const dayNum = parseInt(dayStr, 10);
            setYear(yearNum);
            setMonth(monthNum);
            setDay(dayNum);
        }
    }, [dob]);
    const handleDobChange = (event) => {
        setDob(event.target.value);
    };
    const createStrigaAccountFunction = async () => {
        try {
            if (
                !countryCode?.value ||
                !phone ||
                !countryName.value ||
                !addressLine1 ||
                !city ||
                !postalCode ||
                !year
            ) {
                toast.error("All fields are required");
                return false;
            }

            if (year > 2005) {
                toast.error("Year should be less than 2006");
                return false;
            }
            setIsLoading(true);
            const payload = {
                firstName: userInfo?.data?.user.firstName,
                lastName: userInfo?.data?.user.lastName,
                // email: "emailtohamza92e@gmail.com",
                email: userInfo?.data?.user.email,
                mobile: {
                    countryCode: countryCode?.value,
                    number: phone,
                },
                address: {
                    addressLine1: addressLine1,
                    city: city,
                    country: countryName.value,
                    postalCode: postalCode,
                },
                dateOfBirth: {
                    year: year,
                    month: month,
                    day: day,
                },
            };
            const res = await createUserOnStriga(payload);

            setIsLoading(false);
            reFetch()
            hide();

        } catch (error) {
            toast.error('Something went wrong')
            setIsLoading(false);
        }
    };

    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={hide}
            >
                <Modal.Header closeButton>
                    <h4 className="text-black">Create Bank Account</h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="row flex-column gap-4 container ">
                        <div className="d-flex col-12 flex-wrap">
                            <div className="d-flex flex-column col-12 col-md-4 mb-4">
                                <label>Country Code *</label>
                                <CustomSelect
                                    value={countryCode.value}
                                    onChange={(value) => {
                                   
                                        setCountryCode({
                                            label: value.label,
                                            value: value.value,
                                        });
                                    }}
                                    options={countryCodes}
                                />
                            </div>
                            <div className="d-flex flex-column col-12 col-md-4 mb-4">
                                <label>Phone *</label>
                                <ChatSearchInput
                                    name="phone"
                                    type="text"
                                    placeholder="Enter your phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    className={`form-control w-100`}
                                />
                            </div>
                            <div className="d-flex flex-column col-12 col-md-4 mb-4">
                                <label>Address Line1 *</label>
                                <ChatSearchInput
                                    name="addressLine1"
                                    type="text"
                                    placeholder="Enter your Address Line1"
                                    value={addressLine1}
                                    onChange={(e) => setAddressLine1(e.target.value)}
                                    className={`form-control w-100`}
                                />
                            </div>
                            <div className="d-flex flex-column col-12 col-md-4 mb-4">
                                <label>City *</label>
                                <ChatSearchInput
                                    name="city"
                                    type="text"
                                    placeholder="Enter your City"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    className={`form-control w-100`}
                                />
                            </div>
                            <div className="d-flex flex-column col-12 col-md-4 mb-4">
                                <label>Country *</label>
                                <CustomSelect
                                    value={countryName.value}
                                    onChange={(value) => {
                                     
                                        setCountryName({
                                            label: value.label,
                                            value: value.value,
                                        });
                                    }}
                                    options={countries}
                                />
                            </div>
                            <div className="d-flex flex-column col-12 col-md-4 mb-4">
                                <label>Postal Code *</label>
                                <ChatSearchInput
                                    name="postalCode"
                                    type="text"
                                    placeholder="Enter your postal code"
                                    value={postalCode}
                                    onChange={(e) => setPostalCode(e.target.value)}
                                    className={`form-control w-100`}
                                />
                            </div>
                            <div className="d-flex flex-column col-12 col-md-4 mb-4">
                                <label>DOB *</label>
                                <ChatSearchInput
                                    name="dob"
                                    type="date"
                                    placeholder="Enter your date of birth"
                                    value={dob}
                                    onChange={handleDobChange}
                                    className={`form-control w-100`}
                                    max='2005-01-01'
                                />
                            </div>
                        </div>

                        <div className="d-flex col-12 col-md-4 col-lg-4 align-self-center ">
                            <FormButton
                                type="submit"
                                variant="contain-success"
                                className="d-flex w-100"
                                onClick={createStrigaAccountFunction}
                            >
                                Create
                            </FormButton>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default CreateStrigaAccountModal;

const FormButton = styled(Button)`
  color: white;
  margin-top: 1rem;
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-radius: 0;

  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::placeholder {
    color: #b1b1b1;
  }
`;
