import React from 'react';
import { Web3Button } from '@web3modal/react';

function Web3ButtonPage() {
  return (
    <Web3Button style={{
 display: 'flex',  
 marginLeft: 'auto',
 marginRight: 'auto', 
 marginBottom: '2%', 
 alignItems: 'center'
}}
    />
  );
}

export default Web3ButtonPage;
