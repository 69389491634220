import { Pie, PieChart, Tooltip } from "recharts";
import getTooltipStyles from "@/shared/helpers";

import {
  DashboardPieChartContainer,
  DashboardPieChartFlex,
  DashboardChartLegendTable,
  Bullet,
} from "../../../../containers/Dashboards/BasicDashboardComponents";
import styled from "styled-components";
import { colorDarkText } from "@/utils/palette";

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { optionColors } from "../../../../utils/constants/contants";
import numeral from "numeral";
const PoolInfoGraph = ({ newArray, defaultPool }) => {
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const themeName = useSelector((state) => state.theme.className);
  const [data, setData] = useState([]);

  const onMouseMove = (e) => {
    if (e.tooltipPosition) {
      setCoordinates({ x: e.tooltipPosition.x, y: e.tooltipPosition.y });
    }
  };
  const tooltipFormatter = (value, name, entry) => {
    return [
      <span key="custom-label">
        {`${entry?.payload?.symbol} : ${numeral(
          entry?.payload?.graphVal || 0
        ).format("0,0.00")}`}
      </span>,
    ];
  };

  useEffect(() => {
    if (defaultPool) {
      const [symbol1, symbol2] = defaultPool.split("-");

      const defaultArray = [
        {
          fill: "#ff0000",
          graphVal: 0.0,
          symbol: symbol1?.trim(),
        },
        {
          fill: "#00ff00",
          graphVal: 0.0,
          symbol: symbol2?.trim(),
        },
      ];
      setData(newArray.length > 0 ? newArray : defaultArray);
    }
  }, [defaultPool, newArray]);
  return (
    <>
      <div className="d-flex flex-column flex-md-row py-5 ">
        <div className="col-12 col-md-6 ">
          <DashboardPieChartContainer>
            <DashboardPieChartFlex>
              <Tooltip
                position={coordinates}
                {...getTooltipStyles(themeName)}
                formatter={tooltipFormatter}
              />
              <Pie
                data={newArray}
                dataKey="graphVal"
                cy={130}
                innerRadius={80}
                outerRadius={100}
                label
                onMouseMove={onMouseMove}
                minAngle={20}
              />
            </DashboardPieChartFlex>
          </DashboardPieChartContainer>
        </div>

        <div className="col-12 col-md-6 ">
        <DashboardChartLegendTable>
          <LegendCol>
            <LegendColTitle>Coin List</LegendColTitle>
            <LegendColContent>
              {data.map((entry, index) => (
                <li
                  key={index}
                  style={{
                    width: "max-content",
                    display: "flex",
                    flexWrap: "nowrap",
                    alignItems: "center",
                  }}
                >
                  <Bullet color={optionColors[index]?.value} />

                  {entry?.symbol}
                </li>
                //  )
              ))}
            </LegendColContent>
          </LegendCol>

          <LegendCol>
            <LegendColTitle>Quantity</LegendColTitle>
            <LegendColContent>
              {data.map((entry, idx) => (
                <li key={idx}>
                  {numeral(entry?.graphVal || 0).format("0,0.00000")}
                </li>
              ))}
            </LegendColContent>
          </LegendCol>
        </DashboardChartLegendTable>
        </div>
      </div>
    </>
  );
};
export default PoolInfoGraph;
const LegendCol = styled("div")`
  flex-shrink: 2;
  flex-grow: 0;

  :first-child {
    flex-shrink: 0;
    flex-basis: 100px;
  }

  :last-child {
    flex-grow: 1;
  }
`;

const LegendColTitle = styled("div")`
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.1;
  color: ${colorDarkText};
`;

const LegendColContent = styled("ul")`
  padding: 0;
  list-style: none;
  color: ${colorDarkText};

  li {
    margin-top: 15px;
    font-size: 14px;
  }
`;
