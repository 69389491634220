import { useEffect, useState } from "react";
import {
  getMarketPlaceStatus,
  getMyOtcPackageTrx,
  getMyRequest,
  getSubAccountType,
  getUserInfo1,
  switchAccount,
} from "../../../utils/services/user.service";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { faExternalLinkAlt, faRepeat } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@/shared/components/Button";
import { colorDarkText } from "@/utils/palette";
import { useSelector } from "react-redux";
import { Col } from "react-bootstrap";
import ChooseAccountModal from "./ChoseAccountModal";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import { useChainContext } from "../../context/context";
import SwitchUserModal from "./SwitchUserModal";
const SubAccountsTable = ({ selectedTab }) => {

  const [myAllRequests, setMyAllRequests] = useState([]);

  const [showChooseAccountModal, setShowChooseAccountModal] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otcRequestStatus, setOtcRequestStatus] = useState("");
  const [accountType, setAccountType] = useState("");
  const [show, setShow] = useState(false);
  const { refreshUserData, setRefreshUserData } = useChainContext();
  const [tabChangeFetch, setTabChnageFetch] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));
  const fetchAccountType = async () => {
    try {
      const payload = {
        user_id: user?.data?.user?.user_id,
      };
      const res = await getSubAccountType(payload);

      setAccountType(res.data.data.type);
    } catch (error) {
      console.error("Error fetching account type:", error);
      setAccountType("");

      // Handle the error here, such as displaying a message to the user or setting a default account type
    }
  };

  useEffect(() => {
    fetchAccountType();
    setTabChnageFetch(selectedTab);
  }, [selectedTab]);

  const fetchMyAllRequests = async () => {
    setIsLoading(true);
    try {
      const res = await getMyRequest();

      setMyAllRequests(res?.data?.data);
      setIsLoading(false);
      setTabChnageFetch("");
    } catch (error) {
      console.error("An error occurred while fetching requests:", error);
      setIsLoading(false);
      setTabChnageFetch("");
      // Handle the error as needed, such as showing a message to the user
    }
  };
  useEffect(() => {
    fetchMyAllRequests();
    getProfileStatus();
  }, [reFetch, refreshUserData, tabChangeFetch]);
  const getProfileStatus = async () => {
    const res = await getMarketPlaceStatus();

    setOtcRequestStatus(res?.data?.data?.status);
  };

  const getStatusColor = (status1) => {
    switch (status1.toLowerCase()) {
      case "completed":
        return "#4ce1b6";
      case "pending":
        return "#f6da6e";
      case "rejected":
        return "#ff4861";
      case "waiting":
        return "#f6da6e";
      case "declined":
        return "#ff4861";
      case "failed":
        return "#ff4861";
      case "success":
        return "green";
      default:
        return "#70bbfd";
    }
  };
  const header = [
    { accessor: "request_id", Header: "Request Id" },
    { accessor: "validityTime", Header: "Validity Time" },
    { accessor: "number_of_tokens", Header: " Total Tokens" },
    { accessor: "symbol", Header: "Symbol" },
    { accessor: "type", Header: " Account Type" },
    { accessor: "distribution_type", Header: "Distribution Type" },
    { accessor: "status", Header: "Request Status" },
  ];
  const tableConfi2 = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    isPagination: false,
    withSearchEngine: false,
    manualPageSize: [40, 20, 30, 40],
    placeholder: "Search...",
    withPagination: true,
  };

  const data = [];
  // Sort the myAllRequests array based on the status field
  myAllRequests.sort((a, b) => {
    if (a.status === "parent") return -1; // 'parent' status comes first
    if (b.status === "parent") return 1; // 'parent' status comes first
    return 0; // if both are not 'parent', maintain the original order
  });

  // Map the sorted items to the data array
  myAllRequests.map((item) =>
    data.push({
      request_id: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item._id}
        </h6>
      ),

      type: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.type || "--"}
        </h6>
      ),
      account_time: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.account_time || "--"}
        </h6>
      ),
      account_time_type: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.account_time_type || "--"}
        </h6>
      ),
      distribution_type: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.distribution_type}
        </h6>
      ),
      number_of_accounts: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.number_of_accounts || "--"}
        </h6>
      ),
      number_of_tokens: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.number_of_tokens || "--"}
        </h6>
      ),
      symbol: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.symbol || "--"}
        </h6>
      ),
      validityTime: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {moment(item.validity_time).format("MMM Do YY") || "--"}
        </h6>
      ),

      status: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{ backgroundColor: getStatusColor(item.status) }}
        >
          <h6 className="text-white">
            <b>{item.status}</b>
          </h6>
        </span>
      ),
    })
  );

  const isDisabled = myAllRequests.some(
    (item) =>
      !["completed", "rejected", "parent"].includes(item.status?.toLowerCase())
  );

  return (
    <>
      {/* <Col> */}
      <div className=" flex flex-column overflow-hidden ">
        <div
          className="loader-overlay"
          style={{
            display: isLoading ? "flex" : "none", // Use flex display when loading
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 999,
          }}
        >
          <Ring size={65} color="#00897B" />
        </div>
        <FormButton
          type="submit"
          variant="outline-primary"
          // className=" py-1 w-25 "
          onClick={() => {
            setShowChooseAccountModal(true);
          }}
          disabled={
            (isDisabled && myAllRequests.length > 0) ||
            (accountType !== "parent" && accountType !== "")
          }
        >
          Request New Account
        </FormButton>
        {(accountType === "transfer" || accountType === "purchase") && (
          <FormButton
            type="submit"
            variant="outline-primary"
            className="py-1 w-25"
            onClick={() => {
              setShow(true);
            }}
          >
            Switch to Parent
          </FormButton>
        )}

        {myAllRequests.length > 0 ? (
          <>
            <ReactTableBase
              columns={header}
              data={data}
              tableConfig={tableConfi2}
            />
          </>
        ) : (
          <h3 className="d-flex align-items-center text-center justify-content-center  ">
            No data found
          </h3>
        )}
      </div>
      {/* </Col> */}

      {showChooseAccountModal && (
        <ChooseAccountModal
          show={showChooseAccountModal}
          hide={() => setShowChooseAccountModal(false)}
          reFetch={() => setReFetch(!reFetch)}
        />
      )}

      {show && (
        <SwitchUserModal
          show={show}
          hide={() => setShow(false)}
          reFetch={() => setReFetch(!reFetch)}
          requestUserId={user?.data?.user?.parent_id}
        />
      )}
    </>
  );
};
export default SubAccountsTable;
const HashLink = styled.button`
  border: none;
  background: transparent;
  color: #4ce1b6;
`;
const LegendCol = styled("div")`
  flex-shrink: 2;
  flex-grow: 0;

  :first-child {
    flex-shrink: 0;
    flex-basis: 100px;
  }

  :last-child {
    flex-grow: 1;
  }
`;

const LegendColTitle = styled("div")`
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.1;
  color: ${colorDarkText};
`;

const LegendColContent = styled("ul")`
  padding: 0;
  list-style: none;
  color: ${colorDarkText};

  li {
    margin-top: 15px;
    font-size: 14px;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
