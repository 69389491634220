import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
 colorBorder, colorBackground, colorHover, colorDustyWhite, 
 colorAccent, 
} from '@/utils/palette';
import { left } from '@/utils/directions';
import SidebarLink, { SidebarLinkTitle } from './SidebarLink';
import SidebarCategory from './Category';


const SidebarContent = ({
  onClick, collapse, 
}) => (
  <SidebarContentWrap collapse={collapse}>
    <SidebarBlock collapse={collapse}>
      {/* <SidebarLink
        title="Dashboard"
        icon="home"
        route="/dashboard"
        onClick={onClick}
      />
      <SidebarLink
        title="Sendsss"
        icon="exit-up"
        route="/send"
        onClick={onClick}
      />
      <SidebarLink
        title="Receive"
        icon="enter-down"
        route="/receive"
        onClick={onClick}
      />
      <SidebarLink
        title="Add Contract"
        icon="apartment"
        route="/new-contract"
        onClick={onClick}
      />
      <SidebarLink
        title="Add SafeBox"
        icon="rocket"
        route="/safebox"
        onClick={onClick}
        // newLink
      /> */}
      {/* <SidebarLink
        title="Wallets"
        icon="book"
        route="/wallets"
        onClick={onClick}
      /> */}
      <SidebarCategory title="Operations" icon="layers" collapse={collapse}>
        <SidebarLink title="SafeBoxes" route="/safebox-history" onClick={onClick} />
        <SidebarLink title="Contracts" route="/history" onClick={onClick} />
      </SidebarCategory>

      <SidebarCategory title="Market Place" icon="apartment" collapse={collapse}>
        <SidebarLink title="Home" route="/home" onClick={onClick} />
        <SidebarCategory title=" Product">
          <SidebarLink title="Create Product" route="/createproduct" onClick={onClick} />
          <SidebarLink title="Products" route="/products" onClick={onClick} />
        </SidebarCategory>
        <SidebarCategory title="Service">
          <SidebarLink title="Services" route="/services" onClick={onClick} />
          <SidebarLink title="CreateServices" route="/createservices" onClick={onClick} />
        </SidebarCategory>
      </SidebarCategory>
      
    </SidebarBlock>
    {/* <SidebarCategory title="Menu" icon="layers" collapse={collapse}>
      <SidebarLink title="Orders" route="/e-commerce/orders_list" onClick={onClick} />
      <SidebarLink title="Products" route="/e-commerce/product_page" onClick={onClick} />
      <SidebarLink title="Product List" route="/e-commerce/products_list" onClick={onClick} />
      <SidebarLink title="Catalog" route="/e-commerce/catalog" onClick={onClick} />
    </SidebarCategory> */}
   
  </SidebarContentWrap>
);

SidebarContent.propTypes = {
  onClick: PropTypes.func,
  collapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  collapse: false,
  onClick: () => { },
};

export default SidebarContent;

// region STYLES

const SidebarContentWrap = styled.div`
  height: 100%;
  overflow: auto;
  padding-top: 0;
  
  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }
  
  @media screen and (min-width: 576px) {
    padding-top: 15px;
    
    ${props => props.collapse && `
      width: 55px;
      overflow: visible !important;
      transition: width 0.3s;
    `}
  }
`;

const SidebarBlock = styled.ul`
  padding: 15px 0;
  border-bottom: 1px solid ${colorBorder};
  list-style-type: none;
  
  &:last-child {
    border: none;
  }
  
  @media screen and (min-width: 576px) {
    
    ${props => props.collapse && `
      & > li > a,
      & > li > button {
        overflow: hidden;
        width: 55px;
        background: ${colorBackground(props)};
        
        span:last-of-type {
          opacity: 0;
          transition: 0.3s;
        }
  
        ${SidebarLinkTitle} {
          position: absolute;
          width: 160px;
          ${left(props)}: 70px;
        }
  
        &:hover {
          background: ${colorHover(props)};
        }
      }
      
      & > li:hover > a,
      & > li:hover > button {
        width: 275px;
        
        span {
          opacity: 1;
        }
      }
    `}
  }
`;
// endregion

