/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/named */
import { createAction } from 'redux-actions';

import { firstLetterToUpperCase } from '@/shared/helpers';
import { getUserBalanceInternalWallet } from "@/utils/services/user.service";
import { COIN_LOADS, SET_COIN_LOADS, 
} from './authActions';

export const fetchTopUsdPriceRequest = createAction('FETCH_USDC_PRICE_REQUEST');
export const fetchTopUsdPriceSuccess = createAction('FETCH_USDC_PRICE_SUCCESS');
export const fetchTopUsdPriceError = createAction('FETCH_USDC_PRICE_ERROR');
export const fetchLoad = createAction('FETCH_LOAD');
export const fetchUnLoad = createAction('FETCH_UN_LOAD');

export const fetchAllCurrencies1 = () => async (dispatch) => {
  try {
    dispatch({
      type: COIN_LOADS,
    });

    const internalCoins = await getUserBalanceInternalWallet()


    localStorage.setItem(
      "internalCoins",
      JSON.stringify(internalCoins.data.total_balanceUSDT)
    );
    localStorage.setItem(
      "balance_sub_wallet",
      JSON.stringify(internalCoins.data.total_balance_sub_wallet)
    );

    dispatch({
      type: SET_COIN_LOADS,
    });
    dispatch(fetchTopUsdPriceSuccess(internalCoins.data.data));
  } catch (e) {
    dispatch({
      type: SET_COIN_LOADS,
    });
    dispatch(fetchTopUsdPriceError(firstLetterToUpperCase(e.response?.data?.error || e.response?.statusText)));
  }
};