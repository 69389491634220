/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { right } from "@/utils/directions";
import Panel from "@/shared/components/Panel";
import { Table } from "@/shared/components/TableElements";
import { fetchTopTenCurrencies } from "@/redux/actions/topTenActions";
import {
  getTokenDetailsWithPrice
} from "../../../../utils/services/user.service";
import { Bullet } from "../../BasicDashboardComponents";
import numeral from "numeral";
import { optionColors } from "../../../../utils/constants/contants";
import { SidebarLinkIcon } from "../../../Layout/components/sidebar/SidebarLink";
import Tooltip from "@/shared/components/Tooltip";
import { toast } from "react-toastify";


const TradeHistory = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const [allTokensDetail, setAlltokenDetails] = useState([]);



  const refreshData = () => {
    dispatch(fetchTopTenCurrencies());
  };


  useEffect(() => {

    const getTokenDetails = async () => {
      try {
        const res = await getTokenDetailsWithPrice()
        setAlltokenDetails(res?.data?.data)

      } catch (error) {
        console.log("🚀 ~ getTokenDetails ~ error:", error)

      }
    }

    getTokenDetails()
  }, []);
  const copyContractAddress = (item) => {
    navigator.clipboard.writeText(item);
    toast.success('Text Copied')
  };

  return (
    <Panel
      xl={12}
      xs={12}
      className="mt-3 pb-2"
      title="Coins"
      subhead="Current Prices"
      // isLoading={isTopTenLoading}
      refreshRequest={refreshData}
    >
      {/* <ErrorAlert subTitle="Quotes" error={errorTopTen} /> */}
      <Table responsive striped>
        <thead>
          <tr>
            <TradeHistoryTh style={{ textAlign: "justify" }}>Coins</TradeHistoryTh>
            <TradeHistoryTh style={{ textAlign: "justify" }}>
              Price
            </TradeHistoryTh>
            <TradeHistoryTh style={{ textAlign: "justify" }}>
              Contract Address
            </TradeHistoryTh>
          </tr>
        </thead>
        <tbody style={{ cursor: "pointer" }}>
          {
            allTokensDetail?.map((item, idx) => {
              return (
                <tr>
                  <td style={{ textAlign: "justify" }}>
                    <DashboardCoin color={optionColors[idx].value}>
                      <Bullet color={optionColors[idx].value} /> {item?.symbol}
                    </DashboardCoin>
                  </td>
                  <td style={{ textAlign: "justify" }}>
                    {item?.price?.[0]?.priceUSD ? (
                      <b>${numeral(item.price[0].priceUSD).format('0,0.00')}</b>
                    ) : (
                      <b>$0.00</b> // Default value if priceUSD is undefined or null
                    )}
                  </td>

                  <td style={{ textAlign: "justify" }}>
                    <div className="d-flex flex-row gap-2 align-items-center">

                      {item?.contract_address || item?.symbol}
                      <Tooltip text="Copy Contract address" placement="top">
                        <SidebarLinkIcon
                          className="lnr lnr-book"
                          onClick={() =>
                            copyContractAddress(
                              item?.contract_address || item?.symbol)
                          }
                        />
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              )
            })
          }

        </tbody>
      </Table>
    </Panel>
  );
};

export default TradeHistory;

// region STYLES

const DashboardCoin = styled.p`
  color: ${(props) => props.color};
  font-weight: 700;
`;

const TradeHistoryTh = styled.th``;