import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import MultilevelTreeMap from "./MltilevelTreeMap";
import { poolHolderGraph } from "../../../utils/services/user.service";

const OtcGraphHolder = ({ marketplaceId }) => {
  const [graphData, setGraphData] = useState([]);
  const { t } = useTranslation("common");
  const fetchGraphData = async () => {
    const payload = {
      market_place_id: marketplaceId,
    };
    const res = await poolHolderGraph(payload);
    setGraphData(res.data.data);
  };
  useEffect(() => {
    fetchGraphData();
  }, [marketplaceId]);

  return (
    <Container>
      <Row>
        <Col sm={12} md={12}>
          <h3 className="page-title">OTC Holders Graph</h3>
        </Col>
      </Row>
      <Row>
        {graphData ? (
          <MultilevelTreeMap transformedData={graphData} />
        ) : (
          <h4 className="d-flex align-items-center justify-content-center  ">
            Data Not Found !{" "}
          </h4>
        )}
      </Row>
    </Container>
  );
};

export default OtcGraphHolder;
