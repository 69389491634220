import { Modal } from "react-bootstrap";
import Webcam from "react-webcam";
import { Button } from "../../../shared/components/Button";

import { useCallback, useRef, useState } from "react"; // import useCallback
const WebCam = ({ show, hide, onCapture }) => {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);


  // create a capture function
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    onCapture(imageSrc); // Call the callback function with the captured image
  }, [webcamRef]);

  const retake = () => {
    setImgSrc(null);
  };
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={hide}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: "large" }}>
            Verify Selfie
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container padding-bottom-3x mb-1 p-0">
            <div
              className="card mt-0"
              style={{ boxShadow: "none", fontFamily: "sans-serif" }}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="container">
                      {imgSrc ? (
                        <img
                          src={imgSrc}
                          alt="webcam"
                          className="d-flex rounded-5 "
                        />
                      ) : (
                        <Webcam height={300} width={300} ref={webcamRef} />
                      )}
                      <div className="btn-container">
                        {imgSrc ? (
                          <Button
                            onClick={retake}
                            size="md"
                            variant="outline-success"
                            className={"d-flex mt-2"}
                          >
                            Retake photo
                          </Button>
                        ) : (
                          <Button
                            onClick={capture}
                            size="md"
                            variant="outline-success"
                          >
                            Capture photo
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default WebCam;
