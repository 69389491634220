import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import FilterVariantIcon from 'mdi-react/FilterVariantIcon'; 
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import Alert from '@/shared/components/Alert';
import ChatCard from './components/ChatCard';
import {
  createTicket,
} from '../../../utils/services/user.service';

const Support = () => {
  const { t } = useTranslation('common');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [show1, setShow1] = useState(true);
  const handleClose1 = () => setShow1(false);
  const [SelectedMessege, setSelectedMessege] = useState('');
  const [SelectedSubject, setSelectedSubject] = useState('');
  const [showError1, setShowError1] = useState(false);
  const [showError2, setShowError2] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImages1, setSelectedImages1] = useState([]);
  const handleMessegeChange = (event) => {
    const selectedCondition = event.target.value;
    setSelectedMessege(selectedCondition);
  };
  const handleSubjectChange = (event) => {
    const selectedCondition = event.target.value;
    setSelectedSubject(selectedCondition);
  };
  const handleShow = () => {
    setShow(true);
  };
  const handleImageUpload = (e) => {
    const selectedImagesArray = e.target.files[0];
    // setSelectedImages(Array.from(selectedImagesArray));
    setSelectedImages(selectedImagesArray);
  
    // setSelectedImages1(Array.from(selectedImagesArray));

    // setSelectedImages1(prevImages => [...prevImages, ...selectedImagesArray]);
    // selectedImages1.push(selectedImagesArray);
  };
  // setShow1(true);
  const createTicketUser = () => {
    // console.log('createTicket');
    if (SelectedSubject === '' || SelectedMessege === '') {
      if (SelectedSubject === '') {
        setShowError1(true);    
      }
      if (SelectedMessege === '') {
        setShowError2(true);
      }
    } else {
      setShow(false);
      const formData = new FormData();   
      formData.append('subject', SelectedSubject);
      formData.append('message', SelectedMessege);
      formData.append('File', selectedImages);
      const fetchTicket = async () => {
        try {
          const response = await createTicket(formData);
          if (response.data) {
            if (response.data.status === 200) {
              toast.success('Ticket Created Successfully', {
                position: toast.POSITION.TOP_RIGHT,
              });
              window.location.reload();
            }
            // console.log('createTicket Response Data : ', response.data); 
          } else {
            console.error('No createTicket');
          }
        } catch (error) {
          console.error('Error fetching createTicket:', error);
        }
      };
      fetchTicket();
    }
  }; 
  // const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  //   <span
  //     ref={ref}
  //     onClick={(e) => {
  //       e.preventDefault();
  //       onClick(e);
  //     }}
  //   >
  //     {children}
  //   </span>
  // ));
  return (
    <Container>
      <Row className="mx-3">
        <Col xs={12} lg={2} className="py-1 text-center" />
        <Col xs={12} md={6} lg={8} className="py-1 text-center">
          <h2 className="mb-0 mt-0" style={{ fontSize: 'x-large' }}>Customer Service</h2>
        </Col>
        <Col xs={12} md={6} lg={2} className="py-1 text-center">
          <Button
            variant="dark"
            style={{ fontSize: '14px' }}
            className="purple-button mt-1 py-2 text-capitalize w-100 float-right"
            onClick={() => handleShow()}
          >
            Create Ticket
          </Button>
        </Col>
      </Row>
      <Row>
        {/* <Col md={2}>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
              <FilterVariantIcon style={{ color: 'white' }} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>Action 1</Dropdown.Item>
              <Dropdown.Item>Action 2</Dropdown.Item>
              <Dropdown.Item>Action 3</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col> */}
      </Row>
      <Row>
        <ChatCard />
      </Row>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: 'large' }}>Create Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container padding-bottom-3x p-0">
            <div className="card mb-3 mt-0" style={{ boxShadow: 'none', fontFamily: 'sans-serif' }}>
              <div className="card-body py-0">
                <div className="row">
                  <div className="col-12 text-center">
                    <input
                      type="text"
                      required
                      placeholder="Subject"
                      className="form-control mr-1"
                      onChange={handleSubjectChange}
                      style={{ marginTop: '3px' }}
                    />
                    <input
                      type="file"
                      name="File[]"
                      id="File"
                      multiple
                      onChange={handleImageUpload}
                      className="form-control mt-2"
                      accept="image/*"
                    />
                    {showError1 && <ErrorMsg className="input feedback">Subject is Required</ErrorMsg>}
                    <textarea
                      required
                      onChange={handleMessegeChange}
                      className="form-control mt-2"
                      rows={3}
                      cols={50}
                      placeholder="Enter Message"
                    /> 
                    {showError2 && <ErrorMsg className="input feedback">Messege is Required</ErrorMsg>}
                    <Button
                      variant="dark"
                      style={{ fontSize: '14px', margin: 'auto' }}
                      className="purple-button mt-2 py-2 text-capitalize w-50 mx-auto"
                      onClick={() => createTicketUser()}
                    >
                      Create
                    </Button>
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show1}
        onHide={handleClose1}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: 'large' }}><b>Attention!</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container padding-bottom-3x p-0">
            <div className="card mt-0 p-0" style={{ boxShadow: 'none', fontFamily: 'sans-serif' }}>
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-12 text-danger text-center">
                    <i className="fa fa-info-circle mt-1" style={{ fontSize: '30px' }} aria-hidden="true" />
                  </div>
                  <div className="col-12 text-danger text-center">
                    <p className="text-danger">
                      Our Support will never ask you to share your personal details like pssword etc. 
                      if anyone ask you to share any personal details please report us at 
                      <b className="text-secondary"> <u> support@custodiy.com</u> </b>
                    </p>            
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Support;

const ErrorMsg = styled.div`
 
color: #e66e6e;
padding: 2px 0px;
font-size: 12px;
cursor:none;
`;
