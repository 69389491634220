import ReactTableBase from "@/shared/components/table/ReactTableBase";
import {
  getAllSolBalanceUser,
  getAllTokens,
} from "../../../utils/services/user.service";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { colorDarkText } from "@/utils/palette";

import Tooltip from "@/shared/components/Tooltip";
import { SidebarLinkIcon } from "../../Layout/components/sidebar/SidebarLink";
import { CustomSelect } from "../../../shared/components/CustomSelect";
import { Ring } from "@uiball/loaders";
import { Button } from "@/shared/components/Button";
import { CSVLink, CSVDownload } from "react-csv";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const GetAllSolBalanceUser = ({ marketplaceID }) => {
  const [allBalanceData, setAllBalanceUser] = useState([]);
  const [tooltipText, setTooltipText] = useState("");
  const [allTokens, setAllTokens] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const updatedTokens = allTokens.filter((token) => token.symbol !== "BTC");

  const fetchAllSolBalanceUser = async (selectedSymbol) => {
    try {
      setIsLoading(true);
      const res = await getAllSolBalanceUser(
        selectedSymbol ? selectedSymbol : "SOLANA"
      );
      setAllBalanceUser(res.data.balance);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching SOL balance:", error);
      setIsLoading(false);
    }
  };

  const onCopyWalletAddress = (item) => {
    navigator.clipboard.writeText(item);
    setTooltipText("Copied");
    setTimeout(() => {
      setTooltipText("");
    }, 5000); // Revert to original text after 5 seconds
  };

  const fetchAllTokens = async () => {
    const payload = {
      chain_id: "",
      limit:100,
      page_number:1
    };
    const res = await getAllTokens(payload);
    setAllTokens(res.data.user);
  };

  useEffect(() => {
    fetchAllTokens();
    fetchAllSolBalanceUser();
  }, []);

  const header = [
    { accessor: "symbol", Header: "Symbol" },
    { accessor: "user_name", Header: "Name" },
    { accessor: "user_email", Header: " Email" },
    { accessor: "balance", Header: "Balance" },
    { accessor: "address", Header: "Wallet Address" },
    { accessor: "sol_address", Header: "Sol Address" },
  ];
  const tableConfi2 = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    isPagination: false,
    withSearchEngine: false,
    manualPageSize: [10000, 20, 30, 40],
    placeholder: "Search...",
    withPagination: true,
  };

  const data = [];
  allBalanceData.map((item) =>
    data.push({
      transection_Id: item._id,
      user_name: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.user_name ? item?.user_name : "-"}
        </h6>
      ),
      user_email: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.email ? item.email : "-"}
        </h6>
      ),
      user_email: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.email ? item.email : "-"}
        </h6>
      ),
      address: (
        <div className="flex justify-center items-center gap-2">
          {`${item.address.slice(0, 10)}...${item.address.slice(-5)}`}
          <Tooltip
            text={tooltipText || item.address}
            placement="top"
            style={{ cursor: "pointer" }}
          >
            <SidebarLinkIcon
              style={{ cursor: "pointer" }}
              className="ml-1 lnr lnr-book"
              onClick={() => onCopyWalletAddress(item.user[0].address)}
            />
          </Tooltip>
        </div>
      ),
      balance: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.balance}
        </h6>
      ),
      sol_address: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.sol_address}
        </h6>
      ),
      symbol: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.symbol}
        </h6>
      ),
    })
  );

  const handleSubmit = async () => {
    fetchAllSolBalanceUser(selectedSymbol);
  };


  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(allBalanceData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    saveAs(blob, "data.xlsx");
};
  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <div className="d-md-flex d-block ">
        <div className=" col-md-3 col-sm-12">
          <label className="text-white">Choose Symbol</label>
          <InputDiv>
            <DropdownDiv className="mb-0">
              <CustomSelect
                name="receiver"
                placeholder={`${
                  selectedSymbol ? selectedSymbol : "Choose Symbol"
                }`}
                onChange={(value) => {

                  setSelectedSymbol(value.label);
                }}
                value={selectedSymbol}
                options={updatedTokens}
                isSearchable
              />
            </DropdownDiv>
          </InputDiv>
        </div>
        <div className=" col-md-3 col-sm-12">
          <label className="text-white">Submit</label>

          <InputDiv>
            <FormButton
              variant="outline-white"
              className="py-2 w-100 "
              onClick={handleSubmit}
            >
              Submit
            </FormButton>
          </InputDiv>
        </div>
        <div className="col-md-3 col-sm-12">
          <label className="text-white">Export to CSV</label>

          <InputDiv>
            <FormButton
              variant="outline-white"
              className="py-2 w-100 "
              // onClick={handleExportToCSV}
            >
              <CSVLink data={allBalanceData} className="text-white" filename={"UserSolBlance.csv"}>Export</CSVLink>
            </FormButton>
          </InputDiv>
        </div>
        <div className=" col-md-3 col-sm-12">
          <label className="text-white">Export to Excel</label>

          <InputDiv>
            <FormButton
              variant="outline-white"
              className="py-2 w-100 "
              onClick={handleExportToExcel}
            >
              Export
            </FormButton>
          </InputDiv>
        </div>
      </div>
      {allBalanceData.length > 0 ? (
        <ReactTableBase
          columns={header}
          data={data}
          tableConfig={tableConfi2}
        />
      ) : (
        <h3>No Data Found</h3>
      )}
    </>
  );
};
export default GetAllSolBalanceUser;
const HashLink = styled.button`
  border: none;
  background: transparent;
  color: #4ce1b6;
`;
const LegendCol = styled("div")`
  flex-shrink: 2;
  flex-grow: 0;

  :first-child {
    flex-shrink: 0;
    flex-basis: 100px;
  }

  :last-child {
    flex-grow: 1;
  }
`;

const LegendColTitle = styled("div")`
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.1;
  color: ${colorDarkText};
`;

const LegendColContent = styled("ul")`
  padding: 0;
  list-style: none;
  color: ${colorDarkText};

  li {
    margin-top: 15px;
    font-size: 14px;
  }
`;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
