import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Badge from 'react-bootstrap/Badge';
import MenuIcon from 'mdi-react/MenuIcon';
import MoreVertIcon from 'mdi-react/MoreVertIcon';
import VideoIcon from 'mdi-react/VideoIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import StarIcon from 'mdi-react/StarIcon';
import { ContactProps } from '@/shared/prop-types/ChatProps';
import {
  colorAccent,
  colorAdditional,
  colorBackground,
  colorFieldsBorder,
  colorIcon,
  colorText,
} from '@/utils/palette';
import {
  marginLeft,
  marginRight,
  left,
} from '@/utils/directions';

const ChatTopbar = ({ onClick, contact, online }) => {
  // console.log('online', online);
  // console.log('online', online);
  const getStatus = () => {
    if (contact.isTyping) {
      return 'Typing...';
    } if (contact.online) {
      return 'Online';
    } 
      // You can format the last seen time as needed
      // return `Last seen ${contact.lastSeen}`;
      return 'Online';
  };

  return (
    <ChatTopbarWrap>
      <ChatTopbarButtonMenu type="button">
        <MenuIcon />
      </ChatTopbarButtonMenu>
      {contact && (
        <ChatTopbarContact>
          <ChatTopBbarContactName>Administrator 
            {/* <i className="fas fa-user-cog" style={{ fontSize: '16px', color: 'black', marginLeft: '4px' }} /> */}
          </ChatTopBbarContactName> 
          {online && (
          <ChatTopbarContactStatus>
            <h5 className="leading-9">
              <span style={{ fontSize: '12px', fontFamily: 'system-ui' }}>Connected</span> 
              <Badge 
                pill 
                className="ml-1 mt-1"
                bg="success"
                style={{ 
                    width: '11px',
                    height: '13px',              
                }}
              >
                <span className="text-success">.</span> 
              </Badge>
            </h5>
          </ChatTopbarContactStatus>
          )}
        </ChatTopbarContact>
      )}
      <ChatTopbarRight>
        {contact && (
          <ChatTopbarControls hidden>
            <ChatTopbarButton type="button">
              <VideoIcon />
            </ChatTopbarButton>
            <ChatTopbarButton type="button">
              <PhoneIcon />
            </ChatTopbarButton>
            <ChatTopbarButton type="button">
              <StarIcon />
            </ChatTopbarButton>
          </ChatTopbarControls>
        )}
        <ChatTopbarButton type="button" onClick={onClick}>
          {/* <MoreVertIcon /> */}
          <DivNew>
            <div style={{ fontSize: '13px', marginTop: '2px' }}><i className="fa-solid fa-xmark" /></div>
          </DivNew>
          {/* <i style={{ color: '#dc3545', fontSize: 'x-large' }} className="fa fa-times-circle mt-2" /> */}
        </ChatTopbarButton>
      </ChatTopbarRight>
    </ChatTopbarWrap>
  );
};

ChatTopbar.propTypes = {
  onClick: PropTypes.func.isRequired,
  contact: ContactProps,
};

ChatTopbar.defaultProps = {
  contact: null,
};

export default ChatTopbar;

// region STYLES
const ChatTopbarContactStatus = styled.p`
  margin: 0;
  color: ffffffe6;
  font-size: 11px;
  line-height: 1.45;
`;

const ChatBubbleMessage = styled.p`
  margin: 0 0 10px 0;
  font-size: 12px;
  color: ${colorText};
`;

const ChatTopbarWrap = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid ${colorFieldsBorder};
  border-top: 1px solid white;
  // border-bottom: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  background-color: #232329;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

const DivNew = styled.div`
  width: 25px;
  height: 25px;
  background-color: #dc3545; /* Background color of the circle */
  border-radius: 50%; /* Makes it a circle */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: white; /* Text color */
`;

const ChatTopbarButton = styled.button`
  background-color: transparent;
  border: none;
  transition: all 0.3s;
  cursor: pointer;
  width: 50px;
  height: 50px;
  
  svg {
    height: 18px;
    width: 18px;
    transition: 0.3s;
    fill: ${colorIcon};
  }

  &:hover svg {
    fill: ${colorAccent};
  }
`;

const ChatTopbarButtonMenu = styled(ChatTopbarButton)`
  ${marginRight}: -20px;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const ChatTopbarControls = styled.div`
  display: none;

  @media screen and (min-width: 698px) {
    display: flex;
  }
`;

const ChatTopbarRight = styled.div`
  ${marginLeft}: auto;
  display: flex;
`;

const ChatTopbarContact = styled.div`
  text-align: ${left};
  ${marginLeft}: 20px;
`;

const ChatTopBbarContactName = styled.p`
  font-size: 18px;
  font-family: system-ui;
  font-weight: 500;
  line-height: 1.33;
  color: ffffffe6;
`;

const ChatTopbarContactPost = styled.p`
  margin: 0;
  color: ${colorAdditional};
  font-size: 11px;
  line-height: 1.45
`;

// endregion
