import { handleActions } from "redux-actions";
import {
  fetchCalendarEventsRequest,
  fetchCalendarEventsSuccess,
  fetchCalendarEventsError,
} from "../../containers/DefaultPage/Calendar/components/events";

const defaultState = {
  data: [],
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [fetchCalendarEventsRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchCalendarEventsSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchCalendarEventsError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
