import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { right, left, marginRight } from "@/utils/directions";
import { CRYPTOCURRENCIES_NAMES_COINCAP } from "@/shared/constants/cryptocurrencies";
import { fetchTopTenCurrencies } from "@/redux/actions/topTenActions";
import CryptoRow from "./components/CryptoRow";
import {
  CustomSelect,
  CustomMultiSelect,
} from "../../../shared/components/CustomSelect";
import { fetchAllCurrencies1 } from "@/redux/actions/FetchCoinLists";
import {
  getUSDTConversion,
  getUSDCConversion,
  getPAXConversion,
  getBUSDConversion,
  getOPYConversion,
  getCTYConversion,
  getFTYConversion,
  getETHConversion,
  getBNBConversion,
  getEUROCConversion,
  getJPYCConversion,
  getGBPTConversion,
  getMATICConversion,
  getAllTokens,
} from "../../../utils/services/user.service";
import numeral from "numeral";

const Convertor = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const [showError4, setShowError4] = useState(false);
  const [ShowBalance, setShowBalance] = useState(false);
  const [ShowBalance1, setShowBalance1] = useState(true);
  const [isLoading1, setisLoading1] = useState(false);
  const [selecedTotalAmount, setTotalAmount] = useState("");
  const [selecedTotal, setTotal] = useState(0);
  const [selectedCoin, setSelectedCoin] = useState("");
  const [selectedFrom, setSelectedFrom] = useState("USD");
  const [allTokens, setAllTokens] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [amount, setAmount] = useState("");
  const { coinList1 } = useSelector((state) => ({
    coinList1: state.coinList1.data,
  }));
  const fetchAllTokens = async () => {
    const payload = {
      chain_id: "",
      limit: 100,
      page_number: 1
    };
    const res = await getAllTokens(payload);
    setAllTokens(res.data.user);
  };
  const getContractDetails = () => {
    setisLoading1(true);
    dispatch(fetchAllCurrencies1())
      .then(() => {

        setisLoading1(false);

      })
      .catch((error) => {
        // Handle any errors if needed

        setisLoading1(false);

      });
  };

  useEffect(() => {
    getContractDetails();
  }, [dispatch]);
  useEffect(() => {
    fetchAllTokens();
  }, []);

  useEffect(() => {
    // Filter and map the allTokens array
    const options = allTokens.map(token => ({
      value: token.symbol,
      label: token.symbol
    }));

    // Update the state with the new dropdown options
    setDropdownOptions(options);
  }, [allTokens]);

  useEffect(() => {
    const selectedCoinData = coinList1.find(coin => coin.symbol === selectedCoin);
    if (selectedCoinData) {
      const priceOfSelectedCoin = selectedCoinData.price;
      if (['BNB', 'MATIC', 'SOLANA', 'ETH', 'BTC'].includes(selectedCoin)) {
        setTotal(priceOfSelectedCoin / amount);
      } else {
        setTotal(priceOfSelectedCoin * amount);
      }
      setShowBalance(true);
      setShowBalance1(false);
    } else {
      setTotal(null); // For example, setting total to null if no match is found
    }
  }, [selectedCoin, coinList1, amount]);

  const optionFrom = [{ value: "USD", label: "USD" }];


  const handleCoinsChange = async (value) => {
    setSelectedCoin(value.label);
  };



  useEffect(() => {
    dispatch(fetchTopTenCurrencies());
  }, [dispatch]);

  return (
    <Container>
      <Row>
        <Col xs={12} md={12} lg={12} className="text-center">
          <h3 className="mx-3 mb-4">Currency Convertor</h3>
        </Col>
      </Row>
      <Row className="mx-0 mb-4">
        <Col xs={12} md={4} lg={3} />
        <Col xs={12} md={4} lg={6}>
          <div
            className="card productCard mt-2"
            style={{ background: "#232329", cursor: "pointer" }}
          >
            <div className="card-body">
              <Row className="my-2 mx-3">
                <Col xs={12} md={12} lg={12}>
                  <InputDiv>
                    <label
                      htmlFor="deliveryCharges"
                      className="form-label text-white"
                    >
                      Enter Amount
                    </label>
                    <div>
                      <ChatSearchInput
                        type="number"
                        value={amount}
                        name="deliveryCharges"
                        id="deliveryCharges"
                        placeholder="Enter Amount"
                        onChange={(e) => setAmount(e.target.value)}
                        className="form-control"
                      />
                      {showError4 && (
                        <Error className="input feedback">
                          Total Amount must be greater than 0
                        </Error>
                      )}
                    </div>
                  </InputDiv>
                </Col>
              </Row>
              <Row className="mt-2 mb-5 mx-3">
                <Col xs={12} md={6} lg={6}>
                  <label
                    htmlFor="deliveryCharges"
                    className="form-label text-white"
                  >
                    From
                  </label>
                  <DropdownDiv
                    className="mb-0"
                    style={{ textTransform: "Capitalize" }}
                  >
                    <CustomSelect
                      name="size"
                      id="size"
                      className="w-25 px-0"
                      value={selectedFrom}
                      options={optionFrom}
                    />
                  </DropdownDiv>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <label
                    htmlFor="deliveryCharges"
                    className="form-label text-white"
                  >
                    To
                  </label>
                  <DropdownDiv
                    className="mb-0"
                    style={{ textTransform: "Capitalize" }}
                  >
                    <CustomSelect
                      name="size"
                      id="size"
                      className="w-25 px-0"
                      value={selectedCoin}
                      onChange={handleCoinsChange}
                      placeholder={selectedCoin ? selectedCoin : "Select Coin"}
                      options={dropdownOptions}
                    // placeholder={}
                    />
                  </DropdownDiv>
                </Col>
              </Row>
              {ShowBalance1 && (
                <Row className="mt-2 mb-5 mx-1">
                  <Col xs={12} md={12} lg={12} className="text-center py-4">
                    &nbsp;
                  </Col>
                </Row>
              )}
              {ShowBalance && (
                <Row className="mt-2 mb-5 mx-1">
                  <Col xs={12} md={12} lg={12} className="text-center">
                    <h3>Amount in {selectedCoin} </h3>
                    <h3>
                      {numeral(selecedTotal).format('$0,0.00')} {selectedCoin}
                    </h3>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </Col>
        <Col xs={12} md={4} lg={3} />
      </Row>
    </Container>
  );
};

export default Convertor;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const Error = styled.div`
  color: #e66e6e;
  padding: 4px 0px;
  font-size: 14px;
  cursor: none;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;

const ProductItemLabel = styled.img`
  width: 30px;
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
