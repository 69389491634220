/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable vars-on-top */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { RxCross2 } from "react-icons/rx";
// import Button from "react-bootstrap/Button";
import { Button } from "../../../../../shared/components/Button";

import styled from "styled-components";
import EyeIcon from "mdi-react/EyeIcon";
import { faBullseye, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import {
  Spinner,
  Modal as BootstrapModal,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import {
  faEye,
  faEnvelope,
  faUser,
  faMap,
} from "@fortawesome/free-regular-svg-icons";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Panel from "@/shared/components/Panel";
import { ButtonToolbar } from "@/shared/components/Button";
import { flexFlow, paddingLeft, paddingRight } from "@/utils/directions";

import ReactTableBase from "@/shared/components/table/ReactTableBase";
import {
  API_URL,
  approveContractOtp,
  getAllTokens,
  getContractDetail,
  getContracts,
  getSafebox,
  getSafeboxDetail,
  safeboxApprove,
  safeboxReject,
  uploadBeneficiary_docs,
} from "../../../../../utils/services/user.service";
import {
  colorAccent,
  colorBackground,
  colorIcon,
  colorText,
  colorWhite,
} from "../../../../../utils/palette";
import ApproveOtpForm from "./ApproveOtpForm";
import {
  CustomSelect,
  CustomMultiSelect,
} from "../../../../../shared/components/CustomSelect";
import authHeader from "../../../../../utils/services/auth-header";
import { SidebarLinkIcon } from '../../../../Layout/components/sidebar/SidebarLink';
import { toast } from "react-toastify";

import SignaturePad from "../../../../../../src/store/Layout/orders/SignaturePad";
import PdfSignature from "../../../../../../src/store/Layout/orders/PdfSignature";
import { Ring } from "@uiball/loaders";
import ContractDetailModal from "./ContractDetailModal";
const header = [
  { accessor: "name", Header: "Name" },
  { accessor: "action", Header: "Action", disableGlobalFilter: true },
  { accessor: "date", Header: "Date" },
  { accessor: "amount", Header: "Amount" },
  { accessor: "approver", Header: "Approver" },
  { accessor: "hash", Header: "Hash", disableGlobalFilter: true },
  { accessor: "type_of_contract", Header: "Type" },
  { accessor: "symbol", Header: "Symbol" },
  { accessor: "status", Header: "Status" },
  { accessor: "walletType", Header: "Wallet Type" },
  { accessor: "id", Header: "ID" },
];

const ContractTable = ({ title, viewAll, detailShow }) => {
  const [contract, setcontract] = useState([]);
  const [contract1, setcontract1] = useState([]);
  const [safeboxData, setsafeboxData] = useState([]);
  const [safeboxData1, setsafeboxData1] = useState([]);
  const [mergedArray, setMergedArray] = useState([]);
  const [mergedArray1, setMergedArray1] = useState([]);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalIsOpenContract, setModalIsOpenConract] = useState(false);
  console.log("🚀 ~ ContractTable ~ modalIsOpenContract:", modalIsOpenContract)

  const [selectedOrder, setSelectedOrder] = useState(null);

  const [selectedOrder1, setSelectedOrder1] = useState(null);
  const [allTokens, setAllTokens] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [walletType, setWalletType] = useState("virtual");
  const [file1, setFile1] = useState(null);
  const fileInput = React.createRef();
  const [approveFile2, setFile2] = useState([]);
  console.log("🚀 ~ ContractTable ~ approveFile2:", approveFile2)
  const [getSubmit, setSubmit] = useState(false);
  const [contractRecord, setContractRecord] = useState(null);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [detail, setDetail] = useState([]);
  const [detail1, setDetail1] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [otpModal, setotpModal] = useState(false);
  const [showDocsModal, setShowDocsModal] = useState(false);
  const [otpLoad, setOtpLoad] = useState(false);
  const [Msg, setMsg] = useState(undefined);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedTypeOfContract, setSelectedTypeOfContract] = useState("");
  const [selectedApprovalStatus, setSelectedApprovalStatus] = useState("");
  const [selectedOperation, setSelectedOperation] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedAmount, setSelectedAmount] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedSymbol, setSelectedSymbol] = useState("");
  const [selectedSearch, setSelectedSearch] = useState("");
  const [selectedApprover, setSelectedApprover] = useState("");
  const [isSearchOrderNo, setIsSearchOrderNo] = useState(false);
  const [isSearchAddress, setIsSearchAddress] = useState(false);
  const [isSearchStatus, setIsSearchStatus] = useState(false);
  const [isSearchPaymentStatus, setIsSearchPaymentStatus] = useState(false);
  const [isSearchDate, setIsSearchDate] = useState(false);
  const [isSearchApprovalStatus, setIsSearchApprovalStatus] = useState(false);
  const [isSearchOrderStatus, setIsSearchOrderStatus] = useState(false);
  const [isSearchAmount, setIsSearchAmount] = useState(false);
  const [isSearchOperation, setIsSearchOperation] = useState(false);
  const [approveId, setApproveId] = useState("");
  const [approveLoad, setApproveLoad] = useState(false);
  const [status, setstatus] = useState(400);
  const [req, setReq] = useState("");
  const [rejectExternal, setRejectExternal] = useState([]);
  const Parcel = `${process.env.PUBLIC_URL}/img/remove-data.png`;
  const [signatureImage, setSignatureImage] = useState(null);
  const [signatureImage1, setSignatureImage1] = useState(null);
  const userInfo = JSON.parse(localStorage?.getItem('user'))
  const [selectedId, setSelectedId] = useState()
  const [files, setFiles] = useState([]);
  console.log("🚀 ~ ContractTable ~ files:", files)

  const getPDf = (value) => {

    setFile2((prevFiles) => [...prevFiles, value]); // Append new value to the existing array
  };

  const fetchAllTokens = async () => {
    const payload = {
      chain_id: "",
      limit: 100,
      page_number: 1
    };
    const res = await getAllTokens(payload);
    setAllTokens(res.data.user);
  };
  useEffect(() => {
    fetchAllTokens();
  }, []);
  useEffect(() => {
    // Filter and map the allTokens array
    const options = allTokens.map(token => ({
      value: token.symbol,
      label: token.symbol
    }));

    // Update the state with the new dropdown options
    setDropdownOptions(options);
  }, [allTokens]);

  useEffect(() => {
    const merged = contract.concat(safeboxData);
    setMergedArray(merged);
    setMergedArray1(merged);
  }, [contract, safeboxData]);
  // console.log('New mergedArray', mergedArray);

  const getContractDetails = () => {
    setIsLoading(true);
    getContracts()
      .then((res) => {
        setcontract(res.data.History.result);
        setcontract1(res.data.History.result);
        setIsLoading(false);
      })
      .catch(() => {
        setcontract([]);
        setIsLoading(false);
      });
  };
  const getSafeDetail = () => {
    setIsLoading(true);
    getSafebox()
      .then((res) => {
        setsafeboxData(res.data.data);
        setsafeboxData1(res.data.data);
        setIsLoading(false);
      })
      .catch(() => {
        setsafeboxData([]);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getContractDetails();
    getSafeDetail();
  }, []);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const toggle = () => {
    setModal((prevState) => !prevState);
  };

  const toggle1 = () => {
    setModal1((prevState) => !prevState);
  };

  const toggleOtp = () => {
    setotpModal((prevState) => !prevState);
    setFile1(null);
  };
  const getDetail = (id) => {
    setSelectedId(id)
    setIsLoad(true);
    setIsLoading(true);
    setModalIsOpenConract(true)
    // toggle();
    getContractDetail(id)
      .then((res) => {
        setIsLoad(false);
        // console.log('getDetail true >>>>>>>>>>> ', res.data.result[0]);
        // setDetail(res.data.result[0]);
        setSelectedOrder(res.data.result[0]);
      })
      .catch((error) => {
        setstatus(400);
        setIsLoad(false);
        // setDetail(error.response.data.Message);
      });
    setModalIsOpen(true);
    setIsLoading(false);
  };
  const getOtp = (id, item) => {
    // console.log('==item', item);
    setRejectExternal(item);
    setOtpLoad(true);
    toggleOtp();
    setApproveId(id);
    approveContractOtp(id)
      .then(
        (res) => {
          setstatus(200);
          setOtpLoad(false);
          setMsg(res.data.message);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setstatus(400);
          if (error.response.data.status === 400) {
            setOtpLoad(false);
            // console.log('45sdd45====', error);
            setMsg(error.response.data.Error);
          } else {
            setOtpLoad(false);
            setMsg(message);
          }
        }
      )
      .catch((err) => {
        setstatus(400);
        setOtpLoad(false);
        setMsg(err.data.message);
      });
  };
  const verifyOtp = (values) => {
    console.log('inside if condition')
    setMsg(undefined);
    setApproveLoad(true);
    setIsLoading(true)

    const id = approveId;
    const { otp } = values;
    const trasactionId = id;
    const approverWalletAddress = rejectExternal?.Approver?.address;

    // Create a FormData instance
    const formDataPayload = new FormData();

    // Determine payload content based on `req`
    if (["External Reject", "External Approval"].includes(req)) {
      formDataPayload.append("trasactionId", trasactionId);
      formDataPayload.append("approverWalletAddress", approverWalletAddress);
      formDataPayload.append("otp", otp);
    } else {
      formDataPayload.append("id", id);
      formDataPayload.append("otp", otp);
    }


    // Append each file from the approveFile2 array to FormData under the same "File" key
    if (approveFile2 && approveFile2.length > 0) {
      approveFile2.forEach((file) => {
        formDataPayload.append("File", file); // Append each file under the same "File" key
      });
    }

    // Set up headers
    const headers = {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    };

    // Determine the URL based on the `req` value
    const url =
      walletType !== "virtual" && req === "Approval"
        ? `${API_URL}thirdPartyApproveOnchain`
        : req === "Approval"
          ? `${API_URL}approve_third_party_transaction`
          : req === "External Approval"
            ? `${API_URL}approve_third_party_transaction_external`
            : req === "External Reject"
              ? `${API_URL}reject_third_party_transaction_external`
              : `${API_URL}reject_third_party_transaction`;

    // Make the API call
    axios
      .post(url, formDataPayload, { headers })
      .then((response) => {
        setIsLoading(false)

        setstatus(200);
        setApproveLoad(false);
        req === "Approval"
          ? setMsg(response.data.response.Message)
          : setMsg(response.data.Message);
        getContractDetails();
        // toggleOtp()
        setMsg(undefined);

        // Handle the response here
        setotpModal(false)
      })
      .catch((error) => {
        // Handle the error here
      })

      .then(
        (res) => {
          setIsLoading(false)

          setstatus(200);
          setApproveLoad(false);
          req === "Approval"
            ? setMsg(res.data.response.Message)
            : setMsg(res.data.Message);
          getContractDetails();
          // toggleOtp()
          setMsg(undefined);


        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setstatus(400);
          setIsLoading(false)

          if (error.response.data.status === 400) {
            setIsLoading(false)

            setApproveLoad(false);
            // console.log('=0t', error.response.data.Message, error.response.data.Message.length);
            setMsg(
              error.response.data.Message?.length === undefined
                ? "Undefined Message"
                : error.response.data.Message
            );
          } else {
            setApproveLoad(false);
            setMsg(message);
            setIsLoading(false)

          }
        }
      );
  };

  const getDetail1 = (id) => {
    setIsLoad(true);
    setIsLoading(true);
    // toggle1();
    getSafeboxDetail(id)
      .then((res) => {
        setIsLoad(false);
        // console.log(res);
        // setDetail1(res.data.data);
        setSelectedOrder1(res.data.data);
      })
      .catch((error) => {
        setstatus(400);
        setIsLoad(false);
        // console.log('errorerrorerrorerrorerrorerror', error);
        // setDetail1(error.response.data.message);
      });
    setModalIsOpen1(true);
    setIsLoading(false);
  };
  const getOtp1 = (id) => {
    setOtpLoad(true);
    toggleOtp();
    safeboxApprove(id)
      .then(
        (res) => {
          setstatus(200);
          setOtpLoad(false);
          setMsg(res.data.message);
          getSafeDetail();
          setTimeout(() => {
            setMsg("");
            toggleOtp();
          }, 3000);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setstatus(400);
          if (error.response.data.status === 400) {
            setOtpLoad(false);
            setMsg(error.response.data.Error);
            setTimeout(() => {
              setMsg("");
              toggleOtp();
            }, 3000);
          } else {
            setOtpLoad(false);
            setMsg(message);
            setTimeout(() => {
              setMsg("");
              toggleOtp();
            }, 3000);
          }

          // return Promise.reject();
        }
      )
      .catch((err) => {
        setstatus(400);
        setOtpLoad(false);
        setMsg(err.data.message);
      });
  };
  const getReject1 = (id) => {
    setOtpLoad(true);
    toggleOtp();
    safeboxReject(id)
      .then(
        (res) => {
          setstatus(200);
          setOtpLoad(false);
          setMsg(res.data.message);
          getSafeDetail();
          setTimeout(() => {
            setMsg("");
            toggleOtp();
          }, 3000);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setstatus(400);
          if (error.response.data.status === 400) {
            setOtpLoad(false);
            setMsg(error.response.data.Error);
            setTimeout(() => {
              setMsg("");
              toggleOtp();
            }, 3000);
          } else {
            setOtpLoad(false);
            setMsg(message);
            setTimeout(() => {
              setMsg("");
              toggleOtp();
            }, 3000);
          }

          // return Promise.reject();
        }
      )
      .catch((err) => {
        setstatus(400);
        setOtpLoad(false);
        setMsg(err.data.message);
      });
  };

  const closeContractsDetails = () => {
    setSelectedOrder(null);
    setModalIsOpen(false);
  };

  const closeContractsDetails1 = () => {
    setSelectedOrder1(null);
    setModalIsOpen1(false);
  };

  const optionOrderStatus = [
    // { value: 'All', label: 'All' },
    { value: "Completed", label: "Completed" },
    { value: "pending", label: "Pending" },
    { value: "Rejected", label: "Rejected" },
    { value: "Waiting", label: "Waiting" },
    { value: "Declined", label: "Declined" },
    { value: "Failed", label: "Failed" },
  ];

  const optionContracts = [
    { value: "contract", label: "Contract" },
    { value: "safebox", label: "Safebox" },
  ];

  const searchFilters = [
    { value: "order_no", label: "Order No" },
    { value: "date", label: "Date" },
    { value: "address", label: "Delivery Address" },
    { value: "amount", label: "Amount" },
    { value: "payment_status", label: "Payment Status" },
    { value: "approval_status", label: "Approval Status" },
    { value: "order_status", label: "Order Status" },
  ];

  const handleSearchFilterChange = (value) => {
    if (value.value === "date") {
      setIsSearchOrderNo(false);
      setIsSearchAddress(false);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(false);
      setIsSearchDate(true);
      setSelectedSearch(value.value);
    }
    if (value.value === "order_no") {
      setIsSearchOrderNo(true);
      setIsSearchAddress(false);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(false);
      setIsSearchDate(false);
      setSelectedSearch(value.value);
    }
    if (value.value === "address") {
      setIsSearchOrderNo(false);
      setIsSearchAddress(true);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(false);
      setIsSearchDate(false);
      setSelectedSearch(value.value);
    }
    if (value.value === "amount") {
      setIsSearchOrderNo(false);
      setIsSearchAddress(false);
      setIsSearchAmount(true);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(false);
      setIsSearchDate(false);
      setSelectedSearch(value.value);
    }
    if (value.value === "payment_status") {
      setIsSearchOrderNo(false);
      setIsSearchAddress(false);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(true);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(false);
      setIsSearchDate(false);
      setSelectedSearch(value.value);
    }
    if (value.value === "approval_status") {
      setIsSearchOrderNo(false);
      setIsSearchAddress(false);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(true);
      setIsSearchOrderStatus(false);
      setIsSearchDate(false);
      setSelectedSearch(value.value);
    }
    if (value.value === "order_status") {
      setIsSearchOrderNo(false);
      setIsSearchAddress(false);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(true);
      setIsSearchDate(false);
      setSelectedSearch(value.value);
    }
  };

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    function formatDate(inputDate) {
      const parts = inputDate.split("-");
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    const formattedDate = formatDate(newDate);
    let completedTransactions = "";
    // completedTransactions = mergedArray1.filter(transaction => transaction.Date === formattedDate);
    completedTransactions = mergedArray1.filter(
      (transaction) =>
        (transaction.Date && transaction.Date === formattedDate) ||
        (transaction.date && transaction.date === formattedDate)
    );
    if (selectedName !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          (transaction.Name &&
            transaction.Name.toLowerCase().includes(
              selectedName.toLowerCase()
            )) ||
          (transaction.name &&
            transaction.name.toLowerCase().includes(selectedName.toLowerCase()))
      );
    }
    if (selectedApprover !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          (transaction.Approver?.name &&
            transaction.Approver?.name
              .toLowerCase()
              .includes(selectedApprover.toLowerCase())) ||
          (transaction.approvers &&
            transaction.approvers[0]?.approver?.name &&
            transaction.approvers[0]?.approver?.name
              .toLowerCase()
              .includes(selectedApprover.toLowerCase()))
      );
    }
    if (selectedTypeOfContract !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.contract_type === selectedTypeOfContract
      );
    }
    if (selectedStatus !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.status === selectedStatus
      );
    }
    if (selectedSymbol !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          transaction.Symbol &&
          transaction.Symbol.toLowerCase().includes(
            selectedSymbol.toLowerCase()
          )
      );
    }
    if (selectedAmount !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          transaction.Amount &&
          transaction.Amount.toLowerCase().includes(
            selectedAmount.toLowerCase()
          )
      );
    }
    setMergedArray(completedTransactions);
    setSelectedDate(newDate);
  };

  const handlePaymentStatusChange = (value) => {
    const completedTransactions = mergedArray1.filter(
      (transaction) => transaction.Payment_status === value.value
    );
    setSelectedPaymentStatus(value.value);
    setcontract(completedTransactions);
  };

  const handleApprovalStatusChange = (value) => {
    const completedTransactions = mergedArray1.filter(
      (transaction) => transaction.is_approved_status === value.value
    );
    setSelectedApprovalStatus(value.value);
    setcontract(completedTransactions);
  };

  const handleTypeOfContractChange = (value) => {
    let completedTransactions = "";
    function formatDate(inputDate) {
      const parts = inputDate.split("-");
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    completedTransactions = mergedArray1.filter(
      (transaction) => transaction.contract_type === value.value
    );
    if (selectedName !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          (transaction.Name &&
            transaction.Name.toLowerCase().includes(
              selectedName.toLowerCase()
            )) ||
          (transaction.name &&
            transaction.name.toLowerCase().includes(selectedName.toLowerCase()))
      );
    }
    if (selectedApprover !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          (transaction.Approver?.name &&
            transaction.Approver?.name
              .toLowerCase()
              .includes(selectedApprover.toLowerCase())) ||
          (transaction.approvers &&
            transaction.approvers[0]?.approver?.name &&
            transaction.approvers[0]?.approver?.name
              .toLowerCase()
              .includes(selectedApprover.toLowerCase()))
      );
    }
    if (selectedDate !== "") {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          (transaction.Date && transaction.Date === formattedDate) ||
          (transaction.date && transaction.date === formattedDate)
      );
    }
    if (selectedSymbol !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          transaction.Symbol &&
          transaction.Symbol.toLowerCase().includes(
            selectedSymbol.toLowerCase()
          )
      );
    }
    if (selectedAmount !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          transaction.Amount &&
          transaction.Amount.toLowerCase().includes(
            selectedAmount.toLowerCase()
          )
      );
    }
    if (selectedStatus !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.status === selectedStatus
      );
    }
    setSelectedTypeOfContract(value.value);
    setMergedArray(completedTransactions);
  };

  const handleOrderStatusChange = (value) => {
    function formatDate(inputDate) {
      const parts = inputDate.split("-");
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    let completedTransactions = "";
    completedTransactions = mergedArray1.filter(
      (transaction) => transaction.status === value.value
    );
    if (selectedName !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          (transaction.Name &&
            transaction.Name.toLowerCase().includes(
              selectedName.toLowerCase()
            )) ||
          (transaction.name &&
            transaction.name.toLowerCase().includes(selectedName.toLowerCase()))
      );
    }
    if (selectedApprover !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          (transaction.Approver?.name &&
            transaction.Approver?.name
              .toLowerCase()
              .includes(selectedApprover.toLowerCase())) ||
          (transaction.approvers &&
            transaction.approvers[0]?.approver?.name &&
            transaction.approvers[0]?.approver?.name
              .toLowerCase()
              .includes(selectedApprover.toLowerCase()))
      );
    }
    if (selectedTypeOfContract !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.contract_type === selectedTypeOfContract
      );
    }
    if (selectedSymbol !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          transaction.Symbol &&
          transaction.Symbol.toLowerCase().includes(
            selectedSymbol.toLowerCase()
          )
      );
    }
    if (selectedAmount !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          transaction.Amount &&
          transaction.Amount.toLowerCase().includes(
            selectedAmount.toLowerCase()
          )
      );
    }
    if (selectedDate !== "") {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          (transaction.Date && transaction.Date === formattedDate) ||
          (transaction.date && transaction.date === formattedDate)
      );
    }
    setSelectedStatus(value.value);
    setMergedArray(completedTransactions);
  };

  const handleApproverChange = (value) => {
    const partialValue = value.target.value;
    function formatDate(inputDate) {
      const parts = inputDate.split("-");
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    let completedTransactions = "";
    if (partialValue.length === 0) {
      completedTransactions = mergedArray1;
    } else {
      completedTransactions = mergedArray1.filter(
        (transaction) =>
          (transaction.Approver?.name &&
            transaction.Approver?.name
              .toLowerCase()
              .includes(partialValue.toLowerCase())) ||
          (transaction.approvers &&
            transaction.approvers[0]?.approver?.name &&
            transaction.approvers[0]?.approver?.name
              .toLowerCase()
              .includes(partialValue.toLowerCase()))
      );
    }
    if (selectedName !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          (transaction.Name &&
            transaction.Name.toLowerCase().includes(
              selectedName.toLowerCase()
            )) ||
          (transaction.name &&
            transaction.name.toLowerCase().includes(selectedName.toLowerCase()))
      );
    }
    if (selectedStatus !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.status === selectedStatus
      );
    }
    if (selectedTypeOfContract !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.contract_type === selectedTypeOfContract
      );
    }
    if (selectedSymbol !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          transaction.Symbol &&
          transaction.Symbol.toLowerCase().includes(
            selectedSymbol.toLowerCase()
          )
      );
    }
    if (selectedAmount !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          transaction.Amount &&
          transaction.Amount.toLowerCase().includes(
            selectedAmount.toLowerCase()
          )
      );
    }
    if (selectedDate !== "") {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          (transaction.Date && transaction.Date === formattedDate) ||
          (transaction.date && transaction.date === formattedDate)
      );
    }
    setMergedArray(completedTransactions);
    setSelectedApprover(value.target.value);
  };

  const handleNameChange = (value) => {
    const partialValue = value.target.value;
    function formatDate(inputDate) {
      const parts = inputDate.split("-");
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    let completedTransactions = "";
    completedTransactions = mergedArray1.filter(
      (transaction) =>
        (transaction.Name &&
          transaction.Name.toLowerCase().includes(
            partialValue.toLowerCase()
          )) ||
        (transaction.name &&
          transaction.name.toLowerCase().includes(partialValue.toLowerCase()))
    );
    if (selectedApprover !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.Approver?.name
          .toLowerCase()
          .includes(selectedApprover.toLowerCase())
      );
    }
    if (selectedStatus !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.status === selectedStatus
      );
    }
    if (selectedTypeOfContract !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.contract_type === selectedTypeOfContract
      );
    }
    if (selectedSymbol !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          transaction.Symbol && transaction.Symbol === selectedSymbol
      );
    }
    if (selectedAmount !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          transaction.Amount &&
          transaction.Amount.toLowerCase().includes(
            selectedAmount.toLowerCase()
          )
      );
    }
    if (selectedDate !== "") {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          (transaction.Date && transaction.Date === formattedDate) ||
          (transaction.date && transaction.date === formattedDate)
      );
    }
    setMergedArray(completedTransactions);
    setSelectedName(value.target.value);
  };

  const handleSymbolChange = (value) => {
    const partialValue = value.value;
    function formatDate(inputDate) {
      const parts = inputDate.split("-");
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    let completedTransactions = "";
    completedTransactions = mergedArray1.filter(
      (transaction) => transaction.Symbol && transaction.Symbol === partialValue
    );
    if (selectedApprover !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.Approver?.name
          .toLowerCase()
          .includes(selectedApprover.toLowerCase())
      );
    }
    if (selectedStatus !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.status === selectedStatus
      );
    }
    if (selectedTypeOfContract !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.contract_type === selectedTypeOfContract
      );
    }
    if (selectedAmount !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          transaction.Amount &&
          transaction.Amount.toLowerCase().includes(
            selectedAmount.toLowerCase()
          )
      );
    }
    if (selectedDate !== "") {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          (transaction.Date && transaction.Date === formattedDate) ||
          (transaction.date && transaction.date === formattedDate)
      );
    }
    if (selectedName !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          (transaction.Name &&
            transaction.Name.toLowerCase().includes(
              selectedName.toLowerCase()
            )) ||
          (transaction.name &&
            transaction.name.toLowerCase().includes(selectedName.toLowerCase()))
      );
    }
    setMergedArray(completedTransactions);
    setSelectedSymbol(value.value);
  };

  const handleAmountChange = (value) => {
    const partialValue = value.target.value;
    function formatDate(inputDate) {
      const parts = inputDate.split("-");
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    let completedTransactions = "";
    completedTransactions = mergedArray1.filter(
      (transaction) =>
        transaction.Amount &&
        transaction.Amount.toLowerCase().includes(partialValue.toLowerCase())
    );
    if (selectedApprover !== "") {
      completedTransactions = completedTransactions.filter((transaction) =>
        transaction.Approver?.name
          .toLowerCase()
          .includes(selectedApprover.toLowerCase())
      );
    }
    if (selectedStatus !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.status === selectedStatus
      );
    }
    if (selectedTypeOfContract !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) => transaction.contract_type === selectedTypeOfContract
      );
    }
    if (selectedSymbol !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          transaction.Symbol &&
          transaction.Symbol.toLowerCase().includes(
            selectedSymbol.toLowerCase()
          )
      );
    }
    if (selectedDate !== "") {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          (transaction.Date && transaction.Date === formattedDate) ||
          (transaction.date && transaction.date === formattedDate)
      );
    }
    if (selectedName !== "") {
      completedTransactions = completedTransactions.filter(
        (transaction) =>
          (transaction.Name &&
            transaction.Name.toLowerCase().includes(
              selectedName.toLowerCase()
            )) ||
          (transaction.name &&
            transaction.name.toLowerCase().includes(selectedName.toLowerCase()))
      );
    }
    setMergedArray(completedTransactions);
    setSelectedAmount(value.target.value);
  };

  const resetData = (order) => {
    setIsLoading(true);
    setSelectedDate("");
    setSelectedName("");
    setSelectedApprover("");
    setSelectedStatus("");
    setSelectedTypeOfContract("");
    setSelectedAmount("");
    setSelectedSymbol("");
    setMergedArray(mergedArray1);
    setIsLoading(false);
  };

  const tableConfig = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search...",
    isPagination: true,
  };

  const tableConfi2 = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    isPagination: false,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search...",
    withPagination: true,
  };

  const user = JSON.parse(localStorage.getItem("user"));

  const getStatusColor = (status1) => {
    switch (status1.toLowerCase()) {
      case "completed":
        return "#4ce1b6";
      case "pending":
        return "#f6da6e";
      case "rejected":
        return "#ff4861";
      case "waiting":
        return "#f6da6e";
      case "declined":
        return "#ff4861";
      case "failed":
        return "#ff4861";
      default:
        return "#70bbfd";
    }
  };

  const generateTransactionURL = (item) => {
    let baseURL;

    if (["ETH", "USDT_ERC20", "PAX", "GBPT", "EUROC"].includes(item?.Symbol)) {
      baseURL = "https://etherscan.io/tx/";
    } else if (["BNB", "CTY", "FTY", "OPY", "BUSD", "USDT", "MET$", "META"].includes(item?.Symbol)) {
      baseURL = "https://bscscan.com/tx/";
    } else if (["SOLANA", "LTX", "WEN", "SoLpy"].includes(item?.Symbol)) {
      baseURL = "https://explorer.solana.com/tx/";
    } else {
      baseURL = "https://polygonscan.com/tx/";
    }

    const networkPrefix = item?.network === "testnet" ? "testnet." : "";

    return `${baseURL}${networkPrefix}${item?.hash}`;
  };

  const data = [];

  mergedArray.map((item) =>
    data.push({
      id: (
        <div className="d-flex flex-row gap-2 align-items-center">
          {item._id}
          {/* <SidebarLinkIcon className="lnr lnr-book" style={{
            cursor: "pointer"
          }} onClick={() => {
            navigator.clipboard.writeText(item._id)
            toast.success('Text Copied')
          }
          } /> */}
        </div>
      ),

      date: item.contract_type === "safebox" ? item.date : item.Date,
      name:
        item.contract_type === "safebox" ? (
          <h6
            className="text-capitalize text-nowrap align-self-start d-flex"
            style={{ color: "#999999", fontSize: "inherit" }}
          >
            {item.name}
          </h6>
        ) : (
          // item.type === 'contract' ? `Contract@ ${item.Name?.substring(0, 12)}` : item.Name
          <h6
            className="text-capitalize text-nowrap align-self-start d-flex"
            style={{ color: "#999999", fontSize: "inherit" }}
          >
            {item.Name}
          </h6>
        ),
      hash:
        item.contract_type === "safebox" ? (

          <HashLink
            role="link"
            onClick={() =>
              openInNewTab(`https://bscscan.com/tx/${item.hash[0]}`)
            }
          >
            {" "}
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </HashLink>
        ) : (
          <HashLink
            role="link"
            onClick={() =>
              openInNewTab(`https://bscscan.com/tx/${item.hash}`)
            }
          >
            {" "}
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </HashLink>
        ),
      // hash:
      //   (<HashLink
      //     role="link"
      //     onClick={() => {
      //       item.type === 'transaction' || item.type === 'external_wallet' || item.type === 'contract'
      //         ? openInNewTab(`https://bscscan.com/tx/${item.hash}`)
      //         : item.type === 'contract_test' || item.type === 'external_wallet_test'
      //           ? openInNewTab(`https://testnet.bscscan.com/tx/${item.hash}`)
      //           : null;
      //     }}
      //   >
      //     <FontAwesomeIcon icon={faExternalLinkAlt} />
      //    </HashLink>),
      type_of_contract: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.contract_type}
        </h6>
      ),
      amount: item.contract_type === "safebox" ? "-" : `${item.Amount}`,
      symbol: item.contract_type === "safebox" ? "-" : `${item.Symbol === 'USDT' ? "USDT (BEP20)" : item?.Symbol}`,
      status: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{ backgroundColor: getStatusColor(item.status) }}
        >
          <h6 className="text-white">
            <b>{item.status}</b>
          </h6>
        </span>
      ),
      walletType:
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.trx_status || "Virtual"}
        </h6>
      ,
      approver: (
        <div style={{ display: "contents", background: "red" }}>
          {item.contract_type === "safebox" ? (
            <h6
              className="text-capitalize"
              style={{ color: "#999999", fontSize: "inherit" }}
            >
              {item.approvers[0]?.approver?.name}
            </h6>
          ) : (
            <h6
              className="text-capitalize"
              style={{ color: "#999999", fontSize: "inherit" }}
            >
              {item.Approver ? item.Approver?.name : "-"}
            </h6>
          )}
        </div>
      ),
      action:
        item.contract_type === "safebox" ? (
          item.status === "Waiting" ? (
            item.current_user_role === "approver" ? (
              item.approvers
                .filter((i) => i.approver?.address === user.data.user?.address)
                .some((i) => i.status === "Waiting") ? (
                <HashLink style={{ display: "flex", alignItems: 'center' }}>


                  {/* approve buttin  */}
                  <HashLink
                    onClick={() => {
                      getOtp1(item._id);
                      setReq1("Accept");
                      setWalletType(item?.trx_status)
                      setContractRecord(item)

                    }}
                  >
                    {/* <AiOutlineCheckCircle style={{ padding: '2px', fontSize: '20px' }} /> */}
                    {/* <FormButton
                      variant="outline-success"
                      className="py-2  w-100 btn-sm"

                    >
                      <i class="fa fa-upload" aria-hidden="true"></i>
                    </FormButton> */}
                  </HashLink>

                  {/* reject button  */}

                  <HashLink
                    className="px-0"
                    onClick={() => {
                      getReject1(item._id);
                      setReq("Reject");
                      setContractRecord(item)

                    }}
                  >

                    <Button
                      variant="outline-danger"
                      className="  w-100 btn-sm"

                    >
                      <i class="fa fa-upload" aria-hidden="true"></i>
                    </Button>
                  </HashLink>
                  {item.type !== "external_wallet" ||
                    item.type !== "external_wallet_test" ? (
                    <HashLink>

                      <Button
                        onClick={() => getDetail1(item._id)}

                        size="md"
                        className="py-2 mt-2 transform-lowercase text-white"
                        variant="outline-purple"
                      >
                        View
                      </Button>
                    </HashLink>
                  ) : (
                    ""
                  )}
                </HashLink>
              ) : item.type !== "external_wallet" ||
                item.type !== "external_wallet_test" ? (
                <HashLink>

                  <Button
                    onClick={() => getDetail1(item._id)}

                    size="md"
                    className="py-2 mt-2 transform-lowercase text-white"
                    variant="outline-purple"
                  >
                    View
                  </Button>
                </HashLink>
              ) : (
                ""
              )
            ) : item.type !== "external_wallet" ||
              item.type !== "external_wallet_test" ? (
              <HashLink>

                <Button
                  onClick={() => getDetail1(item._id)}

                  size="md"
                  className="py-2 mt-2 transform-lowercase text-white"
                  variant="outline-purple"
                >
                  View
                </Button>
              </HashLink>
            ) : (
              ""
            )
          ) : item.type !== "external_wallet" ||
            item.type !== "external_wallet_test" ? (
            <HashLink>

              <Button
                onClick={() => getDetail1(item._id)}

                size="md"
                className="py-2 mt-2 transform-lowercase text-white"
                variant="outline-purple"
              >
                View
              </Button>
            </HashLink>
          ) : (
            ""
          )
        ) : item.type === "contract" ? (
          item.role === "approver" &&
            ["pending", "Pending", "waiting", "Waiting"].includes(item.status) &&
            !item.transaction_approved ? (
            <div style={{ display: "flex" }}>
              <Button variant={'outline-success'} onClick={() => {

                if (item?.Beneficiary_wallet_doc && item?.Beneficiary_docs?.length == 0) {
                  toast.error('Benificiary Documents are pending!')
                  return
                }

                getOtp(item._id);
                setReq("Approval");
                setWalletType(item?.trx_status)
                setContractRecord(item)

              }}>
                <i
                  className="fa fa-check"
                />
              </Button>
              {/* </HashLink> */}
              <Button
                onClick={() => {
                  if (item?.Beneficiary_wallet_doc && item?.Beneficiary_docs?.length == 0) {
                    toast.error('Benificiary Documents are pending!')
                    return
                  }
                  getOtp(item._id);
                  setReq("Reject");
                  setContractRecord(item)

                }}
                variant={'outline-danger'}
              >
                <i

                  className="fa fa-close"
                />
              </Button>
              {
                item?.Beneficiary_wallet_doc &&
                item.Beneficiary_docs?.length == 0 &&
                user?.data?.user?.address == item?.Beneficiary_wallet_doc && (
                  <Button
                    variant="outline-white"
                    className="  w-100 btn-sm"
                    onClick={() => {
                      setShowDocsModal(true)
                      setContractRecord(item)
                    }

                    }
                  >
                    <i class="fa fa-upload" aria-hidden="true"></i>
                  </Button>

                )
              }

              {!["external_wallet", "external_wallet_test"].includes(
                item.type
              ) ? (

                <Button
                  onClick={() => getDetail(item._id)}

                  size="md"
                  className="py-2 mt-2 transform-lowercase text-white"
                  variant="outline-purple"
                >
                  View
                </Button>
              ) : (
                ""
              )}
            </div>


          ) : !["external_wallet", "external_wallet_test"].includes(
            item.type
          ) ? (


            <div className="d-flex flex-row gap-2 align-items-center justify-content-center">

              <Button
                onClick={() => getDetail(item._id)}

                size="md"
                className="py-2 mt-2 transform-lowercase text-white"
                variant="outline-purple"
              >
                View
              </Button>
              {
                item?.Beneficiary_wallet_doc &&
                item.Beneficiary_docs?.length == 0 &&
                user?.data?.user?.address == item?.Beneficiary_wallet_doc && (
                  <Button
                    variant="outline-white"
                    className="btn-sm"
                    onClick={() => {
                      setShowDocsModal(true)
                      setContractRecord(item)
                    }

                    }
                  >
                    <i class="fa fa-upload" aria-hidden="true"></i>
                  </Button>

                )
              }



            </div>
          ) : (
            ""
          )
        ) : item.type === "transaction" ? (


          <Button
            onClick={() => getDetail(item._id)}

            size="md"
            className="py-2 mt-2 transform-lowercase text-white"
            variant="outline-purple"
          >
            View
          </Button>
        ) : ["external_wallet", "external_wallet_test"].includes(item.type) ? (
          item.role === "approver" &&
            ["pending", "Pending", "waiting", "Waiting"].includes(item.status) &&
            item.third_party_approver ? (
            <>

              <Button variant={'outline-success'} onClick={() => {
                getOtp(item._id, item);
                setReq1("External Approval");
              }}>
                <i
                  className="fa fa-check"
                />
              </Button>
              <Button
                onClick={() => {
                  getOtp(item._id, item);
                  setReq1("External Reject");
                }}
                variant={'outline-danger'}
              >

                <i
               
                  className="fa fa-close"
                />
              </Button>
            </>
          ) : (

            ""
          )
        ) : (

          ""
        ),

    })
  );


  const handleFileRemove = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove file by index
    toast.success("File Removed", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  function dataURLtoBlob1(dataUrl) {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  const handleSignatureEnd = (dataUrl) => {
    const blob = dataURLtoBlob1(dataUrl);
    const fileName = "signature.png"; // You can set the desired file name
    const fileType = "image/png"; // Adjust the file type accordingly
    const file11 = new File([blob], fileName, { type: fileType });
    setSignatureImage(dataUrl);
    setSignatureImage1(file11);
  };
  const getPDf1 = (value) => {
    setSubmit(false);
  };


  const handleSubmitDocument = async () => {
    setIsLoading(true)
    try {
      const payload = new FormData();
      // payload.append('File', approveFile2);
      if (approveFile2 && approveFile2.length > 0) {
        approveFile2.forEach((file) => {
          payload.append("File", file); // Append each file under the same "File" key
        });
      }
      payload.append('id', contractRecord._id);
      const res = await uploadBeneficiary_docs(payload);
      toast.success(res?.data?.Message)
      getContractDetails()
      setIsLoading(false)
      setShowDocsModal(false)
      setFile1(null);
    } catch (error) {
      setShowDocsModal(false)
      setIsLoading(false)
      setFile1(null);
      console.error("Error uploading document:", error);
    }
  };

  return (
    <>
      {/* <Panel lg={12} title={title} closeBtn minusBtn viewAll={viewAll} refreshRequest={() => getContractDetails()} refresh={isLoading}> */}
      {/* <> */}
      {/* <Row className="mb-0">
            <Col xs={12} md={4} lg={4} />
            <Col xs={12} md={4} lg={4} />
            <Col xs={12} md={4} lg={4}>
              <label htmlFor="features" className="form-label text-white mb-0">Status</label>
              <InputDiv className="px-0">
                <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                  <CustomSelect
                    name="size"
                    id="size"
                    className="w-25 px-0"
                    value={selectedOrderStatus}
                    onChange={handleOrderStatusChange}
                    options={optionOrderStatus}
                  />  
                </DropdownDiv>
              </InputDiv>
            </Col>
        </Row> */}
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Panel
        lg={12}
        title="Contracts List"
        refresh={isLoading}
        // isLoading={isLoading}
        refreshRequest={() => getContractDetails()}
      >
        <Row className="mb-5">
          <Col xs={12} md={4} lg={3} hidden>
            <label htmlFor="features" className="form-label text-white mb-0">
              Search Filter
            </label>
            <InputDiv className="px-0">
              <DropdownDiv
                className="mb-0"
                style={{ textTransform: "Capitalize" }}
              >
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedSearch}
                  onChange={handleSearchFilterChange}
                  options={searchFilters}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          {/* {isSearchDate && ( */}
          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label
                style={{ fontSize: "small" }}
                htmlFor="features"
                className="form-label text-white mb-1"
              >
                Date
              </label>
              <ChatSearchInput
                type="date"
                value={selectedDate}
                className="form-control mt-1"
                onChange={handleDateChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchOrderNo && ( */}
          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label
                style={{ fontSize: "small" }}
                htmlFor="features"
                className="form-label text-white mb-1"
              >
                Name
              </label>
              <ChatSearchInput
                type="text"
                value={selectedName}
                className="form-control mt-2"
                placeholder="Search by Name"
                onChange={handleNameChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchAddress && ( */}
          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label
                style={{ fontSize: "small" }}
                htmlFor="features"
                className="form-label text-white mb-1"
              >
                Approver{" "}
              </label>
              <ChatSearchInput
                type="text"
                value={selectedApprover}
                className="form-control mt-2"
                placeholder="Search by Approver"
                onChange={handleApproverChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchAddress && ( */}
          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label htmlFor="features" className="form-label text-white mb-1">
                Amount
              </label>
              <ChatSearchInput
                type="text"
                value={selectedAmount}
                className="form-control mt-2"
                placeholder="Search by Amount"
                onChange={handleAmountChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>
          {/* )} */}
          <Col xs={12} md={4} lg={3}>
            <label
              style={{ fontSize: "small" }}
              htmlFor="features"
              className="form-label text-white mb-0 mt-2"
            >
              Symbol
            </label>
            <InputDiv className="px-0">
              <DropdownDiv
                className="mb-0"
                style={{ textTransform: "Capitalize" }}
              >
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedSymbol}
                  onChange={handleSymbolChange}
                  options={dropdownOptions}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          {isSearchPaymentStatus && (
            <Col xs={12} md={4} lg={3}>
              <label htmlFor="features" className="form-label text-white mb-0">
                Payment Status
              </label>
              <InputDiv className="px-0">
                <DropdownDiv
                  className="mb-0"
                  style={{ textTransform: "Capitalize" }}
                >
                  <CustomSelect
                    name="size"
                    id="size"
                    className="w-25 px-0"
                    value={selectedPaymentStatus}
                    onChange={handlePaymentStatusChange}
                    options={optionPaymentStatus}
                  />
                </DropdownDiv>
              </InputDiv>
            </Col>
          )}
          {isSearchApprovalStatus && (
            <Col xs={12} md={4} lg={3}>
              <label htmlFor="features" className="form-label text-white mb-0">
                Approval Status
              </label>
              <InputDiv className="px-0">
                <DropdownDiv
                  className="mb-0"
                  style={{ textTransform: "Capitalize" }}
                >
                  <CustomSelect
                    name="size"
                    id="size"
                    className="w-25 px-0"
                    value={selectedApprovalStatus}
                    onChange={handleApprovalStatusChange}
                    options={optionApprovalStatus}
                  />
                </DropdownDiv>
              </InputDiv>
            </Col>
          )}
          {/* {isSearchOrderStatus && ( */}
          <Col xs={12} md={4} lg={3}>
            <label
              style={{ fontSize: "small" }}
              htmlFor="features"
              className="form-label text-white mb-0 mt-2"
            >
              Status
            </label>
            <InputDiv className="px-0">
              <DropdownDiv
                className="mb-0"
                style={{ textTransform: "Capitalize" }}
              >
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedStatus}
                  onChange={handleOrderStatusChange}
                  options={optionOrderStatus}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <label
              style={{ fontSize: "small" }}
              htmlFor="features"
              className="form-label text-white mb-0 mt-2"
            >
              Type of Contract
            </label>
            <InputDiv className="px-0">
              <DropdownDiv
                className="mb-0"
                style={{ textTransform: "Capitalize" }}
              >
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedTypeOfContract}
                  onChange={handleTypeOfContractChange}
                  options={optionContracts}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <label
              htmlFor="color"
              style={{ fontSize: "small", color: "transparent" }}
              className="form-label mb-0"
            >
              .
            </label>
            {/* <Button   
              variant="primary" 
              className="btn btn-danger mt-1 w-100 py-1 pl-1 shadow-none"   
              onClick={() => resetData(1)}
            >
              Reset  <i className="fa fa-refresh" />
            </Button> */}
            <Button
              onClick={() => resetData(1)}
              style={{
                background: "#4ce1b6",
              }}
              size="md"
              className="py-1 btn-block mt-2"
              variant="success"
            >
              Reset <i className="fa fa-refresh" />
            </Button>
          </Col>
          {/* )}       */}
          {!isSearchApprovalStatus &&
            !isSearchOrderNo &&
            !isSearchStatus &&
            !isSearchAddress &&
            !isSearchPaymentStatus &&
            !isSearchOrderStatus &&
            !isSearchAmount &&
            !isSearchOperation &&
            !isSearchDate && <Col xs={12} md={4} lg={3} />}
          <Col xs={12} md={4} lg={4} />
          <Col xs={12} md={4} lg={2} hidden>
            <label
              htmlFor="color"
              style={{ fontSize: "small", color: "transparent" }}
              className="form-label"
            >
              .
            </label>
            <Button
              variant="primary"
              className="btn btn-danger mt-1 w-100 py-1 pl-1 shadow-none"
              onClick={() => resetData(1)}
            >
              Reset <i className="fa fa-refresh" />
            </Button>
          </Col>
        </Row>
        <ReactTableBase
          columns={header}
          data={detailShow ? data.slice(0, 3) : data}
          tableConfig={detailShow ? tableConfi2 : tableConfig}
        />
        {data?.length === 0 && <NoResult>(0) Results! Data Not Found</NoResult>}
      </Panel>
      <StyledModal show={modal} onHide={toggle}>
        <ModalHeader>
          <ModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={toggle}
          />
          <ModalTitle>Contract Hold</ModalTitle>
        </ModalHeader>
        {isLoad ? (
          <ModalBody>
            <SpinnerDiv>
              <Spinner style={{ color: colorAccent }} />
            </SpinnerDiv>
          </ModalBody>
        ) : typeof detail === "string" ? (
          <NoResult>{detail}</NoResult>
        ) : (
          <ModalBody>
            <ModalMainDiv>
              <ModalSubTitle>From:</ModalSubTitle>
              <ModalDetail>
                {typeof detail.Beneficiary === "object"
                  ? detail.Beneficiary?.name
                  : detail.Beneficiary}
              </ModalDetail>
            </ModalMainDiv>
            <ModalMainDiv>
              <ModalSubTitle>To:</ModalSubTitle>
              <ModalDetail>{detail.Transfer?.name}</ModalDetail>
            </ModalMainDiv>
            <ModalMainDiv>
              <ModalSubTitle>Approver:</ModalSubTitle>
              <ModalDetail>
                {typeof detail.Approver === "object"
                  ? detail.Approver?.name
                  : "-"}
              </ModalDetail>
            </ModalMainDiv>
            <ModalMainDiv>
              <ModalSubTitle>Date:</ModalSubTitle>
              <ModalDetail>{detail.Date}</ModalDetail>
            </ModalMainDiv>
            <ModalMainDiv>
              <ModalSubTitle>Type</ModalSubTitle>
              <ModalDetail>{detail.type}</ModalDetail>
            </ModalMainDiv>
            <ModalMainDiv>
              <ModalSubTitle>Role</ModalSubTitle>
              <ModalDetail>{detail.role}</ModalDetail>
            </ModalMainDiv>
            <ModalMainDiv>
              <ModalSubTitle>Amount:</ModalSubTitle>
              <ModalDetail>
                {detail.Amount} {detail.Symbol}
              </ModalDetail>
            </ModalMainDiv>
            <ModalMainDiv>
              <ModalSubTitle>Coin:</ModalSubTitle>
              <ModalDetail>{detail.Symbol}</ModalDetail>
            </ModalMainDiv>
            <ModalMainDiv>
              <ModalSubTitle>Status:</ModalSubTitle>
              <ModalDetail>{detail.status}</ModalDetail>
            </ModalMainDiv>
            <ModalMainDiv>
              <ModalSubTitle>Hash:</ModalSubTitle>
              <div>
                <HashLink
                  role="link"
                  onClick={() => {
                    !["BNB", "CTY", "BUSD"].includes(detail.Symbol) &&
                      detail.type === "transaction"
                      ? openInNewTab(`https://etherscan.io/tx/${detail.hash}`)
                      : openInNewTab(`https://bscscan.com/tx/${detail.hash}`);
                  }}
                >
                  <div
                    style={{
                      wordWrap: "break-word",
                      width: "309px",
                      fontSize: "13px",
                    }}
                  >
                    {!["BNB", "CTY", "BUSD"].includes(detail.Symbol) &&
                      detail.type === "transaction"
                      ? `https://etherscan.io/tx/${detail.hash}`
                      : `https://bscscan.com/tx/${detail.hash}`}
                  </div>
                </HashLink>
              </div>
            </ModalMainDiv>
            <ModalMainDiv>
              <ModalSubTitle>Type:</ModalSubTitle>
              <ModalDetail>{detail.type}</ModalDetail>
            </ModalMainDiv>
            {detail?.FileName && (
              <ModalMainDiv>
                <ModalSubTitle>File:</ModalSubTitle>
                <HashLink
                  role="link"
                  onClick={() => {
                    openInNewTab(FileName[0]);
                  }}
                >
                  Click here to View File
                </HashLink>
              </ModalMainDiv>
            )}
          </ModalBody>
        )}
      </StyledModal>
      <StyledModal show={modal1} onHide={toggle1}>
        <ModalHeader>
          <ModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={toggle1}
          />
          <ModalTitle>SafeBox Detail</ModalTitle>
        </ModalHeader>
        {isLoad ? (
          <ModalBody>
            <SpinnerDiv>
              <Spinner style={{ color: colorAccent }} />
            </SpinnerDiv>
          </ModalBody>
        ) : typeof detail1 === "string" ? (
          <NoResult>{detail1}</NoResult>
        ) : detail1?.length === 0 ? (
          <ModalBody>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: ".5rem",
              }}
            >
              No Data Found
            </div>
          </ModalBody>
        ) : (
          <ModalBody>
            <ModalMainDiv>
              <ModalSubTitle>Name:</ModalSubTitle>
              <ModalDetail>{detail1?.name}</ModalDetail>
            </ModalMainDiv>
            <ModalMainDiv>
              <ModalSubTitle>Owner Name:</ModalSubTitle>
              <ModalDetail>{detail1.owner?.name}</ModalDetail>
            </ModalMainDiv>
            <ModalMainDiv>
              {" "}
              <ModalSubTitle>Status:</ModalSubTitle>
              <ModalDetail>{detail1?.status}</ModalDetail>
            </ModalMainDiv>

            <ModalMainDiv>
              <ModalSubTitle>Date:</ModalSubTitle>
              <ModalDetail>{detail1?.date}</ModalDetail>
            </ModalMainDiv>
            <ModalMainDiv>
              <ModalSubTitle>Role</ModalSubTitle>
              <ModalDetail>{detail1.current_user_role}</ModalDetail>
            </ModalMainDiv>

            <ModalMainDiv>
              <ModalSubTitle>Hash:</ModalSubTitle>
              <div
                style={{
                  wordWrap: "break-word",
                  width: "305px",
                  fontSize: "13px",
                  // marginRight: '1rem',
                }}
              >
                {detail1.hash.map((i) => (
                  <HashLink
                    role="link"
                    onClick={() => {
                      openInNewTab(`https://bscscan.com/tx/${i}`);
                    }}
                  >
                    <div
                      style={{
                        wordWrap: "break-word",
                        width: "288px",
                        fontSize: "13px",
                      }}
                    >
                      {`https://bscscan.com/tx/${i.substring(0, 20)}...\n`}
                    </div>
                  </HashLink>
                ))}
              </div>
            </ModalMainDiv>
            <ModalMainDiv>
              <ModalSubTitle>Approver</ModalSubTitle>

              {detail1.approvers.map((i) => (
                // <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
                <ModalDetail style={{ fontSize: "14px" }}>
                  {i.approver?.name ? i.approver?.name : "  -  "}
                </ModalDetail>
                // </div>
              ))}
            </ModalMainDiv>
            <ModalMainDiv>
              <ModalSubTitle>Files:</ModalSubTitle>

              {detail1.files.map((i) => (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <HashLink
                    style={{ padding: "0px" }}
                    role="link"
                    onClick={() => {
                      openInNewTab(i);
                    }}
                  >
                    Click here to view
                  </HashLink>
                </div>
              ))}
            </ModalMainDiv>
          </ModalBody>
        )}
      </StyledModal>
      <StyledModal show={otpModal} onHide={toggleOtp} backdrop="static">
        <ModalHeader>
          <ModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={() => {
              toggleOtp();
              setMsg(undefined);
              setContractRecord(null)
              setFile1(null);
            }}
          />
          <ModalTitle>Contract {req}</ModalTitle>
        </ModalHeader>
        {otpLoad ? (
          <ModalBody>
            <SpinnerDiv>
              <Spinner style={{ color: colorAccent }} />
            </SpinnerDiv>
          </ModalBody>
        ) : (
          <ModalBody>

            <div className="col-12">
              <InputDiv>
                <label htmlFor="email" className="mt-2">
                  File
                </label>
                <ChatSearchInput
                  style={{ display: "none" }}
                  type="file"
                  accept=".pdf"
                  onChange={(event) => {
                    const selectedFiles = Array.from(event.currentTarget.files); // Convert FileList to array
                    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]); // Append new files to existing array
                  }}
                  ref={fileInput}
                // className={errors.upload && touched.upload && "error"}
                />
                <div style={{ width: "100%" }}>
                  <Button
                    type="button"
                    variant={req == "Approval" ? 'success-outline' : "danger-outline"}
                    className="py-2 d-flex gap-2"
                    style={{ width: "100%" }}
                    onClick={() => fileInput.current.click()}
                  >
                    <i class="fa fa-upload" aria-hidden="true"></i>

                    Upload
                  </Button>
                </div>
              </InputDiv>
            </div>

            <div className="d-flex flex-row overflow-x-scroll " style={{ scrollbarWidth: '0px' }}>
              <div className="d-flex flex-column gap-2 w-100">
                <div className="d-flex flex-row">
                  {files?.length > 0 &&
                    files.map((file, index) => (
                      <div key={index} className="col-12">
                        <label
                          htmlFor="features"
                          style={{ fontSize: "small" }}
                          className="form-label text-white mt-3"
                        >
                          Uploaded File{" "}
                        </label>
                        <br />
                        <div
                          className="py-0"
                          style={{
                            border: "1px solid white",
                            borderRadius: "4px",
                          }}
                        >
                          <label
                            htmlFor="features"
                            className="form-label text-white mt-1 mx-2"
                          >
                            {file.name}{" "}
                          </label>
                          <button
                            type="button"
                            style={{ marginTop: "2%" }}
                            className="btn btn-danger py-0 float-right mr-1 mb-0"
                            onClick={() => handleFileRemove(index)} // Pass the index to remove
                          >
                            Remove <i className="fa fa-file-pdf-o" />
                          </button>
                        </div>

                        {/* {files?.length > 0 &&
                        files.map((file, index) => ( */}
                        <div key={index} className="">

                          <label
                            className="mt-3"
                            style={{ color: "white" }}
                            htmlFor="email"
                          >
                            Signature here
                          </label>

                          <div
                            style={{
                              border: "2px solid white",
                              borderRadius: "6px",
                              background: "white",
                            }}
                          >
                            <SignaturePad
                              getPDf1={getPDf1}
                              onEnd={handleSignatureEnd}
                            />
                            {signatureImage1 && (
                              <>
                                <PdfSignature
                                  pdfFile={file} // Pass the current file to PdfSignature
                                  signatureImage={signatureImage1}
                                  getPDf={getPDf}
                                />
                              </>
                            )}
                          </div>

                        </div>
                        {/* ))
                      } */}
                      </div>
                    ))
                  }

                </div>


              </div>
            </div>


            <ApproveOtpForm
              onClose={() => setMsg(undefined)}
              onSubmit={verifyOtp}
              errorMsg={Msg}
              loading={approveLoad}
              status={status}
            />



          </ModalBody>
        )}
      </StyledModal>

      {/* upload docs modal  */}
      <StyledModal show={showDocsModal} onHide={() => {
        setShowDocsModal(false)
        setContractRecord(null)
        setFile1(null);

      }} backdrop="static">
        <ModalHeader>
          <ModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={() => {
              setShowDocsModal(false)
              setFile1(null);

            }}
          />
          <ModalTitle>Upload Contract Docs</ModalTitle>
        </ModalHeader>

        {isLoading && <ModalBody>
          <SpinnerDiv>
            <Spinner style={{ color: colorAccent }} />
          </SpinnerDiv>
        </ModalBody>}


        <ModalBody>
          {
            console.log("contractRecord", contractRecord)
          }
          <div className="col-12 mb-4">
            <InputDiv>
              <label htmlFor="email" className="mt-2">
                File
              </label>
              <ChatSearchInput
                style={{ display: "none" }}
                type="file"
                accept=".pdf"
                onChange={(event) => {
                  const selectedFiles = Array.from(event.currentTarget.files); // Convert FileList to array
                  setFiles((prevFiles) => [...prevFiles, ...selectedFiles]); // Append new files to existing array
                }}
                ref={fileInput}
              // className={errors.upload && touched.upload && "error"}
              />
              <div style={{ width: "100%" }}>
                <MediaButton
                  type="button"
                  className="py-2"
                  style={{ width: "100%" }}
                  onClick={() => fileInput.current.click()}
                >
                  <span
                    className="lnr lnr-upload"
                    style={{ marginRight: "10px" }}
                  />
                  Upload
                </MediaButton>
              </div>

            </InputDiv>
          </div>
          <div className=" overflow-x-scroll col-12 " style={{ scrollbarWidth: '0px' }}>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row">
                {files?.length > 0 &&
                  files.map((file, index) => (
                    <div key={index} className="col-12">
                      <label
                        htmlFor="features"
                        style={{ fontSize: "small" }}
                        className="form-label text-white mt-3"
                      >
                        Uploaded File{" "}
                      </label>
                      <br />
                      <div
                        className="py-0"
                        style={{
                          border: "1px solid white",
                          borderRadius: "4px",
                        }}
                      >
                        <label
                          htmlFor="features"
                          className="form-label text-white mt-1 mx-2"
                        >
                          {file.name}{" "}
                        </label>
                        <button
                          type="button"
                          style={{ marginTop: "2%" }}
                          className="btn btn-danger py-0 float-right mr-1 mb-0"
                          onClick={() => handleFileRemove(index)} // Pass the index to remove
                        >
                          Remove <i className="fa fa-file-pdf-o" />
                        </button>
                      </div>

                      {/* {files?.length > 0 &&
                        files.map((file, index) => ( */}
                      <div key={index} className="">

                        <label
                          className="mt-3"
                          style={{ color: "white" }}
                          htmlFor="email"
                        >
                          Signature here
                        </label>

                        <div
                          style={{
                            border: "2px solid white",
                            borderRadius: "6px",
                            background: "white",
                          }}
                        >
                          <SignaturePad
                            getPDf1={getPDf1}
                            onEnd={handleSignatureEnd}
                          />
                          {signatureImage1 && (
                            <>
                              <PdfSignature
                                pdfFile={file} // Pass the current file to PdfSignature
                                signatureImage={signatureImage1}
                                getPDf={getPDf}
                              />
                            </>
                          )}
                        </div>
                        {/* {errors.signature && (
                                  <Error className="input feedback">
                                    {errors.signature}
                                  </Error>
                                )} */}
                      </div>
                      {/* ))
                      } */}
                    </div>
                  ))
                }

              </div>


            </div>
          </div>

          <MediaButton
            type="button"
            className="py-2 mt-2"
            style={{ width: "100%" }}
            onClick={() => handleSubmitDocument()}
            disabled={isLoading || !signatureImage1}
          >

            Submit Document
          </MediaButton>
        </ModalBody>

      </StyledModal>

      {
        modalIsOpenContract &&

        <ContractDetailModal selectedId={selectedId} closeContractsDetails1={() => setModalIsOpenConract(false)} modalIsOpen={modalIsOpenContract} />
      }




      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalIsOpen1}
        onHide={closeContractsDetails1}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: "large" }}>
            Safebox Details
          </Modal.Title>
        </Modal.Header>
        {isLoad ? (
          <Modal.Body>
            <SpinnerDiv>
              <Spinner style={{ color: colorAccent }} />
            </SpinnerDiv>
          </Modal.Body>
        ) : (
          <Modal.Body>
            {selectedOrder1 ? (
              <>
                <div
                  className="container mt-0 containerDiv pt-0"
                  style={{ overflowX: "hidden" }}
                >
                  <div
                    className="row mt-0 mb-2 mx-1"
                    style={{ border: "2px solid #7b7b7b", borderRadius: "5px" }}
                  >
                    <div className="col-lg-2 col-md-2 col-sm-12 text-center">
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-1"
                      >
                        <b>name</b>
                      </p>
                      {selectedOrder1.name ? (
                        <p
                          style={{
                            textTransform: "capitalize",
                            color: "#7b7b7b",
                          }}
                          className="pb-1"
                        >
                          {selectedOrder1.name}
                        </p>
                      ) : (
                        <p
                          style={{
                            textTransform: "capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          -
                        </p>
                      )}
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 text-center">
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-1"
                      >
                        <b>Date</b>
                      </p>
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="pb-1"
                      >
                        {selectedOrder1.date}
                      </p>
                    </div>
                    {selectedOrder1.role && (
                      <div className="col-lg-2 col-md-2 col-sm-12 text-center">
                        <p
                          style={{
                            textTransform: "Capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          <b>Role </b>
                        </p>
                        <p
                          style={{
                            textTransform: "Capitalize",
                            color: "#7b7b7b",
                          }}
                          className="pb-1"
                        >
                          {selectedOrder1.current_user_role}
                        </p>
                      </div>
                    )}
                    <div className="col-lg-2 col-md-2 col-sm-12 text-center">
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-1"
                      >
                        <b>Status</b>
                      </p>
                      <Badge
                        bg="dark"
                        className="mb-2"
                        style={{
                          paddingBottom: "6px",
                          fontSize: "small",
                          fontWeight: "400",
                        }}
                      >
                        {selectedOrder1.status}
                      </Badge>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 text-center">
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-1"
                      >
                        <b>Fee </b>
                      </p>
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="pb-1"
                      >
                        {selectedOrder1.fee}
                      </p>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 text-center">
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="py-1"
                      >
                        <b>Note </b>
                      </p>
                      <p
                        style={{
                          textTransform: "Capitalize",
                          color: "#7b7b7b",
                        }}
                        className="pb-1"
                      >
                        {selectedOrder1.note}
                      </p>
                    </div>
                    {selectedOrder1?.files?.length > 0 &&
                      selectedOrder1.files.map((data1, index) => (
                        <div className="col-lg-2 col-md-2 col-sm-12 text-center">
                          <p
                            style={{
                              textTransform: "Capitalize",
                              color: "#7b7b7b",
                            }}
                            className="py-1"
                          >
                            <b>File {index + 1}</b>
                          </p>
                          <a
                            onClick={() => {
                              openInNewTab(data1);
                            }}
                            style={{ color: "#70bbfd", cursor: "pointer" }}
                            rel="noreferrer"
                            className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                          >
                            <span className="text-purple text-xs">View</span>
                          </a>
                        </div>
                      ))}
                    {selectedOrder1.hash.length > 0 &&
                      selectedOrder1.hash.map((data1, index) => (
                        <div className="col-lg-2 col-md-2 col-sm-12 text-center">
                          <p
                            style={{
                              textTransform: "Capitalize",
                              color: "#7b7b7b",
                            }}
                            className="py-1"
                          >
                            <b>Hash {index + 1}</b>
                          </p>
                          <a
                            onClick={() =>
                              openInNewTab(`https://bscscan.com/tx/${data1}`)
                            }
                            style={{ color: "#70bbfd", cursor: "pointer" }}
                            rel="noreferrer"
                          >
                            <span className="text-purple text-xs font-weight-bold pb-2">
                              {data1.substring(0, 15)}....
                            </span>
                          </a>
                        </div>
                      ))}
                  </div>
                  <div className="row mt-4 mb-1">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-0"
                    >
                      <b>Owner Detail</b>
                    </p>
                  </div>
                  {typeof selectedOrder1.owner === "object" ? (
                    <div
                      className="row mt-0 mb-2 mx-1"
                      style={{
                        border: "2px solid #7b7b7b",
                        borderRadius: "5px",
                      }}
                    >
                      <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                        <p
                          style={{
                            textTransform: "capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          <b>Owner Name </b>
                        </p>
                        {selectedOrder1.owner ? (
                          <p
                            style={{
                              textTransform: "capitalize",
                              color: "#7b7b7b",
                            }}
                            className="py-1"
                          >
                            {selectedOrder1.owner.name}
                          </p>
                        ) : (
                          <p
                            style={{
                              textTransform: "capitalize",
                              color: "#7b7b7b",
                            }}
                            className="py-1"
                          >
                            -
                          </p>
                        )}
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                        <p style={{ color: "#7b7b7b" }} className="py-1">
                          <b>Owner Email </b>
                        </p>
                        {selectedOrder1.owner ? (
                          <p style={{ color: "#7b7b7b" }} className="py-1">
                            {selectedOrder1.owner.email}
                          </p>
                        ) : (
                          <p
                            style={{
                              textTransform: "capitalize",
                              color: "#7b7b7b",
                            }}
                            className="py-1"
                          >
                            -
                          </p>
                        )}
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                        <p
                          style={{
                            textTransform: "capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          <b>Owner Address</b>
                        </p>
                        {selectedOrder1.owner ? (
                          <p
                            style={{
                              textTransform: "capitalize",
                              color: "#7b7b7b",
                            }}
                            className="py-1"
                          >
                            {selectedOrder1.owner?.address}
                          </p>
                        ) : (
                          <p
                            style={{
                              textTransform: "capitalize",
                              color: "#7b7b7b",
                            }}
                            className="py-1"
                          >
                            -
                          </p>
                        )}
                      </div>
                    </div>
                  ) : null}
                  {typeof selectedOrder1.approvers === "object" &&
                    selectedOrder1.approvers[0].approver ? (
                    <>
                      <div className="row mt-4 mb-1">
                        <p
                          style={{
                            textTransform: "capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-0"
                        >
                          <b>Approver Detail</b>
                        </p>
                      </div>
                      {selectedOrder1.approvers.map((data1, index) => (
                        <div
                          className="row mt-0 mb-2 mx-1"
                          style={{
                            border: "2px solid #7b7b7b",
                            borderRadius: "5px",
                          }}
                        >
                          <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="py-1"
                            >
                              <b>Approver Name </b>
                            </p>
                            {data1.approver.name ? (
                              <p
                                style={{
                                  textTransform: "capitalize",
                                  color: "#7b7b7b",
                                }}
                                className="pb-1"
                              >
                                {data1.approver.name}
                              </p>
                            ) : (
                              <p
                                style={{
                                  textTransform: "capitalize",
                                  color: "#7b7b7b",
                                }}
                                className="py-1"
                              >
                                -
                              </p>
                            )}
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                            <p style={{ color: "#7b7b7b" }} className="py-1">
                              <b>Approver Email </b>
                            </p>
                            {data1.approver.email ? (
                              <p style={{ color: "#7b7b7b" }} className="pb-1">
                                {data1.approver.email}
                              </p>
                            ) : (
                              <p
                                style={{
                                  textTransform: "capitalize",
                                  color: "#7b7b7b",
                                }}
                                className="py-1"
                              >
                                -
                              </p>
                            )}
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="py-1"
                            >
                              <b>Approver Address </b>
                            </p>
                            {data1.approver?.address ? (
                              <p
                                style={{
                                  textTransform: "capitalize",
                                  color: "#7b7b7b",
                                }}
                                className="pb-1"
                              >
                                {data1.approver?.address}
                              </p>
                            ) : (
                              <p
                                style={{
                                  textTransform: "capitalize",
                                  color: "#7b7b7b",
                                }}
                                className="py-1"
                              >
                                -
                              </p>
                            )}
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                            <p
                              style={{
                                textTransform: "capitalize",
                                color: "#7b7b7b",
                              }}
                              className="py-1"
                            >
                              <b>Approver Status </b>
                            </p>
                            {data1.status ? (
                              <Badge
                                bg="dark"
                                className="mb-2"
                                style={{
                                  paddingBottom: "6px",
                                  fontSize: "small",
                                  fontWeight: "400",
                                }}
                              >
                                {data1.status}
                              </Badge>
                            ) : (
                              <p
                                style={{
                                  textTransform: "capitalize",
                                  color: "#7b7b7b",
                                }}
                                className="py-1"
                              >
                                -
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}
                  <hr
                    hidden
                    style={{ border: "1px solid #b3b3b3", opacity: "1.1" }}
                  />
                </div>
                <button type="button" hidden>
                  Submit Ratings
                </button>
              </>
            ) : (
              <div className="row mt-0 mb-2 mx-1">
                <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                  <p
                    style={{
                      textTransform: "capitalize",
                      color: "#7b7b7b",
                      fontSize: "x-large",
                      fontWeight: "500",
                    }}
                    className="py-1"
                  >
                    Detail Not Found
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 text-center" />
                <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                  <img
                    src={Parcel}
                    className="d-block w-100 card-img-top"
                    alt="img"
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 text-center" />
              </div>
            )}
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};
export default ContractTable;
const FormButton = styled(Button)`
  color: "white";
  margin-top: 8px;
`;
const MediaButton = styled(Button)`
  color: "white";
  margin-top: 0px;
  padding: 3px 10px;
  margin-bottom: 0px;
`;
const HashLink = styled.button`
  border: none;
  background: transparent;
  color: #4ce1b6;
`;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;
const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  button {
    min - width: 100px;
  padding: 4px 25px;
  margin-bottom: 0;
  display: inline-block;
}

  flex-flow: ${flexFlow} !important;
  `;
export const ModalMainDiv = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  overflow-wrap: break-word;
  align-items: baseline;
`;
export const ModalTitle = styled.h4`
  background: #4ce1b6;
  padding: 5px;
  color: #232329;
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size:20px;
  &:first-child {
    margin - top: 0;
}
  `;
export const ModalDetail = styled.h5`
  width:300px;
  margin-bottom: 10px;
  font-size:16px;
  font-weight: 700;
  text-align:left;
  word-wrap: break-word;
  &:first-child {
    margin - top: 0;
}
  `;
export const ModalSubTitle = styled.h4`
  width: 100px;
  margin-top: 10px;
  font-weight: 600;
  font-size:16px;
  text-align:left;
  word-wrap: break-word;
  color:${colorAccent};
  &:first-child {
    margin - top: 0;
}
  `;
export const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`;
const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`;
export const NoResult = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-top: 1rem;
  margin-left: 0rem;
  color: white;
  font-weight: 700;
`;
export const SpinnerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margintop: 0.5rem;
  height: 9rem;
`;
export const ModalHeader = styled.div``;
export const ModalBody = styled.div``;
const getColor = (color) => {
  switch (color) {
    case "success":
      return "#22bb33";
    case "danger":
      return "#ff4861";
    default:
      return "#f88e7d";
  }
};
export const StyledModal = styled(BootstrapModal).withConfig({
  shouldForwardProp: (prop) => !["colored", "header"].includes(prop),
})`

                                .modal-dialog {
                                  max - width: 450px;
}

                                .modal-content {
                                  border - radius: 0;
                                border: none;
                                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                                position: relative;
                                padding: 50px 40px 60px 40px;
                                text-align: center;
                                background-color: ${colorBackground};
                                color: ${colorText};
}

                                ${ModalTitleIcon} {
                                  color: ${(props) => getColor(props.color)};
}

                                ${(props) =>
    props.colored &&
    `
color: ${colorWhite};

${ModalTitle},
${ModalTitleIcon},
${ModalCloseButton} {
 color: ${colorWhite};
}

${ModalFooter} {

 button:first-child {
   color: ${colorWhite};
   background-color: rgba(255, 255, 255, 0.3);
   border-color: ${colorWhite};

   &:before {
     background-color: rgba(255, 255, 255, 0.2);
   }
 }

 button:last-child {
   border-color: white;
   color: ${colorWhite};
 }
}

.modal-content {
 color: ${colorWhite};
 background-color: ${getColor(props.color)};
}
`}

                                ${(props) =>
    props.header &&
    `

.modal-dialog {
 max-width: 520px;
}

.modal-content {
 padding: 0;
 text-align: left};
}

${ModalTitle} {
 color: ${colorWhite};
}

${ModalHeader} {
 color: ${colorWhite};
 padding: 15px 20px;
 position: relative;
}

${ModalTitle} {
 margin: 0;
 font-weight: 300;
}

${ModalCloseButton} {
 color: ${colorWhite};
 top: calc(50% - 8px);
}

${ModalBody} {
 padding-top: 25px;
 padding-bottom: 20px;
 ${paddingRight(props)}: 40px;
 ${paddingLeft(props)}: 20px;
}

${ModalFooter} {
 margin-bottom: 40px;
 justify-content: flex-end;
 ${paddingRight(props)}: 20px;
}

${ModalHeader} {
 background-color: ${getColor(props.color)};
}
`}
                                `;
