/* eslint-disable max-len */
import axios from 'axios';
import { getUserTotalBalance } from '../../utils/services/user.service';
import authHeader from '../../utils/services/auth-header';

export const CHANGE_SIDEBAR_VISIBILITY = 'CHANGE_SIDEBAR_VISIBILITY';
export const CHANGE_MOBILE_SIDEBAR_VISIBILITY = 'CHANGE_MOBILE_SIDEBAR_VISIBILITY';
export const TOTAL_BALANCE = 'TOTAL_BALANCE';
export const FAIL_BALANCE = 'FAIL_BALANCE';

export function changeSidebarVisibility() {
  return {
    type: CHANGE_SIDEBAR_VISIBILITY,
  };
}

export function changeMobileSidebarVisibility() {
  return {
    type: CHANGE_MOBILE_SIDEBAR_VISIBILITY,
  };
}
export const getTotalBalance = () => (dispatch) => {
  getUserTotalBalance().then(
  (data) => {
      dispatch({
        type: TOTAL_BALANCE,
        payload: data.data.balance,
      });
    return Promise.resolve();
  },
  (error) => {
    const message = (error.response
      && error.response.data
      && error.response.data.message)
      || error.message
      || error.toString();

    dispatch({
      type: FAIL_BALANCE,
    });

    return Promise.reject();
  },
).catch((error) => {
  dispatch({
    type: FAIL_BALANCE,
  });
});
};
