/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Button } from "../../../shared/components/Button";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Ring } from '@uiball/loaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye, faEnvelope, faUser, faFile,
} from '@fortawesome/free-regular-svg-icons';
import EyeIcon from 'mdi-react/EyeIcon';
import Badge from 'react-bootstrap/Badge';
import Panel from '@/shared/components/Panel';
// import Modal from 'react-modal'; // Import react-modal
import { CustomSelect, CustomMultiSelect } from '../../../shared/components/CustomSelect';
import {
  getMyOrders,
  updateOrderStatusSeller,
  uploadInvoice,
} from '../../../utils/services/user.service';
import 'react-toastify/dist/ReactToastify.css';
import '../style/getorder.css';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';

const GetMyOrders = () => {
  const [orders, setOrders] = useState([]);

  const [getTransactions1, setTransactions1] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(''); // State to store the selected status
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [selectedOrderQty, setSelectedOrderQty] = useState(0);
  const [gettotalChargesTable, totalChargesTable] = useState(0);
  const [gettotalQtyTable, totalQtyTable] = useState(0);
  const [cartSybmol, setCartSybmol] = useState('');
  const [ratings, setRatings] = useState({});
  const [comments, setComments] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState('');
  const [selectedApprovalStatus, setSelectedApprovalStatus] = useState('');
  const [selectedOrderStatus, setSelectedOrderStatus] = useState('');
  const [selectedOperation, setSelectedOperation] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedOrderNo, setSelectedOrderNo] = useState('');
  const [selectedAmount, setSelectedAmount] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedSearch, setSelectedSearch] = useState('');
  const [selectedApprover, setSelectedApprover] = useState('');
  const [isSearchOrderNo, setIsSearchOrderNo] = useState(false);
  const [isSearchAddress, setIsSearchAddress] = useState(false);
  const [isSearchStatus, setIsSearchStatus] = useState(false);
  const [bapproverDoc, setBApproverDoc] = useState(null);
  const [bapproverReason, setBApproverReason] = useState(null);
  const [brejectDoc, setBRejectDoc] = useState(null);
  const [brejectReason, setBRejectReason] = useState(null);
  const [rejectDoc, setRejectDoc] = useState(null);
  const [rejectReason, setRejectReason] = useState(null);
  const [approverDoc, setApproverDoc] = useState(null);
  const [approverReason, setApproverReason] = useState(null);
  const [isSearchPaymentStatus, setIsSearchPaymentStatus] = useState(false);
  const [isSearchDate, setIsSearchDate] = useState(false);
  const [isSearchApprovalStatus, setIsSearchApprovalStatus] = useState(false);
  const [isSearchOrderStatus, setIsSearchOrderStatus] = useState(false);
  const [isSearchAmount, setIsSearchAmount] = useState(false);
  const [isSearchOperation, setIsSearchOperation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isModalOpen1, setIsModalOpen1] = useState(false); // State to control modal visibility
  const [selectedOrderId1, setSelectedOrderId1] = useState(null);
  const [acceptReason, setAcceptReason] = useState('');
  const [approveFile, setFile1] = useState(null);
  const REACT_APP_BSC_TRANSACTION_EXPLORER_BASE_URL = 'https://testnet.bscscan.com/tx/';
  const REACT_APP_MATIC_TRANSACTION_EXPLORER_BASE_URL = 'https://sepolia.etherscan.io/tx/';
  const REACT_APP_ETH_TRANSACTION_EXPLORER_BASE_URL = 'https://mumbai.polygonscan.com/tx/';
  const { REACT_APP_POLYGON_MATIC, REACT_APP_SMART_CHAIN, REACT_APP_ETHERIUM_CHAIN } = process.env;

  const fetchOrders1 = async () => {
    const payload = {
      limit: 20,
      pageNumber: 1,
      orderStatus: 'all',
    };
    setIsLoading(true);
    try {
      setIsLoading(true);
      const response = await getMyOrders(payload);
      const responseData = response.data;
      if (responseData.data && responseData.data.length > 0) {
        setOrders(responseData.data);
        setTransactions1(responseData.data);
      } else {
        console.error('No orders found.');
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching orders:', error);
    }
  };

  useEffect(() => {
    fetchOrders1();
  }, []);

  // const handleStatusChange = (event) => {
  //   console.log('event', event);
  //   console.log('event target value', event.target);
  //   // setSelectedStatus(event.target.value);
  // };

  const handleUpdateStatus = async (orderId, orderStatus) => {
    if (orderId && orderStatus) {
      const payload = {
        order_id: orderId,
        status: orderStatus,
      };
      setIsLoading(true);
      try {
        setIsLoading(true);
        const response = await updateOrderStatusSeller(payload);
        // console.log('API Response:', response);
        if (response.status === 200) {
          setIsLoading(false);
          toast.success('Order status updated', {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload();
        } else {
          setIsLoading(false);
          toast.error('Failed to update order status', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        setIsLoading(false);
        console.error('Error updating order status:', error);
        toast.error('An error occurred while updating order status', {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
    } else {
      toast.error('Please select a status', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const history = useHistory();

  const handleClick = () => {
    // Redirect to '/livechat' route
    history.push('/livechat');
  };

  const handleShow = (order) => {
    setSelectedOrder(order);
    let totalPrice = 0;
    // Iterate through the array and sum up the prices
    const orderData = order.product_details;
    totalPrice = orderData.reduce((total, product) => total + product.quantity, 0);
    setSelectedOrderQty(totalPrice);
    setShow(true);
  };
  const handleClose = () => setShow(false);
  // const handleClose1 = () => setShow1(false);
  const handleClose1 = (orderId) => {
    setSelectedOrder(null);
    setShow1(false); // Open the modal when "Reject" is clicked
    setBApproverDoc(null);
    setBApproverReason(null);
    setBRejectDoc(null);
    setBRejectReason(null);
    setRejectDoc(null);
    setApproverDoc(null);
    setApproverReason(null);
    setRejectReason(null);
  };

  const openAcceptModal = (orderId) => {
    console.log('insid esufcnti')
    setLoading(true);
    setSelectedOrderId1(orderId);
    setLoading(false);
    setIsModalOpen1(true); // Open the modal when "Reject" is clicked
  };

  const closeAcceptModal = () => {
    setSelectedOrderId1(null);
    setAcceptReason('');
    setIsModalOpen1(false); // Close the modal when "Cancel" is clicked
  };

  const handleFileChange1 = (e) => {
    const selectedFile1 = e.target.files[0];
    setFile1(selectedFile1);
  };

  const handleApprove = async (orderId) => {
    const formData = new FormData();
    formData.append('order_id', selectedOrderId1);
    formData.append('File', approveFile);
    setIsModalOpen1(false);
    setIsLoading(true);
    try {
      if (!approveFile) {
        setIsLoading(false);
        toast.error('Approve document is required', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      const response = await uploadInvoice(formData);

      if (response.status === 200) {
        setIsLoading(false);
        // Order approved successfully, you can handle this as needed
        // console.log('Invoice uploaded successfully');
        toast.success('Invoice uploaded successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        // Reload orders or update state if needed
        window.location.reload();
      } else {
        setIsLoading(false);
        console.error('Error approving order:', response.statusText);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error approving order:', error);
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
      toast.error(error.response.data.Message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleStatusChange = (id, status) => {
    // console.log('id, status', id, status);
    const selectedValue = status; // Get the selected value
    // console.log('Selected value:', selectedValue);
    setSelectedStatus(selectedValue); // You can set the selected value to your state if needed
    handleUpdateStatus(id, selectedValue);
  };

  const openProductDetails = (order) => {
    setIsLoading(true);
    // console.log('openProductDetails', order);
    function base64ToArrayBuffer(base64) {
      const binaryString = window.atob(base64); // Decode base64
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    }
    if (order.buyer_approve_documents !== '') {
      const parsedData = JSON.parse(order.buyer_approve_documents);
      const arrayBuffer = base64ToArrayBuffer(parsedData.pdfData);
      const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      setBApproverDoc(blobUrl);
      setBApproverReason(order.buyer_approve_reason);
    }

    if (order.buyer_rejection_documents !== '') {
      const parsedData = JSON.parse(order.buyer_rejection_documents);
      const arrayBuffer = base64ToArrayBuffer(parsedData.pdfData);
      const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      setBRejectDoc(blobUrl);
      setBRejectReason(order.buyer_rejection_reason);
    }

    if (order.approve_documents !== '') {
      const parsedData = JSON.parse(order.approve_documents);
      const arrayBuffer = base64ToArrayBuffer(parsedData.pdfData);
      const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      setApproverDoc(blobUrl);
      setApproverReason(order.approve_reason);
    }

    if (order.rejection_documents !== '') {
      const parsedData = JSON.parse(order.rejection_documents);
      const arrayBuffer = base64ToArrayBuffer(parsedData.pdfData);
      const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      setRejectDoc(blobUrl);
      setRejectReason(order.rejection_reason);
    }
    const Store = order.product_details;
    const calculateTotalCharges = () => Store.reduce((total, item) => total + item.price, 0);
    const calculateTotalQty = () => Store.reduce((total, item) => total + item.quantity, 0);
    setCartSybmol(order.symbol);
    totalChargesTable(calculateTotalCharges());
    totalQtyTable(calculateTotalQty());
    setSelectedOrder(order);
    setRatings({}); // Reset ratings when opening the modal
    setComments({}); // Reset comments when opening the modal
    setShow1(true);
    setIsLoading(false);
  };

  const detailTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      Detail
    </Tooltip>
  );

  const trackTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      Track Order
    </Tooltip>
  );

  const uploadTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      Upload PDF
    </Tooltip>
  );

  const pdfTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      PDF
    </Tooltip>
  );

  const openTrackDetails = (order) => {
    setIsLoading(true);
    // console.log('track', order);
    setSelectedOrder(order);
    setModalIsOpen1(true);
    setIsLoading(false);
  };

  const closeTrackDetails = () => {
    setSelectedOrder(null);
    setModalIsOpen1(false);
  };

  const closeProductDetails = () => {
    setSelectedOrder(null);
    setModalIsOpen(false);
  };

  const optionOrderStatus = [
    { value: 'pending', label: 'Pending' },
    { value: 'waiting', label: 'Waiting' },
    { value: 'paid', label: 'Paid' },
    { value: 'completed', label: 'Completed' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'declined', label: 'Declined' },
    { value: 'failed', label: 'Failed' },
  ];

  const optionPaymentStatus = [
    { value: 'pending', label: 'Pending' },
    { value: 'waiting', label: 'Waiting' },
    { value: 'paid', label: 'Paid' },
    { value: 'completed', label: 'Completed' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'declined', label: 'Declined' },
    { value: 'failed', label: 'Failed' },
  ];

  const optionApprovalStatus = [
    { value: 'pending', label: 'Pending' },
    { value: 'waiting', label: 'Waiting' },
    { value: 'paid', label: 'Paid' },
    { value: 'completed', label: 'Completed' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'declined', label: 'Declined' },
    { value: 'failed', label: 'Failed' },
  ];

  const searchFilters = [
    { value: 'order_no', label: 'Order No' },
    { value: 'date', label: 'Date' },
    { value: 'address', label: 'Delivery Address' },
    { value: 'amount', label: 'Amount' },
    { value: 'payment_status', label: 'Payment Status' },
    { value: 'approval_status', label: 'Approval Status' },
    { value: 'order_status', label: 'Order Status' },
  ];

  const handleSearchFilterChange = (value) => {
    if (value.value === 'date') {
      setIsSearchOrderNo(false);
      setIsSearchAddress(false);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(false);
      setIsSearchDate(true);
      setSelectedSearch(value.value);
    }
    if (value.value === 'order_no') {
      setIsSearchOrderNo(true);
      setIsSearchAddress(false);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(false);
      setIsSearchDate(false);
      setSelectedSearch(value.value);
    }
    if (value.value === 'address') {
      setIsSearchOrderNo(false);
      setIsSearchAddress(true);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(false);
      setIsSearchDate(false);
      setSelectedSearch(value.value);
    }
    if (value.value === 'amount') {
      setIsSearchOrderNo(false);
      setIsSearchAddress(false);
      setIsSearchAmount(true);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(false);
      setIsSearchDate(false);
      setSelectedSearch(value.value);
    }
    if (value.value === 'payment_status') {
      setIsSearchOrderNo(false);
      setIsSearchAddress(false);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(true);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(false);
      setIsSearchDate(false);
      setSelectedSearch(value.value);
    }
    if (value.value === 'approval_status') {
      setIsSearchOrderNo(false);
      setIsSearchAddress(false);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(true);
      setIsSearchOrderStatus(false);
      setIsSearchDate(false);
      setSelectedSearch(value.value);
    }
    if (value.value === 'order_status') {
      setIsSearchOrderNo(false);
      setIsSearchAddress(false);
      setIsSearchAmount(false);
      setIsSearchPaymentStatus(false);
      setIsSearchApprovalStatus(false);
      setIsSearchOrderStatus(true);
      setIsSearchDate(false);
      setSelectedSearch(value.value);
    }
  };

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    function formatDate(inputDate) {
      const parts = inputDate.split('-');
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    const formattedDate = formatDate(newDate);
    // const completedTransactions = getTransactions1.filter(transaction => transaction.Date === formattedDate);
    let completedTransactions;
    completedTransactions = getTransactions1.filter((transaction) => {
      const transactionDate = new Date(transaction.createdAt);
      const month = (transactionDate.getMonth() + 1).toString().padStart(2, '0');
      const day = transactionDate.getDate().toString().padStart(2, '0');
      const year = transactionDate.getFullYear();
      const formattedTransactionDate = `${month}/${day}/${year}`;

      return formattedTransactionDate === formattedDate;
    });
    if (selectedOrderNo !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction._id.toLowerCase().includes(selectedOrderNo.toLowerCase()));
    }
    if (selectedAmount !== '') {
      completedTransactions = completedTransactions.filter(transaction => String(transaction.total_amount).includes(selectedAmount));
    }
    if (selectedAddress !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.delivery_address.toLowerCase().includes(selectedAddress.toLowerCase()));
    }
    if (selectedPaymentStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Payment_status === selectedPaymentStatus);
    }
    if (selectedOrderStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.order_status === selectedOrderStatus);
    }
    if (selectedApprovalStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.is_approved_status === selectedApprovalStatus);
    }
    setOrders(completedTransactions);
    setSelectedDate(newDate);
  };

  const handlePaymentStatusChange = (value) => {
    let completedTransactions;
    function formatDate(inputDate) {
      const parts = inputDate.split('-');
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    completedTransactions = getTransactions1.filter(transaction => transaction.Payment_status === value.value);
    if (selectedOrderNo !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction._id.toLowerCase().includes(selectedOrderNo.toLowerCase()));
    }
    if (selectedAmount !== '') {
      completedTransactions = completedTransactions.filter(transaction => String(transaction.total_amount).includes(selectedAmount));
    }
    if (selectedAddress !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.delivery_address.toLowerCase().includes(selectedAddress.toLowerCase()));
    }
    if (selectedOrderStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.order_status === selectedOrderStatus);
    }
    if (selectedApprovalStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.is_approved_status === selectedApprovalStatus);
    }
    if (selectedDate !== '') {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        const month = (transactionDate.getMonth() + 1).toString().padStart(2, '0');
        const day = transactionDate.getDate().toString().padStart(2, '0');
        const year = transactionDate.getFullYear();
        const formattedTransactionDate = `${month}/${day}/${year}`;

        return formattedTransactionDate === formattedDate;
      });
    }
    setSelectedPaymentStatus(value.value);
    setOrders(completedTransactions);
  };

  const handleApprovalStatusChange = (value) => {
    let completedTransactions;
    function formatDate(inputDate) {
      const parts = inputDate.split('-');
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    completedTransactions = getTransactions1.filter(transaction => transaction.is_approved_status === value.value);
    if (selectedOrderNo !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction._id.toLowerCase().includes(selectedOrderNo.toLowerCase()));
    }
    if (selectedAmount !== '') {
      completedTransactions = completedTransactions.filter(transaction => String(transaction.total_amount).includes(selectedAmount));
    }
    if (selectedAddress !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.delivery_address.toLowerCase().includes(selectedAddress.toLowerCase()));
    }
    if (selectedPaymentStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Payment_status === selectedPaymentStatus);
    }
    if (selectedOrderStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.order_status === selectedOrderStatus);
    }
    if (selectedDate !== '') {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        const month = (transactionDate.getMonth() + 1).toString().padStart(2, '0');
        const day = transactionDate.getDate().toString().padStart(2, '0');
        const year = transactionDate.getFullYear();
        const formattedTransactionDate = `${month}/${day}/${year}`;

        return formattedTransactionDate === formattedDate;
      });
    }
    setSelectedApprovalStatus(value.value);
    setOrders(completedTransactions);
  };

  const handleOrderStatusChange = (value) => {
    let completedTransactions;
    function formatDate(inputDate) {
      const parts = inputDate.split('-');
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    completedTransactions = getTransactions1.filter(transaction => transaction.order_status === value.value);
    if (selectedOrderNo !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction._id.toLowerCase().includes(selectedOrderNo.toLowerCase()));
    }
    if (selectedAmount !== '') {
      completedTransactions = completedTransactions.filter(transaction => String(transaction.total_amount).includes(selectedAmount));
    }
    if (selectedAddress !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.delivery_address.toLowerCase().includes(selectedAddress.toLowerCase()));
    }
    if (selectedPaymentStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Payment_status === selectedPaymentStatus);
    }
    if (selectedApprovalStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.is_approved_status === selectedApprovalStatus);
    }
    if (selectedDate !== '') {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        const month = (transactionDate.getMonth() + 1).toString().padStart(2, '0');
        const day = transactionDate.getDate().toString().padStart(2, '0');
        const year = transactionDate.getFullYear();
        const formattedTransactionDate = `${month}/${day}/${year}`;

        return formattedTransactionDate === formattedDate;
      });
    }
    setSelectedOrderStatus(value.value);
    setOrders(completedTransactions);
  };

  const handleAddressChange = (value) => {
    const partialValue = value.target.value;
    // console.log('handleAddressChange', partialValue);
    function formatDate(inputDate) {
      const parts = inputDate.split('-');
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    let completedTransactions;
    completedTransactions = getTransactions1.filter(transaction => transaction.delivery_address.toLowerCase().includes(partialValue.toLowerCase()));
    if (selectedOrderNo !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction._id.toLowerCase().includes(selectedOrderNo.toLowerCase()));
    }
    if (selectedAmount !== '') {
      completedTransactions = completedTransactions.filter(transaction => String(transaction.total_amount).includes(selectedAmount));
    }
    if (selectedOrderStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.order_status === selectedOrderStatus);
    }
    if (selectedPaymentStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Payment_status === selectedPaymentStatus);
    }
    if (selectedApprovalStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.is_approved_status === selectedApprovalStatus);
    }
    if (selectedDate !== '') {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        const month = (transactionDate.getMonth() + 1).toString().padStart(2, '0');
        const day = transactionDate.getDate().toString().padStart(2, '0');
        const year = transactionDate.getFullYear();
        const formattedTransactionDate = `${month}/${day}/${year}`;

        return formattedTransactionDate === formattedDate;
      });
    }
    setOrders(completedTransactions);
    setSelectedAddress(value.target.value);
  };

  const handleOrderNoChange = (value) => {
    const partialValue = value.target.value;
    // console.log('handleOrderNoChange', partialValue);
    function formatDate(inputDate) {
      const parts = inputDate.split('-');
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    let completedTransactions;
    completedTransactions = getTransactions1.filter(transaction => transaction._id.toLowerCase().includes(partialValue.toLowerCase()));
    if (selectedAddress !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.delivery_address.toLowerCase().includes(selectedAddress.toLowerCase()));
    }
    if (selectedAmount !== '') {
      completedTransactions = completedTransactions.filter(transaction => String(transaction.total_amount).includes(selectedAmount));
    }
    if (selectedOrderStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.order_status === selectedOrderStatus);
    }
    if (selectedPaymentStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Payment_status === selectedPaymentStatus);
    }
    if (selectedApprovalStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.is_approved_status === selectedApprovalStatus);
    }
    if (selectedDate !== '') {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        const month = (transactionDate.getMonth() + 1).toString().padStart(2, '0');
        const day = transactionDate.getDate().toString().padStart(2, '0');
        const year = transactionDate.getFullYear();
        const formattedTransactionDate = `${month}/${day}/${year}`;

        return formattedTransactionDate === formattedDate;
      });
    }
    setOrders(completedTransactions);
    setSelectedOrderNo(value.target.value);
  };

  const handleAmountChange = (value) => {
    const partialValue = value.target.value;
    // console.log('partialValue', partialValue);
    function formatDate(inputDate) {
      const parts = inputDate.split('-');
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    let completedTransactions;
    completedTransactions = getTransactions1.filter(transaction => String(transaction.total_amount).includes(partialValue));
    if (selectedAddress !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.delivery_address.toLowerCase().includes(selectedAddress.toLowerCase()));
    }
    if (selectedOrderNo !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction._id.toLowerCase().includes(selectedOrderNo.toLowerCase()));
    }
    if (selectedOrderStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.order_status === selectedOrderStatus);
    }
    if (selectedPaymentStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Payment_status === selectedPaymentStatus);
    }
    if (selectedApprovalStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.is_approved_status === selectedApprovalStatus);
    }
    if (selectedDate !== '') {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        const month = (transactionDate.getMonth() + 1).toString().padStart(2, '0');
        const day = transactionDate.getDate().toString().padStart(2, '0');
        const year = transactionDate.getFullYear();
        const formattedTransactionDate = `${month}/${day}/${year}`;

        return formattedTransactionDate === formattedDate;
      });
    }
    setOrders(completedTransactions);
    setSelectedAmount(value.target.value);
  };

  const resetData = (order) => {
    setIsLoading(true);
    setSelectedPaymentStatus('');
    setSelectedApprovalStatus('');
    setSelectedOrderStatus('');
    setSelectedAddress('');
    setSelectedOrderNo('');
    setSelectedAmount('');
    setSelectedAmount('');
    setSelectedDate('');
    setOrders(getTransactions1);
    setIsLoading(false);
  };

  const tableConfig = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    isPagination: true,
  };
  const header = [
    { accessor: 'order_no', Header: 'Order No.' },
    { accessor: 'quantity', Header: 'Quantity' },
    // { accessor: 'created', Header: 'Created' },
    { accessor: 'address', Header: 'Delivery Address' },
    { accessor: 'amount', Header: 'Amount', disableGlobalFilter: true },
    { accessor: 'payment_status', Header: 'Payment' },
    { accessor: 'approval_status', Header: 'Approval' },
    { accessor: 'buyer_approval_status', Header: 'Buyer Approval' },
    { accessor: 'order_status', Header: 'Order' },
    {
      accessor: 'action', Header: 'Action', disableGlobalFilter: true, width: 80,
    },
  ];

  const getStatusColor = (status1) => {
    // console.log('status.toLowerCase()', status1.toLowerCase());
    switch (status1.toLowerCase()) {
      case 'completed':
        return '#4ce1b6';
      case 'paid':
        return '#4ce1b6';
      case 'pending':
        return '#f6da6e';
      case 'rejected':
        return '#ff4861';
      case 'waiting':
        return '#f6da6e';
      case 'declined':
        return '#ff4861';
      case 'failed':
        return '#ff4861';
      default:
        return '#70bbfd';
    }
  };


  const data = [];

  orders.forEach((item) => {
    data.push({
      order_no: item._id,
      quantity: item.total_quantity,
      // created: 
      // (
      //   <div>
      //     {(() => {
      //       const originalDateString = item.createdAt;
      //       const originalDate = new Date(originalDateString);
      //       const formattedDate = `${originalDate.getFullYear()}-${(originalDate.getMonth() + 1).toString().padStart(2, '0')}-${originalDate.getDate().toString().padStart(2, '0')}`;
      //       const hours = originalDate.getHours();
      //       const minutes = originalDate.getMinutes();
      //       const ampm = hours >= 12 ? 'PM' : 'AM';
      //       const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      //       const formattedTime = `${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;

      //       return (
      //         <span>{formattedDate}</span>
      //       );
      //     })()}
      //   </div>
      // ),
      address: item.delivery_address.length > 15 ? `${item.delivery_address.substring(0, 12)}...` : item.delivery_address,
      amount: `${item.total_amount} ${item.symbol}`,
      payment_status: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{ backgroundColor: getStatusColor(item.Payment_status) }}
        >
          <h6 className="text-white"><b>{item.Payment_status}</b></h6>
        </span>
      ),
      approval_status: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{ backgroundColor: getStatusColor(item.is_approved_status) }}
        >
          <h6 className="text-white"><b>{item.is_approved_status}</b></h6>
        </span>
      ),
      buyer_approval_status: (
        item.buyer_approver_userId !== '' ? (
          <span
            className="badge px-2 py-1 text-capitalize"
            style={{ backgroundColor: getStatusColor(item.buyer_is_approved_status) }}
          >
            <h6 className="text-white"><b>{item.buyer_is_approved_status}</b></h6>
          </span>
        ) : (
          '-'
        )
      ),
      order_status: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{ backgroundColor: getStatusColor(item.order_status) }}
        >
          <h6 className="text-white"><b>{item.order_status}</b></h6>
        </span>
      ),
      // payment_status: (
      //   item.Payment_status === 'paid' ? (
      //     <Badge bg="success" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.Payment_status}</Badge>
      //   ) : (
      //     <Badge bg="danger" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.Payment_status}</Badge>
      //   )
      // ),
      // approval_status: (
      //   item.is_approved_status === 'waiting' ? (
      //     <Badge bg="warning" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.is_approved_status}</Badge>
      //   ) : item.is_approved_status === 'pending' ? (
      //     <Badge bg="primary" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.is_approved_status}</Badge>
      //   ) : item.is_approved_status === 'shipped' ? (
      //     <Badge bg="secondary" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.is_approved_status}</Badge>
      //   ) : item.is_approved_status === 'delivered' ? (
      //     <Badge bg="info" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.is_approved_status}</Badge>
      //   ) : (
      //     <Badge bg="success" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.is_approved_status}</Badge>
      //   )
      // ),
      // order_status: (
      //   item.order_status === 'waiting' ? (
      //     <Badge bg="warning" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.order_status}</Badge>
      //   ) : item.order_status === 'pending' ? (
      //     <Badge bg="primary" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.order_status}</Badge>
      //   ) : item.order_status === 'shipped' ? (
      //     <Badge bg="secondary" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.order_status}</Badge>
      //   ) : item.order_status === 'delivered' ? (
      //     <Badge bg="info" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.order_status}</Badge>
      //   ) : (
      //     <Badge bg="success" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.order_status}</Badge>
      //   )
      // ),
      action: (
        <HashLink>
          {item.order_status === 'shipped' && (
            <select
              className="text-center"
              value={selectedStatus}
              onChange={e => handleStatusChange(item._id, e.target.value)}
              style={{ textTransform: 'capitalize', padding: '4px', borderRadius: '4px' }}
            >
              <option
                disabled
                value=""
                style={{ textTransform: 'capitalize' }}
              >{item.order_status}
              </option>
              <option value="delivered">Delivered</option>
              <option value="completed">Completed</option>
            </select>
          )}
          {item.order_status === 'delivered' && (
            <select
              value={selectedStatus}
              onChange={e => handleStatusChange(item._id, e.target.value)}
              style={{ textTransform: 'capitalize', padding: '4px', borderRadius: '4px' }}
            >
              <option
                disabled
                value=""
                style={{ textTransform: 'capitalize' }}
              >{item.order_status}
              </option>
              <option value="completed">Completed</option>
            </select>
          )}
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 200, hide: 200 }}
            overlay={detailTooltip}
          >
            {/* <EyeIcon onClick={() => openProductDetails(item)} style={{ width: '19px' }} /> */}
            {/* <i
              onClick={() => openProductDetails(item)} 
              style={{
 marginLeft: '6px', marginRight: '4px', color: 'white', marginTop: '11px', fontSize: '15px',
}}
              className="fa fa-eye"
            /> */}
            <Button
              style={{ marginTop: '0px' }}
              className="  ml-2 shadow-none text-white"
              variant={'outline-purple'}
              onClick={() => openProductDetails(item)}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 200, hide: 200 }}
            overlay={trackTooltip}
          >
            {/* <i onClick={() => handleShow(item)} style={{ marginLeft: '6px', marginRight: '0px', color: 'white' }} className="fas fa-shipping-fast" /> */}
            <Button
              variant="outline-secondary"
              className=" ml-2  shadow-none"
              onClick={() => handleShow(item)}
            >
              <i style={{ marginLeft: '2px', marginRight: '-2px' }} className="fas fa-shipping-fast" />
            </Button>
          </OverlayTrigger>
          {item.invoice.length === 0 ? (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 200, hide: 200 }}
              overlay={uploadTooltip}
            >
              <Button
                variant='outline-primary'
                className=" ml-2 btn-sm shadow-none "
                onClick={() => openAcceptModal(item._id)}
              >
                <i style={{ marginLeft: '-2px', marginRight: '-2px', fontSize: '13px' }} className="fa fa-cloud-upload" />
              </Button>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 200, hide: 200 }}
              overlay={pdfTooltip}
            >
              <Button
                variant="primary"
                className="btn btn-danger btn-sm ml-2 py-1 pl-1 shadow-none rounded-circle text-white"
              >
                <a
                  // eslint-disable-next-line max-len
                  href={item.invoice}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i style={{ marginLeft: '4px', marginRight: '-1px', color: 'white' }} className="fas fa-file-pdf" />
                </a>
              </Button>
            </OverlayTrigger>
          )}
        </HashLink>
      ),
    });
  });

  const optionChain = [
    { value: 'ETH', label: 'ETH' },
    { value: 'BNB', label: 'BNB' },
    { value: 'MATIC', label: 'MATIC' },
  ];

  const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? 'flex' : 'none', // Use flex display when loading
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Panel
        lg={12}
        title="Seller Orders"
        // isLoading={isLoading}
        refreshRequest={() => fetchOrders1()}
      >
        <Row className="mb-5">
          <Col xs={12} md={4} lg={3} hidden>
            <label htmlFor="features" className="form-label text-white mb-0">Search Filter</label>
            <InputDiv className="px-0">
              <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedSearch}
                  onChange={handleSearchFilterChange}
                  options={searchFilters}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          {/* {isSearchDate && ( */}
          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-1">Date</label>
              <ChatSearchInput
                type="date"
                value={selectedDate}
                className="form-control mt-2"
                onChange={handleDateChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchOrderNo && ( */}
          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-1">Order No</label>
              <ChatSearchInput
                type="text"
                value={selectedOrderNo}
                className="form-control mt-2"
                placeholder="Search by Order No"
                onChange={handleOrderNoChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchAddress && ( */}
          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-1">Address</label>
              <ChatSearchInput
                type="text"
                value={selectedAddress}
                className="form-control mt-2"
                placeholder="Search by Address"
                onChange={handleAddressChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchAmount && ( */}
          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-1">Amount</label>
              <ChatSearchInput
                type="text"
                value={selectedAmount}
                className="form-control mt-2"
                placeholder="Search by Amount"
                onChange={handleAmountChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchPaymentStatus && ( */}
          <Col xs={12} md={4} lg={3}>
            <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-0 mt-2">Payment Status</label>
            <InputDiv className="px-0">
              <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedPaymentStatus}
                  onChange={handlePaymentStatusChange}
                  options={optionPaymentStatus}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchApprovalStatus && ( */}
          <Col xs={12} md={4} lg={3}>
            <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-0 mt-2">Approval Status</label>
            <InputDiv className="px-0">
              <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedApprovalStatus}
                  onChange={handleApprovalStatusChange}
                  options={optionApprovalStatus}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchOrderStatus && ( */}
          <Col xs={12} md={4} lg={3}>
            <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-0 mt-2">Order Status</label>
            <InputDiv className="px-0">
              <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedOrderStatus}
                  onChange={handleOrderStatusChange}
                  options={optionOrderStatus}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          <Col xs={12} md={4} lg={3}>
            {/* <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-0 mt-2">Type</label> */}
            <InputDiv className="px-0">
              <FormButton
                type="submit"
                variant="danger-outline"
                className="btn-block mt-4"
                onClick={() => resetData(1)}

              >
                Reset  <i className="fa fa-refresh" />
              </FormButton>
            </InputDiv>
          </Col>
          {/* )}       */}
          {!isSearchApprovalStatus && !isSearchOrderNo && !isSearchStatus && !isSearchAddress && !isSearchPaymentStatus && !isSearchOrderStatus && !isSearchAmount && !isSearchOperation && !isSearchDate && (
            <Col xs={12} md={4} lg={3} />
          )}
          <Col xs={12} md={4} lg={4} />
          <Col xs={12} md={4} lg={2} hidden>
            <label htmlFor="color" style={{ fontSize: 'small', color: 'transparent' }} className="form-label">.</label>
            <Button
              variant="primary"
              className="btn btn-danger mt-1 w-100 py-1 pl-1 shadow-none"
              onClick={() => resetData(1)}
            >
              Reset  <i className="fa fa-refresh" />
            </Button>
          </Col>
        </Row>
        <ReactTableBase
          columns={header}
          data={data}
          tableConfig={tableConfig}
        />
      </Panel>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: 'large' }}>Track Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOrder && (
            <div className="container padding-bottom-3x mb-1 p-0">
              <div className="card mb-3 mt-0" style={{ boxShadow: 'none', fontFamily: 'sans-serif' }}>
                <div className="row steps flex-wrap flex-sm-nowrap justify-content-between py-2 mb-3 px-2 bg-secondary">
                  <div className="col-lg-12 col-md-12 col-sm-12 d-flex">
                    <span>
                      <div className="step completed mb-0">
                        <div className="step-icon-wrap bg-transparent" style={{ border: 'none', height: '60px' }}>
                          <div className="step-icon bg-transparent" style={{ color: 'black', border: 'none' }}><i className="pe-7s-box2" /></div>
                        </div>
                      </div>
                    </span>
                    <span>
                      <div className="py-1 px-0">
                        {selectedOrderQty > 1 ? (
                          <span className="mb-2"> {selectedOrderQty} Items </span>
                        ) : (
                          <span className="mb-2"> {selectedOrderQty} Item </span>
                        )}
                        <br />
                        {/* <p className="mb-0" style={{ color: '#575757', fontSize: 'small' }}>Deliver on April 27, 2023 </p>  */}
                        <p className="mb-0" style={{ color: '#575757', fontSize: 'small' }}>{selectedOrder.delivery_address} <i className="mr-1 fa fa-map-marker" /></p>
                      </div>
                    </span>
                  </div>
                </div>
                <div className="card-body">
                  <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                    <div className="step completed mb-2">
                      <div className="step-icon-wrap">
                        {selectedOrder.order_status === 'pending' || selectedOrder.order_status === 'Pending' ? (
                          <div className="step-icon" style={{ borderColor: '#007bff', backgroundColor: '#007bff', color: '#fff' }}><i className="pe-7s-config" /></div>
                        ) : (
                          <div className="step-icon"><i className="pe-7s-config" /></div>
                        )}
                      </div>
                      <h4 className="step-title mt-0"> Pending</h4>
                    </div>
                    <div className="step completed mb-2">
                      <div className="step-icon-wrap">
                        {selectedOrder.order_status === 'waiting' || selectedOrder.order_status === 'Waiting' ? (
                          <div className="step-icon" style={{ borderColor: '#ffc107', backgroundColor: '#ffc107', color: '#fff' }}><i className="pe-7s-hourglass" /></div>
                        ) : (
                          <div className="step-icon"><i className="pe-7s-hourglass" /></div>
                        )}
                      </div>
                      <h4 className="step-title mt-0">Waiting</h4>
                    </div>
                    <div className="step completed mb-2">
                      <div className="step-icon-wrap">
                        {selectedOrder.order_status === 'shipped' || selectedOrder.order_status === 'Shipped' ? (
                          <div className="step-icon" style={{ borderColor: '#6c757d', backgroundColor: '#6c757d', color: '#fff' }}><i className="pe-7s-car" /></div>
                        ) : (
                          <div className="step-icon"><i className="pe-7s-car" /></div>
                        )}
                      </div>
                      <h4 className="step-title mt-0">Shipped</h4>
                    </div>
                    <div className="step completed mb-2">
                      <div className="step-icon-wrap">
                        {selectedOrder.order_status === 'delivered' || selectedOrder.order_status === 'Delivered' ? (
                          <div className="step-icon" style={{ borderColor: '#17a2b8', backgroundColor: '#17a2b8', color: '#fff' }}><i className="pe-7s-home" /></div>
                        ) : (
                          <div className="step-icon"><i className="pe-7s-home" /></div>
                        )}
                      </div>
                      <h4 className="step-title mt-0">Delivered</h4>
                    </div>
                    <div className="step completed mb-2">
                      <div className="step-icon-wrap">
                        {selectedOrder.order_status === 'completed' || selectedOrder.order_status === 'Completed' ? (
                          <div className="step-icon" style={{ borderColor: '#28a745', backgroundColor: '#28a745', color: '#fff' }}><i className="pe-7s-check" /></div>
                        ) : (
                          <div className="step-icon"><i className="pe-7s-check" /></div>
                        )}
                      </div>
                      <h4 className="step-title mt-0">Completed</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show1}
        onHide={handleClose1}
      >
        <Modal.Header closeButton>
          {selectedOrder && (
            <Modal.Title className="text-center" style={{ fontSize: 'large' }}>Order No: <span style={{ fontWeight: 400 }}> {selectedOrder._id}</span></Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {selectedOrder && (
            <>
              <div className="container mt-0 containerDiv pt-0" style={{ overflowX: 'hidden' }}>
                {/* <div className="row">
                <div className="col-12 text-center">
                  <h3 style={{ color: '#7b7b7b' }} className="mb-2"> Order Details</h3>
                </div>
              </div> */}
                <div className="row mt-0 mb-1">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="row mt-2 mb-1">
                      <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                        <b>Order Detail</b>
                      </p>
                    </div>
                    <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                      <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1 mt-1"
                        ><b>Quantity</b>
                        </p>
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        >{selectedOrder.product_details.length}
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1 mt-1"
                        ><b>Shipping</b>
                        </p>
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        >{selectedOrder.total_delivery_charges} {selectedOrder.symbol}
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1 mt-1"
                        ><b>Grand Total</b>
                        </p>
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        >{selectedOrder.total_amount} {selectedOrder.symbol}
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1 mt-1"
                        ><b>Payment Hash</b>
                        </p>
                        <a
                          // eslint-disable-next-line max-len
                          href={`${'https://bscscan.com/tx'}/${selectedOrder.payment_transaction_hash}`}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                        >
                          View
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="row mt-2 mb-1">
                      <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                        <b>Buyer Detail</b>
                      </p>
                    </div>
                    <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                      <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        ><b>Buyer Name</b> <FontAwesomeIcon icon={faUser} />
                        </p>
                        {selectedOrder.buyer_detail[0] ? (
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1 pb-3"
                          >{selectedOrder.buyer_detail[0].name}
                          </p>
                        ) : (
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          >-
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        ><b>Buyer Email</b> <FontAwesomeIcon icon={faEnvelope} />
                        </p>
                        {selectedOrder.buyer_detail[0] ? (
                          <p
                            style={{ textTransform: 'inherit', color: '#7b7b7b' }}
                            className="py-1"
                          >{selectedOrder.buyer_detail[0].email}
                          </p>
                        ) : (
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          >-
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2 mb-1">
                  <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                    <b>Store Approver Detail</b>
                  </p>
                </div>
                <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                      className="py-1 mt-1"
                    ><b>Name</b> <FontAwesomeIcon icon={faUser} />
                    </p>
                    {selectedOrder.approver_detail[0] ? (
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      >{selectedOrder.approver_detail[0].name}
                      </p>
                    ) : (
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      >-
                      </p>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                      className="py-1 mt-1"
                    ><b>Email</b> <FontAwesomeIcon icon={faEnvelope} />
                    </p>
                    {selectedOrder.approver_detail[0] ? (
                      <p
                        style={{ textTransform: 'inherit', color: '#7b7b7b' }}
                        className="py-1"
                      >{selectedOrder.approver_detail[0].email}
                      </p>
                    ) : (
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      >-
                      </p>
                    )}
                  </div>
                  {approverDoc && (
                    <>
                      <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        ><b>Approve Document</b> <FontAwesomeIcon icon={faFile} />
                        </p>
                        <a
                          href={approverDoc}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                        >
                          View
                        </a>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                        <span>
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          ><b>Approve Reason</b>
                          </p>

                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          >{approverReason}
                          </p>
                        </span>
                      </div>
                    </>
                  )}
                  {rejectDoc && (
                    <>
                      <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        ><b>Rejection Document</b> <FontAwesomeIcon icon={faFile} />
                        </p>
                        <a
                          href={rejectDoc}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                        >
                          View
                        </a>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                        <span>
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          ><b>Rejection Reason</b>
                          </p>

                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          >{rejectReason}
                          </p>
                        </span>
                      </div>
                    </>
                  )}
                </div>
                {selectedOrder.buyer_approver_detail[0] && (
                  <>
                    <div className="row mt-3 mb-1">
                      <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                        <b>Buyer Approver Detail</b>
                      </p>
                    </div>
                    <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                      <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        ><b>Name</b> <FontAwesomeIcon icon={faUser} />
                        </p>
                        {selectedOrder.buyer_approver_detail[0] ? (
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          >{selectedOrder.buyer_approver_detail[0].name}
                          </p>
                        ) : (
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          >-
                          </p>
                        )}
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        ><b>Email</b> <FontAwesomeIcon icon={faEnvelope} />
                        </p>
                        {selectedOrder.buyer_approver_detail[0] ? (
                          <p
                            style={{ textTransform: 'inherit', color: '#7b7b7b' }}
                            className="py-1"
                          >{selectedOrder.buyer_approver_detail[0].email}
                          </p>
                        ) : (
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          >-
                          </p>
                        )}
                      </div>
                      {bapproverDoc && (
                        <>
                          <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            ><b>Approve Document</b> <FontAwesomeIcon icon={faFile} />
                            </p>
                            <a
                              href={bapproverDoc}
                              target="_blank"
                              rel="noreferrer"
                              className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                            >
                              View
                            </a>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                            <span>
                              <p
                                style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                className="py-1"
                              ><b>Approve Reason</b>
                              </p>

                              <p
                                style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                className="py-1"
                              >{bapproverReason}
                              </p>
                            </span>
                          </div>
                        </>
                      )}
                      {brejectDoc && (
                        <>
                          <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            ><b>Rejection Document</b> <FontAwesomeIcon icon={faFile} />
                            </p>
                            <a
                              href={brejectDoc}
                              target="_blank"
                              rel="noreferrer"
                              className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                            >
                              View
                            </a>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                            <span>
                              <p
                                style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                className="py-1"
                              ><b>Rejection Reason</b>
                              </p>

                              <p
                                style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                className="py-1"
                              >{brejectReason}
                              </p>
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
                {selectedOrder.seller_wallet.length !== 0 && (
                  <>
                    <div className="row mt-3 mb-1">
                      <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                        <b>Seller Detail</b>
                      </p>
                    </div>
                    <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                      <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        ><b>Name</b> <FontAwesomeIcon icon={faUser} />
                        </p>
                        {selectedOrder.seller_wallet[0] ? (
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          >{selectedOrder.seller_wallet[0].name}
                          </p>
                        ) : (
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          >-
                          </p>
                        )}
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        ><b>Seller ID</b> <FontAwesomeIcon icon={faEnvelope} />
                        </p>
                        {selectedOrder.seller_wallet[0] ? (
                          <p
                            style={{ textTransform: 'inherit', color: '#7b7b7b' }}
                            className="py-1"
                          >{selectedOrder.seller_wallet[0]._id}
                          </p>
                        ) : (
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          >-
                          </p>
                        )}
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        ><b>Seller Amount</b>
                        </p>
                        {selectedOrder.seller_wallet[0] ? (
                          <p
                            style={{ textTransform: 'inherit', color: '#7b7b7b' }}
                            className="py-1"
                          >
                            {`${selectedOrder?.seller_total_amount || 0} USDT`}

                          </p>
                        ) : (
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          >-
                          </p>
                        )}
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        ><b>Agency Amount</b>
                        </p>
                        {selectedOrder.seller_wallet[0] ? (
                          <p
                            style={{ textTransform: 'inherit', color: '#7b7b7b' }}
                            className="py-1"
                          >
                            {`${selectedOrder?.agency_total_amount || 0} USDT`}
                          </p>
                        ) : (
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          >-
                          </p>
                        )}
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        ><b>Agency % </b>
                        </p>
                        {selectedOrder.seller_wallet[0] ? (
                          <p
                            style={{ textTransform: 'inherit', color: '#7b7b7b' }}
                            className="py-1"
                          >
                            {`${selectedOrder?.agency_percentage || 0}`}

                          </p>
                        ) : (
                          <p
                            style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                            className="py-1"
                          >-
                          </p>
                        )}
                      </div>






                      <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1 mt-1"
                        ><b>Payment Hash</b>
                        </p>
                        <a
                          // eslint-disable-next-line max-len
                          href={`${'https://bscscan.com/tx'}/${orders[0].payment_transaction_hash_agency}`}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                        >
                          View
                        </a>
                      </div>
                      {bapproverDoc && (
                        <>
                          <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            ><b>Approve Document</b> <FontAwesomeIcon icon={faFile} />
                            </p>
                            <a
                              href={bapproverDoc}
                              target="_blank"
                              rel="noreferrer"
                              className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                            >
                              View
                            </a>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                            <span>
                              <p
                                style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                className="py-1"
                              ><b>Approve Reason</b>
                              </p>

                              <p
                                style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                className="py-1"
                              >{bapproverReason}
                              </p>
                            </span>
                          </div>
                        </>
                      )}
                      {brejectDoc && (
                        <>
                          <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                            <p
                              style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                              className="py-1"
                            ><b>Rejection Document</b> <FontAwesomeIcon icon={faFile} />
                            </p>
                            <a
                              href={brejectDoc}
                              target="_blank"
                              rel="noreferrer"
                              className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                            >
                              View
                            </a>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                            <span>
                              <p
                                style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                className="py-1"
                              ><b>Rejection Reason</b>
                              </p>

                              <p
                                style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                                className="py-1"
                              >{brejectReason}
                              </p>
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
                <hr hidden style={{ border: '1px solid #b3b3b3', opacity: '1.1' }} />
                <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0 mt-4">
                  <b>Invoice</b>
                </p>
                <div>
                  <div className="row mt-2 mb-2">
                    <div className="col-12">
                      <div style={{ overflowY: 'auto' }}>
                        <table className="table table-sm" style={{ textTransform: 'Capitalize' }}>
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">Product</th>
                              <th scope="col">Qty</th>
                              <th scope="col">Color</th>
                              <th scope="col">Discount</th>
                              <th scope="col">Price</th>
                            </tr>
                          </thead>
                          <tbody style={{ borderBottom: '2px solid #7b7b7b' }}>
                            {selectedOrder.product_details.map(product => (
                              <tr key={product._id}>
                                <td>{product.title ? product.title : '-'}</td>
                                <td>{product.quantity}</td>
                                <td>{product.color}</td>
                                <td>{product.discount} %</td>
                                <td>{product.price} {selectedOrder.symbol}</td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td style={{ borderTop: '2px solid #7b7b7b', borderBottom: '2px solid #7b7b7b' }}>-</td>
                              <td style={{ borderTop: '2px solid #7b7b7b', borderBottom: '2px solid #7b7b7b' }}><b style={{ fontWeight: '600' }}> {gettotalQtyTable} </b> </td>
                              <td style={{ borderTop: '2px solid #7b7b7b', borderBottom: '2px solid #7b7b7b' }}>-</td>
                              <td style={{ borderTop: '2px solid #7b7b7b', borderBottom: '2px solid #7b7b7b' }}>-</td>
                              <td style={{ borderTop: '2px solid #7b7b7b', borderBottom: '2px solid #7b7b7b' }}> <b style={{ fontWeight: '600' }}> {gettotalChargesTable} {cartSybmol}</b></td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" hidden>
                Submit Ratings
              </button>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isModalOpen1}
        onHide={closeAcceptModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: 'large' }}>Upload a Invoive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mt-0 containerDiv" style={{ overflowX: 'hidden' }}>
            <div>
              <div className="row mt-0 mb-2">
                <div className="col-12">
                  <label
                    htmlFor="description"
                    style={{ color: '#7b7b7b' }}
                    className="form-label mb-1"
                  > <b>Upload Invoive</b>
                  </label>
                  <input
                    type="file"
                    className="form-control mt-0"
                    onChange={handleFileChange1}
                    id="File"
                    name="File"
                    accept=".pdf"
                  />
                </div>
                <div className="col-12 my-2">
                  <Button
                    type="Button"
                    className=" w-100 shadow-none text-capitalize"
                    onClick={handleApprove}
                    variant='outline-purple'
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        isOpen={modalIsOpen1}
        onRequestClose={closeTrackDetails}
        contentLabel="Track Details"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
          },
          content: {
            background: 'white',
            width: '750px',
            margin: 'auto',
            height: '400px',
          },
        }}
      >
        {selectedOrder && (
          <div className="container padding-bottom-3x mb-1 p-0">
            <div className="card mb-3" style={{ boxShadow: 'none', fontFamily: 'sans-serif' }}>
              <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between py-2 mb-3 px-2 bg-secondary">
                <div className="w-30 text-center py-1 px-2">
                  <div className="step completed mb-0">
                    <div className="step-icon-wrap bg-transparent" style={{ border: 'none' }}>
                      <div className="step-icon bg-transparent" style={{ color: 'black', border: 'none' }}><i className="pe-7s-box2" /></div>
                    </div>
                  </div>
                </div>
                <div className="w-100 py-1 px-0"><span className="mb-2"> 2 Items + Delivery</span>
                  <br />
                  <p className="mb-0" style={{ color: '#575757', fontSize: 'small' }}>Deliver on April 27, at 16:00</p>
                  <p className="mb-0" style={{ color: '#575757', fontSize: 'small' }}>Mascow, Mira st. house 21, apt. 14</p>
                </div>
              </div>
              <div className="card-body">
                <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                  <div className="step completed mb-2">
                    <div className="step-icon-wrap">
                      {selectedOrder.order_status === 'pending' || selectedOrder.order_status === 'Pending' ? (
                        <div className="step-icon" style={{ borderColor: '#007bff', backgroundColor: '#007bff', color: '#fff' }}><i className="pe-7s-config" /></div>
                      ) : (
                        <div className="step-icon"><i className="pe-7s-config" /></div>
                      )}
                    </div>
                    <h4 className="step-title mt-0"> Pending</h4>
                  </div>
                  <div className="step completed mb-2">
                    <div className="step-icon-wrap">
                      {selectedOrder.order_status === 'waiting' || selectedOrder.order_status === 'Waiting' ? (
                        <div className="step-icon" style={{ borderColor: '#ffc107', backgroundColor: '#ffc107', color: '#fff' }}><i className="pe-7s-hourglass" /></div>
                      ) : (
                        <div className="step-icon"><i className="pe-7s-hourglass" /></div>
                      )}
                    </div>
                    <h4 className="step-title mt-0">Waiting</h4>
                  </div>
                  <div className="step completed mb-2">
                    <div className="step-icon-wrap">
                      {selectedOrder.order_status === 'shipped' || selectedOrder.order_status === 'Shipped' ? (
                        <div className="step-icon" style={{ borderColor: '#6c757d', backgroundColor: '#6c757d', color: '#fff' }}><i className="pe-7s-car" /></div>
                      ) : (
                        <div className="step-icon"><i className="pe-7s-car" /></div>
                      )}
                    </div>
                    <h4 className="step-title mt-0">Shipped</h4>
                  </div>
                  <div className="step completed mb-2">
                    <div className="step-icon-wrap">
                      {selectedOrder.order_status === 'delivered' || selectedOrder.order_status === 'Delivered' ? (
                        <div className="step-icon" style={{ borderColor: '#17a2b8', backgroundColor: '#17a2b8', color: '#fff' }}><i className="pe-7s-home" /></div>
                      ) : (
                        <div className="step-icon"><i className="pe-7s-home" /></div>
                      )}
                    </div>
                    <h4 className="step-title mt-0">Delivered</h4>
                  </div>
                  <div className="step completed mb-2">
                    <div className="step-icon-wrap">
                      {selectedOrder.order_status === 'completed' || selectedOrder.order_status === 'Completed' ? (
                        <div className="step-icon" style={{ borderColor: '#28a745', backgroundColor: '#28a745', color: '#fff' }}><i className="pe-7s-check" /></div>
                      ) : (
                        <div className="step-icon"><i className="pe-7s-check" /></div>
                      )}
                    </div>
                    <h4 className="step-title mt-0">Completed</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
      <div className="bg-transparent chat-box1 open">
        <Button
          variant="primary"
          className="btn btn-info mt-1 py-3 px-3 shadow-none rounded-circle button-hover"
          onClick={handleClick}
        >
          <i style={{ fontSize: 'x-large', color: 'white' }} className="fa fa-comments" />
        </Button>
      </div>
    </>
  );
};

export default GetMyOrders;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const HashLink = styled.button`
  border: none; 
  background: transparent; 
  color: #4ce1b6;
  display: flex;
`;


const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;  
  }
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus, &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;

