import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { useState, useEffect } from "react";
import {
  getAllTokens,
  getToken,
  getUserInfo1,
  submitNewAccountRequest,
  swapOtcMarketPlacePackge,
  swapOverviewMarketPlacePackge,
  switchAccount,
} from "../../../utils/services/user.service";
import { Button } from "@/shared/components/Button";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import { Field, Form } from "react-final-form";
import { CustomSelect } from "../../../shared/components/CustomSelect";
import { useChainContext } from "../../context/context";

const SwitchUserModal = ({ show, hide, reFetch, requestUserId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState(null);
  const { refreshUserData, setRefreshUserData } = useChainContext();


  const handleSwitchAccount = async () => {
    setIsLoading(true);
    try {
      const res = await switchAccount({
        password: password,
        userId: requestUserId,
      });

     localStorage.setItem("user", JSON.stringify(res?.data));
      await getUserInfo1({ email: res?.data?.data?.user?.email });
      toast.success("Account Switched Successfully");
      reFetch();
      setRefreshUserData(!refreshUserData)
      hide();
      setIsLoading(false);
    } catch (error) {
      toast.error(error?.response?.data.Message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 99999,
        }}
      >
        <Ring size={65} color="#00897B" />

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={hide}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <>
              {/* admin detail section  */}
              <div
                className="container mt-0 containerDiv pt-0"
                style={{ overflowX: "hidden" }}
              >
                <div className="row mt-2 mb-1">
                  <p
                    style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                    className="py-0"
                  >
                    <b> Confirm you Login Password</b>
                  </p>
                </div>
                <div
                  className="row mx-1 py-2 gap-2 "
                  style={{ border: "2px solid #7b7b7b", borderRadius: "5px" }}
                >
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <InputDiv>
                      <label htmlFor="email" className="text-black mb-3">
                        Confirm Password *
                      </label>
                      <ChatSearchInput
                        name="amount"
                        type="password"
                        placeholder="Enter you login password"
                        value={password}
                        onChange={(value) => {
                          setPassword(value.target.value);
                        }}
                        className={`form-control w-100 `}
                      />
                    </InputDiv>
                  </div>
                </div>

                <div className="md:flex">
                  <FormButton
                    variant="outline-success"
                    className="py-2 mt-4 btn-sm"
                    onClick={hide}
                  >
                    Cancel
                  </FormButton>
                  <FormButton
                    variant="contain-success"
                    className="py-2 mt-4 btn-sm"
                    onClick={handleSwitchAccount}
                    disabled={!password}
                  >
                    Submit
                  </FormButton>
                </div>
              </div>
            </>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export default SwitchUserModal;

const Label = styled.label`
  @media screen and (max-width: 876px) {
    margin-bottom: 0px;
    margin-top: 8px;
  }
`;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
