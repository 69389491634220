import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ProfileMain from './components/ProfileMain';
import ProfileCalendar from './components/ProfileCalendar';
import ProfileTasks from './components/ProfileTasks';
import ProfileTabs from './components/ProfileTabs';

const Calendar = () => (
  <Container >
    <ProfileMain />
    <Col md={12} >
      <Row>
        <ProfileTasks />
        <ProfileTabs  />
      </Row>
    </Col>
    <Row>

      <ProfileCalendar />
    </Row>

  </Container>
);

export default Calendar;
