/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Ring } from "@uiball/loaders";
import styled from "styled-components";

import { colorAccent } from "@/utils/palette";

import StrigaDetailPage from "../../../Dashboards/Swap/StrigaDetailPage";
import {
  getAllWallets,
  getUser,
  getUserInfo,
} from "../../../../utils/services/user.service";

const StrigaDetails = ({ onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [strigaWalletDetail, setStrigaWalletDetail] = useState(null);
  const [strigaUserData, setStrigaUserData] = useState(null);

  const [userinfo, setUserInfo] = useState(null);
  const fetchStrigaUser = async () => {
    setIsLoading(true);
    try {
      const res = await getUser();
      setStrigaUserData(res?.data);
      setIsLoading(false);
    } catch (error) {

      setIsLoading(false);
    }
  };
  const fetchUserDetail = async () => {
    setIsLoading(true);
    try {
      const res = await getUserInfo();
      setUserInfo(res?.data?.User);
      setIsLoading(false);
    } catch (error) {

      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStrigaUser();
    fetchUserDetail()
  }, []);
  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <StrigaDetailPage strigaUser={strigaUserData} userinfo={userinfo} />
    </>
  );
};

StrigaDetails.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default StrigaDetails;

const ErrorMsg = styled.div`
  color: #e66e6e;
  padding: 2px 0px;
  font-size: 12px;
  cursor: none;
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;

export const ModalDiv = styled.div`
  height: 16rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TwoFactorTitle = styled.h4`
  padding: 5px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: ${colorAccent};
`;

export const TwoFactorSubTitle = styled.h6`
  padding: 5px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1rem;
`;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const Error = styled.div`
  color: #e66e6e;
  padding: 2px 0px;
  font-size: 12px;
  cursor: none;
`;

const ModalBody = styled.div``;
