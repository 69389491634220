/* eslint-disable no-restricted-globals */
/* eslint-disable use-isnan */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Web3 from 'web3';
import {
  Col, Spinner, Container, Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Switch, Stack } from '@mui/material';
import { Legend, Pie, Tooltip } from 'recharts';
import { fetchAllCurrencies } from '@/redux/actions/FetchCoinList';
import Panel from '@/shared/components/Panel';
import getTooltipStyles from '@/shared/helpers';
import useMatchMedia from '@/shared/hooks/useMatchMedia';
import {
  colorAdditional, colorDarkText, scrollbarStyles, colorAccent,
} from '@/utils/palette';
import {
  Bullet,
  DashboardChartLegendTable,
  DashboardPieChartContainer,
  DashboardPieChartFlex,
} from '../../BasicDashboardComponents';

const tooltipFormatter = (value, name, entry) => {

  return [
    <span key="custom-label">{`${entry?.payload?.symbol
      } : ${entry?.payload?.graphVal}`}</span>,
    // <span key="custom-value">{entry?.payload?.graphVal}</span>,
  ];
};
const Coins1 = ({ dir }) => {
  const { t } = useTranslation('common');
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });

  const themeName = useSelector(state => state.theme.className);
  const [isLoading, setIsLoading] = useState(false);
  const isMdBreakpoint = useMatchMedia(1199);
  const [twoFactors2, setTwoFactor2] = useState(false);

  const [isLoading1, setIsLoading1] = useState(true);
  const [filterData, setFilterData] = useState([])
  const [refresh, setRefresh] = useState(true)

  const [data, setData] = useState([])
  const {
    coinList,
  } = useSelector(state => ({
    coinList: state.coinList.data,
  }));
  const dispatch = useDispatch();
  const optionColors = [
    { value: '#f79413', label: 'Red' },
    { value: '#939396', label: 'Blue' },
    { value: '#ffffff', label: 'Green' },
    { value: '#e6e00b', label: 'Yellow' },
    { value: '#7e45dd', label: 'Purple' },
    { value: '#8d9fcb', label: 'White' },
    { value: '#154190', label: 'Gold' },
    { value: '#2584cd', label: 'Gold' },
    { value: '#F6DA6E', label: 'Gold' },
    { value: '#70BBFD', label: 'Gold' },
    { value: '#76767a', label: 'Gold' },
    { value: '#a832ce', label: 'Gold' },
    { value: '#76767a', label: 'Gold' },
    { value: '#7e45dd', label: 'Gold' },
    { value: '#a832ce', label: 'Gold' },

  ];

  // useEffect to  create array for graph data from colorOptions and CoinList
  useEffect(() => {
    const res = filterData?.map((item, index) => {
      const graphVal = parseFloat(item?.quantity) || 0; // Convert quantity to a number, default to 0 if not a valid number
      const fill = optionColors[index]?.value || '#ffff'; // Assuming optionColors is the array from your previous question
      const symbol = item.symbol; // Include the symbol property
      return { fill, graphVal, symbol };
    });
    setData(res)
  }, [filterData])

  const storedValue = localStorage.getItem('metaMaskVal');

  const getContractDetails = () => {

  };
  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    dispatch(fetchAllCurrencies(storedValue))
      .then(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (isMounted) {
          setIsLoading(false);
        }
      });
    return () => {
      isMounted = false;
    };
  }, [dispatch, refresh]);


  useEffect(() => {
    setFilterData(coinList)
  }, [coinList])

  const newArray = [];

  useEffect(() => {
    if (coinList.length > 0) {
      setIsLoading1(false)
    }
  }, [coinList])


  const onMouseMove = (e) => {
    if (e.tooltipPosition) {
      setCoordinates({ x: dir === 'ltr' ? e.tooltipPosition.x : e.tooltipPosition.x / 10, y: e.tooltipPosition.y });
    }
  };

  const data2 = [
    {
      fill: "#f79413",
      graphVal: 30, // Adjusted graphVal for demonstration
      symbol: "USDT (BEP20)",
    },
    {
      fill: "#939396",
      graphVal: 30, // Adjusted graphVal for demonstration
      symbol: "CTY",
    },
  ];
  const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: colorAccent,
      '&:hover': {
        backgroundColor: '#4ce1b65e',
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: colorAccent,
    },
  }));

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const handleTwoFactorChange = async (e) => {
    const value = e.target.checked;
    if (value) {
      // Filter out coins with quantity greater than 0
      const filteredCoinList = coinList.filter((coin) => Number(coin.quantity) > 0);
      const resGraphValues = data.filter((data) => data.graphVal > 0);
      setFilterData(filteredCoinList);
      setData(resGraphValues)
      setTwoFactor2(true);
    } else {
      // Set the entire data
      setFilterData(coinList);
      setData(data)
      setTwoFactor2(false);
    }
  };


  return (
    <Panel lg={12} xxl={9} title={t('wallet.coins')} isLoading={isLoading} refreshRequest={() => setRefresh(!refresh)}>
      {isLoading1 ? <Spinner style={{ color: colorAccent }} />
        : (
          <>
            <Row>
              <Col xs={12} sm={12} md={4} lg={4}>
                <Stack direction="row" spacing={1}>
                  <CustomSwitch
                    {...label}
                    value={twoFactors2}
                    checked={twoFactors2}
                    onChange={(e) => {
                      handleTwoFactorChange(e);
                    }}
                    color="default"
                  />
                  <div className="mx-auto text-white mt-1">Hide Zero Balance</div>
                </Stack>
              </Col>
            </Row>
            <div className='d-flex d-xl-none w-100 align-items-center justify-content-center  ' >
              <DashboardCoinsChartContainer width="90%">
                <DashboardPieChartFlex>
                  <Tooltip position={coordinates} {...getTooltipStyles(themeName)} formatter={tooltipFormatter} />
                  <Pie
                    data={data}
                    dataKey="graphVal"
                    cy={210}
                    innerRadius={40}
                    outerRadius={60}

                    label
                    onMouseMove={onMouseMove}
                    minAngle={20}
                    width={'50%'}
                  />


                </DashboardPieChartFlex>
              </DashboardCoinsChartContainer>
            </div>
            <div dir="ltr">
              <DashboardCoinsChartContainer width="100%">
                <DashboardPieChartFlex>
                  <Tooltip position={coordinates} {...getTooltipStyles(themeName)} formatter={tooltipFormatter} />
                  <Pie
                    data={data2}
                    dataKey="graphVal"
                    cy={230}
                    innerRadius={80}
                    outerRadius={100}
                    className='d-none d-xl-block' // Apply responsive classes here

                    label
                    onMouseMove={onMouseMove}
                  />

                  <Legend
                    layout="vertical"
                    verticalAlign={isMdBreakpoint ? 'bottom' : 'top'}
                    content={
                      <DashboardChartLegendTable>
                        <LegendCol>
                          <LegendColTitle>Coin List</LegendColTitle>
                          <LegendColContent>
                            {filterData.map((entry, index) => (
                              <li
                                key={index}
                                style={{
                                  width: "max-content",
                                  display: 'flex',
                                  flexWrap: 'nowrap',
                                  alignItems: 'center'
                                }}
                              >

                                <Bullet color={optionColors[index]?.value} />

                                {entry?.symbol}
                              </li>
                              //  )
                            ))}

                          </LegendColContent>
                        </LegendCol>
                        <LegendCol>
                          <LegendColTitle>Quantity</LegendColTitle>
                          <LegendColContent>
                            {filterData?.map(entry => (
                              <li
                                // key={5}
                                style={{
                                  width: "max-content",
                                  display: 'flex',
                                  flexWrap: 'nowrap',
                                  alignItems: 'center'
                                }}
                              >
                                {entry?.quantity || 0.00}
                              </li>
                            ))}

                          </LegendColContent>
                        </LegendCol>

                        <LegendCol>
                          <LegendColTitle>Balance</LegendColTitle>
                          <LegendColContent>
                            {filterData.map(entry => (
                              <li style={{
                                width: "max-content",
                                display: 'flex',
                                flexWrap: 'nowrap',
                                alignItems: 'center'
                              }}>
                                {entry?.balance || 0.00}
                              </li>
                            ))}

                          </LegendColContent>
                        </LegendCol>

                      </DashboardChartLegendTable>   
                    }
                    align={isMdBreakpoint ? 'center' : 'right'}
                  />
                </DashboardPieChartFlex>
              </DashboardCoinsChartContainer>
            </div>
          </>
        )}

    </Panel>
  );
};

Coins1.propTypes = {
  dir: PropTypes.string.isRequired,
};

export default Coins1;

// region

const DashboardCoinsChartContainer = styled(DashboardPieChartContainer)`
  min-height: 360px !important;
  height: 100% !important;
  
  svg {
    min-height: 360px;
    position: absolute;
    left: 0;
    top: 0;
    
    @media(max-width: 1640px) {
      max-width: 90%;
    }
    
    @media(max-width: 1199px) {
      max-width: 100%;
    }
    
    @media(max-width: 768px) {
      height: 80% !important;
    }
    
    @media(max-width: 400px) {
      height: 60% !important;
    }

    .recharts-pie {
      transform: translateX(-60px);
      
      @media(max-width: 1919px) {
        transform: translateX(0);
      }
    }
  }
  
  .recharts-legend-wrapper {
    position: relative !important;
    max-width: 55%;
    width: 100% !important;
    z-index: 5;

    @media(max-width: 1919px) {
      max-width: 50%;
    }
    @media(max-width: 1199px) {
      max-width: 100%;
    }
    @media(max-width: 768px) {
      overflow-x: auto;
      
      ${scrollbarStyles};
    }
  }
`;

const LegendCol = styled('div')`
  flex-shrink: 2;
  flex-grow: 0;

  :first-child {
    flex-shrink: 0;
    flex-basis: 100px;
  }

  :last-child {
    flex-grow: 1;
  }
`;

const LegendColTitle = styled('div')`
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.1;
  color: ${colorDarkText};
`;

const LegendColContent = styled('ul')`
  padding: 0;
  list-style: none;
  color: ${colorDarkText};

  li {
    margin-top: 15px;
    font-size: 14px;
  }
`;

const LegendSubtext = styled('span')`
  color: ${colorAdditional};
`;

// endregion
