import React from 'react';

const ProductSearch = ({ searchInput, setSearchInput, handleInputChange }) => (
  <div>
    <input
      type="text"
      className="form-control"
      placeholder="Search for products..."
      value={searchInput}
      onChange={e => handleInputChange(e.target.value)}
    />
  </div>
  );

export default ProductSearch;
