/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { Field, Formik } from 'formik';
import styled from 'styled-components';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import {
  configureChains, createConfig, WagmiConfig,
} from 'wagmi';
import { arbitrum, mainnet, polygon } from 'wagmi/chains';
import { Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormControlLabel, FormControl, RadioGroup, Radio, FormLabel,
} from '@mui/material';
import config from '@/config/walletConnect';
import Panel from '@/shared/components/Panel';
import { CustomSelect } from '../../../../shared/components/CustomSelect';
import Web3ButtonPage from './W3ButtonPage';
import WalletResponse from './WalletResponse';
import { clearMsg } from '../../../../redux/actions/authActions';
import ContractForm from './ContractForm';
import { colorAccent } from '../../../../utils/palette';
import CreateStore from '../../../../store/Layout/createstore/CreateStore';
import ProductInvoice from '../../../../store/Layout/product/ProductInvoice';

const chains = [arbitrum, mainnet, polygon];
const {
  projectId, REACT_APP_ETHERIUM_CHAIN, REACT_APP_SMART_CHAIN, REACT_APP_POLYGON_MATIC,
} = config;
const optionChains = [
  { value: "BNB", label: "Smart chain" },
  { value: 'ETH', label: 'Etherium' },
  { value: 'MATIC', label: 'Polygon' },
  { value: 'BTC', label: 'BTC' },
  { value: 'SOL', label: 'Solana' },
];
const optionChainsCheckout = [
  { value: REACT_APP_SMART_CHAIN, label: 'Smart chain' },
];
const optionChainsStore = [
  { value: REACT_APP_SMART_CHAIN, label: 'Smart chain' },
  // { value: REACT_APP_SMART_CHAIN, label: 'Smart chain' },
  // { value: REACT_APP_POLYGON_MATIC, label: 'Polygon matic' },
];
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);
const MetaMaskForm = (props) => {
  // console.log(props, '->>>>>>>>>>>>>>>>>>>>>');
  const { message } = useSelector(state => state.message);
  const [optionChain, setOptionChain] = useState(optionChains);
  const dispatch = useDispatch();
  const [statusCode, setstatusCode] = useState(400);
  const [selectedValue, setSelectedValue] = useState('internal');
  const [ChainValue, setChainValue] = useState(null);
  const [showTokenDropdown, setShowTokenDropdown] = useState(false);
  const [selectedToken, setSelectedToken] = useState("USDT");
  const [selectedChain, setSelectedChain] = useState("BNB");
  const [chainDetails, setChainDetails] = useState({
    ETH: { chainId: Number(REACT_APP_ETHERIUM_CHAIN), symbol: "USDT" },
    BNB: { chainId: Number(REACT_APP_SMART_CHAIN), symbol: "USDT" },
    MATIC: { chainId: Number(REACT_APP_POLYGON_MATIC), symbol: "USDT" },
    // BTC: { chainId: 'BTC', symbol: 'USDT' },
  });
  // console.log(ChainValue, '-------chain value');
  useEffect(() => {
    if (props.accessType === 'cart') {
      setOptionChain(optionChainsCheckout);
    }
    if (props.from === 'store') {
      setOptionChain(optionChainsStore);
    }
  }, []);
  useEffect(() => {
    // console.log('set wallet');
    const fetchChainId = async () => {
      const web3 = new Web3(window.ethereum);
      const connection = await web3.eth.getChainId();
      const chainId = Number(connection);
      // console.log('test chain---------------', chainId.toString(), window.ethereum);
      setChainValue(chainId);
    };
    if (window.ethereum && window.ethereum.providers?.length) {
      fetchChainId();
    } else {
      // console.log('set wallet');
      setChainValue(REACT_APP_SMART_CHAIN);
    }
  }, [selectedValue]);
  // if (window.ethereum) {
  //   fetchChainId();
  // } else {
  //   setSelectedValue(REACT_APP_SMART_CHAIN);
  // }
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const controlProps = item => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  const handleChainChange = (value) => {
    // console.log('Selected value:', value);
    if (value.value === "BNB") {
      setShowTokenDropdown(true);
    } else {
      setShowTokenDropdown(false);
      setSelectedToken("USDT");
      setChainDetails((prevDetails) => ({
        ...prevDetails,
        BNB: { ...prevDetails.BNB, symbol: "USDT" }, // Reset BNB symbol when changing chain
      }));
    }
    setSelectedChain(value.value);
  };
  return (
    <>
      <div style={{
        width: '100%', padding: '5px',
      }}
      >
        <StyledAlert
          variant={statusCode === 200 ? 'success' : 'danger'}
          show={message !== undefined}
          style={{
            marginTop: '1rem', width: '100%',
          }}
          onClose={() => {
            dispatch(clearMsg());
          }}
          dismissible
        >
          <b>{message}</b>
        </StyledAlert>
      </div>
      <Grid className="px-4">
        <FormControl>
          <FormLabel style={{ color: 'white' }}>Select Wallet</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={selectedValue}
            name="wallets"
          >
            <FormControlLabel
              style={{ color: 'white' }}
              value="internal"
              name="wallets"
              control={(
                <Radio
                  {...controlProps('internal')}
                  sx={{
                    color: 'white',
                    '&.Mui-checked': {
                      color: colorAccent,
                    },
                  }}
                />
              )}
              label="Virtual Wallet"
            />
            <FormControlLabel
              style={{ color: 'white' }}
              value="external"
              name="wallets"
              control={(
                <Radio
                  {...controlProps('external')}
                  sx={{
                    color: 'white',
                    '&.Mui-checked': {
                      color: colorAccent,
                    },
                  }}
                />
              )}
              label="External Wallet"
            />
          </RadioGroup>
        </FormControl>
        {selectedValue === 'external' && (
          <>
            <WagmiConfig config={wagmiConfig}>
              <Web3ButtonPage />
            </WagmiConfig>
          </>
        )}
      </Grid>
      {
        selectedValue === 'external' ? (
          <>
            <Web3Modal
              projectId={projectId}
              ethereumClient={ethereumClient}
              themeVariables={{
                '--w3m-accent-color': '#4CE1B6',
                '--w3m-z-index': 127,
                '--w3m-overlay-backdrop-filter': 'blur(6px)',
              }}
            />
            <WagmiConfig config={wagmiConfig}>
              <WalletResponse setstatusCode={setstatusCode} chain={ChainValue} selectedValue={selectedValue} />
              {
                props.from === 'store' && (
                  <CreateStore benficiaryToggle={selectedValue} chain={ChainValue} />
                )
              }
              {
                props.accessType === 'cart' && (
                  <ProductInvoice benficiaryToggle={selectedValue}  productList={props.productList} chain={ChainValue} />
                )
              }
              {
                props.accessType === 'contract' && (
                  <ContractForm benficiaryToggle={selectedValue} chain={ChainValue} />
                )
              }
            </WagmiConfig>
          </>
        )
          : (
            <WagmiConfig config={wagmiConfig}>
              {/* <WalletRespo nse setstatusCode={setstatusCode} /> */}
              {
                props.from === 'store' && (
                  <CreateStore />
                )
              }
              {
                props.accessType === 'cart' && (
                  <ProductInvoice productList={props.productList}/>
                )
              }
              {
                props.accessType === 'contract' && (
                  <ContractForm benficiaryToggle={selectedValue} chain={ChainValue} />
                )
              }
            </WagmiConfig>
          )
      }
    </>
  );
};
export default MetaMaskForm;
const getMainColor = (color) => {
  switch (color) {
    case 'success':
      return '#C1F9C1';
    case 'danger':
      return '#F1BEB0';
    default:
      return '#F1BEB0';
  }
};
const getLightColor = (color) => {
  switch (color) {
    case 'success':
      return '#22BB33';
    case 'danger':
      return '#FF4861';
    default:
      return '#F88E7D';
  }
};
const StyledAlert = styled(Alert).withConfig({
  shouldForwardProp: prop => !['bordered', 'neutral', 'colored'].includes(prop),
})`
  background-color: ${props => (getMainColor(props.variant))};
  border-color: ${props => (getMainColor(props.variant))};
  color: ${props => (getLightColor(props.variant))};
`;
const Grid = styled.div`
        display: grid;
        grid-template-columns: 20rem 33rem 50rem;
        width:100%;
        @media screen and (max-width: 876px) {
          grid-template: none;
          gap: 1rem;
  }
        `;
const DropdownDiv = styled.div`
        display: block;
        float: right;
        width: 25%;
        @media screen and (max-width: 876px) {
          width: 100%;
  }
        `;

