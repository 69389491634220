import { Card, Col } from "react-bootstrap";
import {
  DashboardBookingCard,
  DashboardBookingDescription,
  DashboardBookingTitle,
  DashboardBookingTotalWrap,
} from "../../../containers/Dashboards/Finance1/components/ProgessChart/BookingCardDashboardElements";
const HomeCard = ({ title, value }) => {
  return (
    <Col md={6} xl={3} lg={4} xs={12}>
      <DashboardBookingCard
        className={
          "d-flex flex-column justify-content-center align-items-center "
        }
      >
        <DashboardBookingTotalWrap>
          <DashboardBookingTitle color={"#fffff"}>
            <h3 className="d-flex text-center  p-2 text-nowrap w-100    " style={{color:'#2584CD'}}>
           <b>{value}</b>
            </h3>
          </DashboardBookingTitle>
        </DashboardBookingTotalWrap>
        <DashboardBookingDescription>
          <h5 className="d-flex text-white text-center  p-2 text-nowrap w-100    ">
            <b>{title}</b>
          </h5>
        </DashboardBookingDescription>
      </DashboardBookingCard>
    </Col>
  );
};
export default HomeCard;
