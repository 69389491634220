import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import TopbarProfile from '../components/topbar/TopbarProfile';
import { Button } from '../../../shared/components/Button';
import { useCart } from '../../../store/Layout/product/CartContext';
import TopbarSidebarButton from '../components/topbar/TopbarSidebarButton';
import {
  TopbarContainer,
  TopbarLeft,
  TopbarLogo,
  TopbarRight,
  TopbarRightOver,
  TopbarButton,
} from '../components/topbar/BasicTopbarComponents';
import TopbarWallet from '../components/topbar/TopbarWallet';
import Logo from '../../../utils/img/custodiy.png';
import './topbar.css';

const Topbar = ({
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
}) => {
  const { cartCount, incrementCartCount, decrementCartCount } = useCart();
  const { isLoggedIn } = useSelector(state => state.user);
  const currentPath = window?.location?.pathname;


  return (
    <TopbarContainer className="px-3  w-100  ">
      <TopbarLeft className='d-flex  justify-content-between '>
        <TopbarSidebarButton
          onClickMobile={changeMobileSidebarVisibility}
          onClickDesktop={changeSidebarVisibility}
        />
        <TopbarLogo src={Logo} />

      </TopbarLeft>

      <TopbarRight>
        <TopbarRightOver className="px-3">
          <Link to="/showcart" className="cicon">
            {/* <FontAwesomeIcon className="mt-3" icon={faShoppingCart} size="2x" /> */}
            {isLoggedIn && (
              <TopbarButton type="button" className="mx-0 p-0">
                {/* <NotificationsIcon style={{ width: '24px', height: '24px' }} /> */}
                <FontAwesomeIcon
                  className="mr-0 icon-hover"
                  icon={faShoppingCart}
                  style={{
                    width: '24px',
                    height: '24px',
                    marginTop: '32%',
                    transition: 'color 0.3s',
                  }}
                />
                {cartCount > 0 && (
                  <h5 className="leading-9 mt-2 ml-1 mr-0">
                    <Badge bg="danger rounded-md">{cartCount}</Badge>
                  </h5>
                )}
              </TopbarButton>
            )}
          </Link>
        </TopbarRightOver>
        {currentPath != '/' && <Link to="/login">
          <button style={{ marginTop: '12%' }} type="button" className="btn btn-outline-info px-4">Login</button>
        </Link>}
      </TopbarRight>
    </TopbarContainer>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;

const FormButton = styled(Button)`
  color: white;
  margin-top: 1rem;
`;
