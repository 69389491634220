import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Spinner, Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Switch, Stack } from '@mui/material';
import { Legend, Pie, Tooltip } from 'recharts';
import { fetchAllCurrencies1 } from '@/redux/actions/FetchCoinLists';
import Panel from '@/shared/components/Panel';
import getTooltipStyles from '@/shared/helpers';
import useMatchMedia from '@/shared/hooks/useMatchMedia';
import { Modal as BootstrapModal, Modal } from "react-bootstrap";
import { Button } from "@/shared/components/Button";
import {
  colorAdditional, colorDarkText, scrollbarStyles, colorAccent,
} from '@/utils/palette';
import {
  Bullet,
  DashboardChartLegendTable,
  DashboardPieChartContainer,
  DashboardPieChartFlex,
} from '../../BasicDashboardComponents';
import { getUserLockBalanceWebapp, getUserPendingBalanceWebapp, userInternalWalletBalanceRefresh } from '../../../../utils/services/user.service';
import { toast } from 'react-toastify';
import { useChainContext } from '../../../context/context';
import numeral from 'numeral';
import { Ring } from '@uiball/loaders';
import Alert from '../../../../shared/components/Alert';
import { optionColors } from '../../../../utils/constants/contants';


const tooltipFormatter = (value, name, entry) => {
  return [
    <span key="custom-label">{`${entry?.payload?.symbol
      } : ${numeral(entry.payload.graphVal).format('0,0.0000')}`}</span>
  ];
};
const Coins = ({ dir }) => {
  const { t } = useTranslation('common');
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const { refreshInternalBalace } = useChainContext()
  const themeName = useSelector(state => state.theme.className);
  const [isLoading, setIsLoading] = useState(false);
  const isMdBreakpoint = useMatchMedia(1199);
  const [twoFactors2, setTwoFactor2] = useState(false);
  const [pendingBalance, setPendingBalance] = useState([]);

  const [otcLockInfo, setOtcLockBalance] = useState([]);
  const [isLoading1, setIsLoading1] = useState(true);
  const [filterData, setFilterData] = useState([])
  const [data, setData] = useState([])
  const [show1, setShow1] = useState(false);
  const [reFetch, setRefetch] = useState(false);
  const handleClose1 = () => setShow1(false);
  const { reFethData, setReFetchData } = useChainContext()


  const {
    coinList,
  } = useSelector(state => ({
    coinList: state.coinList1.data,
  }));
  const dispatch = useDispatch();


  // useEffect to  create array for graph data from colorOptions and CoinList
  useEffect(() => {
    const res = filterData?.map((item, index) => {
      const newVal = Number(item?.quantity);
      const priceValue = newVal.toFixed(2) || 0;
      const graphVal = parseFloat(priceValue);
      const fill = optionColors[index]?.value || '#ffff';
      const symbol = item.symbol;
      return { fill, graphVal, symbol };
    });
    setData(res);
  }, [filterData]);

  const refreshStatus = async () => {
    setIsLoading(true)
    try {
      const res = await userInternalWalletBalanceRefresh();
      setIsLoading(false)
      return res?.data?.Message
    } catch (error) {

      setIsLoading(false)
      return error.response.data
    }

  };

  useEffect(()=>{
    userInternalWalletBalanceRefresh()
  },[reFethData])

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(fetchAllCurrencies1())
      .then(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (isMounted) {
          setIsLoading(false);
        }
      });
    return () => {
      isMounted = false;
    };
  }, [dispatch]);


  useEffect(() => {
    setFilterData(coinList)
  }, [coinList])


  useEffect(() => {
    if (coinList.length > 0) {
      setIsLoading1(false)
    }
  }, [coinList])


  const onMouseMove = (e) => {
    if (e.tooltipPosition) {
      setCoordinates({ x: dir === 'ltr' ? e.tooltipPosition.x : e.tooltipPosition.x / 10, y: e.tooltipPosition.y });
    }
  };

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: colorAccent,
      '&:hover': {
        backgroundColor: '#4ce1b65e',
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: colorAccent,
    },
  }));

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const handleTwoFactorChange = async (e) => {
    const value = e.target.checked;
    if (value) {
      // Filter out coins with quantity greater than 0 in coinList or pendingBalance
      const filteredCoinList = coinList.filter((coin) => {
        const pendingCoin = pendingBalance.find((balance) => balance.symbol === coin.symbol);
        return Number(coin.quantity) > 0 || (pendingCoin && Number(pendingCoin.quantity) > 0);
      });
  
      const resGraphValues = data.filter((data) => data.graphVal > 0);
      
      setFilterData(filteredCoinList);
      setData(resGraphValues);
      setTwoFactor2(true);
    } else {
      // Set the entire data
      setFilterData(coinList);
      setData(data);
      setTwoFactor2(false);
    }
  };
  
  const handleRefresh = async () => {
    setIsLoading(true)
    setRefetch(true)
    const res = await refreshStatus();
    if (res.status == 200) {
      setIsLoading(true)
      dispatch(fetchAllCurrencies1());
      setIsLoading(false)
    } else if (res.status == 404) {
      toast.error(res.Message)
    }
    setIsLoading(false)
  };
  const handleRefreshUpcoming = async () => {
    try {
      await refreshStatus()
    } catch (error) {

    }
  };

  useState(() => {
    if (refreshInternalBalace === true) {
      handleRefresh()
    }
  }, [refreshInternalBalace])

  const fetchBalanceInfo = async () => {
    setIsLoading(true);
    const res = await getUserPendingBalanceWebapp();
    setPendingBalance(res?.data?.data);
    setIsLoading(false);
  };
  const fetchOtcLockInfo = async () => {
    setIsLoading(true);
    const res = await getUserLockBalanceWebapp();
    setOtcLockBalance(res?.data?.data);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchBalanceInfo();
    fetchOtcLockInfo()
  }, [reFetch]);
  return (
    <Panel lg={12} xxl={9} title={t('wallet.coins')} isLoading={isLoading} >

      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.6)",
          zIndex: 999000,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      {isLoading1 ? <Spinner style={{ color: colorAccent }} />
        : (
          <>
            <Row>
              <Col xs={12} sm={12} md={4} lg={4}>
                <Stack direction="row" spacing={1}>
                  <CustomSwitch
                    {...label}
                    value={twoFactors2}
                    checked={twoFactors2}
                    onChange={(e) => {
                      handleTwoFactorChange(e);
                    }}
                    color="default"
                  />
                  <div className="mx-auto text-white mt-1">Hide Zero Balance</div>
                </Stack>
              </Col>
            </Row>
            <div className='d-flex d-xl-none w-100 align-items-center justify-content-center  ' >
              <DashboardCoinsChartContainer width="90%">
                <DashboardPieChartFlex>
                  <Tooltip position={coordinates} {...getTooltipStyles(themeName)} formatter={tooltipFormatter} />
                  <Pie
                    data={data}
                    dataKey="graphVal"
                    cy={210}
                    innerRadius={40}
                    outerRadius={60}
                    label
                    onMouseMove={onMouseMove}
                    minAngle={20}
                    width={'50%'}
                  />


                </DashboardPieChartFlex>
              </DashboardCoinsChartContainer>
            </div>
            <div dir="ltr" >
              <DashboardCoinsChartContainer width="100%">
                <DashboardPieChartFlex>
                  <Tooltip position={coordinates} {...getTooltipStyles(themeName)} formatter={tooltipFormatter} />
                  <Pie
                    data={data}
                    dataKey="graphVal"
                    cy={230}
                    innerRadius={80}
                    outerRadius={100}
                    className='d-none d-xl-block' // Apply responsive classes here
                    label
                    onMouseMove={onMouseMove}
                    minAngle={20}
                    width={'50%'}
                  />

                  <Legend
                    layout="vertical"
                    verticalAlign={isMdBreakpoint ? 'bottom' : 'top'}
                    content={
                      <DashboardChartLegendTable>
                        <LegendCol>
                          <LegendColTitle>Coin List</LegendColTitle>
                          <LegendColContent>
                            {filterData.map((entry, index) => (
                              <li
                                key={index}
                                style={{
                                  width: "max-content",
                                  display: 'flex',
                                  flexWrap: 'nowrap',
                                  alignItems: 'center'
                                }}
                              >

                                <Bullet color={optionColors[index]?.value} />

                                {entry?.symbol}
                              </li>
                              //  )
                            ))}

                          </LegendColContent>
                        </LegendCol>
                        <LegendCol>
                          <LegendColTitle>Quantity</LegendColTitle>
                          <LegendColContent>
                            {filterData?.map((entry,idx) => (
                              <li key={idx} style={{
                                width: "max-content",
                                display: 'flex',
                                flexWrap: 'nowrap',
                                alignItems: 'center'
                              }}> {/* Assuming entry has an id */}
                                {numeral(entry.quantity).format('0,0.00000')}
                              </li>
                            ))}

                          </LegendColContent>
                        </LegendCol>

                        <LegendCol>
                          <LegendColTitle>Balance</LegendColTitle>
                          <LegendColContent>
                            {filterData.map((entry,idx) => (
                              <li 
                              key={idx}
                              style={{
                                width: "max-content",
                                display: 'flex',
                                flexWrap: 'nowrap',
                                alignItems: 'center'
                              }}>
                                {numeral(entry.balance).format('0,0.0000')}
                              </li>
                            ))}

                          </LegendColContent>
                        </LegendCol>
                        <LegendCol>
                          <LegendColTitle>
                            <span className='d-flex gap-2' style={{
                              cursor: 'pointer'
                            }}>
                              <p className='d-flex gap-2' style={{
                                color: 'yellow',
                                cursor: 'pointer'
                              }} onClick={() => setShow1(true)}>
                                Upcoming
                              </p>
                              <i className="fa fa-refresh" aria-hidden="true" onClick={handleRefreshUpcoming}></i>
                            </span>
                          </LegendColTitle>
                          <LegendColContent>
                            {filterData.map(({ symbol, quantity ,idx}) => {
                              const pendingCoin = pendingBalance.find(coin => coin.symbol == symbol);
                              const quantityToShow = pendingCoin ? numeral(pendingCoin.quantity).format('0,0.0000') : '--';

                              return (
                                <li key={idx} style={{
                                  width: "max-content",
                                  display: 'flex',
                                  flexWrap: 'nowrap',
                                  alignItems: 'center'
                                }}>

                                  {quantityToShow}
                                </li>
                              );
                            })}

                          </LegendColContent>
                        </LegendCol>
                        <LegendCol>
                          <LegendColTitle>
                            <p className='d-flex gap-1 '
                            >
                              OTC
                              <i className="fa fa-lock" aria-hidden="true"></i>


                            </p>
                          </LegendColTitle>
                          <LegendColContent>
                            {filterData.map(({ symbol, quantity,idx }) => {
                              const pendingCoin = otcLockInfo.find(coin => coin.symbol === symbol && coin.quantity !== 0);
                              const quantityToShow = pendingCoin ? numeral(pendingCoin.quantity).format('0,0.00000') : '--';

                              return (
                                <li key={idx} style={{
                                  width: "max-content",
                                  display: 'flex',
                                  flexWrap: 'nowrap',
                                  alignItems: 'center'
                                }}>

                                  {quantityToShow}
                                </li>
                              );
                            })}

                          </LegendColContent>
                        </LegendCol>

                      </DashboardChartLegendTable>
                    }
                    align={isMdBreakpoint ? 'center' : 'right'}
                  />
                </DashboardPieChartFlex>
              </DashboardCoinsChartContainer>
            </div>
          </>
        )}


      <div className='mt-4'>
        <Alert color="info" neutral icon>
          <p> Use this wallet for:  hold your coins, OTC, Marktplace and all custody functions
          </p>
        </Alert>
      </div>

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        show={show1}
        onHide={handleClose1}
      >
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body>
          <div className="container  mb-1 p-0 d-flex flex-column ">

            <div className='d-flex flex-column gap-2  justify-content-center align-items-center text-center  '>
              <strong className='d-flex text-center '>Attention!</strong>

              <p className='d-flex text-black  w-75'>The upcoming balance is currently in progress and will be transferred within the next 24 hours.</p>
            </div>


            <div
              className="card mt-0"
              style={{ boxShadow: "none", fontFamily: "sans-serif" }}
            >
              <div className="card-body">
                <div className="row">

                  <div className="col-md-12">

                    <Button
                      onClick={handleClose1}
                      // disabled={load}
                      size="md"
                      className="py-2 btn-block mt-2"
                      variant="outline-success"
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </Panel>
  );
};

Coins.propTypes = {
  dir: PropTypes.string.isRequired,
};

export default Coins;

// region

const DashboardCoinsChartContainer = styled(DashboardPieChartContainer)`
  min-height: 360px !important;
  height: 100% !important;
  
  svg {
    min-height: 360px;
    position: absolute;
    left: 0;
    top: 0;
    
    @media(max-width: 1640px) {
      max-width: 90%;
    }
    
    @media(max-width: 1199px) {
      max-width: 100%;
    }
    
    @media(max-width: 768px) {
      height: 80% !important;
    }
    
    @media(max-width: 400px) {
      height: 60% !important;
    }

    .recharts-pie {
      transform: translateX(-60px);
      
      @media(max-width: 1919px) {
        transform: translateX(0);
      }
    }
  }
  
  .recharts-legend-wrapper {
    position: relative !important;
    max-width: 55%;
    width: 100% !important;
    z-index: 5;

    @media(max-width: 1919px) {
      max-width: 50%;
    }
    @media(max-width: 1199px) {
      max-width: 100%;
    }
    @media(max-width: 768px) {
      overflow-x: auto;
      
      ${scrollbarStyles};
    }
  }
`;

const LegendCol = styled('div')`
  flex-shrink: 2;
  flex-grow: 0;

  :first-child {
    flex-shrink: 0;
    flex-basis: 100px;
  }

  :last-child {
    flex-grow: 1;
  }
`;

const LegendColTitle = styled('div')`
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.1;
  color: ${colorDarkText};
`;

const LegendColContent = styled('ul')`
  padding: 0;
  list-style: none;
  color: ${colorDarkText};

  li {
    margin-top: 15px;
    font-size: 14px;
  }
`;

const LegendSubtext = styled('span')`
  color: ${colorAdditional};
`;