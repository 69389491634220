import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import { CRYPTOCURRENCIES } from "@/shared/constants/cryptocurrencies";
import ErrorAlert from "@/shared/components/ErrorAlert";
import { getWeekChartData } from "@/utils/helpers";
import { fetchQuotesBySymbol } from "@/redux/actions/quotesBySymbolActions";
import CryptoCell from "./CryptoCell";
import { colorRed, colorBlue, colorGreen, colorYellow } from "@/utils/palette";

import {
  // createMarketPlace,
  gettotalBalance,
  getcontractStats,
  getsafeBoxStats,
  gettotalAccounts,
  getstoreDetails,
  getotcMarketplaceDetails,
  // getWeekGraph,
} from "../../../../utils/services/user.service";
import Chart from "./FitnessChart/Chart";
import ProgressChart from "../components/ProgessChart/ProgressChart";
import numeral from "numeral";

const CRYPTOCURRENCIES_NAMES = {
  BTC: "Bitcoin",
  ETH: "Ethereum",
  DOGE: "Dogecoin",
  BCH: "Bitcoin-cash",
};

const CryptoRow = () => {
  const {
    quotes,
    rtl,
    cryptoHistory,
    errorQuotes,
    errorHistory,
    isHistoryLoading,
    isQuoteLoading,
  } = useSelector((state) => ({
    quotes: state.quotesBySymbol.data,
    errorQuotes: state.quotesBySymbol.error,
    isQuoteLoading: state.quotesBySymbol.isFetching,
    errorHistory: state.cryptoHistory.error,
    cryptoHistory: state.cryptoHistory.data.cryptoHistory,
    isHistoryLoading: state.cryptoHistory.isFetching,
    rtl: state.rtl,
  }));

  const dispatch = useDispatch();
  const [getGraphval, setGraphval] = useState("BTC");
  const [gettotalBalanceVal, settotalBalanceVal] = useState(0);
  const [totalBalancePercentage, setTotalBalancePercentage] = useState(0);
  const [getcontractStatsVal, setcontractStatsVal] = useState([]);
  const [getsafeBoxStatsVal, setsafeBoxStatsVal] = useState([]);
  const [getStoreDetail, setStoreDetail] = useState([]);
  const [getMarketplaceData, setMarketplaceData] = useState([]);
  const [gettotalAccountsVal, settotalAccountsVal] = useState("");


  useEffect(() => {
    const fetchtotalBalance = async () => {
      try {
        const response = await gettotalBalance();
        if (response.data) {
          const Data = response.data;
          setTotalBalancePercentage(Data.percentage);
          const formattedData = Data.result.totalBalance.toFixed(2);
          settotalBalanceVal(formattedData);
        } else {
          console.error("Error fetching gettotalBalance:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching gettotalBalance:", error);
      }
    };
    const fetchcontractStats = async () => {
      try {
        const response = await getcontractStats();
        if (response.data) {
          const Data = response.data;

          setcontractStatsVal(Data.result);
          // console.log('getcontractStats', Data.result);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchsafeBoxStats = async () => {
      try {
        const response = await getsafeBoxStats();
        if (response.data) {
          const Data = response.data;
          setsafeBoxStatsVal(Data.data);
          // console.log('getsafeBoxStats', Data.data);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchtotalAccounts = async () => {
      try {
        const response = await gettotalAccounts();
        if (response.data) {
          const Data = response.data;

          settotalAccountsVal(Data);
        } else {
          console.error("Error fetching getBTC:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchStoreDetail = async () => {
      try {
        const response = await getstoreDetails();
        setStoreDetail(response.data);
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    const fetchOtchMarketplace = async () => {
      try {
        const response = await getotcMarketplaceDetails();
        setMarketplaceData(response.data);
      } catch (error) {
        console.error("Error fetching getBTC:", error);
      }
    };
    fetchtotalBalance();
    fetchcontractStats();
    fetchsafeBoxStats();
    fetchtotalAccounts();
    fetchStoreDetail();
    fetchOtchMarketplace();
  }, []);

  useEffect(() => {
    dispatch(fetchQuotesBySymbol(CRYPTOCURRENCIES));
  }, [dispatch]);

  return (
    <>
      <Row className="mb-4">
        <ProgressChart
          color={colorBlue}
          percentage={totalBalancePercentage}
          val={(numeral(gettotalBalanceVal).format("0,0.00")) || 0}
          subtitle={"Total Amount"}
        />
        <ProgressChart
          color={colorBlue}
          val={gettotalAccountsVal?.result?.totalUsers || 0}
          percentage={gettotalAccountsVal.percentage}
          subtitle={"Total Users"}
        />
        <ProgressChart
          val={getcontractStatsVal.completed || 0}
          percentage={getcontractStatsVal.percentage_completed}
          title={"Contracts"}
          color={colorGreen}
          subtitle={"Complete Contracts"}
        />
        <ProgressChart
          val={getcontractStatsVal.declined || 0}
          title={"Contracts"}
          percentage={getcontractStatsVal.percentage_declined}
          color={colorRed}
          subtitle={"Declined Contracts"}
        />
        <ProgressChart
          val={getcontractStatsVal.waiting || 0}
          title={"Contracts"}
          color={colorYellow}
          percentage={getcontractStatsVal.percentage_waiting}
          subtitle={"Waiting Contracts"}
        />

        <ProgressChart
          val={getsafeBoxStatsVal.completed || 0}
          title={"Safeboxes"}
          percentage={getsafeBoxStatsVal.percentage_completed}
          color={colorGreen}
          subtitle={"Complete Safeboxes"}
        />
        <ProgressChart
          val={getsafeBoxStatsVal.rejected || 0}
          title={"Safeboxes"}
          percentage={getsafeBoxStatsVal.percentage_declined}
          color={colorRed}
          subtitle={"Rejected Safeboxes"}
        />
        <ProgressChart
          val={getsafeBoxStatsVal.waiting || 0}
          title={"Safeboxes"}
          percentage={getsafeBoxStatsVal.percentage_waiting}
          color={colorYellow}
          subtitle={"Waiting Safeboxes"}
        />

        <ProgressChart
          color={colorBlue}
          percentage={getStoreDetail.percentage_total}
          val={getStoreDetail?.totalStore || 0}
          subtitle={"Total Stores"}
        />
        <ProgressChart
          val={getStoreDetail?.pendingStore || 0}
          percentage={getStoreDetail.percentage_pending}
          title={"Stores"}
          color={colorYellow}
          subtitle={"Pending Stores"}
        />
        <ProgressChart
          val={getStoreDetail?.otherStore || 0}
          title={"Others"}
          percentage={getStoreDetail.percentage_other}
          color={colorYellow}
          subtitle={"others"}
        />
        <ProgressChart
          val={getStoreDetail?.activeStore || 0}
          title={"Others"}
          percentage={getStoreDetail.percentage_active}
          color={colorGreen}
          subtitle={"Active Stores"}
        />

        <ProgressChart
          val={getMarketplaceData.totalOtcMarketPlace || 0}
          percentage={getMarketplaceData.percentage_total}
          color={colorBlue}
          subtitle={"Total OTC Marketplace"}
        />
        <ProgressChart
          val={getMarketplaceData.activeOtcMarketPlace || 0}
          percentage={getMarketplaceData.percentage_active}
          color={colorGreen}
          subtitle={"Active OTC Marketplace"}
        />
        <ProgressChart
          val={getMarketplaceData.pauseOtcMarketPlace || 0}
          percentage={getMarketplaceData.percentage_pending}
          color={colorYellow}
          subtitle={"Paused OTC Marketplace"}
        />
        <ProgressChart
          val={getMarketplaceData.otherOtcMarketPlace || 0}
          percentage={getMarketplaceData.percentage_other}
          color={colorYellow}
          subtitle={"Other OTC Marketplace"}
        />
      </Row>
    </>
  );
};

export default CryptoRow;
