import React from 'react'
import { Modal } from 'react-bootstrap';

const ViewModal = ({ showEditModal, changeShowEditModal, selectedItem }) => {
    const description = selectedItem?.description || 'No description available';
    return (
        <Modal
            show={showEditModal}
            onHide={changeShowEditModal}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-center" style={{ fontSize: 'large' }}><b>DESCRIPTION: </b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container padding-bottom-3x p-0">
                    <div className="card mt-0 p-0" style={{ boxShadow: 'none', fontFamily: 'sans-serif' }}>
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-12 text-dark text-center">
                                    <p className="text-dark fs-5">
                                       {description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ViewModal
