import React, { useEffect, useMemo } from 'react';
import { Col } from 'react-bootstrap';
import Calendar from '@/shared/components/Calendar';
import { Card, CardBody } from '@/shared/components/Card';
import { fetchCalendarEvents } from '../../../DefaultPage/Calendar/components/events';
import { useDispatch, useSelector } from 'react-redux';

const ProfileCalendar = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchCalendarEvents())
  }, [])
  const
    { calendarEvents }
    = useSelector(state => ({
      calendarEvents: state.calendarEvents.data,
    }));
  const MyCalendar = useMemo(() => {
    return <Calendar events={calendarEvents} />;
  }, [calendarEvents]);
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          {MyCalendar}
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProfileCalendar;
