import { React, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import {
  configureChains, createConfig, WagmiConfig,
} from 'wagmi';
import { arbitrum, mainnet, polygon } from 'wagmi/chains';
import { Alert } from 'react-bootstrap';

import styled from 'styled-components';
import Panel from '@/shared/components/Panel';
import config from '@/config/walletConnect';
import Web3ButtonPage from './W3ButtonPage';
import WalletResponse from './WalletResponse';
import { clearMsg } from '../../../../redux/actions/authActions';

const chains = [arbitrum, mainnet, polygon];
const { projectId } = config;

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

const MetaMaskForm = () => {
  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();
  const [statusCode, setstatusCode] = useState(400);

  return (
    <>
      <div style={{
          width: '100%', padding: '5px',
        }}
      >
        <StyledAlert
          variant={statusCode === 200 ? 'success' : 'danger'}
          show={message !== undefined}
          style={{
                  marginTop: '1rem', width: '100%', 
                }}
          onClose={() => {
          dispatch(clearMsg());
        }}
          dismissible
        >
          <b>{message}</b>
        </StyledAlert>
      </div>
      <Panel lg={12} title="Connect Wallet" closeBtn minusBtn refreshLoad>
        <WagmiConfig config={wagmiConfig}>
          <Web3ButtonPage />
        </WagmiConfig>
        
        <Web3Modal
          projectId={projectId}
          ethereumClient={ethereumClient}
          themeVariables={{
              '--w3m-accent-color': '#4ce1b6',
              '--w3m-z-index': 127,
              '--w3m-overlay-backdrop-filter': 'blur(6px)',
            }}
        />
        <WagmiConfig config={wagmiConfig}>
          <WalletResponse setstatusCode={setstatusCode} />
        </WagmiConfig>
      </Panel>
    </>
  );
};

export default MetaMaskForm;
const getMainColor = (color) => {
  switch (color) {
    case 'success': 
      return '#c1f9c1';
    case 'danger': 
      return '#f1beb0';
      default:
        return '#f1beb0';
  }
};

const getLightColor = (color) => {
  switch (color) {
    case 'success': 
      return '#22bb33';
    case 'danger': 
      return '#ff4861';
    default:
      return '#f88e7d';
  }
};

const StyledAlert = styled(Alert).withConfig({
  shouldForwardProp: prop => !['bordered', 'neutral', 'colored'].includes(prop),
})`
  
  background-color: ${props => (getMainColor(props.variant))};
  border-color: ${props => (getMainColor(props.variant))};
  color: ${props => (getLightColor(props.variant))};
 
`;
