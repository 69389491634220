/* eslint-disable max-len */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";
import Panel from "@/shared/components/Panel";
import Tooltip from "@/shared/components/Tooltip";
import { getShortCryptoAddress } from "@/utils/helpers";
import {
  WalletInfoWrapper,
  WalletStatRowTitle,
  WalletStatRowValue,
  WalletStatRowWrapper,
} from "../styled";
import { SidebarLinkIcon } from "../../../Layout/components/sidebar/SidebarLink";
import {
  getAllWallets,
  getUserLockBalanceWebapp,
} from "../../../../utils/services/user.service";
import numeral from "numeral";

const OtcWAlletInfo = ({ dir, subWallet }) => {
  const { t } = useTranslation("common");

  const onCopyWalletAddress = (item) => {
    navigator.clipboard.writeText(item);
  };

  const [strigaWalletDetail, setStrigaWalletDetail] = useState(null);
  const [strigaTotalBalance, setStrigatotalBalance] = useState(0);
  const [otcLockInfo, setOtcLockBalance] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const fetchData1 = async () => {
    getWalletDetail();
  };
  useEffect(() => {
    getWalletDetail();
  }, []);

  const getWalletDetail = async () => {
    setIsLoading(true);
    try {
      const res = await getAllWallets();
      setStrigaWalletDetail(res?.data);
      setIsLoading(false);
    } catch (error) {

      setIsLoading(false);
    }
  };

  useEffect(() => {
    let totalBalance = 0;

    if (strigaWalletDetail?.wallets?.length) {
      strigaWalletDetail.wallets.forEach((wallet) => {
        // Ensure wallet.accounts is an array
        const accounts = Object.values(wallet.accounts);
      
        accounts.forEach((account) => {
          if (account?.availableBalance?.hAmount) {
            totalBalance +=
              account?.availableBalance?.hAmount *
              account?.availableBalance?.rate;
          }
        });
      });
    }
    setStrigatotalBalance(Number(totalBalance));
  }, [strigaWalletDetail]);

  const fetchOtcLockInfo = async () => {
    setIsLoading(true);
    const res = await getUserLockBalanceWebapp();
    setOtcLockBalance(res?.data);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchOtcLockInfo();
  }, []);

  return (
    <Panel
      xs={12}
      lg={6}
      xxl={3}
      title={'OTC Wallet'}
      refreshRequest={() => fetchData1()}
    >
      <WalletInfoWrapper>
        <WalletStatRowWrapper>
          {
            otcLockInfo?.otc_name?.map((data) => {
              return (
                <h5>
                  {" "}
                  <b> {data}-</b>
                </h5>
              )
            })
          }
          {!subWallet && (
            <>
              <WalletStatRowTitle>{t("wallet.address")}</WalletStatRowTitle>
              <WalletStatRowValue variant="small">
                <span>
                  {getShortCryptoAddress(
                    JSON.parse(localStorage.getItem("user"))?.data?.user
                      ?.address
                  ) || "--"}
                </span>
                <Tooltip text="Copy wallet address" dir={dir} placement="top">
                  <SidebarLinkIcon
                    className="lnr lnr-book"
                    onClick={() =>
                      onCopyWalletAddress(
                        JSON.parse(localStorage.getItem("user")).data.user
                          .address
                      )
                    }
                  />
                </Tooltip>
              </WalletStatRowValue>
            </>
          )}
        </WalletStatRowWrapper>

        {subWallet && (
          <WalletStatRowWrapper>
            <WalletStatRowTitle> OTC Wallet Balance</WalletStatRowTitle>
            <WalletStatRowValue>
              {`${numeral(otcLockInfo?.total_balance).format("0,0.00") || 0} $`}
            </WalletStatRowValue>
          </WalletStatRowWrapper>
        )}
      </WalletInfoWrapper>

  

    </Panel>
  );
};

OtcWAlletInfo.propTypes = {
  dir: PropTypes.string.isRequired,
};

export default OtcWAlletInfo;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const DropdownDiv = styled.div`
  display: block;
  float: right;
  width: 100%;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;
