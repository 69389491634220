import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import MetaMaskForm from '../../../containers/Dashboards/NewContract/component/MetaMaskForm';

const checkoutView = ({productList}) => (
  <Container className="dashboard">
    <Row hidden>
      <Col md={12}>
        <h3 className="page-title">Cart</h3>
      </Col>
    </Row>
    <Row>
      <div className="col-12">
        <MetaMaskForm accessType="cart" productList={productList} />
      </div>
      <div className="col-12">
        {/* <ContractForm /> */}
      </div>
    </Row>
  </Container>
    );
export default checkoutView;
