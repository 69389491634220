import Modal from "react-bootstrap/Modal";
import { CustomSelect } from "../../../shared/components/CustomSelect";
import styled from "styled-components";
import { useState } from "react";
import { Button } from "@/shared/components/Button";

import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import { deleteOtcMarketPlacePackage } from "../../../utils/services/user.service";
import { useChainContext } from "../../../containers/context/context";


const DeleteGeneralPackageCard = ({ show, hide, reFetchPackages, package_id }) => {
  const [isLoading, setIsLoading] = useState(false);
  //   const { setReFetchData,reFethData } = useChainContext()
  const {setReFetchData ,reFethData}=useChainContext()


  const handleDeleteMarketplacePackage = async () => {
    try {
      setIsLoading(true);
      const payload = {
        package_id: package_id,
      };
      const res = await deleteOtcMarketPlacePackage(payload);
      setReFetchData(!reFethData);
      toast.success(res.data.Message);
      setIsLoading(false);
      hide();
    } catch (error) {
      toast.error(error?.response?.data.Message);
      setIsLoading(false);
    }
  };
  return (
    <>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={hide}
      >
        <div
          className="loader-overlay"
          style={{
            display: isLoading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 999,
          }}
        >
          <Ring size={65} color="#00897B" />
        </div>
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: "large" }}>
            <b>Delete OTC Marketplace Package </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container padding-bottom-3x p-0">
            <div
              className=" mt-0 p-0 flex flex-col gap-8 justify-center items-center"
              style={{ boxShadow: "none", fontFamily: "sans-serif" }}
            >
              <div>
                <h4 className="text-black">
                  Are You sure you want to Delete the Package
                </h4>
              </div>
              <div className="d-flex flex-column flex-md-row w-full gap-2 gap-md-4 justify-center items-center">

                <FormButton
                  type="submit"
                  variant="outline-success"
                  className="py-2 mt-2 w-100"
                  onClick={handleDeleteMarketplacePackage}

                >
                  Yes
                </FormButton>

                <FormButton
                  type="submit"
                  variant="contain-success"
                  className="py-2 mt-2 w-100"
                  onClick={hide}

                >
                  No
                </FormButton>

              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default DeleteGeneralPackageCard;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;