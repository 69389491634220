/* eslint-disable max-len */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import { useTranslation } from 'react-i18next';
import {
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import {
  configureChains, createConfig, WagmiConfig,
} from 'wagmi';
import { arbitrum, mainnet, polygon } from 'wagmi/chains';
import config from '@/config/walletConnect';
import Panel from '@/shared/components/Panel';
import Tooltip from '@/shared/components/Tooltip';
import { getShortCryptoAddress } from '@/utils/helpers';
import {
  WalletConnection,
  WalletInfoWrapper,
  WalletStatRowTitle,
  WalletStatRowValue,
  WalletStatRowWrapper,
} from '../styled';
import { SidebarLinkIcon } from '../../../Layout/components/sidebar/SidebarLink';
import Web3ButtonPage from '../../NewContract/component/W3ButtonPage';
import W3ButtonPageNew from '../../NewContract/component/W3ButtonPageNew';
import WalletResponseNew from '../../NewContract/component/WalletResponseNew';
import { colorAccent } from '../../../../utils/palette';
import { getContractAmount, getReceiveAmount, getTotalBalance } from '../../../../utils/services/user.service';
import { CustomSelect } from '../../../../shared/components/CustomSelect';

const WALLET_ADDRESS = '0xe9be55ffedb6c2a2f3f8eac31e60d7f122f799';
const chains = [arbitrum, mainnet, polygon];
const {
  projectId, REACT_APP_ETHERIUM_CHAIN, REACT_APP_SMART_CHAIN, REACT_APP_POLYGON_MATIC,
} = config;

const optionChain = [
  // { value: REACT_APP_ETHERIUM_CHAIN, label: 'Etherium' },
  { value: REACT_APP_SMART_CHAIN, label: 'Smart chain' },
  // { value: REACT_APP_POLYGON_MATIC, label: 'Polygon matic' },
];

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});

const ethereumClient = new EthereumClient(wagmiConfig, chains);

const WalletInfo4 = ({ dir }) => {
  const { t } = useTranslation('common');
  const [statusCode, setstatusCode] = useState(400);
  const [ChainValue, setChainValue] = useState(REACT_APP_SMART_CHAIN);

  const onCopyWalletAddress = () => {
    navigator.clipboard.writeText(JSON.parse(localStorage.getItem('user')).data.user.address);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [contract, setcontract] = useState([]);
  const [sendAmount, setsendAmount] = useState([]);
  const [receiveAmount, setreceiveAmount] = useState([]);
  const [totalBalance, setTotalBalance] = useState('');

  const getContractDetails = () => {
    setIsLoading(true);
    getTotalBalance().then((res) => {
      setTotalBalance(res.data.balance);
      setIsLoading(false);
    }).catch((error) => {
      setTotalBalance(null);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    getContractDetails();
  }, []);

  useEffect(() => {
    // console.log('set wallet');
    const fetchChainId = async () => {
      const web3 = new Web3(window.ethereum);
      const connection = await web3.eth.getChainId();
      const chainId = Number(connection);
      // console.log('test chain---------------', chainId.toString(), window.ethereum);
      setChainValue(chainId);
    };
    if (window.ethereum) {
      fetchChainId();
    } else {
      // console.log('set wallet');
      setChainValue(REACT_APP_SMART_CHAIN);
    }
  }, []);

  const numberString = totalBalance?.toString();

  // const panelFooter = (
  //   <WalletConnection type="button">
  //     <span>{t('wallet.disconnect')}</span>
  //     <SidebarLinkIcon className="lnr lnr-exit" />
  //   </WalletConnection>
  // );

  return (
    <Panel xs={12} lg={6} xxl={3} title={t('wallet.page_title')} refreshRequest={() => getContractDetails()}>
      <WalletInfoWrapper>
        <WalletStatRowWrapper hidden>
          <h4> <b> Virtual Wallet</b></h4>
          <WalletStatRowTitle>{t('wallet.address')}</WalletStatRowTitle>
          <WalletStatRowValue variant="small">
            <span>{getShortCryptoAddress(JSON.parse(localStorage.getItem('user')).data.user.address)}</span>
            <Tooltip text="Copy wallet address" dir={dir} placement="top">
              <SidebarLinkIcon className="lnr lnr-book" onClick={onCopyWalletAddress} />
            </Tooltip>
          </WalletStatRowValue>
        </WalletStatRowWrapper>

        <WalletStatRowWrapper hidden>
          <WalletStatRowTitle>Total Balance</WalletStatRowTitle>
          <WalletStatRowValue>
            {isLoading ? <Spinner style={{ color: colorAccent }} />
              : (
                `${totalBalance} $`
              )}
          </WalletStatRowValue>
        </WalletStatRowWrapper>
        <WalletStatRowWrapper>
          <h4> <b> External Wallet</b></h4>
          <WagmiConfig config={wagmiConfig}>
            <Web3ButtonPage />
          </WagmiConfig>
          <InputDiv>
            <DropdownDiv className="">
              <label style={{ fontSize: 'small' }} htmlFor="subcategory_id" className="form-label text-white mt-0">Select Chain</label>
              <CustomSelect
                name="chain"
                id="chain"
                // eslint-disable-next-line max-len
                className="form-control w-100"
                styles={{
                  menu: provided => ({
                    ...provided,
                    textTransform: 'capitalize',
                  }),
                }}
                value={ChainValue}
                options={optionChain}
                onChange={(
                  value,
                ) => {
                  setChainValue(
                    value.value,
                  );
                }}
              />
            </DropdownDiv>
          </InputDiv>
          <>
            <Web3Modal
              projectId={projectId}
              ethereumClient={ethereumClient}
              themeVariables={{
                '--w3m-accent-color': '#4ce1b6',
                '--w3m-z-index': 127,
                '--w3m-overlay-backdrop-filter': 'blur(6px)',
              }}
            />
            <WagmiConfig config={wagmiConfig}>
              <WalletResponseNew setstatusCode={setstatusCode} chain={ChainValue} selectedValue="external" />
            </WagmiConfig>
          </>
        </WalletStatRowWrapper>
      </WalletInfoWrapper>
      <Container hidden>
        <Row xs={12} md={6} lg={3} style={{ textTransform: 'Capitalize' }}>
          <Col sm={12} md={12} lg={12} xl={12}>
            <WalletInfoWrapper>
              {/* <WalletStatRowWrapper>
                <WalletStatRowTitle>{t('wallet.address')}</WalletStatRowTitle>
                <WalletStatRowValue variant="small">
                  <span>{getShortCryptoAddress(WALLET_ADDRESS)}</span>
                  <Tooltip text="Copy wallet address" dir={dir} placement="top">
                    <SidebarLinkIcon className="lnr lnr-book" onClick={onCopyWalletAddress} />
                  </Tooltip>
                </WalletStatRowValue>
              </WalletStatRowWrapper> */}
              <WalletStatRowWrapper>
                <WalletStatRowTitle>Send</WalletStatRowTitle>
                <WalletStatRowValue>
                  {isLoading ? <Spinner style={{ color: colorAccent }} />
                    : (

                      `${sendAmount} $`
                    )}
                </WalletStatRowValue>
              </WalletStatRowWrapper>
              <WalletStatRowWrapper>
                <WalletStatRowTitle>Receive</WalletStatRowTitle>
                <WalletStatRowValue>
                  {isLoading ? <Spinner style={{ color: colorAccent }} />
                    : (

                      `${receiveAmount} $`
                    )}
                </WalletStatRowValue>
              </WalletStatRowWrapper>
            </WalletInfoWrapper>
          </Col>
        </Row>
      </Container>
    </Panel>
  );
};

WalletInfo4.propTypes = {
  dir: PropTypes.string.isRequired,
};

export default WalletInfo4;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const DropdownDiv = styled.div`
  display: block;
  float: right;
  width: 100%;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;
