import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { useState, useEffect } from "react";
import {
  getAllTokens,
  getTotalFeeSubAccounts,
  submitNewAccountRequest,
} from "../../../utils/services/user.service";
import { Button } from "@/shared/components/Button";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import { Field, Form } from "react-final-form";
import { CustomSelect } from "../../../shared/components/CustomSelect";

const ChooseAccountModal = ({ show, hide, reFetch }) => {
  const [selectedDate, setSelectedDate] = useState(""); // State to store selected date
  const [isLoading, setIsLoading] = useState(false);
  const [accountTimeType, setAccountTimeType] = useState("");
  const [numberOfAccounts, setNumberOfAccounts] = useState("");
  const [accountTime, setAccountTime] = useState(null);
  const [numberOfTokens, setNumberOfTokens] = useState(null);
  const [selectedTypeOption, setSelectedTyopeOption] = useState("");
  const [selectedToken, setSelectedToken] = useState("");
  const [selectedDisributionType, setSelectedDistributionType] = useState("");
  const [allTokens, setAllTokens] = useState([]);
  const [overviewData, setOverviewData] = useState({});
  const [showOverview, setShowOverview] = useState(false);
  const [isTokenBalance, setIsTokenBalance] = useState(false);
  const [isCoinBalance, setIsCoinBalance] = useState(false);
  const excludedLabels = ["SOLANA", "WEN", "LTX", "SoLpy", "BTC"];

  const filteredTokens = allTokens.filter(
    (token) => !excludedLabels.includes(token.symbol)
  );

  const labelsOptions = [
    {
      label: "Minutes",
      value: "minutes",
    },
    {
      label: "Days",
      value: "day",
    },
    {
      label: "Week",
      value: "week",
    },
    {
      label: "Month",
      value: "month",
    },
  ];

  const typesOption = [
    { label: "Transfer", value: "transfer" },
    { label: "Purchase", value: "purchase" },
  ];
  const distributionOptions = [
    { label: "Same Quantiy", value: "same_quantity" },
    { label: "Different Quantity", value: "different_quantity" },
  ];

  const handleDateChange = (event) => {
    // When date changes, update the selected date state
    setSelectedDate(event.target.value);
  };

  const fetchAllTokens = async () => {
    setIsLoading(true);
    const payload = {
      chain_id: "",
      limit:100,
      page_number:1
    };
    const res = await getAllTokens(payload);
    setAllTokens(res.data.user);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllTokens();
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // Check if any required field is missing

      const payload = {
        type: selectedTypeOption,
        validity_time: selectedDate,
        number_of_accounts: numberOfAccounts,
        account_time_type: accountTimeType,
        account_time: accountTime,
        symbol: selectedToken,
        distribution_type: selectedDisributionType,
        number_of_tokens: numberOfTokens,
      };

      if (
        !selectedTypeOption ||
        !selectedDate ||
        !numberOfAccounts ||
        !accountTimeType ||
        !accountTime ||
        !selectedToken ||
        !selectedDisributionType ||
        !numberOfTokens
      ) {
        toast.error("All fields are required.");
        setIsLoading(false);
        return;
      }

      const res = await submitNewAccountRequest(payload);
      toast.success(res.data.Message);
      hide();
      reFetch();
      setIsLoading(false);
    } catch (error) {
      toast.error(error?.response?.data.Message);
      setIsLoading(false);
    }
  };
  const handleOverview = async () => {
    setIsLoading(true);
    try {
      // Check if any required field is missing

      const payload = {
        validity_time: selectedDate,
        number_of_accounts: numberOfAccounts,
        account_time_type: accountTimeType,
      };

      if (!selectedDate || !numberOfAccounts || !accountTimeType) {
        toast.error("All fields are required.");
        setIsLoading(false);
        return;
      }

      const res = await getTotalFeeSubAccounts(payload);

      setOverviewData(res.data.data);
      setShowOverview(true);
      // toast.success(res.data.Message);
      // hide();
      // reFetch();
      setIsLoading(false);
    } catch (error) {
      toast.error(error?.response?.data.Message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 99999,
        }}
      >
        <Ring size={65} color="#00897B" />

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={hide}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <>
              {/* admin detail section  */}
              {showOverview && (
                <div
                  className="container mt-0 containerDiv pt-0"
                  style={{ overflowX: "hidden" }}
                >
                  <div className="row mt-2 mb-1">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-0"
                    >
                      <b> Overview Details</b>
                    </p>
                  </div>
                  <div
                    className="row mx-1 py-2 gap-2 "
                    style={{ border: "2px solid #7b7b7b", borderRadius: "5px" }}
                  >
                    <div className="  col-md-3 col-sm-12 ">
                      <h5 htmlFor="text" className="text-black">
                        Approx Account
                      </h5>
                      <h6 className="text-black">
                        {overviewData?.approx_account}
                      </h6>
                    </div>

                    <div className="  col-md-3 col-sm-12 ">
                      <h5 htmlFor="text" className="text-black">
                        Approx total fee
                      </h5>
                      <h6 className="text-black">{`${overviewData.total_fee} ${overviewData.symbol}`}</h6>
                    </div>
                  </div>
                  {!overviewData.token_balance ||
                    (!overviewData.coin_balance && (
                      <div className="  col-md-3 col-sm-12 ">
                        <h6 className="text-red">
                          you can not submit balance beacuse of insufficient
                          balance{" "}
                        </h6>
                      </div>
                    ))}
                </div>
              )}
              <div
                className="container mt-0 containerDiv pt-0"
                style={{ overflowX: "hidden" }}
              >
                <div className="row mt-2 mb-1">
                  <p
                    style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                    className="py-0"
                  >
                    <b> Sub Account Details</b>
                  </p>
                </div>
                <div
                  className="row mx-1 py-2 gap-2 "
                  style={{ border: "2px solid #7b7b7b", borderRadius: "5px" }}
                >
                  <div className="col-lg-4 col-md-6 col-sm-12 ">
                    <InputDiv>
                      <label htmlFor="text" className="text-black">
                        Type *
                      </label>
                      <CustomSelect
                        name="accountLabel"
                        options={typesOption}
                        value={selectedTypeOption}
                        onChange={(value) => {
                          setSelectedTyopeOption(value.value);
                        }}
                        isSearchable
                      />
                    </InputDiv>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 ">
                    <InputDiv>
                      <label htmlFor="text" className="text-black">
                        Distribution Type *
                      </label>
                      <CustomSelect
                        name="accountLabel"
                        options={distributionOptions}
                        value={selectedDisributionType}
                        onChange={(value) => {
                          setSelectedDistributionType(value.value);
                        }}
                        isSearchable
                      />
                    </InputDiv>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 ">
                    <InputDiv>
                      <label htmlFor="text" className="text-black">
                        Symbol *
                      </label>
                      <CustomSelect
                        name="accountLabel"
                        options={filteredTokens}
                        placeholder={
                          selectedToken ? selectedToken : "Select..."
                        }
                        value={selectedToken}
                        onChange={(value) => {
                          setSelectedToken(value.label);
                        }}
                        isSearchable
                      />
                    </InputDiv>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 ">
                    <InputDiv>
                      <label htmlFor="text" className="text-black">
                        Account Time Type *
                      </label>
                      <CustomSelect
                        name="accountLabel"
                        options={labelsOptions}
                        // placeholder={
                        //   selectedToken ? selectedToken : "Select..."
                        // }
                        value={accountTimeType}
                        onChange={(value) => {
                          setAccountTimeType(value.value);
                        }}
                        isSearchable
                      />
                    </InputDiv>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 ">
                    <InputDiv>
                      <label htmlFor="text" className="text-black">
                        Number of Accounts *
                      </label>
                      <ChatSearchInput
                        name="amount"
                        type="number"
                        placeholder="Enter Number of Accounts"
                        value={numberOfAccounts}
                        onChange={(value) => {
                          const newValue = parseInt(value.target.value, 10);
                          if (newValue < 1) {
                            toast.error(
                              "Number of accounts must be at least 1"
                            );
                          } else {
                            setNumberOfAccounts(newValue);
                          }
                        }}
                        className={`form-control w-100 `}
                      />
                    </InputDiv>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <InputDiv>
                      <label htmlFor="email" className="text-black mb-3">
                        Account Time *
                      </label>
                      <ChatSearchInput
                        name="amount"
                        type="number"
                        placeholder="Enter Account Time"
                        value={accountTime}
                        onChange={(value) => {
                          if (value.target.value < 5) {
                            toast.error("Account Time Must be greater than 4");
                            setAccountTime(null);

                            return;
                          } else {
                            setAccountTime(value.target.value);
                          }
                        }}
                        className={`form-control w-100 `}
                      />
                    </InputDiv>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <InputDiv>
                      <label htmlFor="email" className="text-black mb-3">
                        Number of Tokens *
                      </label>
                      <ChatSearchInput
                        name="amount"
                        type="number"
                        placeholder="Enter Number of Tokens"
                        value={numberOfTokens}
                        onChange={(value) => {
                          setNumberOfTokens(value.target.value);
                        }}
                        className={`form-control w-100 `}
                      />
                    </InputDiv>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 ">
                    <InputDiv>
                      <label htmlFor="text" className="text-black">
                        Validity Time *
                      </label>
                      <input
                        type="date"
                        value={selectedDate} // Set value to selectedDate state
                        onChange={handleDateChange} // Call handleDateChange on change
                        min={new Date().toISOString().split("T")[0]} // Disable past dates
                      />
                    </InputDiv>
                  </div>
                </div>

                <div className="md:flex">
                  <FormButton
                    variant="outline-success"
                    className="py-2 mt-4 btn-sm"
                    onClick={hide}
                  >
                    Cancel
                  </FormButton>

                  <FormButton
                    variant="contain-success"
                    className="py-2 mt-4 btn-sm"
                    onClick={handleOverview}
                    // onClick={handleSubmit}
                    //   disabled={!swapAmount}
                  >
                    Overview
                  </FormButton>

                  {overviewData.coin_balance && overviewData.token_balance && (
                    <FormButton
                      variant="contain-success"
                      className="py-2 mt-4 btn-sm"
                      onClick={handleSubmit}
                      //   disabled={!swapAmount}
                    >
                      Submit
                    </FormButton>
                  )}
                </div>
              </div>
            </>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export default ChooseAccountModal;

const Label = styled.label`
  @media screen and (max-width: 876px) {
    margin-bottom: 0px;
    margin-top: 8px;
  }
`;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
