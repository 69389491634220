/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SafeBoxTable from './component/SafeBoxTable';
import { getSafebox, getSafeboxDetail } from '../../../../utils/services/user.service';

const SafeBoxesOperations = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">SafeBox Operations</h3>
      </Col>
    </Row>
    <Row>
      <SafeBoxTable title="SafeBox" />
    </Row>

  </Container>
  );

export default SafeBoxesOperations;
