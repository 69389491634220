/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Collapse, Badge } from 'react-bootstrap';
import NotificationsIcon from 'mdi-react/NotificationsIcon';
import { TopbarBack, TopbarButton } from './BasicTopbarComponents';
import {
  TopbarCollapse,
  TopbarCollapseButton,
  TopbarCollapseContent,
  TopbarCollapseDate,
  TopbarCollapseImageWrap,
  TopbarCollapseItem,
  TopbarCollapseLink,
  TopbarCollapseMessage,
  TopbarCollapseName,
  TopbarCollapseTitle,
  TopbarCollapseTitleWrap,
} from './CollapseTopbarComponents';
import { getNotification, markAllAsRead } from '../../../../utils/services/user.service';
import './topbar.css';

const TopbarNotification = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [Notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([...Notifications]);
  useEffect(() => {
    let isMounted = true;
    const fetchNotifications = async () => {
      try {
        const payload = {
          type: 'user',
          limit: 10,
          page_number: 1,
        };
        const response = await getNotification(payload); // Assuming getNotification is an asynchronous function

        if (isMounted) {
          if (response.data.Notifications) {
            setNotifications(response.data.Notifications);
          } else {
            // Handle the case when there are no notifications
          }
        }
      } catch (error) {
        // Handle any errors that occur during the API request
      }
    };

    fetchNotifications();

    return () => {
      // Cleanup function to cancel ongoing tasks or subscriptions
      isMounted = false;
    };
  }, []);
  const notification1 = async () => {
    try {
      const payload = {
        type: 'user',
        limit: 10,
        page_number: 1,
      };
      const response = await getNotification(payload); // Assuming getNotification is an asynchronous function
      // console.log('Notifications  response', response);
      if (response.data.Notifications) {
        setNotifications(response.data.Notifications);
        // console.log('setNotifications', response.data.Notifications);
      } else {

      }
    } catch (error) {
      // Handle any errors that occur during the API request
    }
  };
  const collapseNotification = () => {
    setIsOpen(!isOpen);
  };
  const markAsRead = (id) => {
    // Find the notification by its ID and mark it as read
    const updatedNotifications = unreadNotifications.map(notification => (notification.id === id ? { ...notification, isRead: true } : notification));
    setUnreadNotifications(updatedNotifications);
  };
  const markAllRead = async () => {
    try {
      const payload = {
        type: 'user',
      };
      const response = await markAllAsRead(payload); // Add 'await' here
      if (response.data.success === true) { // Replace '====' with '==='
        notification1();
      } else {
        // console.log('response', response);
      }
      // console.log('response', response);
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };
  const unreadCount = Notifications.filter(notification => !notification.is_read).length;
  // console.log('unreadCount', unreadCount);
  return (
    <TopbarCollapse>
      <TopbarButton type="button" aria-expanded={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <NotificationsIcon
          className="icon-hover"
          style={{
            width: '24px', height: '24px', transition: 'fill 0.3s',
          }}
        />
        {unreadCount > 0 && (
          <h5 className="leading-9 mt-2 ml-0">
            <Badge bg="danger rounded-md">{unreadCount}</Badge>
          </h5>
        )}
      </TopbarButton>
      {isOpen && (
        <TopbarBack
          aria-label="topbar__back"
          type="button"
          onClick={collapseNotification}
        />
      )}
      <Collapse in={isOpen} style={{ right: '0px' }}>
        <TopbarCollapseContent className="overflow-y-auto max-h-32">
          <TopbarCollapseTitleWrap>
            <TopbarCollapseTitle>Notifications</TopbarCollapseTitle>
            <TopbarCollapseButton type="button" onClick={markAllRead}>Mark all as read</TopbarCollapseButton>
          </TopbarCollapseTitleWrap>
          <div style={{ maxHeight: '320px', overflowY: 'auto' }} className='d-flex flex-column text-wrap'>
            {Notifications.map(notification => (
              <TopbarCollapseItem key={notification._id} className={`flex items-center py-2 ${notification.is_read ? 'bg-gray-600' : 'bg-gray-700'}`}>
                <TopbarCollapseImageWrap>
                  <img
                    src={notification?.user_detail?.[0]?.image || `${process.env.PUBLIC_URL}/img/avat.png`}
                    alt="userimage"
                    // style={{
                    //   height:'40px',
                    //   width:'40px'
                    // }}
                  />
                </TopbarCollapseImageWrap>
                <TopbarCollapseMessage>
                  <TopbarCollapseName>
                    {notification?.user_detail?.[0]?.name ?? ''}
                  </TopbarCollapseName>

                  <span className="text-white">{notification.notification_message}</span>
                  {/* {notification.is_read.toString()} */}
                </TopbarCollapseMessage>
                <TopbarCollapseDate>{notification.date}
                  {!notification.is_read && (
                    <span className="unread-tag text-white align-right">New</span>
                  )}
                </TopbarCollapseDate>
              </TopbarCollapseItem>
            ))}
          </div>
          <TopbarCollapseLink
            to="/online_marketing_dashboard"
            onClick={collapseNotification}
            hidden
          >
            See all notifications
          </TopbarCollapseLink>
        </TopbarCollapseContent>
      </Collapse>
    </TopbarCollapse>
  );
};
export default TopbarNotification;
