/* eslint-disable max-len */
import React, {
 createContext, useContext, useState, useMemo, 
} from 'react';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const userAdress = JSON.parse(localStorage.getItem('user'));
  let storedArray = JSON.parse(localStorage.getItem('customArray')) || [];
  if (userAdress !== null) {
    if (userAdress.status !== 'not_verified') { 
      storedArray = storedArray.filter(item => item?.userId === userAdress?.data?.user?.user_id);
    } else {
      storedArray = JSON.parse(localStorage.getItem('customArray')) || [];
    }
  }
  // console.log('storedArray >>>> length >>>>>>', storedArray);
  const [cartCount, setCartCount] = useState(storedArray.length);

  const incrementCartCount = () => {
    setCartCount(cartCount + 1);
  };

  const decrementCartCount = () => {
    setCartCount(cartCount - 1);
  };

  const resetCartCount = () => {
    setCartCount(cartCount - 1);
  };

  const contextValue = useMemo(() => ({ 
    cartCount, incrementCartCount, decrementCartCount, resetCartCount,
  }), [cartCount]);

  return (
    <CartContext.Provider value={contextValue}>
      {children}
    </CartContext.Provider>
  );
};
