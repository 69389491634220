/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Badge from 'react-bootstrap/Badge';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faEye, faEnvelope, faUser, faFile, faPenToSquare,
} from '@fortawesome/free-regular-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getMyStore,
  editStoreLogo,
  getStoreInfoById,
} from '../../../utils/services/user.service';
import Product from '../product/Product';
import ProductNew from '../product/ProductNew';
import { Button } from '../../../shared/components/Button';
import '../style/storedetail.css';

const StoreDetail = () => {
  const { storeId } = useParams(); // Get the store ID from URL parameter
  const [storeData, setStoreData] = useState(null);
  const [A, AA] = useState([]);
  const [B, BB] = useState([]);
  const [A1, AAA] = useState([]);
  const [B1, BBB] = useState([]);
  const [A2, AAAA] = useState([]);
  const [B2, BBBB] = useState([]);
  const location = useLocation();
  const currentUrl = location?.pathname;
  // console.log('currentUrl currentUrl', currentUrl);
  const parts = currentUrl.split('/');
  const firstPart = parts[1]; 
  const secondPart = parts[2];
  // console.log(firstPart, secondPart);

  const [showFullAddress, setShowFullAddress] = useState(false);
  const handleViewMoreClick = () => {
    setShowFullAddress(!showFullAddress);
  };
    const productList = [
    {
      store_id: storeId,
    },
  ];
  const [getStoreName, setStoreName] = useState('');  
  const [showFullAddress1, setShowFullAddress1] = useState(false);
  const handleViewMoreClick1 = () => {
    setShowFullAddress1(!showFullAddress1);
  };
  const [showFullAddress2, setShowFullAddress2] = useState(false);
  const handleViewMoreClick2 = () => {
    setShowFullAddress2(!showFullAddress2);
  };
  // console.log('store data==>', storeData);
  useEffect(() => {
    const fetchStoreById = async () => {
      try {
        // const response = await getMyStore(storeId); // Replace with your API call
        const response = await getStoreInfoById(productList[0]);
        if (response.data) {
          setStoreData(response.data); // Set the detailed store data
          // console.log('store Data store Data store Data store Data store Data', response.data);
          AA(response.data.result[0].storeAddress);
          BB(showFullAddress ? response.data.result[0].storeAddress : response.data.result[0].storeAddress.slice(0, 10));
          AAA(response.data.result[0].approver_reason);
          BBB(showFullAddress1 ? response.data.result[0].approver_reason : response.data.result[0].approver_reason.slice(0, 10));
          AAAA(response.data.result[0].rejection_reason);
          BBBB(showFullAddress2 ? response.data.result[0].rejection_reason : response.data.result[0].rejection_reason.slice(0, 10));        
          const address = storeData.result[0].rejection_reason;
          const truncatedAddress = showFullAddress ? address : address.slice(0, 10);
          setStoreName(storeData.result[0].storeName);
        } else {
          console.error('Error fetching store details:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching store details:', error);
      }
    };

    fetchStoreById();
  }, [storeId]);

  const handleLogoChange = async (event) => {
    const formData = new FormData();
    const logoFile = event.target.files[0];
    formData.append('File', logoFile);
    formData.append('storeId', storeId);

    try {
      const response = await editStoreLogo(formData);
      if (response.status === 200) {
        // Show a success toast message


        // Refresh the page after a short delay (e.g., 2 seconds)
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        toast.success('Store logo updated successfully', {
          autoClose: 2000, // Close the toast after 2 seconds
        });
      }
    } catch (error) {
      console.error('Error updating store logo:', error);
    }
  };


  // console.log('A', A);
  // console.log('B', B);

  return (
    <div className="container py-3">
      <h2 hidden className="text-center mb-4">Welcome to Your Store</h2>
      {storeData ? (
        <div className="row px-4 mt-2">
          <div
            className="col-lg-4 order-1 order-lg-1 hero-img py-1 justify-content-center"
            data-aos="fade-right" 
            data-aos-delay="200"
          >
            <div className="square-img-wrapper">
              <img src={storeData.result[0].storeLogo} className="card-img-top square-img" alt="storelogo" />
            </div>
          </div>
          <div className="col-lg-8 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center py-1 px-3">
            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-12">
                <h3 style={{ textTransform: 'Capitalize' }} id="h2" data-aos="fade-up">{storeData.result[0].storeName}</h3>
              </div>
            </div>
            <div hidden className="row mt-3 mb-1">
              <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                <b>Store Detail</b>
              </p> 
            </div>
            <div hidden className="row px-2 py-1">
              <div data-aos="fade-up" data-aos-delay="300" className="col-12 text-center CreatorDiv1">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <p className="py-2"> <b style={{ fontWeight: '600' }}> Store Owner </b></p> 
                    <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2">{storeData.result[0].ownerName}</h5>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <p className="py-2"> <b style={{ fontWeight: '600' }}> Address</b></p>
                    <p className="py-2">
                      {A.length < 10 && (
                        <span>
                          <b style={{ fontWeight: '400' }}>{A}</b>
                        </span>
                      )}
                      {A.length > 10 && (
                        <span>
                          <b style={{ fontWeight: '400' }}>{showFullAddress ? A : `${B }....` }</b>
                          <button
                            className="btn btn-link btn-sm"
                            onClick={handleViewMoreClick}
                            type="button"
                            style={{ 
                              textDecoration: 'none', color: 'white', fontWeight: '400', marginTop: '-6px',
                            }}
                          >
                            {showFullAddress ? '▲' : '▼'}
                          </button>
                        </span>
                      )}
                    </p>                    
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <p className="py-2"> <b style={{ fontWeight: '600' }}> Cateogory </b></p> 
                    <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2">{storeData.result[0].categories[0].category_name}</h5>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}> Status</b></p>
                    <span 
                      style={{ textTransform: 'Capitalize' }} 
                      className={`badge px-3 py-1 mb-2 ${
                        // eslint-disable-next-line max-len, no-nested-ternary
                        storeData.result[0].status === 'rejected' || storeData.result[0].status === 'deactivated' ? 'badge-danger' : storeData.result[0].status === 'pending' ? 'badge-warning' : 'badge-success'
                      }`}
                    > <h5 className="currentPrice"> {storeData.result[0].status}</h5></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <Tabs
                  defaultActiveKey="home"
                  transition={false}
                  id="noanim-tab-example"
                  className="mb-3 mt-4"
                >
                  <Tab eventKey="home" ClassName="text-white" title="Store Info">
                    <div className="row text-center">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <p className="py-2"> <b style={{ fontWeight: '600' }}> Store Owner </b></p> 
                        <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2">{storeData.result[0].ownerName}</h5>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <p className="py-2"> <b style={{ fontWeight: '600' }}> Address</b></p>
                        <p className="py-2">
                          {A.length <= 10 && (
                          <span>
                            <b style={{ fontWeight: '400' }}>{A}</b>
                          </span>
                      )}
                          {A.length > 10 && (
                          <span>
                            <b style={{ fontWeight: '400' }}>{showFullAddress ? A : `${B }....` }</b>
                            <button
                              className="btn btn-link btn-sm"
                              onClick={handleViewMoreClick}
                              type="button"
                              style={{ 
                              textDecoration: 'none', color: 'white', fontWeight: '400', marginTop: '-6px',
                            }}
                            >
                              {showFullAddress ? '▲' : '▼'}
                            </button>
                          </span>
                      )}
                        </p>                    
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <p className="py-2"> <b style={{ fontWeight: '600' }}> Cateogory </b></p> 
                        <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2">{storeData.result[0].categories[0].category_name}</h5>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <p className="py-2"> <b style={{ fontWeight: '600' }}> Store Type </b></p> 
                        <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2">{storeData.result[0].store_type}</h5>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}> Status</b></p>
                        <span 
                          style={{ textTransform: 'Capitalize' }} 
                          className={`badge px-3 py-1 mb-2 ${
                        // eslint-disable-next-line max-len, no-nested-ternary
                        storeData.result[0].status === 'rejected' || storeData.result[0].status === 'deactivated' ? 'badge-danger' : storeData.result[0].status === 'pending' ? 'badge-warning' : 'badge-success'
                      }`}
                        > 
                          {storeData.result[0].status === 'pending' || storeData.result[0].status === 'Pending' ? (
                            <h5 className="currentPrice text-dark"> {storeData.result[0].status}</h5>
                          ) : (
                            <h5 className="currentPrice"> {storeData.result[0].status}</h5>
                          )}
                        </span>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Profile" ClassName="text-white" title="Approver Info">        
                    <div className="row text-center">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <p className="py-2"> <b style={{ fontWeight: '600' }}> Approver Name </b></p>
                        {storeData.result[0].approver_name[0] ? (
                          <h5 style={{ textTransform: 'capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2">
                            {storeData.result[0].approver_name[0].name}
                          </h5>
                        ) : (
                          <h6>-</h6>
                        )}
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}>Store Doc </b></p> 
                        <Button size="sm" className="btn btn-sm mb-2 px-2" variant="outline-success">
                          <a 
                            href={storeData.result[0].store_document}
                            target="_blank" 
                            rel="noreferrer" 
                            className="text-white"
                          >
                            View
                          </a>
                        </Button>
                      </div>
                      {storeData.result[0].status === 'approved' && (
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}> Approve Reason </b></p> 
                        <p className="py-2">
                          {A1.length <= 10 && (
                          <span>
                            <b style={{ fontWeight: '400', textTransform: 'Capitalize' }}>{A1}</b>
                          </span>
                  )}
                          {A1.length > 10 && (
                          <span>
                            <b style={{ fontWeight: '400', textTransform: 'Capitalize' }}>{showFullAddress1 ? A1 : `${B1 }....` }</b>
                            <button
                              className="btn btn-link btn-sm"
                              onClick={handleViewMoreClick1}
                              type="button"
                              style={{ 
                          textDecoration: 'none', color: 'white', fontWeight: '400', marginTop: '-6px', textTransform: 'Capitalize',
                        }}
                            >
                              {showFullAddress1 ? '▲' : '▼'}
                            </button>
                          </span>
                  )}
                        </p>
                      </div>                   
              )}  
                      {storeData.result[0].status === 'approved' && (                      
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}> Approve Doc </b></p>                         
                        <Button size="sm" className="btn btn-sm mb-2 px-2" variant="outline-success">
                          <a 
                            href={storeData.result[0].approver_document}
                            target="_blank" 
                            rel="noreferrer" 
                            className="text-white"
                          >
                            View
                          </a>
                        </Button>
                      </div>
              )}
                      {storeData.result[0].status === 'rejected' && (
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}> Reject Reason </b></p> 
                        <p className="py-2">
                          {A2.length <= 10 && (
                          <span>
                            <b style={{ fontWeight: '400', textTransform: 'Capitalize' }}>{A2}</b>
                          </span>
                  )}
                          {A2.length > 10 && (
                          <span>
                            <b style={{ fontWeight: '400', textTransform: 'Capitalize' }}>{showFullAddress2 ? A2 : `${B2 }....` }</b>
                            <button
                              className="btn btn-link btn-sm"
                              onClick={handleViewMoreClick2}
                              type="button"
                              style={{ 
                          textDecoration: 'none', color: 'white', fontWeight: '400', marginTop: '-6px', textTransform: 'Capitalize',
                        }}
                            >
                              {showFullAddress2 ? '▲' : '▼'}
                            </button>
                          </span>
                  )}
                        </p>
                      </div>                   
              )}  
                      {storeData.result[0].status === 'rejected' && (                      
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}> Reject Doc </b></p> 
                        <a 
                          href={storeData.result[0].reject_document}
                          target="_blank" 
                          rel="noreferrer" 
                          className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                        >
                          View
                        </a>
                      </div>
              )}
                      {storeData.result[0].status !== 'rejected' && storeData.result[0].status !== 'approved' && (
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <p className="py-2 mb-1"> <b>  </b></p> 
                        <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2"> </h5>
                      </div>                   
              )}  
                      {storeData.result[0].status !== 'rejected' && storeData.result[0].status !== 'approved' && (          
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <p className="py-2 mb-1"> <b>  </b></p> 
                        <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2"> </h5>
                      </div>
              )}                                    
                    </div>                     
                  </Tab>
                </Tabs>
              </div>
            </div>
            <div hidden className="row mt-3 mb-1">
              <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                <b>Approver Detail</b>
              </p> 
            </div>
            <div hidden className="row px-2 py-1">
              <div data-aos="fade-up" data-aos-delay="300" className="col-12 text-center CreatorDiv1">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <p className="py-2"> <b style={{ fontWeight: '600' }}> Store Owner </b></p> 
                    <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2">{storeData.result[0].ownerName}</h5>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}>Store Doc </b></p> 
                    <a 
                      href={storeData.result[0].store_document}
                      target="_blank" 
                      rel="noreferrer" 
                      className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                    >
                      View 
                    </a>
                  </div>
                  {storeData.result[0].status === 'approved' && (
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}> Approve Reason </b></p> 
                      <p className="py-2">
                        {A1.length < 10 && (
                        <span>
                          <b style={{ fontWeight: '400', textTransform: 'Capitalize' }}>{A1}</b>
                        </span>
                      )}
                        {A1.length > 10 && (
                        <span>
                          <b style={{ fontWeight: '400', textTransform: 'Capitalize' }}>{showFullAddress1 ? A1 : `${B1 }....` }</b>
                          <button
                            className="btn btn-link btn-sm"
                            onClick={handleViewMoreClick1}
                            type="button"
                            style={{ 
                              textDecoration: 'none', color: 'white', fontWeight: '400', marginTop: '-6px', textTransform: 'Capitalize',
                            }}
                          >
                            {showFullAddress1 ? '▲' : '▼'}
                          </button>
                        </span>
                      )}
                      </p>
                    </div>                   
                  )}  
                  {storeData.result[0].status === 'approved' && (                      
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}> Approve Doc </b></p> 
                      <a 
                        href={storeData.result[0].approver_document}
                        target="_blank" 
                        rel="noreferrer" 
                        className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                      >
                        View
                      </a>
                    </div>
                  )}
                  {storeData.result[0].status === 'rejected' && (
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}> Reject Reason </b></p> 
                      <p className="py-2">
                        {A2.length < 10 && (
                        <span>
                          <b style={{ fontWeight: '400', textTransform: 'Capitalize' }}>{A2}</b>
                        </span>
                      )}
                        {A2.length > 10 && (
                        <span>
                          <b style={{ fontWeight: '400', textTransform: 'Capitalize' }}>{showFullAddress2 ? A2 : `${B2 }....` }</b>
                          <button
                            className="btn btn-link btn-sm"
                            onClick={handleViewMoreClick2}
                            type="button"
                            style={{ 
                              textDecoration: 'none', color: 'white', fontWeight: '400', marginTop: '-6px', textTransform: 'Capitalize',
                            }}
                          >
                            {showFullAddress2 ? '▲' : '▼'}
                          </button>
                        </span>
                      )}
                      </p>
                    </div>                   
                  )}  
                  {storeData.result[0].status === 'rejected' && (                      
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <p className="py-2 mb-1"> <b style={{ fontWeight: '600' }}> Reject Doc </b></p> 
                      <a 
                        href={storeData.result[0].reject_document}
                        target="_blank" 
                        rel="noreferrer" 
                        className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                      >
                        View
                      </a>
                    </div>
                  )}
                  {storeData.result[0].status !== 'rejected' && storeData.result[0].status !== 'approved' && (
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <p className="py-2 mb-1"> <b>  </b></p> 
                      <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2"> </h5>
                    </div>                   
                  )}  
                  {storeData.result[0].status !== 'rejected' && storeData.result[0].status !== 'approved' && (          
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <p className="py-2 mb-1"> <b>  </b></p> 
                      <h5 style={{ textTransform: 'Capitalize', fontWeight: '400' }} className="currentPrice py-2 mb-2"> </h5>
                    </div>
                  )}                                    
                </div>
              </div>
            </div>
            <hr className="mt-3" style={{ borderTop: '1px solid white', opacity: '1.1' }} />
            <p style={{ textTransform: 'Capitalize' }} className="mb-2 mt-2">{storeData.result[0].storeDescription}</p>
          </div>
        </div>
        ) : (
          <p className="text-center mb-5">No Products available.</p>
        )}
      <hr className="mt-5 mx-4" style={{ border: '1px solid #4d4d4d', opacity: '1.1' }} />
      <div>
        {storeData ? (
          <h3 style={{ textTransform: 'Capitalize' }} className="text-center storeName py-4">{storeData.result[0].storeName} Products</h3>
          ) : (
            <h3 className="text-center py-4"> Products</h3>
          )}
        <ProductNew storeId={storeId} />
      </div>

      {/* Your Store Detail Card */}
    </div>



  );
};

export default StoreDetail;
