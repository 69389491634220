import React, { useState } from 'react';
import { Field, Formik } from 'formik';

import styled from 'styled-components';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import {
  configureChains, createConfig, WagmiConfig,
} from 'wagmi';
import { arbitrum, mainnet, polygon } from 'wagmi/chains';

import { Alert, Tab, Tabs } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import {
  FormControlLabel, FormControl, RadioGroup, Radio, FormLabel,
} from '@mui/material';
import config from '@/config/walletConnect';
import Panel from '@/shared/components/Panel';
import { CustomSelect } from '../../../../shared/components/CustomSelect';
import Web3ButtonPage from '../../NewContract/component/W3ButtonPage';
import WalletResponse from '../../NewContract/component/WalletResponse';
import { clearMsg } from '../../../../redux/actions/authActions';
import SendForm from './SendForm';
import { colorAccent } from '../../../../utils/palette';
import './sendmetamaskform.css';
import StrigaWithdraw from './StrigaWithdraw';
import VirtualWalletSendForm from './VirtualWalletSendForm';
import BankWalletSendForm from './BankWalletSendForm';
import OnchainWalletSendForm from './OnchainWalletSendForm';

const chains = [arbitrum, mainnet, polygon];
const {
  projectId, REACT_APP_ETHERIUM_CHAIN, REACT_APP_SMART_CHAIN, REACT_APP_POLYGON_MATIC,
} = config;

const optionChain = [
  // { value: REACT_APP_ETHERIUM_CHAIN, label: 'Etherium' },
  { value: REACT_APP_SMART_CHAIN, label: 'Smart chain' },
  // { value: REACT_APP_POLYGON_MATIC, label: 'Polygon matic' },
  // { value: 'BTC', label: 'BTC' },
];

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

const SendMetaMaskForm = () => {
  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();
  const [statusCode, setstatusCode] = useState(400);
  const [ChainValue, setChainValue] = useState(REACT_APP_SMART_CHAIN);
  // console.log(ChainValue, '-------chain value');
  const [selectedValue, setSelectedValue] = useState('internal');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = item => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });


  const [mainSelectedWithdrawOption, setMainSelectedWithdrawOption] = useState("virtual_wallet")
  const [selectedSubOption, setSelectedSubOption] = useState("")
  const [togle, setTogle] = useState('internal')
  const [toValue, setToValue] = useState('')




  const mainWithdrawOptions = [
    {
      label: 'Virtual Wallet',
      value: "virtual_wallet"
    },
    {
      label: 'Bank Wallet',
      value: "bank_wallet"
    },
    {
      label: 'From Onchain Wallet',
      value: 'sub_wallet'
    },
  ]

  const SubwalletOptionsVirtual = [
    {
      label: 'From Main Wallet',
      value: 'main_wallet'
    },
    // {
    //   label: 'From Onchain Wallet',
    //   value: 'sub_wallet'
    // },
  ];
  const SubwalletOptionsBank = [
    // { label: 'From Bank Wallet', value: 'bank_transfer' },
    { label: 'Intraledger Payment (Only EUR)', value: 'intraledger_payment' },
    { label: 'Sepa Payment', value: 'sepa_payment' },
    { label: 'Crypto Asset', value: 'onChain_Transection' }
  ];


  const SubMainWallet = [
    { label: 'Virtual Wallet', value: 'internal' },
    { label: 'Web3 wallet', value: 'external' },
  ];
  const SubOnchainWallet = [
    { label: 'Virtual Wallet', value: 'internal_onChain' },
    { label: 'External', value: 'external_onChain' },
  ];
  const toOptionsVirtualInternal = [
    { label: 'Bank', value: 'bank' },
    { label: 'Onchain Wallet', value: 'onChain_wallet' },
    // { label: 'Virtual Wallet ', value: 'internal_wallet' },
    { label: 'External Wallet ', value: 'external_wallet' },
  ];
  const toOptionsVirtualExternal = [
    { label: 'Bank', value: 'bank' },
    { label: 'Onchain Wallet', value: 'onChain_wallet' },
    { label: 'Virtual Wallet', value: 'virtual' },
  ];
  const toOptionsBank = [
    { label: 'Onchain Wallet', value: 'onChain' },
    { label: 'Virtual Wallet', value: 'virtual' },
    { label: 'External', value: 'external_wallet' },
  ];
  const toOptionsOnchain = [
    { label: 'Virtual Wallet', value: 'virtual' },
    { label: 'Bank ', value: 'bank' },
    { label: 'External', value: 'external_wallet' },
  ];
  const fromOptionsBank = [
    { label: 'Bank Wallet', value: 'bank_wallet' },

  ];
  const fromOptionsOnchain = [
    { label: 'onChain Wallet', value: 'onChain_wallet' },

  ];

  const handleSelect = (key) => {
    setMainSelectedWithdrawOption(key);
    setSelectedSubOption(key);
    setSelectedValue(key)
  };
  return (
    <>
      <div style={{
        width: '100%', padding: '5px',
      }}
      >
        <StyledAlert
          variant={statusCode === 200 ? 'success' : 'danger'}
          show={message !== undefined}
          style={{
            marginTop: '1rem', width: '100%',
          }}
          onClose={() => {
            dispatch(clearMsg());
          }}
          dismissible
        >
          <b>{message}</b>
        </StyledAlert>
      </div>

      <Panel lg={12} title="Withdraw" closeBtn minusBtn refreshLoad>


        <FormControl className="mx-3 d-flex w-100">

          {/* <FormLabel style={{ color: 'white' }}>Select Wallet</FormLabel> */}
          {/* <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={selectedValue}
            name="wallets"
            className="mt-3"
          >
            <FormControlLabel
              style={{ color: 'white' }}
              value="internal"
              name="wallets"
              control={(
                <Radio
                  {...controlProps('internal')}
                  sx={{
                    color: 'white',
                    '&.Mui-checked': {
                      color: colorAccent,
                    },
                  }}
                />
              )}
              label="Virtual Wallet"
            />
            <FormControlLabel
              style={{ color: 'white' }}
              value="external"
              name="wallets"
              control={(
                <Radio
                  {...controlProps('external')}
                  sx={{
                    color: 'white',
                    '&.Mui-checked': {
                      color: colorAccent,
                    },
                  }}
                />
              )}
              label="External Wallet"
            />
          </RadioGroup> */}
        </FormControl>
        <Tabs
          defaultActiveKey="virtual_wallet"
          transition={false}
          id="noanim-tab-example"
          className="my-4 overflow-hidden"
          onSelect={handleSelect}
        >
          <Tab eventKey="virtual_wallet" className="text-white" title="Virtual Wallet">
            {/* Content for Virtual Wallet */}

            <WagmiConfig config={wagmiConfig}>
              <VirtualWalletSendForm wagmiConfig={wagmiConfig} message={message} ethereumClient={ethereumClient} />
            </WagmiConfig>
          </Tab>
          <Tab eventKey="bank_wallet" className="text-white" title="Bank Wallet">
            {/* Content for Bank Wallet */}
            <WagmiConfig config={wagmiConfig}>

              <BankWalletSendForm wagmiConfig={wagmiConfig} message={message} ethereumClient={ethereumClient} />
            </WagmiConfig>
          </Tab>
          <Tab eventKey="sub_wallet" className="text-white" title=" onChain Wallet">
            {/* Content for Bank Wallet */}
            <WagmiConfig config={wagmiConfig}>
              <OnchainWalletSendForm wagmiConfig={wagmiConfig} message={message} ethereumClient={ethereumClient} />
            </WagmiConfig>
          </Tab>
        </Tabs>
        <div className='d-flex flex-column flex-md-row'>
          {/* <div className="col-lg-4 col-md-4 col-sm-12 mt-1">
            <SelectDiv style={{ display: "flex", width: "100%" }}>

              <label
                htmlFor="email"
                className="text-white"
                style={{ fontSize: "small" }}
              >
                Chose wallet
              </label>

              <CustomSelect
                value={mainSelectedWithdrawOption}
                onChange={(value) => {
                  setMainSelectedWithdrawOption(value.value);
                  setSelectedSubOption(value.value);
                }}
                options={mainWithdrawOptions}
                isSearchable={false}
              />


            </SelectDiv>
          </div> */}

          {/* From and To COmmented  */}

          {/* 
          {mainSelectedWithdrawOption == "bank_wallet" && <div className="col-lg-3 col-md-3 col-sm-12 mt-1">
            <SelectDiv style={{ display: "flex", width: "100%" }}>

              <label
                htmlFor="email"
                className="text-white"
                style={{ fontSize: "small" }}
              >
                From
              </label>
              <CustomSelect
                value={selectedSubOption}
                onChange={(value) => {
                  setSelectedSubOption(value.value);
                  setTogle(value.value)
                  setSelectedValue(value.value)
                }}
                options={
                  // mainSelectedWithdrawOption == "virtual_wallet" ? SubwalletOptionsVirtual :
                  mainSelectedWithdrawOption == "bank_wallet" ? SubwalletOptionsBank :
                    []
                }
                isSearchable={false}
              />
            </SelectDiv>
          </div>}

          {(mainSelectedWithdrawOption === "virtual_wallet"
            || mainSelectedWithdrawOption == "sub_wallet"
            // ||  mainSelectedWithdrawOption == "bank_wallet"
            //  || selectedSubOption == "intraledger_payment"
          ) &&
            <div className="col-lg-3 col-md-3 col-sm-12 mt-1">
              <SelectDiv style={{ display: "flex", width: "100%" }}>
                <label
                  htmlFor="email"
                  className="text-white"
                  style={{ fontSize: "small" }}
                >
                  From
                </label>
                <CustomSelect
                  value={togle}
                  onChange={(value) => {
                    console.log("🚀 ~ SendMetaMaskForm ~ value:", value)
                    setTogle(value.value)
                    setSelectedValue(value.value)
                  }}
                  options={
                    (mainSelectedWithdrawOption == "virtual_wallet")
                      //  || selectedSubOption == "intraledger_payment"
                      ? SubMainWallet :
                      mainSelectedWithdrawOption == "virtual_wallet"
                        // && selectedSubOption == "onChain_Transection"
                        ? SubOnchainWallet :
                        mainSelectedWithdrawOption == "bank_wallet" ? fromOptionsBank :
                          mainSelectedWithdrawOption == "sub_wallet" ? fromOptionsOnchain :

                            []
                  }
                  isSearchable={false}
                />
              </SelectDiv>
            </div>
          }
          {((mainSelectedWithdrawOption === "virtual_wallet" || mainSelectedWithdrawOption == "bank_wallet" ||
            mainSelectedWithdrawOption == "sub_wallet")
            && (selectedSubOption != "intraledger_payment" && selectedSubOption != "sepa_payment")
          ) &&
           <div className="col-lg-3 col-md-3 col-sm-12 mt-1">
              <SelectDiv style={{ display: "flex", width: "100%" }}>
                <label
                  htmlFor="email"
                  className="text-white"
                  style={{ fontSize: "small" }}
                >
                  To
                </label>
                <CustomSelect
                  value={toValue}
                  onChange={(value) => {
                    console.log("🚀 ~ SendMetaMaskForm ~ value:", value)
                    setToValue(value.value)
                  }}
                  options={(
                    (mainSelectedWithdrawOption === "virtual_wallet" && togle === "internal") ? toOptionsVirtualInternal :
                      (mainSelectedWithdrawOption === "virtual_wallet" && togle === "external") ? toOptionsVirtualExternal :
                        (mainSelectedWithdrawOption === "bank_wallet") ? toOptionsBank :
                          (mainSelectedWithdrawOption === "sub_wallet") ? toOptionsOnchain :
                            []
                  )}

                  isSearchable={false}
                />
              </SelectDiv>
            </div>
            } */}

          
          {
            selectedValue == "external" &&
            <>  <div className='col-lg-3 col-md-3 col-sm-12 mt-3 align-self-end'>
            <WagmiConfig config={wagmiConfig}>
              <Web3ButtonPage />
            </WagmiConfig>

          </div>
              <div className=" col-lg-3 col-md-3 col-sm-12 mt-1">


                <SelectDiv style={{ display: "flex", width: "100%" }}>
                  <label
                    htmlFor="email"
                    className="text-white"
                    style={{ fontSize: "small" }}
                  >
                    Select Chain
                  </label>
                  <CustomSelect
                    name="chain"
                    className="w-25"
                    value={
                      ChainValue
                    }
                    onChange={(
                      value,
                    ) => {
                      setChainValue(
                        value.value,
                      );
                    }}
                    options={
                      optionChain
                    }
                  />
                </SelectDiv>
              </div>
            </>
          }
        </div>
        {/* {selectedValue === 'external' && (
          <>
            <DropdownDiv className="col-lg-3 col-md-3 col-sm-12 mb-5 classNamee">
              <FormLabel style={{ color: 'white' }}>Select Chain</FormLabel>
              <CustomSelect
                name="chain"
                className="w-25"
                value={
                  ChainValue
                }
                onChange={(
                  value,
                ) => {
                  setChainValue(
                    value.value,
                  );
                }}
                options={
                  optionChain
                }
              />
            </DropdownDiv>
          </>
        )} */}

        {
          selectedValue === 'external' &&
           (
            <>
              <Web3Modal
                projectId={projectId}
                ethereumClient={ethereumClient}
                themeVariables={{
                  '--w3m-accent-color': '#4ce1b6',
                  '--w3m-z-index': 127,
                  '--w3m-overlay-backdrop-filter': 'blur(6px)',
                }}
              />
              <WagmiConfig config={wagmiConfig}>
                <WalletResponse setstatusCode={setstatusCode} chain={ChainValue} selectedValue={selectedValue} />
                <SendForm benficiaryToggle={selectedValue} togle={toValue} chain={ChainValue} errorMsg={message} />
              </WagmiConfig>
            </>
          )


          // : selectedSubOption === 'intraledger_payment' || selectedSubOption === 'sepa_payment' || selectedSubOption == 'onChain_Transection' || selectedSubOption === "bank_transfer" || (selectedValue == 'onChain_wallet' && toValue == "bank") ? (
          //   <WagmiConfig config={wagmiConfig}>
          //     <StrigaWithdraw benficiaryToggle={selectedSubOption} selectedValue={selectedSubOption} togle={toValue} errorMsg={message} />
          //   </WagmiConfig>
          // ) : (
          //   <WagmiConfig config={wagmiConfig}>
          //     {/* <WalletResponse setstatusCode={setstatusCode} /> */}
          //     <SendForm errorMsg={message} togle={toValue} walletOption={selectedSubOption} />
          //   </WagmiConfig>
          // )
        }

      </Panel>


    </>
  );
};

export default SendMetaMaskForm;
const getMainColor = (color) => {
  switch (color) {
    case 'success':
      return '#c1f9c1';
    case 'danger':
      return '#f1beb0';
    default:
      return '#f1beb0';
  }
};

const getLightColor = (color) => {
  switch (color) {
    case 'success':
      return '#22bb33';
    case 'danger':
      return '#ff4861';
    default:
      return '#f88e7d';
  }
};

const StyledAlert = styled(Alert).withConfig({
  shouldForwardProp: prop => !['bordered', 'neutral', 'colored'].includes(prop),
})`
  
  background-color: ${props => (getMainColor(props.variant))};
  border-color: ${props => (getMainColor(props.variant))};
  color: ${props => (getLightColor(props.variant))};
 
`;
const Grid = styled.div`
        display: grid;
        grid-template-columns: 20rem 33rem 50rem;
        width:100%;
        @media screen and (max-width: 876px) {
          grid-template: none;
          gap: 1rem;
  }
        `;
const DropdownDiv = styled.div`
        display: block; 
        float: right; 
        width: 25%;
        @media screen and (max-width: 876px) {
          width: 100%;
  }
        `;
const SelectDiv = styled.div`
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 5px 0px;
        font-size: 14px;
      `;