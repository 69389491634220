import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import {
  GoogleMap, useJsApiLoader, Marker, InfoWindow, LoadScript,
} from '@react-google-maps/api';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import { getLocation } from '../../../../utils/services/user.service';

const containerStyle = {
  height: '360px',
};

const user = JSON.parse(localStorage.getItem("user"));
const center = {
  lat: user?.data?.user?.LAT,
  lng: user?.data?.user?.LANG,
};

const PinWithInfoWindow = () => {
  const { t } = useTranslation('common');
  const [userLocationData, setUserLocationData] = useState([]);

  const { isLoaded } = useJsApiLoader({
    id: 'AIzaSyDy8rO9ai89Ro9vpQPD7fC8QR',
  });

  const [isOpened, setIsOpened] = useState(true);

  const onToggleIsOpen = () => {
    setIsOpened(!isOpened);
  };

  const onLoad = (infoWindow) => {

  };

  const fetchUserLocation = async () => {
    try {
      const res = await getLocation();
      setUserLocationData(res?.data.data);
    } catch (error) {

    }
  };

  useEffect(() => {
    fetchUserLocation()
  }, [])



  const Mymap = useMemo(() => {
    return <LoadScript
      googleMapsApiKey="AIzaSyCYx6E7TSAHDQMFB41DNksmX7v3zXaKt5Y"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={20}
      >
        <Marker position={center} onClick={onToggleIsOpen} />
        {isOpened && (
          <InfoWindow
            position={center}
            onLoad={onLoad}
            options={{ closeBoxURL: '', enableEventPropagation: true }}
          >
            <MapMarkerContent>
              {userLocationData?.country},{userLocationData?.capital},{userLocationData?.city}
            </MapMarkerContent>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>;
  }, [user, userLocationData]);
  return (
    <Col >

      <address className='d-flex'>{'IP Address:'} {userLocationData?.ip}</address>
      <address className='d-flex mb-4'>{'Your Position:'} {userLocationData?.country},{userLocationData?.capital},{userLocationData?.city}</address>
      {Mymap}

    </Col>
  );
};

export default PinWithInfoWindow;

// region STYLES

// endregion
const MapMarkerContent = styled.div`
  font-size: 12px;
  width: 130px;
  min-height: 10px;
  position: relative;
  color:black;

`;
