import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import styled from 'styled-components';
import FormField from '@/shared/components/form/FormField';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import validate from './validate';

const renderDatePicker = ({ dueDate, handleDateChange }) => (
  <InputDiv>
    <ChatSearchInput
      type="date"
      value={dueDate}
      min={new Date()}
      className="form-control mt-1"
      onChange={handleDateChange}
    />
  </InputDiv>
);

renderDatePicker.propTypes = {
  handleDateChange: PropTypes.func.isRequired,
  dueDate: PropTypes.shape().isRequired,
};

const ItemEditModalForm = ({
  onSubmit, dueDate, handlePriorityChange, priority,
  handleDateChange, currentEditItem, changeShowEditModal, priorityOptions,
}) => (
  <FormWrapper>
    <Form onSubmit={onSubmit} validate={validate} initialValues={currentEditItem}>
      {({ handleSubmit }) => (
        <FormContainer onSubmit={handleSubmit}>
          <FormGroup>
            <FormGroupLabel color="#000000 !important" className="typography-message">Title</FormGroupLabel>
            <FormGroupField>
              <Field
                name="title"
                type="text"
                component={FormField}
                placeholder="Title.."

              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel color="#000000 !important" className="typography-message">Description</FormGroupLabel>
            <FormGroupField>
              <Field
                component="textarea"
                name="description"
                placeholder="What to do.."
                color="#000000 !important"
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel color="#000000 !important" className="typography-message">Due Date</FormGroupLabel>
            <FormGroupField className="priority">
              <Field
                name="dueDate"
                type="text"
                dueDate={dueDate}
                handleDateChange={handleDateChange}
                component={renderDatePicker}
                required
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel className="typography-message" color="#000000 !important">Priority</FormGroupLabel>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              onChange={handlePriorityChange}
              options={priorityOptions}
              value={priority}
              defaultValue={priority}
              clearable={false}
            />
          </FormGroup>
          <FormButtonToolbar>
            <Button variant="primary" type="submit">
              {!currentEditItem ? 'Add' : 'Edit'}
            </Button>
            <Button variant="secondary" type="button" onClick={changeShowEditModal}>Cancel</Button>

          </FormButtonToolbar>
        </FormContainer>
      )}
    </Form>
  </FormWrapper>
);

ItemEditModalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  changeShowEditModal: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handlePriorityChange: PropTypes.func.isRequired,
  currentEditItem: PropTypes.shape(),
  dueDate: PropTypes.shape().isRequired,
  priority: PropTypes.shape().isRequired,
  priorityOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

ItemEditModalForm.defaultProps = {
  currentEditItem: null,
};

export default ItemEditModalForm;

const FormWrapper = styled.div`
  padding: 20px;
  color:black;
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;