/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable new-cap */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Ring } from '@uiball/loaders';
import { 
  faEye, faEnvelope, faUser, faFile,
} from '@fortawesome/free-regular-svg-icons';
// import Modal from 'react-modal'; // Import react-modal
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CustomSelect, CustomMultiSelect } from '../../../shared/components/CustomSelect';
import 'react-toastify/dist/ReactToastify.css';
import '../style/getorder.css';
import GetMarketplaceContracts from './GetMarketplaceContracts';
import {
  getMarketplaceContracts,
  updateOrderStatusSeller,
} from '../../../utils/services/user.service';
import SafeBoxTable from '../../../containers/Dashboards/Operations/SafeBoxes/component/SafeBoxTable';
import ContractTable from '../../../containers/Dashboards/Operations/Contract/component/ContractTable';

const GeneralContracts = () => {
  const history = useHistory();
  const [orders, setOrders] = useState([]);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState('Contract');
  const [selectedOrderStatus1, setSelectedOrderStatus1] = useState('Contract');
  const [selectedOrderStatus2, setSelectedOrderStatus2] = useState('all');
  const [selectedApproverStatus, setSelectedApproverStatus] = useState('approver');  
  const [isLoading, setIsLoading] = useState(false);
  const [isContracts, setIsContracts] = useState(true);
  const [isMarketplaceContracts, setIsMarketplaceContracts] = useState(false);
  const [isSafebox, setIsSafebox] = useState(false);
  
  const handleOrderStatusChange = (value) => {
      if (value.value === 'Order Marketplace') {
          setSelectedOrderStatus(value.value);
          setIsContracts(false);
          setIsMarketplaceContracts(true);
          setIsSafebox(false);
      } else if (value.value === 'Safebox') {
          setSelectedOrderStatus(value.value);
          setIsContracts(false);
          setIsMarketplaceContracts(false);
          setIsSafebox(true);
      } else if (value.value === 'Contract') {
          setSelectedOrderStatus(value.value);
          setIsContracts(true);
          setIsMarketplaceContracts(false);
          setIsSafebox(false);
      } else {
          // console.log('Unhandled condition:', value.value);
      }
  };

  const handleOrderStatusChange2 = (value) => {
    // console.log('handleOrderStatusChange', value.value);
    setSelectedOrderStatus2(value.value);
    const statusValue = value.value;
    const fetchOrders = async (typeVal, statusVal) => {
      const payload = {
        page_number: 1,
        limit: 10,
        type: typeVal,
        orderStatus: statusVal,
      };
      try {
        setIsLoading(true);
        const response = await getMarketplaceContracts(payload);
        const responseData = response.data;
        if (responseData.data && responseData.data.length > 0) {
          // console.log('getMarketplaceContracts data >>>>>>>>>>>>>>>>>>>> ', responseData.data);
          setOrders(responseData.data);
          setIsLoading(false);
        } else {
          console.error('No getMarketplaceContracts found.', response);
          setOrders([]);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error fetching getMarketplaceContracts:', error);
        setIsLoading(false);
      }
    };
  };

  const optionOrderStatus = [
    { value: 'Order Marketplace', label: 'Order Marketplace' },
    { value: 'Safebox', label: 'Safebox' },
    { value: 'Contract', label: 'Contract' },
  ];

  const optionOrderStatus1 = [
    { value: 'Order Marketplace', label: 'Order Marketplace' },
    { value: 'Safebox', label: 'Safebox' },
    { value: 'Contract', label: 'Contract' },
  ];

  const optionApproverStatus = [
    { value: 'approve', label: 'Approve' },
    { value: 'seller', label: 'Seller' },
    { value: 'buyer', label: 'Buyer' },
  ];

  const optionOrderStatus2 = [
    { value: 'pending', label: 'Pending' },
    { value: 'waiting', label: 'Waiting' },
    { value: 'shipped', label: 'Shipped' },
    { value: 'delivered', label: 'Delivered' },
    { value: 'completed', label: 'Completed' },
    { value: 'refund', label: 'Refund' },
  ];

  const handleApproverStatusChange = (value) => {
    setSelectedApproverStatus(value.value);
    const statusValue = value.value;
    const fetchOrders = async (typeVal, statusVal) => {
      const payload = {
        page_number: 1,
        limit: 10,
        type: typeVal,
        orderStatus: statusVal,
      };
      try {
        setIsLoading(true);
        const response = await getMarketplaceContracts(payload);
        const responseData = response.data;
        if (responseData.data && responseData.data.length > 0) {
          // console.log('getMarketplaceContracts data >>>>>>>>>>>>>>>>>>>> ', responseData.data);
          setOrders(responseData.data);
          setIsLoading(false);
        } else {
          console.error('No getMarketplaceContracts found.', response);
          setOrders([]);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error fetching getMarketplaceContracts:', error);
        setIsLoading(false);
      }
    };
    fetchOrders(statusValue, selectedOrderStatus);
  };

  const resetData = (order) => {
    setSelectedOrderStatus2('all');
    setSelectedApproverStatus('approver');
    // const fetchOrders = async (typeVal, statusVal) => {
    //   const payload = {
    //     page_number: 1,
    //     limit: 10,
    //     type: typeVal,
    //     orderStatus: statusVal,
    //   };
    //   try {
    //     const response = await getMarketplaceContracts(payload);
    //     const responseData = response.data;
    //     if (responseData.data && responseData.data.length > 0) {
    //       // console.log('getMarketplaceContracts data >>>>>>>>>>>>>>>>>>>> ', responseData.data);
    //       setOrders(responseData.data);
    //     } else {
    //       console.error('No getMarketplaceContracts found.', response);
    //     }
    //   } catch (error) {
    //     console.error('Error fetching getMarketplaceContracts:', error);
    //   }
    // };

    // fetchOrders('approver', 'all');
  };

  const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;

  return (
    <>
      <div
        className="loader-overlay"
        style={{
      display: isLoading ? 'flex' : 'none', // Use flex display when loading
      justifyContent: 'center', // Center horizontally
      alignItems: 'center', // Center vertically
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(255, 255, 255, 0.8)',
      zIndex: 999,
    }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <div hidden>
        <div className="container mt-1 containerDiv">
          <div className="row">
            <h2 className="text-center mb-5">Contracts List</h2>
            <div hidden className="col-12">
              <div>
                <table className="table text-center table-hover">
                  <thead className="thead-dark">
                    <tr>
                      <div 
                        className="row mx-0"
                        style={{ background: '#232329' }}
                      >
                        <div 
                          className="col-lg-4 col-md-4 col-sm-12" 
                          style={{ 
                          background: '#232329', color: 'white', textAlign: 'justify',
                        }}
                        >
                          <label htmlFor="color" style={{ fontSize: 'small' }} className="form-label text-white">Contract Type</label>
                          <InputDiv>
                            <DropdownDiv className="mb-1" style={{ textTransform: 'Capitalize' }}>
                              <CustomSelect
                                name="size"
                                id="size"
                                className="w-25 px-0"
                                value={selectedOrderStatus}
                                onChange={handleOrderStatusChange}
                                options={optionOrderStatus}
                              />  
                            </DropdownDiv>
                          </InputDiv>
                        </div>
                        <div 
                          className="col-lg-2 col-md-12 col-sm-12" 
                          style={{ 
                          background: '#232329', color: 'white', textAlign: 'justify',
                        }}
                        />
                        {isMarketplaceContracts && (
                          <>
                            <div 
                              className="col-lg-2 col-md-4 col-sm-12" 
                              style={{ 
                          background: '#232329', color: 'white', textAlign: 'justify',
                        }}
                            >
                              <label htmlFor="color" style={{ fontSize: 'small' }} className="form-label text-white">Order Status</label>
                              <InputDiv>
                                <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                                  <CustomSelect
                                    name="size"
                                    id="size"
                                    className="w-25 px-0"
                                    value={selectedOrderStatus2}
                                    onChange={handleOrderStatusChange2}
                                    options={optionOrderStatus2}
                                  />  
                                </DropdownDiv>
                              </InputDiv>
                            </div>
                            <div 
                              className="col-lg-2 col-md-4 col-sm-12" 
                              style={{ 
                          background: '#232329', color: 'white', textAlign: 'justify',
                        }}
                            >
                              <label htmlFor="color" style={{ fontSize: 'small' }} className="form-label text-white">Approver Status</label>
                              <InputDiv>
                                <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                                  <CustomSelect
                                    name="size"
                                    id="size"
                                    className="w-25 px-0"
                                    value={selectedApproverStatus}
                                    onChange={handleApproverStatusChange}
                                    options={optionApproverStatus}
                                  />  
                                </DropdownDiv>
                              </InputDiv>
                            </div>
                            <div 
                              className="col-lg-2 col-md-4 col-sm-12" 
                              style={{ 
                          background: '#232329', color: 'white', textAlign: 'justify',
                        }}
                            >
                              <label htmlFor="color" style={{ fontSize: 'small', color: 'transparent' }} className="form-label">.</label>
                              <Button   
                                variant="primary" 
                                className="btn btn-danger mt-1 w-100 py-1 pl-1 shadow-none"   
                                onClick={() => resetData(1)}
                              >
                                Reset  <i className="fa fa-refresh" />
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <div className="col-12" hidden>
              <div>
                <table className="table text-center table-hover">
                  <thead className="thead-dark">
                    <tr>
                      <div 
                        className="row mx-0"
                        style={{ background: '#232329' }}
                      >                       
                        <div 
                          className="col-lg-4 col-md-4 col-sm-12" 
                          style={{ 
                          background: '#232329', color: 'white', textAlign: 'justify',
                        }}
                        >
                          <label htmlFor="color" style={{ fontSize: 'medium' }} className="form-label text-white">Contract Type</label>
                          <InputDiv>
                            <DropdownDiv className="mb-1" style={{ textTransform: 'Capitalize' }}>
                              <CustomSelect
                                name="size"
                                id="size"
                                className="w-25 px-0"
                                value={selectedOrderStatus}
                                onChange={handleOrderStatusChange}
                                options={optionOrderStatus}
                              />  
                            </DropdownDiv>
                          </InputDiv>
                        </div>
                      </div>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isContracts && (
        <ContractTable title="Contract" />      
      )}
      {isMarketplaceContracts && (
        <GetMarketplaceContracts value1={selectedOrderStatus2} value2={selectedApproverStatus} />   
      )}
      {isSafebox && (
        <SafeBoxTable title="SafeBox" />
      )}
    </>
  );
};

export default GeneralContracts;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
