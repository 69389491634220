export const getDateDaysBack = (daysBack = 7) => {
  const now = new Date();

  return new Date(now.getFullYear(), now.getMonth(), now.getDate() - daysBack);
};

export const padTo2Digits = num => num.toString().padStart(2, '0');

export const getDayAndMonth = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(d);
  const day = d.getDate();
  return `${day} ${month} ${year}`;
};

export const getDatesFromYesterdayArr = (daysNumber = 14) => {
  const dates = [];

  for (let i = 1; i <= daysNumber; i += 1) {
    dates.push(getDayAndMonth(getDateDaysBack(i)));
  }

  return dates;
};
