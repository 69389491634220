import React from "react";
import { Col } from "react-bootstrap";
import TrendingUpIcon from "mdi-react/TrendingUpIcon";
import ProgressBar from "@/shared/components/ProgressBar";
import { Card } from "@/shared/components/Card";
import {
  DashboardBookingCard,
  DashboardBookingDescription,
  DashboardBookingTitle,
  DashboardBookingTotalWrap,
} from "./BookingCardDashboardElements";
import numeral from "numeral";

const ProgressChart = ({ val, subtitle,color, percentage }) => {

  let gradientColor;

  // Determine gradient color based on percentage value
  if (percentage >= 0 && percentage <= 30) {
    gradientColor = "pink";
  } else if (percentage >= 31 && percentage <= 70) {
    gradientColor = "yellow";
  } else {
    gradientColor = "green";
  }

  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <DashboardBookingCard>
          <DashboardBookingTotalWrap>
            <DashboardBookingTitle  color={color}>
              {val}
            </DashboardBookingTitle>
            <TrendingUpIcon />
          </DashboardBookingTotalWrap>
          <DashboardBookingDescription>{subtitle}</DashboardBookingDescription>
          <ProgressBar
            now={percentage}
            label={`${parseFloat(percentage).toFixed(2)}%`}
            rounded
            size="small"
            gradient={gradientColor}
            top
          />
        </DashboardBookingCard>
      </Card>
    </Col>
  );
};

export default ProgressChart;
