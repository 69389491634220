import Modal from "react-bootstrap/Modal";

const ChatWarningModal = ({ open }) => {

  return (
    <div
      className="position-absolute fixed-top w-100 h-100"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="d-flex justify-content-center align-items-center h-100">
        <div
          className="card mt-0 p-0 text-center"
          style={{ width: "50%", boxShadow: "none", fontFamily: "sans-serif" }}
        >
          <div
            className=" card-body p-3"
            style={{
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="row">
              <div className="col-12 text-danger">
                <i
                  className="fa fa-info-circle mt-1"
                  style={{ fontSize: "30px" }}
                  aria-hidden="true"
                />
              </div>
              <div className="col-12 text-danger">
                <p className="text-danger">
                  We regret to inform you that your access to our chat system
                  has been restricted due to a violation of our community
                  guidelines. Our administrators have taken this action to
                  ensure the integrity of our platform and maintain a positive
                  user experience. We understand that this may cause
                  inconvenience, and we encourage you to reach out to our
                  dedicated support team for further clarification and
                  assistance. Thanks
                  <b className="text-secondary">
                    {" "}
                    <u> support@custodiy.com</u>{" "}
                  </b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatWarningModal;
