import { createAction } from "redux-actions";
import { getUserBalanceExternalWallet } from "@/utils/services/user.service";
import { firstLetterToUpperCase } from "@/shared/helpers";
import { COIN_LOAD, SET_COIN_LOAD } from "./authActions";

export const fetchTopUsdExternalPriceRequest = createAction(
  "FETCH_USDC_EXTERNAL_PRICE_REQUEST"
);
export const fetchTopUsdExternalPriceSuccess = createAction(
  "FETCH_USDC_EXTERNAL_PRICE_SUCCESS"
);
export const fetchTopUsdExternalPriceError = createAction(
  "FETCH_USDC_EXTERNAL_PRICE_ERROR"
);
export const fetchLoad = createAction("FETCH_LOAD");
export const fetchUnLoad = createAction("FETCH_UN_LOAD");

export const fetchAllCurrencies = (storedValue) => async (dispatch) => {
  try {
    dispatch({
      type: COIN_LOAD,
    });

    const externalCoins = await getUserBalanceExternalWallet({
      wallet_address: storedValue,
    });
    // Save the response in local storage
    localStorage.setItem(
      "externalCoins",
      JSON.stringify(externalCoins.data.total_balanceUSDT)
    );
    dispatch({
      type: SET_COIN_LOAD,
    });

    dispatch(fetchTopUsdExternalPriceSuccess(externalCoins.data.data));
  } catch (e) {
    dispatch({
      type: SET_COIN_LOAD,
    });
    dispatch(
      fetchTopUsdExternalPriceError(
        firstLetterToUpperCase(
          e.response?.data?.error || e.response?.statusText
        )
      )
    );
  }
};
