import { Modal } from "react-bootstrap";
import {
  faEye,
  faEnvelope,
  faUser,
  faMap,
  faFile,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import styled from "styled-components";
import moment from "moment";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
  CardSubhead,
} from "@/shared/components/Card";
import Carousel from "@/shared/components/carousel/CarouselMultiply";
import "../style/home.css";
import "../style/Rating.css";
import "../style/storedetail.css";
import "../style/storedetailuser.css";
import "../style/homecard.css";
import "./customStyle.css";
import CarouselSingle from "../../../shared/components/carousel/CarouselSingle";

const NewDetailModal = ({ show, hide, data }) => {

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={hide}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: "large" }}>
            News Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" d-flex flex-row   col-lg-12 col-md-12 col-sm-12 ">
            {/* <Card className="d-flex w-50 "> */}
              {/* <CardBody> */}
                <CarouselSingle>
                  {data?.images?.map((item) => (
                    <img src={item} alt={`slide_${item}`} className=" " />
                  ))}
                </CarouselSingle>
              {/* </CardBody> */}
            {/* </Card> */}
            <div
              className="container d-flex flex-column gap-2   mt-0 containerDiv pt-0"
              style={{ overflowX: "hidden" }}
            >
              <h3
                style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                className="py-0 text-center "
              >
                <b>{data.title}</b>
              </h3>
              <p className="d-flex align-items-start  text-black ">{data.description}</p>
            </div>

         
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default NewDetailModal;
const HashLink = styled.button`
  border: none;
  background: transparent;
  color: #4ce1b6;
`;
