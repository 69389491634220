export const CONNECTED = 'CONNECTED';
export const DISCONNECTED = 'DISCONNECTED';
export const CONNECTED_ERROR_WALLET = 'CONNECTED_ERROR_WALLET';

export const connectWallets = wallets => (dispatch) => {
  // console.log('dsd', wallets);
  dispatch({
    type: CONNECTED,
    payload: wallets,
  });
};
export const disconnectWallets = () => (dispatch) => {
  dispatch({
    type: DISCONNECTED,
    payload: null,
  });
};
