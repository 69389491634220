/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';

import { Alert } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { MdOutlineNumbers } from 'react-icons/md';
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { AccountButton } from '../AccountElements';
import FormField from '../../form/FormField';
import { clearMsg } from '../../../../redux/actions/authActions';
import PasswordField from '../../form/Password';

const ResetPasswordForm = ({
  onSubmit, fieldUser, errorMsg, isRegister, isLoading, newPass, newOtp,
}) => {
  const dispatch = useDispatch();

  return (
    <Form
      onSubmit={(values, { resetForm }) => onSubmit(values, { resetForm })}
      validate={(values) => {
        const errors = {};
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!values.email) {
          errors.email = 'Required email';
        } else if (!emailRegex.test(values.email)) {
          errors.email = 'Invalid Email Field must contain @ and .';
        }
        if (newOtp) {
          const regex = /^[0-9\b]+$/;
        if (!values.otp) {
          errors.otp = 'Otp is Required!!';
        } else if (!regex.test(values.otp)) {
          errors.otp = 'Invalid Otp';
        }
        }
        if (newPass) {
          const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

          if (!values.password) {
            errors.password = 'Required password';
          } else if (values.password.length < 8) {
            errors.password = 'Password must be 8 characters long';
          } else if (!passwordRegex.test(values.password)) {
            errors.password = 'Password must contain at least one Uppercase,Lowercase,number,and special character';
          }
          if (!values.confirmPassword) {
            errors.confirmPassword = 'Required confirmPassword';
          } else if (values.confirmPassword.length < 8) {
            errors.confirmPassword = 'Password must be 8 characters long';
          } else if (!passwordRegex.test(values.password)) {
            errors.confirmPassword = 'Password must contain at least one Uppercase,Lowercase,number,and special character';
          } else if (values.password !== values.confirmPassword) {
            errors.confirmPassword = 'Password must be match';
          }
        }
        return errors;
      }}
    >
      {({ handleSubmit }) => (
        <FormContainer onSubmit={handleSubmit}>
          <Alert
            variant="danger"
            show={!!(!isRegister && errorMsg !== undefined)}
            style={{
             marginTop: '1rem', color: 'red', width: '100%', textAlign: 'center', 
            }}
            onClose={() => dispatch(clearMsg())}
            dismissible
          >
            {errorMsg}
          </Alert>
          <FormGroup>
            <div>
              <FormGroupLabel>{fieldUser}</FormGroupLabel>
            </div>
            <FormGroupField>
              <FormGroupIcon>
                <AlternateEmailIcon />
              </FormGroupIcon>
              <Field
                name="email"
                id="email"
                component={FormField}
                type="email"
                placeholder="example@gmail.com"
                className="input-without-border-radius"
                disabled={newOtp}
              />
            </FormGroupField>
          </FormGroup>
          {newOtp ? (
            <FormGroup>
              <FormGroupField>
                <FormGroupIcon>
                  <MdOutlineNumbers />
                </FormGroupIcon>
                <Field
                  name="otp"
                  id="otp"
                  component={FormField}
                  type="text"
                  placeholder="Enter Your Otp"
                  className="input-without-border-radius"
                  disabled={newPass}
                />
              </FormGroupField>
            </FormGroup>
          ) : ''}
          {
            newPass === true ? (
              <>
                <FormGroup>
                  <div>
                    <FormGroupLabel>Password</FormGroupLabel>
                  </div>
                  <FormGroupField>
                    <FormGroupIcon>
                      <AlternateEmailIcon />
                    </FormGroupIcon>
                    <Field
                      name="password"
                      id="password"
                      component={PasswordField}
                      type="password"
                      placeholder="Enter New Password"
                      className="input-without-border-radius"
                    />
                  </FormGroupField>
                </FormGroup>

                <FormGroup>
                  <div>
                    <FormGroupLabel>Confirm Password</FormGroupLabel>
                  </div>
                  <FormGroupField>
                    <FormGroupIcon>
                      <AlternateEmailIcon />
                    </FormGroupIcon>
                    <Field
                      name="confirmPassword"
                      id="password"
                      component={PasswordField}
                      type="password"
                      placeholder="Enter Confirm Password"
                      className="input-without-border-radius"
                    />
                  </FormGroupField>
                </FormGroup>
              </>
            ) : ''
          }
         
          <AccountButton variant={isLoading ? 'secondary' : 'primary'} type="submit">
            {newPass === true ? isLoading ? 'Loading...' : 'Reset' : isLoading ? 'Loading...' : ' Get Otp'}
          </AccountButton>
        </FormContainer>
      )}
    </Form>
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fieldUser: PropTypes.shape(),
};

ResetPasswordForm.defaultProps = {
  fieldUser: null,
};

export default ResetPasswordForm;
