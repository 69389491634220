/* eslint-disable radix */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import { React, useState, useEffect } from "react";
import Web3 from "web3";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useDisconnect, useAccount, useBalance } from "wagmi";
import config from "@/config/walletConnect";
import {
  connectWallets,
  disconnectWallets,
} from "@/redux/actions/walletActions";

import { walletBalance } from "../../../../redux/actions/authActions";
import Abi from "./Abi/tokenAbi.json";
import ContractAbi from "./Abi/contractAbi.json";
import { Button } from "../../../../shared/components/Button";
import { CustomSelect } from "../../../../shared/components/CustomSelect";

import { getTotalBalance } from "../../../../redux/actions/sidebarActions";
import { StyledAlert } from "../../AddSafebox/component/SafeboxForm";

function WalletResponse({ chain }) {
  const {
    REACT_APP_ETHERIUM_CHAIN,
    REACT_APP_SMART_CHAIN,
    REACT_APP_POLYGON_MATIC,
  } = config;
  const options = [
    { value: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48", label: "USDC" },
    { value: "0x8E870D67F660D95d5be530380D0eC0bd388289E1", label: "PAX" },
    { value: "0xdAC17F958D2ee523a2206206994597C13D831ec7", label: "USDT" },
    { value: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56", label: "BUSD" },
    { value: "0xBa08Da6b46E3dd153DD8b66A6E4cfd37A6359559", label: "CTY" },
    { value: "0x1aBaEA1f7C830bD89Acc67eC4af516284b1bC33c", label: "EUROC" },
    { value: "0x86B4dBE5D203e634a12364C0e428fa242A3FbA98", label: "GBPT" },
    { value: "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB", label: "JPYC" },
    // { value: '0x6f9CbE318a6BD9a199BD947451F0F26609ccC4F6', label: 'GULF' },
  ];
  const account = useAccount();
  const [isWallet, setisWallet] = useState(false);
  const [currentBalance, setCurrentBalance] = useState("");
  const { disconnect } = useDisconnect();
  const [msg, setMsg] = useState("");
  const [statusCode, setStCode] = useState(400);
  useEffect(() => {
    if (account.address !== undefined) return setisWallet(true);
    return setisWallet(false);
  }, [account]);

  // console.log("check wallet connect or not", isWallet);

  const dispatch = useDispatch();

  async function fetchBalance() {
    // console.log('------external');
    // console.log();
    const web3 = new Web3(window.ethereum);
    const { address } = account; // Replace with the Ethereum address you want to check.

    const connection = await web3.eth.getChainId();
    const chainId = Number(connection);

    const newChainId = Number(chain);
    // const chainsArray = [parseInt(process.env.REACT_APP_ETHERIUM_CHAIN), parseInt(process.env.REACT_APP_SMART_CHAIN), parseInt(process.env.REACT_APP_POLYGON_MATIC)];

    web3.eth
      .getBalance(address)
      .then((balance) => {
        // console.log(balance);
        const balanceInWei = balance.toString(); // The balance is returned in wei (smallest unit of ether).
        const balanceInEther = web3.utils.fromWei(balanceInWei, "ether"); // Convert to Ether.
        // console.log(`Balance of ${address}: ${balanceInEther} ETH`);
        let symbol = null;
        if (
          chainId === REACT_APP_SMART_CHAIN ||
          chainId === REACT_APP_POLYGON_MATIC
        ) {
          symbol = chainId === REACT_APP_SMART_CHAIN ? "BNB" : "MATIC";
        } else {
          symbol = "ETH";
        }
        setCurrentBalance(`${Number(balanceInEther).toFixed(4)} ${symbol}`);
        localStorage.setItem("userData", currentBalance);
      })
      .catch((error) => {
        setStCode(400);
        if (error.message === "Network Error") {
          // setMsg(`Please Check Your Network Something  went Wrong!!${error?.message}`);
        } else {
          // setMsg(`${error}`);
        }
        // setTimeout(() => {
        //   setMsg('');
        // }, 4000);
      });
  }
  if (isWallet) {
    dispatch(connectWallets(true));
  } else {
    dispatch(disconnectWallets(false));
  }
  async function changeChain(newChainId) {
    const web3 = new Web3(window.ethereum);
    await web3.currentProvider.sendAsync(
      {
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${newChainId.toString(16)}` }],
      },
      (err, result) => {
        if (err) {
          // setMsg(`Error switching chain:${err?.message}`);
        } else {
          fetchBalance();
          setMsg("");
          // console.log('Switched to new chain:', result);
          // Your code here...
        }
      }
    );
  }
  useEffect(() => {
    async function checkWallet() {
      if (chain === "BTC") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        disconnect();
        setisWallet(false);
      } else if (account) {
        const web3 = new Web3(window.ethereum);
        const connection = await web3.eth.getChainId();
        const chainId = Number(connection);

        const newChainId = Number(chain);
        // console.log(chainId, newChainId, '&&&&&&&&&&&&&&&&&&&');
        if (chainId === newChainId) {
          fetchBalance();
        } else {
          changeChain(newChainId);
        }
      }
    }
    if (chain) {
      if (window.ethereum) {
        checkWallet();
      }
    }
  }, [chain]);
  const { data, isError, isLoading } = useBalance({
    address: account.address,
  });
  async function fetchWalletBalance() {
    // console.log('------external');
    // console.log();
    const web3 = new Web3(window.ethereum);
    const { address } = account; // Replace with the Ethereum address you want to check.

    const connection = await web3.eth.getChainId();
    const chainId = Number(connection);

    const newChainId = Number(chain);
    // const chainsArray = [parseInt(process.env.REACT_APP_ETHERIUM_CHAIN), parseInt(process.env.REACT_APP_SMART_CHAIN), parseInt(process.env.REACT_APP_POLYGON_MATIC)];

    web3.eth
      .getBalance(address)
      .then((balance) => {
        // console.log(balance);
        const balanceInWei = balance.toString(); // The balance is returned in wei (smallest unit of ether).
        const balanceInEther = web3.utils.fromWei(balanceInWei, "ether"); // Convert to Ether.
        // console.log(`Balance of ${address}: ${balanceInEther} ETH`);
        let symbol = null;
        if (chainId === 97 || chainId === 80001) {
          symbol = chainId === 97 ? "BNB" : "MATIC";
        } else {
          symbol = "BNB";
        }
        setCurrentBalance(`${Number(balanceInEther).toFixed(4)} ${symbol}`);
        localStorage.setItem("userData", currentBalance);
      })
      .catch((error) => {
        setStCode(400);
        if (error.message === "Network Error") {
          // setMsg(`Please Check Your Network Something  went Wrong!!${error?.message}`);
        } else {
          // setMsg(`${error}`);
        }
        // setTimeout(() => {
        //   setMsg('');
        // }, 4000);
      });
  }
  if (window.ethereum) {
    fetchWalletBalance();
  }
  return (
    <>
      <StyledAlert
        variant={statusCode === 200 ? "success" : "danger"}
        show={msg !== ""}
        style={{
          marginTop: "1rem",
          width: "100%",
          textAlign: "center",
        }}
        onClose={() => {
          setMsg("");
        }}
        dismissible
      >
        {msg}
      </StyledAlert>
      {isWallet && (
        <>
          <div className="px-4 mb-4" style={{ marginTop: ".6rem" }}>
            <BalanceTitle>
              Connected Account: <Balance>{account.address}</Balance>
            </BalanceTitle>
            {/* <BalanceTitle>Balance: <Balance>{currentBalance}</Balance></BalanceTitle> */}
          </div>
        </>
      )}
    </>
  );
}

export default WalletResponse;

export const BalanceTitle = styled.p`
  font-size: 14px;
  text-align: left;
  font-weight: 600;
`;
export const Balance = styled.span`
  font-size: 16px;
  text-align: left;
  font-weight: 700;
  color: #4ce1b6;
`;
