import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ItemEditModalForm from './ItemEditModalForm';
import { createCalendarActivity, getCalendarActivities } from '../../../../utils/services/user.service';
import { fetchCalendarEvents } from '../../../DefaultPage/Calendar/components/events';

import { toast } from 'react-toastify';
const priorityOptions = [
  { value: 'high', label: 'high' },
  { value: 'medium', label: 'medium' },
  { value: 'low', label: 'low' },
];

const ItemEditModal = ({
  todoElements,
  currentEditItem,
  changeShowEditModal,
  editTodoElementAction,
  addTodoElementAction,
  showEditModal,
}) => {
  const [priority, setPriority] = useState({ value: 'low', label: 'low' });
  const [dueDate, setDueDate] = useState(new Date());
  const [dateValue, setDateValue] = useState(new Date());
  const dispatch = useDispatch()


  useEffect(() => {
    if (currentEditItem) {
      setPriority({ value: currentEditItem.priority, label: currentEditItem.priority });
      setDueDate(new Date(currentEditItem.date));
    } else {
      setPriority({ value: 'low', label: 'low' });
      setDueDate(new Date());
    }
  }, [currentEditItem, todoElements]);

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short',
    }).format(new Date(selectedDate));

    setDueDate(formattedDate);
    setDateValue(selectedDate)
  };

  const handlePriorityChange = (option) => {
    setPriority(option);
  };

  const handleSubmit = async (formData) => {
    let tempDueData = new Date();
    if (dueDate) {
      tempDueData = dueDate;
    }
    const updatedTodoData = {
      ...currentEditItem,
      title: formData.title,
      description: formData.description,
      priority: priority.value,
      date: dueDate,
    };

    if (!currentEditItem) {
      updatedTodoData.id = -(Date.now()); // new elements will have id < 0
      updatedTodoData.isCompleted = false;
      updatedTodoData.isArchived = false;
    }

    if (updatedTodoData.title !== '') {
      try {
        // Assuming createCalendarActivity is your API function
        const res = await createCalendarActivity({
          title: updatedTodoData.title,
          endDate: updatedTodoData.date,
          priority: updatedTodoData.priority,
          description: updatedTodoData.description,
        });

        if (res) {
          toast.success('Event Created Successfully')
          changeShowEditModal()

          dispatch(fetchCalendarEvents())

          if (!currentEditItem) {
            addTodoElementAction(updatedTodoData);
          } else {
            editTodoElementAction(updatedTodoData);
          }
          // window.location.reload()
          return
        }
        else {
          console.error('Error creating calendar activity:', res);
        }
        changeShowEditModal()
      } catch (error) {
        // Handle API request error
        console.error('Error in handleSubmit:', error);
      }
    }
  };


  return (
    <Modal
      show={showEditModal}
      onHide={changeShowEditModal}
    >
      <ItemEditModalForm
        initialValues={currentEditItem}
        onSubmit={handleSubmit}
        changeShowEditModal={changeShowEditModal}
        handleDateChange={handleDateChange}
        handlePriorityChange={handlePriorityChange}
        currentEditItem={currentEditItem}
        dueDate={dateValue}
        priority={priority}
        priorityOptions={priorityOptions}
      />
    </Modal>
  );
};

// ItemEditModal.propTypes = {
//   // todoElements: PropTypes.arrayOf(PropTypes.shape()).isRequired,
//   currentEditItem: PropTypes.shape(),
//   changeShowEditModal: PropTypes.func.isRequired,
//   editTodoElementAction: PropTypes.func.isRequired,
//   // addTodoElementAction: PropTypes.func.isRequired,
//   showEditModal: PropTypes.bool.isRequired,
// };

ItemEditModal.defaultProps = {
  currentEditItem: null,
};

export default ItemEditModal;
