import moment from "moment";
import { useEffect, useState } from "react";
import {
  getAllOtcMarketPlaceWebapp,
} from "../../../utils/services/user.service";
import "react-toastify/dist/ReactToastify.css";

import { Container, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import { Ring } from "@uiball/loaders";

function OtcMarketplaceLists() {
  const { t } = useTranslation("common");

  const itemsPerPages = 20;

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [pageCount, setPageCount] = useState(1);
  const [otcMarketplaceLists, setOtcMarketplaceLists] = useState([]);
  const [reFetch, setReFetch] = useState(false);

  useEffect(() => {
    const getAllOtcMarketLists = async () => {
      try {
        setIsLoading(true);
        const payload = {
          limit: itemsPerPages,
          page_number: currentPage,
        };
        const response = await getAllOtcMarketPlaceWebapp(payload);
        setIsLoading(false);
        if (response) {

          setOtcMarketplaceLists(response?.data?.response);
          const totalUsers = response.data.count;
          const pages = Math.ceil(totalUsers / itemsPerPages);
          setPageCount(pages);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    getAllOtcMarketLists();
  }, [currentPage, reFetch]);

  return (
    <>
      <Container>
        <div
          className="loader-overlay"
          style={{
            display: isLoading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 999,
          }}
        >
          <Ring size={65} color="#00897B" />
        </div>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t("OTC Marketplace Lists")}</h3>
          </Col>
        </Row>

        <div className="d-flex flex-wrap py-2">
          {otcMarketplaceLists.map((item, idx) => (
            <Col
              lg={6}
              md={12}
              xl={3}
              sm={12}
              className="py-2"
              style={{ textTransform: "capitalize" }}
              key={item._id}
            >
              <div
                className="card productCard"
                style={{ background: "#232329" }}
              >
                <div className="card-body">
                  <div className="d-flex justify-content-between gap-2">
                    <h4 className="mt-0 mb-1 text-white">Name:</h4>
                    <span style={{ width: "100%" }}>
                      <h4 className="card-title producttitle mt-0 mb-1 text-white">
                        {item?.market_place_name}
                      </h4>
                    </span>
                  </div>
                  <div className="d-flex justify-content-between gap-2">
                    <h4 className="mt-0 mb-1 text-white">Pool:</h4>
                    <span style={{ width: "100%" }}>
                      <h4 className="card-title producttitle mt-0 mb-1 text-white">
                        {item?.pool}
                      </h4>
                    </span>
                  </div>
                  <div className="row mb-1">
                    <div className="col-6">
                      <span
                        style={{
                          textTransform: "Capitalize",
                          float: "left",
                        }}
                        className={`badge px-2 py-1 mt-2 ${
                          item?.status === "rejected"
                            ? "badge-danger"
                            : item?.status === "pending"
                            ? "badge-warning"
                            : "badge-success"
                        }`}
                      >
                        <h6 className="currentPrice p-0.5"> {item?.status}</h6>
                      </span>
                    </div>
                    <div className="col-6">
                      <div
                        className="Rating mb-3"
                        style={{ float: "right", marginTop: "5%" }}
                      >
                        <Link to={`/otcmarketplacedetail?id=${item._id}`}>
                          <Button className="text-white p-1 rounded-md bg-primary">
                            View Details
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </div>
      </Container>
    </>
  );
}

export default OtcMarketplaceLists;
