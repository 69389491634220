import { useEffect, useState } from "react";
import {
  getMyOtcPackageTrx,
  getMyRequest,
  getMySubAccounts,
  getUserInfo1,
  switchAccount,
} from "../../../utils/services/user.service";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { faExternalLinkAlt, faRepeat } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@/shared/components/Button";
import { colorDarkText } from "@/utils/palette";
import { useSelector } from "react-redux";
import { Col } from "react-bootstrap";
import ChooseAccountModal from "./ChoseAccountModal";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import { useChainContext } from "../../context/context";
import SwitchUserModal from "./SwitchUserModal";
import Tooltip from "@/shared/components/Tooltip";
import { SidebarLinkIcon } from "../../Layout/components/sidebar/SidebarLink";
const GetMySubAccountsTable = ({ selectedTab }) => {
  const [myAllRequests, setMyAllRequests] = useState([]);

  const [showChooseAccountModal, setShowChooseAccountModal] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [requestUserId, setRequestUserId] = useState("");
  const [tabChangeFetch, setTabChnageFetch] = useState("");
  const [tooltipText, setTooltipText] = useState("");


  const [show, setShow] = useState(false);
  const loggedinUser = JSON.parse(localStorage.getItem("user"));

  const fetchMyAllRequests = async () => {
    setIsLoading(true);
    try {
      const payload = {
        limit: 100000,
        page_number: 1,
      };
      const res = await getMySubAccounts(payload);

      setMyAllRequests(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      console.error("An error occurred while fetching requests:", error);
      setIsLoading(false);
      // Handle the error as needed, such as showing a message to the user
    }
  };
  useEffect(() => {
    setTabChnageFetch(selectedTab);
  }, [selectedTab]);
  const onCopyWalletAddress = (item) => {
    navigator.clipboard.writeText(item);
    setTooltipText("Copied");
    setTimeout(() => {
      setTooltipText("");
    }, 5000); // Revert to original text after 5 seconds
  };

  useEffect(() => {
    fetchMyAllRequests();
  }, [reFetch, tabChangeFetch]);

  const getStatusColor = (status1) => {
    switch (status1) {
      case "completed":
        return "#4ce1b6";
      case "pending":
        return "#f6da6e";
      case "rejected":
        return "#ff4861";
      case "waiting":
        return "#f6da6e";
      case "declined":
        return "#ff4861";
      case "failed":
        return "#ff4861";
      case "success":
        return "green";
      default:
        return "#70bbfd";
    }
  };
  const header = [
    { accessor: "request_id", Header: "User Id" },
    { accessor: "distribution_type", Header: "Distribution Type" },
    { accessor: "symbol", Header: "Symbol" },
    { accessor: "walletAddress", Header: "Wallet Address" },
    { accessor: "type", Header: "Type" },
    { accessor: "token_distribution", Header: "Distribution Status" },
    { accessor: "createdDate", Header: "Created Date" },
    { accessor: "switch", Header: "Switch Account" },
  ];
  const tableConfi2 = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    isPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search...",
    withPagination: true,
  };

  const data = [];
  // Sort the myAllRequests array based on the status field
  myAllRequests.sort((a, b) => {
    if (a.status === "parent") return -1; // 'parent' status comes first
    if (b.status === "parent") return 1; // 'parent' status comes first
    return 0; // if both are not 'parent', maintain the original order
  });

  // Map the sorted items to the data array
  myAllRequests.map((item) =>
    data.push({
      request_id: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?._id}
        </h6>
      ),
      walletAddress: (
        <h6 >
          {["SOLANA", "LTX", "WEN", "SoLpy"].includes(item?.request_details[0]?.symbol) ? 
            (
              <Tooltip
                text={tooltipText || item.sol_address}
                placement="top"
                style={{ cursor: "pointer" }}
              >
                <span onClick={() => onCopyWalletAddress(item.sol_address)}>
                  {item.sol_address ? `${item.sol_address.slice(0, 5)}...${item.sol_address.slice(-5)}` : "--"}
                  <SidebarLinkIcon className="ml-1 lnr lnr-book" />
                </span>
              </Tooltip>
            ) 
            : 
            (
              <Tooltip
                text={tooltipText || item.address}
                placement="top"
                style={{ cursor: "pointer" }}
              >
                <span onClick={() => onCopyWalletAddress(item.address)}>
                  {item.address ? `${item.address.slice(0, 5)}...${item.address.slice(-5)}` : "--"}
                  <SidebarLinkIcon className="ml-1 lnr lnr-book" />
                </span>
              </Tooltip>
            )
          }
        </h6>
      ),
      
      distribution_type: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.request_details[0].distribution_type}
        </h6>
      ),
      createdDate: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {moment(item?.request_details[0].createdAt).format("MMM Do YY")}
        </h6>
      ),

      type: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.request_details[0]?.type || "--"}
        </h6>
      ),
      account_time: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.request_details[0]?.account_time || "--"}
        </h6>
      ),
      account_time_type: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.request_details[0]?.account_time_type || "--"}
        </h6>
      ),
      number_of_accounts: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.request_details[0]?.number_of_accounts || "--"}
        </h6>
      ),
      number_of_tokens: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.request_details[0]?.number_of_tokens || "--"}
        </h6>
      ),
      symbol: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.request_details[0]?.symbol || "--"}
        </h6>
      ),

      status: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{
            backgroundColor: getStatusColor(item?.request_details[0]?.status),
          }}
        >
          <h6 className="text-white">
            <b>{item?.request_details[0]?.status}</b>
          </h6>
        </span>
      ),

      token_distribution: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{
            backgroundColor: getStatusColor(item?.token_distribution),
          }}
        >
          <h6 className="text-white">
            <b>{item?.token_distribution}</b>
          </h6>
        </span>
      ),
      switch: (
        <div className="d-flex mt-3  justify-content-center  ">
          <FormButton
            type="submit"
            variant="outline-success"
            className="  w-80"
            onClick={() => {
              setShow(true);
              setRequestUserId(item?._id);
            }}
            disabled={loggedinUser.data.user.user_id == item?._id}
          >
            <FontAwesomeIcon icon={faRepeat} />{" "}
          </FormButton>
          {loggedinUser.data.user.user_id == item?._id && (
            <FormButton
              type="submit"
              variant="contain-success"
              className="  w-80"
              // onClick={() => {
              //   setShow(true);
              //   setRequestUserId(item?._id)
              // }}
              // disabled={item?.request_details[0].status !== "Approved"}
            >
              Logedin Account
            </FormButton>
          )}
        </div>
      ),
    })
  );

  return (
    <>
      {/* <Col> */}
      <div className=" flex flex-column overflow-hidden ">
        <div
          className="loader-overlay"
          style={{
            display: isLoading ? "flex" : "none", // Use flex display when loading
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 999,
          }}
        >
          <Ring size={65} color="#00897B" />
        </div>
        {myAllRequests.length > 0 ? (
          <>
            <ReactTableBase
              columns={header}
              data={data}
              tableConfig={tableConfi2}
            />
          </>
        ) : (
          <h3 className="d-flex align-items-center text-center justify-content-center  ">
            No data found
          </h3>
        )}
      </div>
      {/* </Col> */}

      {showChooseAccountModal && (
        <ChooseAccountModal
          show={showChooseAccountModal}
          hide={() => setShowChooseAccountModal(false)}
          reFetch={() => setReFetch(!reFetch)}
        />
      )}

      {show && (
        <SwitchUserModal
          show={show}
          hide={() => setShow(false)}
          reFetch={() => setReFetch(!reFetch)}
          requestUserId={requestUserId}
        />
      )}
    </>
  );
};
export default GetMySubAccountsTable;
const HashLink = styled.button`
  border: none;
  background: transparent;
  color: #4ce1b6;
`;
const LegendCol = styled("div")`
  flex-shrink: 2;
  flex-grow: 0;

  :first-child {
    flex-shrink: 0;
    flex-basis: 100px;
  }

  :last-child {
    flex-grow: 1;
  }
`;

const LegendColTitle = styled("div")`
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.1;
  color: ${colorDarkText};
`;

const LegendColContent = styled("ul")`
  padding: 0;
  list-style: none;
  color: ${colorDarkText};

  li {
    margin-top: 15px;
    font-size: 14px;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
