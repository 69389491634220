import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import { CiUser } from "react-icons/ci";

import { Alert } from 'react-bootstrap';
import styled from 'styled-components';
import PasswordField from '@/shared/components/form/Password';
import { FaPhoneAlt } from "react-icons/fa";

import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { marginLeft } from '@/utils/directions';

import {
  AccountButton, AccountButtons, ForgotFormGroup, PasswordFormGroup,
} from '../AccountElements';
import FormField from '../../form/FormField';
import { clearMsg } from '../../../../redux/actions/authActions';
import { CustomSelect } from '../../CustomSelect';
import { useChainContext } from '../../../../containers/context/context';
import { FaMapLocationDot } from "react-icons/fa6";
import { CgWebsite } from "react-icons/cg";

const RegisterForm = ({
  onSubmit, errorMsg, isRegister, isLoading,
}) => {
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState('user')
  console.log("🚀 ~ selectedRole:", selectedRole)
  const { setSelectRole } = useChainContext()


  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) => {
        const errors = {};
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!values.firstName) {
          errors.firstName = 'Required first name field!!';
        }
        if (!values.lastName && selectedRole === "user") {
          errors.lastName = 'Required last name field!!';
        }
        if (selectedRole != 'user' && !values.complete_address) {
          errors.complete_address = 'Required   complete_address field!!';
        }

        if (selectedRole == 'project' && !values.website) {
          errors.website = 'Required  website field!!';
        }
        if (selectedRole == 'project' && !values.project_company) {
          errors.project_company = 'Required  project_company field!!';
        }

        if (selectedRole == 'company' && !values.phone_number) {
          errors.phone_number = 'Required  phone_number field!!';
        }
        if (!values.email) {
          errors.email = 'Required email field!!';
        } else if (!emailRegex.test(values.email)) {
          errors.email = 'Invalid Email Field must contain @ and .';
        }
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        if (!values.password) {
          errors.password = 'Required password field!!';
        } else if (values.password.length < 8) {
          errors.password = 'Password must be 8 characters long';
        } else if (!passwordRegex.test(values.password)) {
          errors.password = 'Password must contain at least one Uppercase,Lowercase,number,and special character';
        }
        if (!values.confirmPassword) {
          errors.confirmPassword = 'Required confirm Password field!!';
        } else if (values.confirmPassword.length < 8) {
          errors.confirmPassword = 'Password must be 8 characters long';
        } else if (!passwordRegex.test(values.password)) {
          errors.confirmPassword = 'Password must contain at least one Uppercase,Lowercase,number,and special character';
        } else if (values.password !== values.confirmPassword) {
          errors.confirmPassword = 'Password must be match';
        }
        return errors;
      }}
    >
      {({ handleSubmit }) => (
        <FormContainer onSubmit={handleSubmit}>
          <Alert
            variant="danger"
            show={!!isRegister}
            style={{
              marginTop: '1rem', color: 'red', width: '100%', textAlign: 'center',
            }}
            onClose={() => dispatch(clearMsg())}
            dismissible
          >
            {errorMsg}
          </Alert>
          <FormGroup>
            <FormGroupLabel>Chose Role</FormGroupLabel>
            <FormGroupField>
              <FormGroupIcon>
                <CiUser />
              </FormGroupIcon>
              <div className='col-12'>

                <Field name="role">
                  {({ input }) => (
                    <select
                      {...input}
                      className="input-without-border-radius bg-transparent w-100 col-12"
                      style={{
                        height: '32px',
                        color: "#999999"
                      }}
                      onChange={(event) => {
                        input.onChange(event); // This is required to update the form state
                        setSelectedRole(event.target.value);
                        setSelectRole(event.target.value);
                      }}
                    >
                      <option value="user">User</option>
                      <option value="company">Company</option>
                      <option value="project">Project</option>
                    </select>
                  )}
                </Field>
              </div>

            </FormGroupField>
          </FormGroup>
          {selectedRole == "user" ?  <>
          <FormGroup>
            <FormGroupLabel>First Name</FormGroupLabel>
            <FormGroupField>
              <FormGroupIcon>
                <AccountOutlineIcon />
              </FormGroupIcon>
              <Field
                name="firstName"
                component={FormField}
                type="text"
                placeholder="First Name"
                className="input-without-border-radius" />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
              <FormGroupLabel>Last name</FormGroupLabel>
              <FormGroupField>
                <FormGroupIcon>
                  <AccountOutlineIcon />
                </FormGroupIcon>
                <Field
                  name="lastName"
                  component={FormField}
                  type="text"
                  placeholder="Last name"
                  className="input-without-border-radius" />
              </FormGroupField>
            </FormGroup></> :   <FormGroup>
            <FormGroupLabel> Name</FormGroupLabel>
            <FormGroupField>
              <FormGroupIcon>
                <AccountOutlineIcon />
              </FormGroupIcon>
              <Field
                name="firstName"
                component={FormField}
                type="text"
                placeholder=" Name"
                className="input-without-border-radius" />
            </FormGroupField>
          </FormGroup>}
          <FormGroup>
            <FormGroupLabel>E-mail</FormGroupLabel>
            <FormGroupField>
              <FormGroupIcon>
                <AlternateEmailIcon />
              </FormGroupIcon>
              <Field
                name="email"
                component={FormField}
                type="email"
                placeholder="example@mail.com"
                required
                className="input-without-border-radius"
              />
            </FormGroupField>
          </FormGroup>
        

          {/* if role company  */}
          {selectedRole === "company" &&
            <>
              <FormGroup>
                <FormGroupLabel>Address</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <FaMapLocationDot />
                  </FormGroupIcon>
                  <Field
                    name="complete_address"
                    component={FormField}
                    type="text"
                    placeholder="Compnay Address"
                    className="input-without-border-radius" />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Phone Number</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <FaPhoneAlt />
                  </FormGroupIcon>
                  <Field
                    name="phone_number"
                    component={FormField}
                    type="text"
                    placeholder=" Company Phone Number"
                    className="input-without-border-radius" />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Vat Number</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <FaPhoneAlt />
                  </FormGroupIcon>
                  <Field
                    name="vat_number"
                    component={FormField}
                    type="text"
                    placeholder="Vat Number"
                    className="input-without-border-radius" />
                </FormGroupField>
              </FormGroup>


            </>}


          {/* if role Project  */}
          {selectedRole === "project" &&
            <>

              <FormGroup>
                <FormGroupLabel>Address</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <FaMapLocationDot />
                  </FormGroupIcon>
                  <Field
                    name="complete_address"
                    component={FormField}
                    type="text"
                    placeholder="project Address"
                    className="input-without-border-radius" />
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>Project Company</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <AccountOutlineIcon />
                  </FormGroupIcon>
                  <Field
                    name="project_company"
                    component={FormField}
                    type="text"
                    placeholder="project Company"
                    className="input-without-border-radius" />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Vat Number</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <FaPhoneAlt />
                  </FormGroupIcon>
                  <Field
                    name="vat_number"
                    component={FormField}
                    type="text"
                    placeholder="Vat Number"
                    className="input-without-border-radius" />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>Website</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <CgWebsite />
                  </FormGroupIcon>
                  <Field
                    name="website"
                    component={FormField}
                    type="text"
                    placeholder="project Website"
                    className="input-without-border-radius" />
                </FormGroupField>
              </FormGroup>
            </>}


          <PasswordFormGroup>
            <FormGroupLabel>Password</FormGroupLabel>
            <FormGroupField>
              <Field
                name="password"
                component={PasswordField}
                placeholder="Password"
                className="input-without-border-radius"
                required
                keyIcon
              />
            </FormGroupField>
          </PasswordFormGroup>
          <ForgotFormGroup>
            <FormGroupLabel>Confirm Password</FormGroupLabel>
            <FormGroupField>
              <Field
                name="confirmPassword"
                component={PasswordField}
                placeholder="Confirm Password"
                className="input-without-border-radius"
                required
                keyIcon
              />
            </FormGroupField>
          </ForgotFormGroup>
          <RegisterButtons>
            <AccountButton variant={isLoading ? 'secondary' : 'primary'} type="submit">
              {isLoading ? 'Loading...' : 'Sign Up'}
            </AccountButton>
          </RegisterButtons>
        </FormContainer>
      )}
    </Form>
  );
};

RegisterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};



export default RegisterForm;

// region STYLES

const RegisterButtons = styled(AccountButtons)`
  ${marginLeft}: 0!important;
  margin-bottom: 20px;
  
  button {
    margin-bottom: 0;
  }
`;

// endregion
