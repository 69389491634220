/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPaperPlane } from '@fortawesome/free-solid-icons';
import SendIcon from 'mdi-react/SendIcon';
import PaperclipIcon from 'mdi-react/PaperclipIcon';
import EmoticonIcon from 'mdi-react/EmoticonIcon';
import { 
  colorAccent, 
  colorAdditional,
  colorBackgroundBody,
  colorFieldsBorder, 
  colorText,
} from '@/utils/palette';
import {
  right,
  marginLeft,
  paddingRight,
  paddingLeft,
} from '@/utils/directions';

const ChatField = ({ recieveMessages, onClickChat }) => {
  const [messageInput, setMessageInput] = useState('');
  const [messageInputClick, setMessageClick] = useState(true);

  function handleButtonClick() {
    recieveMessages(messageInput);
    setMessageInput('');
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleButtonClick();
    }
  };

  function handleOnClickChat() {
    onClickChat(messageInputClick);
  }

  return (
    <ChatTextField>
      <ChatForm>
        <ChatFieldInput
          type="text"
          name="chatField"
          value={messageInput}
          onChange={(e) => { setMessageInput(e.target.value); }}
          onClick={handleOnClickChat} 
          onKeyPress={handleKeyPress}
          placeholder="Type here…"
        />
        <ChatFieldControls>
          <ChatIconButton
            hidden
            type="button"
            onClick={(e) => { e.preventDefault(); }}
          >
            <EmoticonIcon />
          </ChatIconButton>
          <ChatIconButton
            hidden
            type="button"
            onClick={(e) => { e.preventDefault(); }}
          >
            <PaperclipIcon />
          </ChatIconButton>
          <ChatIconButton
            type="button"
            onClick={handleButtonClick} 
          >
            <SendIcon />
          </ChatIconButton>
        </ChatFieldControls>
      </ChatForm>
    </ChatTextField>
  );
};

export default ChatField;

// region STYLES

const ChatTextField = styled.div`
  height: 64px;
  width: 100%;
  // background-color: red;
  border-top: 1px solid ${colorFieldsBorder};
`;

const ChatForm = styled.form`
  width: 100%;
  position: relative;
  padding: 12px 20px;
  background-color: #e7e7e7;
`;

const ChatFieldInput = styled(Form.Control)`
  width: 100%;
  height: 36px;
  border-radius: 18px;
  font-size: 12px;
  line-height: 1.33;
  transition: 0.3s;
  ${paddingLeft}: 15px;
  ${paddingRight}: 65px;
  // background-color: ${colorBackgroundBody};
  background-color: white;
  border: 1px solid ${colorBackgroundBody};
  color: ${colorText};

  &:focus, &:hover, &:active {
    border-color: ${colorAccent};
    outline: none;
    box-shadow: none;
  }
`;

const ChatFieldControls = styled.div`
  position: absolute;
  top: 12px;
  height: 36px;
  display: flex;
  ${right}: 35px;
`;

const ChatIconButton = styled.button`
  background: transparent;
  border: none;
  height: 36px;
  cursor: pointer;
  padding: 0 5px;
  ${marginLeft}: 5px;

  svg {
    height: 16px;
    width: 16px;
    fill: ${colorAdditional};
    transition: 0.3s;
  }

  &:hover {

    svg {
      fill: ${colorAccent};
    }
  }
`;

// endregion
