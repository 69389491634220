/* eslint-disable radix */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import { React, useState, useEffect } from 'react';
import Web3 from 'web3';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import {
  useDisconnect, useAccount, useBalance,
} from 'wagmi';
import config from '@/config/walletConnect';
import { getShortCryptoAddress } from '@/utils/helpers';
import Tooltip from '@/shared/components/Tooltip';
import { connectWallets, disconnectWallets } from '@/redux/actions/walletActions';
import { colorAccent } from '../../../../utils/palette';
import { walletBalance } from '../../../../redux/actions/authActions';
import Abi from './Abi/tokenAbi.json';
import ContractAbi from './Abi/contractAbi.json';
import { Button } from '../../../../shared/components/Button';
import { CustomSelect } from '../../../../shared/components/CustomSelect';
import { getTotalBalance } from '../../../../redux/actions/sidebarActions';
import { StyledAlert } from '../../AddSafebox/component/SafeboxForm';
import { SidebarLinkIcon } from '../../../Layout/components/sidebar/SidebarLink';
import {
  WalletConnection,
  WalletInfoWrapper,
  WalletStatRowTitle,
  WalletStatRowValue,
  WalletStatRowWrapper,
} from '../../Wallet/styled';


function WalletResponseNew({ setstatusCode, chain, selectedValue }) {
  const {
    REACT_APP_ETHERIUM_CHAIN, REACT_APP_SMART_CHAIN, REACT_APP_POLYGON_MATIC,
  } = config;
  const options = [
    { value: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', label: 'USDC' },
    { value: '0x8E870D67F660D95d5be530380D0eC0bd388289E1', label: 'PAX' },
    { value: '0xdAC17F958D2ee523a2206206994597C13D831ec7', label: 'USDT' },
    { value: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', label: 'BUSD' },
    { value: '0xBa08Da6b46E3dd153DD8b66A6E4cfd37A6359559', label: 'CTY' },
    { value: '0x1aBaEA1f7C830bD89Acc67eC4af516284b1bC33c', label: 'EUROC' },
    { value: '0x86B4dBE5D203e634a12364C0e428fa242A3FbA98', label: 'GBPT' },
    { value: '0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB', label: 'JPYC' },
    // { value: '0x6f9CbE318a6BD9a199BD947451F0F26609ccC4F6', label: 'GULF' },
  ];
  const account = useAccount();
  const { t } = useTranslation('common');
  const [isWallet, setisWallet] = useState(false);
  const [currentBalance, setCurrentBalance] = useState('');
  const [currentBalance1, setCurrentBalance1] = useState('');
  const [currentBalance2, setCurrentBalance2] = useState('');
  const { disconnect } = useDisconnect();
  const [msg, setMsg] = useState('');
  const [statusCode, setStCode] = useState(400);
  const onCopyWalletAddress = () => {
    navigator.clipboard.writeText(JSON.parse(localStorage.getItem('user')).data.user.address);
  };

  useEffect(() => {
    if (account.address !== undefined) return setisWallet(true);
    return setisWallet(false);
  }, [account]);
  const dispatch = useDispatch();

  async function fetchBalance() {
    // console.log('------external');
    // console.log();
    const web3 = new Web3(window.ethereum);
    const { address } = account; // Replace with the Ethereum address you want to check.

    const connection = await web3.eth.getChainId();
    const chainId = Number(connection);
    // console.log('chain  aaaaaaaaaaaaaaaaaaaaaaaaaa ', chain);
    const newChainId = Number(chain);
    // const chainsArray = [parseInt(process.env.REACT_APP_ETHERIUM_CHAIN), parseInt(process.env.REACT_APP_SMART_CHAIN), parseInt(process.env.REACT_APP_POLYGON_MATIC)];

    web3.eth.getBalance(address)
      .then((balance) => {
        // console.log(balance);
        const balanceInWei = balance.toString(); // The balance is returned in wei (smallest unit of ether).
        const balanceInEther = web3.utils.fromWei(balanceInWei, 'ether'); // Convert to Ether.
        // console.log(`Balance of ${address}: ${balanceInEther} ETH`);
        let symbol = null;
        if (chainId === REACT_APP_SMART_CHAIN || chainId === REACT_APP_POLYGON_MATIC) {
          symbol = (chainId === REACT_APP_SMART_CHAIN) ? 'BNB' : 'MATIC';
        } else {
          symbol = 'ETH';
        }
        setCurrentBalance(`${Number(balanceInEther).toFixed(4)} ${symbol}`);
        setCurrentBalance1(Number(balanceInEther).toFixed(4));
        setCurrentBalance2(symbol);
        localStorage.setItem('userData', currentBalance);
        localStorage.setItem('userData1', currentBalance1);
        localStorage.setItem('userData2', currentBalance2);
      })
      .catch((error) => {
        setStCode(400);
        if (error.message === 'Network Error') {
          // setMsg(`Please Check Your Network Something  went Wrong!!${error?.message}`);
        } else { // setMsg(`${error}`); 
        }
        // setTimeout(() => {
        //   setMsg('');
        // }, 4000);
      });
  }

  const newChainIdVal = 97;
  localStorage.setItem('metaMaskChain', `0x${newChainIdVal.toString(16)}`);
  localStorage.setItem('metaMaskVal', 0);
  localStorage.setItem('metaMaskVal1', 10);
  if (isWallet) {
    dispatch(connectWallets(true));
    localStorage.setItem('metaMaskVal', account.address);
    localStorage.setItem('metaMaskVal1', 10);
  } else {
    dispatch(disconnectWallets(false));
  }

  async function changeChain(newChainId) {
    const web3 = new Web3(window.ethereum);
    // console.log('1111111');
    localStorage.setItem('metaMaskChain', `0x${newChainId.toString(16)}`);
    await web3.currentProvider.sendAsync(
      {
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${newChainId.toString(16)}` }],
      },
      (err, result) => {
        if (err) {
          // setMsg(`Error switching chain:${err?.message}`);
        } else {
          fetchBalance();
          setMsg('');
          // console.log('Switched to new chain:', result);
          // Your code here...
        }
      },
    );
  }
  useEffect(() => {
    async function checkWallet() {
      if (chain === 'BTC') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        disconnect();
        setisWallet(false);
      } else if (account) {
        const web3 = new Web3(window.ethereum);
        const connection = await web3.eth.getChainId();
        const chainId = Number(connection);

        const newChainId = Number(chain);
        // console.log(chainId, newChainId, '&&&&&&&&&&&&&&&&&&&');
        if (chainId === newChainId) {
          fetchBalance();
        } else {
          changeChain(newChainId);
        }
      }
    }
    if (chain) {
      if (window.ethereum) {
        checkWallet();
      }
    }
  }, [chain]);
  const { data, isError, isLoading } = useBalance({
    address: account.address,
  });
  useEffect(() => {
    let isMounted = true;

    async function fetchWalletBalance() {
      try {
        const web3 = new Web3(window.ethereum);
        const { address } = account;

        const connection = await web3.eth.getChainId();
        const chainId = Number(connection);

        const newChainId = Number(chain);
        web3.eth.getBalance(address)
          .then((balance) => {
            if (isMounted) {
              const balanceInWei = balance.toString();
              const balanceInEther = web3.utils.fromWei(balanceInWei, 'ether');
              let symbol = null;
              if (chainId === 97 || chainId === 80001) {
                symbol = (chainId === 97) ? 'BNB' : 'MATIC';
              } else {
                symbol = 'BNB';
              }
              setCurrentBalance(`${Number(balanceInEther).toFixed(4)} ${symbol}`);
              setCurrentBalance1(Number(balanceInEther).toFixed(4));
              setCurrentBalance2(symbol);
              localStorage.setItem('userData', currentBalance);
              localStorage.setItem('userData1', currentBalance1);
              localStorage.setItem('userData2', currentBalance2);
            }
          })
          .catch((error) => {
            if (isMounted) {
              setStCode(400);
              if (error.message === 'Network Error') {
                // setMsg(`Please Check Your Network Something went Wrong!!${error?.message}`);
              } else {
                // setMsg(`${error}`);
              }
              // setTimeout(() => {
              //   setMsg('');
              // }, 4000);
            }
          });
      } catch (error) {
        // Handle any errors that occur during the API request
      }
    }

    if (window.ethereum) {
      fetchWalletBalance();
    }

    // Cleanup function
    return () => {
      isMounted = false;
      // Any cleanup tasks can be added here
    };
  }, [account, account.address, chain, currentBalance, currentBalance1, currentBalance2]); // Add dependencies if needed

  // console.log('isWalletisWalletisWalletisWalletisWalletisWalletisWallet', isWallet);

  var externalBalance = localStorage?.getItem('externalCoins');
  var totalExternalBalance = externalBalance?.replace(/"/g, '');

  var numberValue = parseFloat(totalExternalBalance);

  return (
    <>
      <StyledAlert
        variant={statusCode === 200 ? 'success' : 'danger'}
        show={msg !== ''}
        style={{
          marginTop: '1rem', width: '100%', textAlign: 'center',
        }}
        onClose={() => {
          setMsg('');
        }}
        dismissible
      >
        {msg}
      </StyledAlert>
      {
        isWallet && (
          <>
            <div style={{ marginTop: '.6rem' }}>
              <WalletStatRowWrapper>
                <WalletStatRowTitle>{t('wallet.address')}</WalletStatRowTitle>
                <WalletStatRowValue variant="small">
                  {account.address && (
                    <span>{getShortCryptoAddress(account.address)}</span>
                  )}
                  <Tooltip text="Copy wallet address" placement="top" dir="ltr">
                    <SidebarLinkIcon className="lnr lnr-book" onClick={onCopyWalletAddress} />
                  </Tooltip>
                </WalletStatRowValue>
              </WalletStatRowWrapper>
              <WalletStatRowWrapper className="mt-4">
                <WalletStatRowTitle>Total Balance</WalletStatRowTitle>
                <WalletStatRowValue>
                  {numberValue ==='undefined' ? (
                    <Spinner style={{ color: colorAccent }} />
                  ) : (
                    <>{numberValue} $</>
                  )}
                  
                </WalletStatRowValue>
              </WalletStatRowWrapper>
            </div>
          </>
        )
      }
    </>
  );
}

export default WalletResponseNew;

export const BalanceTitle = styled.p`
 font-size: 14px;
 text-align: left;
 font-weight: 600;
`;
export const Balance = styled.span`
font-size: 16px;
text-align: left;
font-weight: 700;
color:#4ce1b6
`;
