/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SendForm from './component/SendForm';
import SendMetaMaskForm from './component/SendMetaMaskForm';

const SendPage = () => (
  <Container className="dashboard">
    <Row hidden>
      <Col md={12}>
        <h3 className="page-title mx-3">Send</h3>
      </Col>
    </Row>
    <Row>
      <SendMetaMaskForm />
      {/* <SendForm errorMsg={message} /> */}
    </Row>

  </Container>
  );
export default SendPage;
