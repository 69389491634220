import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import ChatCard from './components/ChatCard';
import './components/chat.css';
import ChatButton from './components/ChatButton';
import ChatBox from './components/ChatBox';

const Chat = () => {
  const { t } = useTranslation('common');

  return (
    <Container>
      <Row className="mx-3">
        <Col xs={12} lg={2} className="py-1 text-center" />
        <Col xs={12} md={6} lg={8} className="py-1 text-center">
          <h2 className="mb-0 mt-0" style={{ fontSize: 'x-large' }}>Chat Application</h2>
        </Col>
        <Col xs={12} lg={2} className="py-1 text-center" />
      </Row>
      <Row>
        {/* <ChatCard /> */}
        {/* <ChatButton /> */}
        <ChatBox />
      </Row>
    </Container>
  );
};

export default Chat;
