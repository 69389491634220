/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Ring } from '@uiball/loaders';
import Button from 'react-bootstrap/Button';
import EyeIcon from 'mdi-react/EyeIcon';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye, faEnvelope, faUser, faMap,
}
  from '@fortawesome/free-regular-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Panel from '@/shared/components/Panel';
import { Table } from '@/shared/components/TableElements';
import { getWeekChartData } from '@/utils/helpers';
import ErrorAlert from '@/shared/components/ErrorAlert';
import { fetchTopTenCurrencies } from '@/redux/actions/topTenActions';
import { CustomSelect, CustomMultiSelect } from '../../../../shared/components/CustomSelect';
import TopTenRow from './TopTenRow';
import {
  // createMarketPlace,
  getPublicTransactions,
  getdetailSearch,
} from '../../../../utils/services/user.service';
import ReactTableBase from '../../../../shared/components/table/ReactTableBase';

const TopTen = () => {
  const {
    topTen, cryptoHistory, isHistoryLoading, isTopTenLoading,
  } = useSelector(state => ({
    topTen: state.topTen.data,
    cryptoHistory: state.cryptoHistory.data.cryptoHistory,
    errorTopTen: state.topTen.error,
    errorHistory: state.cryptoHistory.error,
    isHistoryLoading: state.cryptoHistory.isFetching,
    isTopTenLoading: state.topTen.isFetching,
  }));
  // console.log('isHistoryLoading', isHistoryLoading);
  // console.log('isTopTenLoading', isTopTenLoading);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(state => state.user);
  const [getTransactions, setTransactions] = useState([]);
  const [getTransactions1, setTransactions1] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState('');
  const [selectedOperation, setSelectedOperation] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [selectedAmount, setSelectedAmount] = useState('');
  const [selectedSymbol, setSelectedSymbol] = useState('');
  const [selectedSearch, setSelectedSearch] = useState('');
  const [selectedApprover, setSelectedApprover] = useState('');
  const [isSearchName, setIsSearchName] = useState(false);
  const [isSearchDate, setIsSearchDate] = useState(false);
  const [isSearchStatus, setIsSearchStatus] = useState(false);
  const [isSearchContract, setIsSearchContract] = useState(false);
  const [isSearchApprover, setIsSearchApprover] = useState(false);
  const [isSearchSymbol, setIsSearchSymbol] = useState(false);
  const [isSearchAmount, setIsSearchAmount] = useState(false);
  const [isSearchOperation, setIsSearchOperation] = useState(false);
  const REACT_APP_BSC_TRANSACTION_EXPLORER_BASE_URL = 'https://testnet.bscscan.com/tx/';

  const fetchTransactions = async () => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      const response = await getPublicTransactions();
      if (response.data.status === 200) {
        // console.log('response >>>>>>>>>>', response);
        const Data = response.data.result;
        // console.log('getPublicTransactions >>>>>>>>>>', Data);
        setTransactions(Data);
        setTransactions1(Data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.error('Error fetching getPublicTransactions:', response.statusText);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching getPublicTransactions:', error);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  const refreshRequest = () => {
    dispatch(fetchTopTenCurrencies());
  };

  const openProductDetails = async (id) => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      const response = await getdetailSearch(id);
      if (response.data.status === 200) {
        const Data = response.data.result[0];
        // console.log('getdetailSearch >>>>>>>>>>', Data);
        setSelectedOrder(Data);
        setIsLoading(false);
      } else {
        console.error('Error fetching getdetailSearch:', response.statusText);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching getdetailSearch:', error);
    }
    setModalIsOpen(true);
  };

  const closeProductDetails = () => {
    setSelectedOrder(null);
    setModalIsOpen(false);
  };

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const tableConfig = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    isPagination: true,
  };

  const optionOrderStatus = [
    { value: 'Completed', label: 'Completed' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Rejected', label: 'Rejected' },
    { value: 'Waiting', label: 'Waiting' },
    { value: 'Declined', label: 'Declined' },
    { value: 'Failed', label: 'Failed' },
  ];

  const optionOperations = [
    { value: 'Send', label: 'Send' },
    { value: 'Receive', label: 'Receive' },
  ];

  const optionSymbol = [
    { value: 'BTC', label: 'BTC' },
    { value: 'BNB', label: 'BNB' },
    { value: 'BUSD', label: 'BUSD' },
    { value: 'CTY', label: 'CTY' },
    { value: 'ETH', label: 'ETH' },
    { value: 'EUROC', label: 'EUROC' },
    { value: 'GBPT', label: 'GBPT' },
    { value: 'JPYC', label: 'JPYC' },
    { value: 'USDT', label: 'USDT' },
    { value: 'USDC', label: 'USDC' },
    { value: 'PAX', label: 'PAX' },
    { value: 'MATIC', label: 'MATIC' },
    { value: 'MET$', label: 'MET$' },
    { value: 'OPY', label: 'OPY' },
    { value: 'FTY', label: 'FTY' },
  ];

  const header = [
    { accessor: 'date', Header: 'Date' },
    { accessor: 'name', Header: 'Name' },
    // { accessor: 'contract', Header: 'Contract' },
    { accessor: 'hash', Header: 'Hash', disableGlobalFilter: true },
    { accessor: 'approver', Header: 'Approver' },
    { accessor: 'amount', Header: 'Amount' },
    { accessor: 'symbol', Header: 'Symbol' },
    ...(isLoggedIn
      ? [{ accessor: 'operation', Header: 'Operation' }]
      : []),
    { accessor: 'status', Header: 'Status' },
    {
      accessor: 'action',
      Header: 'Action',
      disableGlobalFilter: true,
      width: 80,
    },
  ];

  const getStatusColor = (status1) => {
    switch (status1.toLowerCase()) {
      case 'completed':
        return '#4ce1b6';
      case 'paid':
        return '#4ce1b6';
      case 'pending':
        return '#f6da6e';
      case 'rejected':
        return '#ff4861';
      case 'waiting':
        return '#f6da6e';
      case 'declined':
        return '#ff4861';
      case 'failed':
        return '#ff4861';
      default:
        return '#70bbfd';
    }
  };

  const data = [];

  getTransactions.map((item) => {
    const row = {
      date: item.Date,
      name: item.Name,
      // contract: item.type === 'contract' ? `Contract@ ${item.Name.substring(0, 12)}...` : 'Direct Transaction',
      hash: (
        <HashLink
          role="link"
          onClick={() => {
            openInNewTab(`https://bscscan.com/tx/${item.hash}`);
          }}
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </HashLink>
      ),
      approver: item.type === 'contract' ? '*****' : '-',
      amount: item.Amount,
      symbol: item.Symbol,
      ...(isLoggedIn
        ? [{ operation: item.Transfer === JSON.parse(localStorage.getItem('user')).data.user.address ? 'Send' : 'Receive' }]
        : []),
      status: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{ backgroundColor: getStatusColor(item.status) }}
        >
          <h6 className="text-white"><b>{item.status}</b></h6>
        </span>
      ),
      action: (
        <button
          className="btn btn-primary purple-button btn-sm ml-2 px-2 shadow-none"
          type="button"
          style={{ marginTop: '0px' }}
          onClick={() => openProductDetails(item.hash)}
        >
          <FontAwesomeIcon icon={faEye} />
        </button>
      ),
    };

    data.push(row);
  });

  const resetData = (order) => {
    setSelectedDate('');
    setSelectedOrderStatus('');
    setSelectedSymbol('');
    setSelectedName('');
    setSelectedAmount('');
    setSelectedApprover('');
    // setSelectedOperation('');
    setTransactions(getTransactions1);
  };

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    function formatDate(inputDate) {
      const parts = inputDate.split('-');
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    const formattedDate = formatDate(newDate);
    let completedTransactions;
    completedTransactions = getTransactions1.filter(transaction => transaction.Date === formattedDate);
    if (selectedAmount !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Amount.toLowerCase().includes(selectedAmount.toLowerCase()));
    }
    if (selectedApprover !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.approver?.name.toLowerCase().includes(selectedApprover.toLowerCase()));
    }
    if (selectedName !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Name.toLowerCase().includes(selectedName.toLowerCase()));
    }
    if (selectedSymbol !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Symbol === selectedSymbol);
    }
    if (selectedOrderStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.status === selectedOrderStatus);
    }
    // if (selectedOperation !== '') {
    //   if (selectedOperation === 'Send') {
    //     completedTransactions = completedTransactions.filter(transaction => transaction.Transfer === JSON.parse(localStorage.getItem('user')).data.user.address);
    //     setTransactions(completedTransactions);
    //   } else {
    //     completedTransactions = completedTransactions.filter(transaction => transaction.Transfer !== JSON.parse(localStorage.getItem('user')).data.user.address);
    //     setTransactions(completedTransactions);
    //   }
    // } 
    setTransactions(completedTransactions);
    setSelectedDate(newDate);
  };

  const handleOrderStatusChange = (value) => {
    let completedTransactions;
    completedTransactions = getTransactions1.filter(transaction => transaction.status === value.value);
    if (selectedAmount !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Amount.toLowerCase().includes(selectedAmount.toLowerCase()));
    }
    if (selectedApprover !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.approver?.name.toLowerCase().includes(selectedApprover.toLowerCase()));
    }
    if (selectedName !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Name.toLowerCase().includes(selectedName.toLowerCase()));
    }
    if (selectedSymbol !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Symbol === selectedSymbol);
    }
    // if (selectedOperation !== '') {
    //   if (selectedOperation === 'Send') {
    //     completedTransactions = completedTransactions.filter(transaction => transaction.Transfer === JSON.parse(localStorage.getItem('user')).data.user.address);
    //     setTransactions(completedTransactions);
    //   } else {
    //     completedTransactions = completedTransactions.filter(transaction => transaction.Transfer !== JSON.parse(localStorage.getItem('user')).data.user.address);
    //     setTransactions(completedTransactions);
    //   }
    // } 
    function formatDate(inputDate) {
      const parts = inputDate.split('-');
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    if (selectedDate !== '') {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        const month = (transactionDate.getMonth() + 1).toString().padStart(2, '0');
        const day = transactionDate.getDate().toString().padStart(2, '0');
        const year = transactionDate.getFullYear();
        const formattedTransactionDate = `${month}/${day}/${year}`;

        return formattedTransactionDate === formattedDate;
      });
    }
    setSelectedOrderStatus(value.value);
    setTransactions(completedTransactions);
  };

  const handleOrderOperationChange = (value) => {
    let completedTransactions;
    if (value.value === 'Send') {
      completedTransactions = getTransactions1.filter(transaction => transaction.Transfer === JSON.parse(localStorage.getItem('user')).data.user.address);
      setTransactions(completedTransactions);
    } else {
      completedTransactions = getTransactions.filter(transaction => transaction.Transfer !== JSON.parse(localStorage.getItem('user')).data.user.address);
      setTransactions(completedTransactions);
    }
    if (selectedAmount !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Amount.toLowerCase().includes(selectedAmount.toLowerCase()));
    }
    if (selectedApprover !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.approver?.name.toLowerCase().includes(selectedApprover.toLowerCase()));
    }
    if (selectedName !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Name.toLowerCase().includes(selectedName.toLowerCase()));
    }
    if (selectedOrderStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.status === selectedOrderStatus);
    }
    if (selectedSymbol !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Symbol === selectedSymbol);
    }
    function formatDate(inputDate) {
      const parts = inputDate.split('-');
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    if (selectedDate !== '') {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        const month = (transactionDate.getMonth() + 1).toString().padStart(2, '0');
        const day = transactionDate.getDate().toString().padStart(2, '0');
        const year = transactionDate.getFullYear();
        const formattedTransactionDate = `${month}/${day}/${year}`;

        return formattedTransactionDate === formattedDate;
      });
    }
    setSelectedOperation(value.value);
    setTransactions(completedTransactions);
  };

  const handleSymbolChange = (value) => {
    let completedTransactions;
    completedTransactions = getTransactions1.filter(transaction => transaction.Symbol === value.value);
    if (selectedAmount !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Amount.toLowerCase().includes(selectedAmount.toLowerCase()));
    }
    if (selectedApprover !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.approver?.name.toLowerCase().includes(selectedApprover.toLowerCase()));
    }
    if (selectedName !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Name.toLowerCase().includes(selectedName.toLowerCase()));
    }
    if (selectedOrderStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.status === selectedOrderStatus);
    }
    function formatDate(inputDate) {
      const parts = inputDate.split('-');
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    // if (selectedOperation !== '') {
    //   if (selectedOperation === 'Send') {
    //     completedTransactions = completedTransactions.filter(transaction => transaction.Transfer === JSON.parse(localStorage.getItem('user')).data.user.address);
    //     setTransactions(completedTransactions);
    //   } else {
    //     completedTransactions = completedTransactions.filter(transaction => transaction.Transfer !== JSON.parse(localStorage.getItem('user')).data.user.address);
    //     setTransactions(completedTransactions);
    //   }
    // } 
    if (selectedDate !== '') {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        const month = (transactionDate.getMonth() + 1).toString().padStart(2, '0');
        const day = transactionDate.getDate().toString().padStart(2, '0');
        const year = transactionDate.getFullYear();
        const formattedTransactionDate = `${month}/${day}/${year}`;

        return formattedTransactionDate === formattedDate;
      });
    }
    setSelectedSymbol(value.value);
    setTransactions(completedTransactions);
  };

  const handleNameChange = (value) => {
    const partialValue = value.target.value;
    let completedTransactions;
    completedTransactions = getTransactions1.filter(transaction => transaction.Name.toLowerCase().includes(partialValue.toLowerCase()));
    if (selectedAmount !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Amount.toLowerCase().includes(selectedAmount.toLowerCase()));
    }
    if (selectedApprover !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.approver?.name.toLowerCase().includes(selectedApprover.toLowerCase()));
    }
    if (selectedSymbol !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Symbol === selectedSymbol);
    }
    if (selectedOrderStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.status === selectedOrderStatus);
    }
    // if (selectedOperation !== '') {
    //   if (selectedOperation === 'Send') {
    //     completedTransactions = completedTransactions.filter(transaction => transaction.Transfer === JSON.parse(localStorage.getItem('user')).data.user.address);
    //     setTransactions(completedTransactions);
    //   } else {
    //     completedTransactions = completedTransactions.filter(transaction => transaction.Transfer !== JSON.parse(localStorage.getItem('user')).data.user.address);
    //     setTransactions(completedTransactions);
    //   }
    // }  
    function formatDate(inputDate) {
      const parts = inputDate.split('-');
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    if (selectedDate !== '') {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        const month = (transactionDate.getMonth() + 1).toString().padStart(2, '0');
        const day = transactionDate.getDate().toString().padStart(2, '0');
        const year = transactionDate.getFullYear();
        const formattedTransactionDate = `${month}/${day}/${year}`;

        return formattedTransactionDate === formattedDate;
      });
    }
    setTransactions(completedTransactions);
    setSelectedName(value.target.value);
  };

  const handleAmountChange = (value) => {
    const partialValue = value.target.value;
    let completedTransactions;
    completedTransactions = getTransactions1.filter(transaction => transaction.Amount.toLowerCase().includes(partialValue.toLowerCase()));
    if (selectedApprover !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.approver?.name.toLowerCase().includes(selectedApprover.toLowerCase()));
    }
    if (selectedName !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Name.toLowerCase().includes(selectedName.toLowerCase()));
    }
    if (selectedSymbol !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Symbol === selectedSymbol);
    }
    if (selectedOrderStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.status === selectedOrderStatus);
    }
    // if (selectedOperation !== '') {
    //   if (selectedOperation === 'Send') {
    //     completedTransactions = completedTransactions.filter(transaction => transaction.Transfer === JSON.parse(localStorage.getItem('user')).data.user.address);
    //     setTransactions(completedTransactions);
    //   } else {
    //     completedTransactions = completedTransactions.filter(transaction => transaction.Transfer !== JSON.parse(localStorage.getItem('user')).data.user.address);
    //     setTransactions(completedTransactions);
    //   }
    // } 
    function formatDate(inputDate) {
      const parts = inputDate.split('-');
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    if (selectedDate !== '') {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        const month = (transactionDate.getMonth() + 1).toString().padStart(2, '0');
        const day = transactionDate.getDate().toString().padStart(2, '0');
        const year = transactionDate.getFullYear();
        const formattedTransactionDate = `${month}/${day}/${year}`;

        return formattedTransactionDate === formattedDate;
      });
    }
    setTransactions(completedTransactions);
    setSelectedAmount(value.target.value);
  };

  const handleApproverChange = (value) => {
    const partialValue = value.target.value;
    let completedTransactions;
    if (partialValue.length === 0) {
      completedTransactions = getTransactions1;
    } else {
      completedTransactions = getTransactions1.filter(transaction => transaction.approver?.name.toLowerCase().includes(partialValue.toLowerCase()));
    }
    if (selectedAmount !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Amount.toLowerCase().includes(selectedAmount.toLowerCase()));
    }
    if (selectedName !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Name.toLowerCase().includes(selectedName.toLowerCase()));
    }
    if (selectedSymbol !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.Symbol === selectedSymbol);
    }
    if (selectedOrderStatus !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.status === selectedOrderStatus);
    }
    // if (selectedOperation !== '') {
    //   if (selectedOperation === 'Send') {
    //     completedTransactions = completedTransactions.filter(transaction => transaction.Transfer === JSON.parse(localStorage.getItem('user')).data.user.address);
    //   } else {
    //     completedTransactions = completedTransactions.filter(transaction => transaction.Transfer !== JSON.parse(localStorage.getItem('user')).data.user.address);
    //   }
    // } 
    function formatDate(inputDate) {
      const parts = inputDate.split('-');
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${month}/${day}/${year}`;
      }
    }
    if (selectedDate !== '') {
      const formattedDate = formatDate(selectedDate);
      completedTransactions = completedTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        const month = (transactionDate.getMonth() + 1).toString().padStart(2, '0');
        const day = transactionDate.getDate().toString().padStart(2, '0');
        const year = transactionDate.getFullYear();
        const formattedTransactionDate = `${month}/${day}/${year}`;

        return formattedTransactionDate === formattedDate;
      });
    }
    setTransactions(completedTransactions);
    setSelectedApprover(value.target.value);
  };

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? 'flex' : 'none', // Use flex display when loading
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Panel
        lg={12}
        title="Transactions"
        // isLoading={isHistoryLoading || isTopTenLoading}
        refreshRequest={() => fetchTransactions()}
      >
        <Row className="mb-5">
          <Col xs={12} md={4} lg={3} hidden>
            <label htmlFor="features" className="form-label text-white mb-0">Search Filter</label>
            <InputDiv className="px-0">
              <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedSearch}
                // onChange={handleSearchFilterChange}
                // options={searchFilters}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          {isSearchContract && (
            <Col xs={12} md={4} lg={3}>
              <InputDiv>
                <label htmlFor="features" className="form-label text-white mb-1">Contract</label>
                <ChatSearchInput
                  type="text"
                  className="form-control mt-2"
                  placeholder="Search by contract"
                  // onChange={handleFileChange}
                  id="File2"
                  name="File2"
                />
              </InputDiv>
            </Col>
          )}
          {/* {isSearchDate && ( */}
          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-1">Date</label>
              <ChatSearchInput
                type="date"
                value={selectedDate}
                className="form-control mt-2"
                onChange={handleDateChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-1">Name</label>
              <ChatSearchInput
                type="text"
                value={selectedName}
                className="form-control mt-2"
                placeholder="Search by name"
                onChange={handleNameChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchApprover && ( */}
          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-1">Approver</label>
              <ChatSearchInput
                type="text"
                value={selectedApprover}
                className="form-control mt-2"
                placeholder="Search by approver"
                onChange={handleApproverChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>
          {/* )} */}

          {/* {isSearchName && ( */}
          {/* )} */}
          {/* {isSearchAmount && ( */}
          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-1">Amount</label>
              <ChatSearchInput
                type="text"
                value={selectedAmount}
                className="form-control mt-2"
                placeholder="Search by Amount"
                onChange={handleAmountChange}
                id="File2"
                name="File2"
              />
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchOperation && ( */}
          <Col xs={12} md={4} lg={3}>
            <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-0 mt-3">Symbol</label>
            <InputDiv className="px-0">
              <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedSymbol}
                  onChange={handleSymbolChange}
                  options={optionSymbol}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          <Col xs={12} md={4} lg={3} hidden>
            <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-0 mt-2">Operation</label>
            <InputDiv className="px-0">
              <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedOperation}
                  onChange={handleOrderOperationChange}
                  options={optionOperations}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchStatus && ( */}
          <Col xs={12} md={4} lg={3}>
            <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-0 mt-3">Status</label>
            <InputDiv className="px-0">
              <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={selectedOrderStatus}
                  onChange={handleOrderStatusChange}
                  options={optionOrderStatus}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>
          {/* )} */}
          {/* {isSearchSymbol && ( */}
          <Col xs={12} md={4} lg={3}>
            <label htmlFor="color" style={{ fontSize: 'small', color: 'transparent' }} className="form-label mb-2">.</label>
            {/* <Button   
              variant="primary" 
              className="btn btn-danger mt-1 w-100 py-1 pl-1 shadow-none"   
              onClick={() => resetData(1)}
            >
              Reset  <i className="fa fa-refresh" />
            </Button> */}
            <Button
              onClick={() => resetData(1)}
              style={{
                background: '#4ce1b6',
              }}
              size="md"
              className="py-1 btn-block mt-2"
              variant="success"
            >Reset  <i className="fa fa-refresh" />
            </Button>
          </Col>
          {/* )} */}
          {!isSearchApprover && !isSearchName && !isSearchStatus && !isSearchDate && !isSearchContract && !isSearchSymbol && !isSearchAmount && !isSearchOperation && (
            <Col xs={12} md={4} lg={3} />
          )}
          <Col xs={12} md={4} lg={4} />
          <Col xs={12} md={4} lg={2} hidden>
            <label htmlFor="color" style={{ fontSize: 'small', color: 'transparent' }} className="form-label">.</label>
            <Button
              variant="primary"
              className="btn btn-danger mt-1 w-100 py-1 pl-1 shadow-none"
              onClick={() => resetData(1)}
            >
              Reset  <i className="fa fa-refresh" />
            </Button>
          </Col>
        </Row>
        <ReactTableBase
          columns={header}
          data={data}
          tableConfig={tableConfig}
        />
        {data.length === 0 && (
          <NoResult>(0) Results! Data Not Found
          </NoResult>
        )}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalIsOpen}
          onHide={closeProductDetails}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center" style={{ fontSize: 'large' }}>Transaction Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedOrder && (
              <>
                <div className="container mt-0 containerDiv pt-0" style={{ overflowX: 'hidden' }}>
                  {/* <div className="row">
                <div className="col-12 text-center">
                  <h3 style={{ color: '#7b7b7b' }} className="mb-2"> Order Details</h3>
                </div>
              </div> */}
                  <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                    <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      ><b>Name</b>
                      </p>
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-0"
                      >{selectedOrder.Name}
                      </p>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12 text-center">
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      ><b>Transaction Hash</b>
                      </p>
                      <a target="_blank" style={{ wordWrap: 'break-word' }} href={`https://bscscan.com/tx/${selectedOrder.hash}`} rel="noreferrer">
                        <span className="text-purple text-xs font-weight-bold">
                          {selectedOrder.hash}
                        </span>
                      </a>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      ><b>Status</b>
                      </p>
                      <Badge bg="dark" className="mb-1" style={{ fontSize: 'small', fontWeight: '400' }}>{selectedOrder.status}</Badge>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12 text-center">
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      ><b>Amount</b>
                      </p>
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="pb-1 mb-2"
                      >{selectedOrder.Amount} {selectedOrder.Symbol}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-4 mb-1">
                    <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                      <b>Transfer Detail</b>
                    </p>
                  </div>
                  <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                    <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      ><b>Transfer Name </b>
                      </p>
                      {selectedOrder.Transfer ? (
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        >*****
                        </p>
                      ) : (
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        >-
                        </p>
                      )}
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12 text-center">
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      ><b>Transfer Address </b>
                      </p>
                      {selectedOrder.Transfer ? (
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b', wordWrap: 'break-word' }}
                          className="py-1"
                        >{selectedOrder.Transfer.address}
                        </p>
                      ) : (
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        >-
                        </p>
                      )}
                    </div>
                  </div>
                  {selectedOrder.Approver ? (
                    <>
                      <div className="row mt-4 mb-1">
                        <p style={{ textTransform: 'capitalize', color: '#7b7b7b' }} className="py-0">
                          <b>Approver Detail</b>
                        </p>
                      </div>
                      <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                        <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                          <p style={{ textTransform: 'capitalize', color: '#7b7b7b' }} className="py-1">
                            <b>Approver Name </b>
                          </p>
                          {selectedOrder.Approver ? (
                            <p style={{ textTransform: 'capitalize', color: '#7b7b7b' }} className="py-1">
                              *****
                            </p>
                          ) : (
                            <p style={{ textTransform: 'capitalize', color: '#7b7b7b' }} className="py-1">
                              -
                            </p>
                          )}
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 text-center">
                          <p style={{ textTransform: 'capitalize', color: '#7b7b7b' }} className="py-1">
                            <b>Approver Address </b>
                          </p>
                          {selectedOrder.Approver ? (
                            <p style={{ textTransform: 'capitalize', color: '#7b7b7b', wordWrap: 'break-word' }} className="py-1">
                              {selectedOrder.Approver.address}
                            </p>
                          ) : (
                            <p style={{ textTransform: 'capitalize', color: '#7b7b7b' }} className="py-1">
                              -
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="row mt-4 mb-1">
                    <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                      <b>Beneficiary  Detail</b>
                    </p>
                  </div>
                  <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                    <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      ><b>Beneficiary Name</b>
                      </p>
                      {selectedOrder.Beneficiary ? (
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        >*****
                        </p>
                      ) : (
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        >-
                        </p>
                      )}
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12 text-center">
                      <p
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                        className="py-1"
                      ><b>Beneficiary Address</b>
                      </p>
                      {selectedOrder ? (
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b', wordWrap: 'break-word' }}
                          className="py-1"
                        >{selectedOrder.Beneficiary.address}
                        </p>
                      ) : (
                        <p
                          style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}
                          className="py-1"
                        >-
                        </p>
                      )}
                    </div>
                  </div>
                  <hr hidden style={{ border: '1px solid #b3b3b3', opacity: '1.1' }} />
                </div>
                <button type="button" hidden>
                  Submit Ratings
                </button>
              </>
            )}
          </Modal.Body>
        </Modal>
      </Panel>
    </>
  );
};

export default TopTen;

// region STYLES

const DashboardTableCrypto = styled(Table)`

  tbody td {
    padding: 10px;
    white-space: nowrap;
  }
`;

const HashLink = styled.button`
  border: none; 
  background: transparent; 
  color: #4ce1b6;
`;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;  
  }
`;

const NoResult = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px ;
  margin-top: 1rem;
  margin-left: 0rem;
  color: white;
  font-weight: 700;
  `;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus, &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;

// endregion
