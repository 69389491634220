import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { right, left, marginRight } from '@/utils/directions';
import { CRYPTOCURRENCIES_NAMES_COINCAP } from '@/shared/constants/cryptocurrencies';
import { fetchTopTenCurrencies } from '@/redux/actions/topTenActions';

const Roadmap = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const whitepaper = `${process.env.PUBLIC_URL}/img/logo/whitepaper.png`;

  useEffect(() => {
    dispatch(fetchTopTenCurrencies());
  }, [dispatch]);


  return (
    <Container>
      <Row>
        <Col xs={12} md={12} lg={12} className="text-center">
          <h3 className="mx-3 mb-4">Roadmap 2022</h3>
        </Col>
      </Row>
      <Row className="mx-0">
        <Col xs={12} md={4} lg={4} className="text-center">
          <div className="card productCard py-5" style={{ background: '#232329' }}>
            <br />
            <br />
            <br />
            <h3>1 Quarter</h3>
            <br />
            <p>Webapp on testnet → done</p>
            <p>Private sale → done</p>
            <p>Marketing → done</p>
          </div>
        </Col>
        <Col xs={12} md={4} lg={4} className="text-center">
          <div className="card productCard py-5" style={{ background: '#232329' }}>
            <h3>2 Quarter</h3>
            <br />
            <p><b>New website web3</b></p>
            <p><b>Presale on pinksale</b></p>
            <br />
            <p>Marketing → done</p>
            <p>KYC on pinksale → done</p>
            <p>Audit contract → done</p>
            <br />
            <p><b>Launch token</b></p>
            <p><b>Launch webapp</b></p>
            <br />
            <br />
          </div>
        </Col>
        <Col xs={12} md={4} lg={4} className="text-center">
          <div className="card productCard py-5" style={{ background: '#232329' }}>
            <br />
            <br />
            <h3>3 Quarter</h3>
            <br />
            <p>+ More Stablecoins</p>
            <p>+ More Smartcontracts</p>
            <p>+ KYC on Account</p>
            <p>+ Guarantee fund</p>
            <p>+ Audit on webapp</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={12} className="text-center">
          <h3 className="mx-3 mb-4">Roadmap 2024</h3>
        </Col>
      </Row>
      <Row className="mx-0">
        <Col xs={12} md={4} lg={4} />
        <Col xs={12} md={4} lg={4} className="text-center">
          <div className="card productCard py-5" style={{ background: '#232329' }}>
            <h3>1 Quarter</h3>
            <br />
            <br />
            <p>+ More Smartcontracts</p>
            <p>+ Bank Account</p>
            <p>+ More Stablecoins</p>
            <br />
            <br />
            <br />
          </div>
        </Col>
        <Col xs={12} md={4} lg={4} />
      </Row>
    </Container>
  );
};

export default Roadmap;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const Error = styled.div`
 
color: #e66e6e;
padding: 4px 0px;
font-size: 14px;
cursor:none;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;

const ProductItemLabel = styled.img`
  border-radius: '10%'
`;

const ProductItemLabel1 = styled.img`
  position: absolute;
  top: -10px;
  ${right}: 5px;
  width: 65px;
`;
