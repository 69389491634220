import styled from "styled-components";
import React, { useEffect, useState } from "react";
import TimeLineItem from "@/shared/components/TimeLineItem";
import { colorDustyWhite, colorText } from "@/utils/palette";
import { left } from "@/utils/directions";
import { getCalendarActivities } from "../../utils/services/user.service";
import { useChainContext } from "../../containers/context/context";
import { Ring } from "@uiball/loaders";

const Ava1 = `${process.env.PUBLIC_URL}/img/14.png`;
const Ava2 = `${process.env.PUBLIC_URL}/img/15.png`;

const TimelineHistory = () => {
  const [timelineActivities, setTimelineActivities] = useState([]);
  const { setCurrentCalendarDate, currentCalendarData } = useChainContext();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      setIsLoading(true)
      try {
        const res = await getCalendarActivities();
        setTimelineActivities(res.data.data);
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error("Error fetching calendar activities:", error);
      }
    };

    fetchEvents();
  }, []);

  const [data, setData] = useState([
  ]);

  // Function to filter activities for the selected month and year
  const filterActivitiesForMonth = (activities, date) => {
    const selectedMonth = date.getMonth();
    const selectedYear = date.getFullYear();
    return activities.filter(activity => {
      const activityDate = new Date(activity.start);
      return (
        activityDate.getMonth() === selectedMonth &&
        activityDate.getFullYear() === selectedYear
      );
    });
  };


  // Update the data based on the selected month
  useEffect(() => {
    if (timelineActivities.length > 0 && currentCalendarData) {
      const filteredActivities = filterActivitiesForMonth(timelineActivities, new Date(currentCalendarData));
      setData(filteredActivities);
    }
  }, [timelineActivities, currentCalendarData]);

  return (

    <><div
      className="loader-overlay"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(255, 255, 255, 0.8)",
        zIndex: 999,
      }}
    >
      <Ring size={65} color="#00897B" />
    </div>
      <TimeLineWrap>
        {data?.map(({ id, index, text, ...props }) => (
          <TimeLineItem key={index} {...props}>
            <p className="flex overflow-hidden">
              {text || props.description}
            </p>
          </TimeLineItem>
        ))}
      </TimeLineWrap></>
  );
};

export default TimelineHistory;

// region STYLES

const TimeLineWrap = styled.div`
  position: relative;
  color: ${colorText};

  &:before {
    content: "";
    background-color: ${colorDustyWhite};
    width: 1px;
    height: 100%;
    position: absolute;
    ${left}: 50%;
  }

  p {
    margin-top: 5px;
  }

  @media screen and (max-width: 991px) {
    &:before {
      ${left}: 25px;
    }
  }
`;

// endregion
