/* eslint-disable react/destructuring-assignment */
/* eslint-disable arrow-parens */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import {
  colorAdditional,
  colorBackgroundBody,
  colorBlue,
  colorFieldsBorder,
  colorText,
} from "@/utils/palette";
import { paddingLeft, paddingRight, left } from "@/utils/directions";

const ChatSearch = ({ onSearchChange, onClick }) => {

  const [selectedSearch, setSelectedSearch] = useState("");

  const handleSearchChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedSearch(selectedValue);
    onSearchChange(selectedValue); // Call the callback with the updated value
  };

  return (
    <ChatSearchWrap>
      <ChatSearchInput
        type="text"
        value={selectedSearch}
        placeholder="Search Name"
        onChange={handleSearchChange}
        className="form-control"
        onClick={onClick}
      />
      {/* <MagnifyIcon /> */}
    </ChatSearchWrap>
  );
};

export default ChatSearch;

// region STYLES

const ChatSearchWrap = styled.div`
  // height: 50px;
  width: 95%;
  display: flex;
  margin-right: 5%;
  margin-bottom: 2%;
  position: relative;
  border-bottom: 1px solid ${colorFieldsBorder};
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  padding-left: 6px;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;

// endregion
