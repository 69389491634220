/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import {
  AccountCard,
  AccountContent,
  AccountHaveAccount,
  AccountWrap,
 AccountButton, 
} from '@/shared/components/account/AccountElements';
import { colorAccent } from '@/utils/palette';
import ResetOtpForm from '../reset_password/ResetOtpForm';
import { clearMsg, resendOtp, verifyOtp } from '../../../../redux/actions/authActions';
import SimpleModal from '../../Modal/SimpleModal';

const GetOTP = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [newUser, setnewUser] = React.useState(JSON.parse(localStorage.getItem('newUser')));
  const dispatch = useDispatch();
  const { message } = useSelector(state => state.message);
  const { isRegister } = useSelector(state => state.user);
   const history = useHistory();
   const [minutes, setMinutes] = useState(0);
   const [seconds, setSeconds] = useState(0);
 
 
   useEffect(() => {
     const interval = setInterval(() => {
       if (seconds > 0) {
         setSeconds(seconds - 1);
       }
 
       if (seconds === 0) {
         if (minutes === 0) {
           clearInterval(interval);
         } else {
           setSeconds(59);
           setMinutes(minutes - 1);
         }
       }
     }, 1000);
 
     return () => {
       clearInterval(interval);
     };
   });
  const SendOtp = (user) => {
    setIsLoading(true);
    dispatch(verifyOtp(user.otp, newUser)).then(() => {
      setIsLoading(false);
      setTimeout(() => {
        dispatch(clearMsg());
      }, 3000);
      setisOpen(true);
      // toast.success('Account Created Successfully!!', {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      // history.push('/login');
    })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const ResendOtp = () => {
      dispatch(resendOtp(newUser));
      setMinutes(1);
      setSeconds(59);
  };
  return (
    <>
      <AccountWrap>
        <AccountContent>
          <AccountCard>
            <h4
              className="subhead" 
              style={{ fontSize: '18px', fontWeight: '700', textAlign: 'center' }}
            >Enter Your Verification Code
            </h4>
            <h4
              className="subhead"
              style={{
 fontSize: '14px', fontWeight: '600', textAlign: 'center', marginTop: '1rem', 
}}
            >Please Check Your Email 
            </h4>

            <ResetOtpForm
              {...props}
              onSubmit={SendOtp}
              name="otp"
              isRegister={isRegister}
              successMsg={message}
              loading={isLoading}
            />
           
                       
            <AccountHaveAccount>
              {(seconds > 0 || minutes > 0) ? (
                <p style={{ color: colorAccent }}>
                  Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>
                        ) : <span>Does not get otp?</span>}
              <AccountButton variant="primary-outline" as={Button} onClick={ResendOtp} disabled={seconds > 0 || minutes > 0}>
                Resend OTP
              </AccountButton>
                            
            </AccountHaveAccount>
           
          </AccountCard>
        </AccountContent>
      </AccountWrap>
      <SimpleModal isActive={isOpen} onClose={() => setisOpen(false)}>
        <ModalDiv>
          <span className="lnr lnr-checkmark-circle" style={{ color: colorAccent, fontSize: '55px' }} />
          <h4
            className="subhead"
            style={{
 padding: '5px', fontSize: '18px', fontWeight: '700', textAlign: 'center', marginBottom: '1.3rem', marginTop: '1rem', 
}}
          >Account Created Successfully!!{message}
          </h4>
          <div>
            <AccountButton
              variant="outline-success"
              as={NavLink}
              to="/login"  
              
            >
              Go to Login
            </AccountButton>
          </div>
        </ModalDiv>
      </SimpleModal>
    </>
  );
};

export default GetOTP;
const ModalDiv = styled.div`
height: 12rem;
justify-content: space-between;
display: flex;
align-items: center;
flex-direction: column;
`;
