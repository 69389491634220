import { getCalendarActivities } from '../../../../utils/services/user.service';
import { createAction } from "redux-actions";
import { SET_CALENDAR_EVENTS } from '../../../../redux/actions/authActions';

export const fetchCalendarEventsRequest = createAction(
  "FETCH_CALENDAR_EVENTS_REQUEST"
);
export const fetchCalendarEventsSuccess = createAction(
  "FETCH_CALENDAR_EVENTS_SUCCESS"
);
export const fetchCalendarEventsError = createAction(
  "FETCH_CALENDAR_EVENTS_ERROR"
);

export const fetchCalendarEvents = () => async (dispatch) => {
  try {
    dispatch(fetchCalendarEventsRequest());

    const response = await getCalendarActivities();
    const events = response.data?.data || [];

    // Convert date strings to Date objects
    const formattedEvents = events.map(event => ({
      ...event,
      start: new Date(event.start),
      end: new Date(event.end),
    }));

    dispatch(fetchCalendarEventsSuccess(formattedEvents));
  } catch (error) {
    console.error('Error fetching events from API:', error);
    dispatch(fetchCalendarEventsError(error.message || 'An error occurred'));
  }
};
