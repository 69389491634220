/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Ring } from '@uiball/loaders';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { 
  faEye, faEnvelope, faUser, faFile,
} from '@fortawesome/free-regular-svg-icons';
import Panel from '@/shared/components/Panel';
// import Modal from 'react-modal'; // Import react-modal
import { CustomSelect, CustomMultiSelect } from '../../../shared/components/CustomSelect';
import {
  getMarketplaceContracts,
  updateOrderStatusSeller,
} from '../../../utils/services/user.service';
import 'react-toastify/dist/ReactToastify.css';
import '../style/getorder.css';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';

const GetMarketplaceContracts = (props) => {
  const [orderStatus, setOrderStatus] = useState(props.value1);
  const [approverStatus, setApproverStatus] = useState(props.value2);
  useEffect(() => {
    setOrderStatus(props.value1);
  }, [props.value1]);
  useEffect(() => {
    setApproverStatus(props.value2);
  }, [props.value2]);
  // console.log('orderStatus >>>>>>>>>>>>>>>>>>>>>>>>>>>> Parse', orderStatus);
  // console.log('approverStatus >>>>>>>>>>>>>>>>>>>>>>>>>>>> Parse', approverStatus);
  const [orders, setOrders] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(''); // State to store the selected status
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedOrderQty, setSelectedOrderQty] = useState(0);
  const [gettotalChargesTable, totalChargesTable] = useState(0); 
  const [gettotalQtyTable, totalQtyTable] = useState(0);   
  const [cartSybmol, setCartSybmol] = useState('');
  const [selectedOrderStatus, setSelectedOrderStatus] = useState('all');
  const [selectedApproverStatus, setSelectedApproverStatus] = useState('approver');  
  const [ratings, setRatings] = useState({});
  const [comments, setComments] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const REACT_APP_BSC_TRANSACTION_EXPLORER_BASE_URL = 'https://testnet.bscscan.com/tx/'; 
  // 97
  const REACT_APP_MATIC_TRANSACTION_EXPLORER_BASE_URL = 'https://sepolia.etherscan.io/tx/'; 
  // 11155111
  const REACT_APP_ETH_TRANSACTION_EXPLORER_BASE_URL = 'https://mumbai.polygonscan.com/tx/'; 
  // 80001
  const { REACT_APP_POLYGON_MATIC, REACT_APP_SMART_CHAIN, REACT_APP_ETHERIUM_CHAIN } = process.env;

  const fetchOrders = async (typeVal, statusVal) => {
    const payload = {
      page_number: 1,
      limit: 10,
      type: typeVal,
      orderStatus: statusVal,
    };
    setIsLoading(true);
    try {
      setIsLoading(true);
      const response = await getMarketplaceContracts(payload);
      const responseData = response.data;
      if (responseData.data && responseData.data.length > 0) {
        setOrders(responseData.data);
      } else {
        console.error('No getMarketplaceContracts found.', response);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching getMarketplaceContracts:', error);
    }
  };

  useEffect(() => {
    fetchOrders('approver', 'all');
  }, []);

  // const handleStatusChange = (event) => {
  //   console.log('event', event);
  //   console.log('event target value', event.target);
  //   // setSelectedStatus(event.target.value);
  // };

  const handleUpdateStatus = async (orderId, orderStatus1) => {
    if (orderId && orderStatus1) {
      const payload = {
        order_id: orderId,
        status: orderStatus1,
      };
      setIsLoading(true);
      try {
       setIsLoading(true);
        const response = await updateOrderStatusSeller(payload);
        if (response.status === 200) {
        setIsLoading(false);
          toast.success('Order status updated', {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload();
        } else {
          setIsLoading(false);
          toast.error('Failed to update order status', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        setIsLoading(false);
        console.error('Error updating order status:', error);
        toast.error('An error occurred while updating order status', {
          position: toast.POSITION.TOP_RIGHT,
        });
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
      }
    } else {
      toast.error('Please select a status', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleShow = (order) => {
    setSelectedOrder(order);
    let totalPrice = 0;
    // Iterate through the array and sum up the prices
    const orderData = order.product_details;
    totalPrice = orderData.reduce((total, product) => total + product.quantity, 0);
    setSelectedOrderQty(totalPrice);
    setShow(true);
  };

  const resetData = (order) => {
    setSelectedOrderStatus('all');
    setSelectedApproverStatus('approver');
    // console.log(order);
    const fetchOrders1 = async (typeVal, statusVal) => {
      const payload = {
        page_number: 1,
        limit: 10,
        type: typeVal,
        orderStatus: statusVal,
      };
      try {
        const response = await getMarketplaceContracts(payload);
        const responseData = response.data;
        if (responseData.data && responseData.data.length > 0) {
          // console.log('getMarketplaceContracts data >>>>>>>>>>>>>>>>>>>> ', responseData.data);
          setOrders(responseData.data);
        } else {
          console.error('No getMarketplaceContracts found.', response);
        }
      } catch (error) {
        console.error('Error fetching getMarketplaceContracts:', error);
      }
    };

    fetchOrders1('approver', 'all');
  };

  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);

  const handleOrderStatusChange = () => {
    // console.log('handleOrderStatusChange');
    setSelectedOrderStatus(orderStatus);
    const statusValue = orderStatus;
    const fetchOrders3 = async (typeVal, statusVal) => {
      const payload = {
        page_number: 1,
        limit: 10,
        type: typeVal,
        orderStatus: statusVal,
      };
      try {
        setIsLoading(true);
        const response = await getMarketplaceContracts(payload);
        const responseData = response.data;
        if (responseData.data && responseData.data.length > 0) {
          // console.log('getMarketplaceContracts data >>>>>>>>>>>>>>>>>>>> ', responseData.data);
          setOrders(responseData.data);
          setIsLoading(false);
        } else {
          console.error('No getMarketplaceContracts found.', response);
          setOrders([]);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error fetching getMarketplaceContracts:', error);
        setIsLoading(false);
      }
    };
    fetchOrders3(selectedApproverStatus, statusValue);
  };

  useEffect(() => {
    // This effect will run whenever 'orderStatus' changes
    handleOrderStatusChange();
  }, [orderStatus]);

  const handleApproverStatusChange = () => {
    setSelectedApproverStatus(approverStatus);
    const statusValue = approverStatus;
    const fetchOrders2 = async (typeVal, statusVal) => {
      const payload = {
        page_number: 1,
        limit: 10,
        type: typeVal,
        orderStatus: statusVal,
      };
      try {
        setIsLoading(true);
        const response = await getMarketplaceContracts(payload);
        const responseData = response.data;
        if (responseData.data && responseData.data.length > 0) {
          // console.log('getMarketplaceContracts data >>>>>>>>>>>>>>>>>>>> ', responseData.data);
          setOrders(responseData.data);
          setIsLoading(false);
        } else {
          console.error('No getMarketplaceContracts found.', response);
          setOrders([]);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error fetching getMarketplaceContracts:', error);
        setIsLoading(false);
      }
    };
    fetchOrders2(statusValue, selectedOrderStatus);
  };

  useEffect(() => {
    // This effect will run whenever 'orderStatus' changes
    handleApproverStatusChange();
  }, [approverStatus]);

  const handleStatusChange = (id, status) => {
  const selectedValue = status; // Get the selected value
  setSelectedStatus(selectedValue); // You can set the selected value to your state if needed
  handleUpdateStatus(id, selectedValue);
  };

  const openProductDetails = (order) => {
    setIsLoading(true);
    const Store = order.product_details;
    const calculateTotalCharges = () => Store.reduce((total, item) => total + item.price, 0);
    const calculateTotalQty = () => Store.reduce((total, item) => total + item.quantity, 0);
    setCartSybmol(order.symbol);
    totalChargesTable(calculateTotalCharges());
    totalQtyTable(calculateTotalQty());
    setSelectedOrder(order);
    setRatings({}); // Reset ratings when opening the modal
    setComments({}); // Reset comments when opening the modal
    setShow1(true);
    setIsLoading(false);
  };

  const optionOrderStatus = [
    { value: 'pending', label: 'Pending' },
    { value: 'waiting', label: 'Waiting' },
    { value: 'shipped', label: 'Shipped' },
    { value: 'delivered', label: 'Delivered' },
    { value: 'completed', label: 'Completed' },
    { value: 'refund', label: 'Refund' },
  ];

  
  const optionApproverStatus = [
    { value: 'approve', label: 'Approve' },
    { value: 'seller', label: 'Seller' },
    { value: 'buyer', label: 'Buyer' },
  ];

  const openTrackDetails = (order) => {
    setIsLoading(true);
    // console.log('track', order);
    setSelectedOrder(order);
    setModalIsOpen1(true);
    setIsLoading(false);
  };

  const closeTrackDetails = () => {
    setSelectedOrder(null);
    setModalIsOpen1(false);
  };

  const closeProductDetails = () => {
    setSelectedOrder(null);
    setModalIsOpen(false);
  };

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const tableConfig = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    isPagination: true,
  };
  const header = [
    { accessor: 'name', Header: 'Name' },  
    { accessor: 'date', Header: 'Date' },
    { accessor: 'hash', Header: 'Hash' },
    { accessor: 'type', Header: 'Type of Contract', disableGlobalFilter: true },
    { accessor: 'order_status', Header: 'Status' },
    {
      accessor: 'action', Header: 'Action', disableGlobalFilter: true, width: 80, 
    },
  ];

  const data = [];

  orders.forEach((item) => {
    data.push({
      name: item.buyer_detail[0].name,
      date: (
        <div>
          {(() => {
            const originalDateString = item.createdAt;
            const originalDate = new Date(originalDateString);
            const formattedDate = `${originalDate.getFullYear()}-${(originalDate.getMonth() + 1).toString().padStart(2, '0')}-${originalDate.getDate().toString().padStart(2, '0')}`;
            const hours = originalDate.getHours();
            const minutes = originalDate.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
            const formattedTime = `${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;
            
            return (
              <span>{formattedDate}</span>
            );
          })()}
        </div>
      ),
      hash: (
        <>
          {item.chain_id === REACT_APP_SMART_CHAIN && (
            <HashLink
              role="link"
              onClick={() => {
                openInNewTab(`${REACT_APP_BSC_TRANSACTION_EXPLORER_BASE_URL}/${item.payment_transaction_hash}`);
              }}
            >
              {item.payment_transaction_hash?.substring(0, 12)}
            </HashLink>
          )}
          {item.chain_id === REACT_APP_POLYGON_MATIC && (
            <HashLink
              role="link"
              onClick={() => {
              openInNewTab(`${REACT_APP_MATIC_TRANSACTION_EXPLORER_BASE_URL}/${item.payment_transaction_hash}`);
            }}
            >
              {item.payment_transaction_hash?.substring(0, 12)}
            </HashLink>
          )}
          {item.chain_id !== REACT_APP_SMART_CHAIN && item.chain_id !== REACT_APP_POLYGON_MATIC && (
            <HashLink
              role="link"
              onClick={() => {
              openInNewTab(`${REACT_APP_ETH_TRANSACTION_EXPLORER_BASE_URL}/${item.payment_transaction_hash}`);
            }}
            >
              {item.payment_transaction_hash?.substring(0, 12)}
            </HashLink>
          )}
        </>
      ),      
      type: 'Order Marketplace',
      order_status: <span style={{ textTransform: 'capitalize' }}>{item.order_status}</span>,
      // order_status: (
      //   item.order_status === 'waiting' ? (
      //     <Badge bg="warning" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.order_status}</Badge>
      //   ) : item.order_status === 'pending' ? (
      //     <Badge bg="primary" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.order_status}</Badge>
      //   ) : item.order_status === 'shipped' ? (
      //     <Badge bg="secondary" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.order_status}</Badge>
      //   ) : item.order_status === 'delivered' ? (
      //     <Badge bg="info" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.order_status}</Badge>
      //   ) : (
      //     <Badge bg="success" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: 'small' }}>{item.order_status}</Badge>
      //   )
      // ),
      action: (
        <HashLink>
          {item.order_status === 'shipped' && (
          <select
            className="text-center"
            value={selectedStatus}
            onChange={e => handleStatusChange(item._id, e.target.value)}
            style={{ textTransform: 'capitalize', padding: '4px', borderRadius: '4px' }}
          >
            <option 
              disabled  
              value="" 
              style={{ textTransform: 'capitalize' }}
            >{item.order_status}
            </option>
            <option value="delivered">Delivered</option>
            <option value="completed">Completed</option>
          </select>
          )}
          {item.order_status === 'delivered' && (
          <select
            value={selectedStatus}
            onChange={e => handleStatusChange(item._id, e.target.value)}
            style={{ textTransform: 'capitalize', padding: '4px', borderRadius: '4px' }}
          >
            <option 
              disabled  
              value="" 
              style={{ textTransform: 'capitalize' }}
            >{item.order_status}
            </option>
            <option value="completed">Completed</option>
          </select>
          )}
          <i
            onClick={() => openProductDetails(item)}
            style={{
 marginLeft: '6px', marginRight: '5px', color: 'white', marginTop: '9px', fontSize: '18px',
}}
            className="fa fa-eye"
          />
          <i
            onClick={() => handleShow(item)}
            style={{
 marginLeft: '6px', marginRight: '-2px', color: 'white', marginTop: '8px', fontSize: '17px',
}}
            className="fas fa-shipping-fast"
          />
          {item.chain_id === REACT_APP_SMART_CHAIN && (
          <a 
                                // eslint-disable-next-line max-len
            href={`${REACT_APP_BSC_TRANSACTION_EXPLORER_BASE_URL}/${item.payment_transaction_hash}`}
            target="_blank" 
            rel="noreferrer" 
            style={{ marginTop: '6px', marginLeft: '12px' }}
            // className="btn btn-primary btn-sm py-1 ml-2 shadow-none rounded-circle"
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
                            )}
          {item.chain_id === REACT_APP_POLYGON_MATIC && (
          <a 
                                // eslint-disable-next-line max-len
            href={`${REACT_APP_MATIC_TRANSACTION_EXPLORER_BASE_URL}/${item.payment_transaction_hash}`}
            target="_blank" 
            rel="noreferrer" 
            className="btn btn-primary btn-sm py-1 ml-2 shadow-none rounded-circle"
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
                            )}
          {item.chain_id !== REACT_APP_SMART_CHAIN && item.chain_id !== REACT_APP_POLYGON_MATIC && (
          <a 
                                // eslint-disable-next-line max-len
            href={`${REACT_APP_ETH_TRANSACTION_EXPLORER_BASE_URL}/${item.payment_transaction_hash}`}
            target="_blank" 
            rel="noreferrer" 
            className="btn btn-primary btn-sm py-1 ml-2 shadow-none rounded-circle"
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
                            )}
        </HashLink>
     ),
    });
  });

  const optionChain = [
    { value: 'ETH', label: 'ETH' },
    { value: 'BNB', label: 'BNB' },
    { value: 'MATIC', label: 'MATIC' },
  ];

  const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;

  return (
    <>
      <div
        className="loader-overlay"
        style={{
      display: isLoading ? 'flex' : 'none', // Use flex display when loading
      justifyContent: 'center', // Center horizontally
      alignItems: 'center', // Center vertically
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(255, 255, 255, 0.8)',
      zIndex: 999,
    }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Panel
        lg={12}
        title="Marketplace Contracts"
        // isLoading={isLoading}
        refreshRequest={() => fetchOrders('approver', 'all')}
      >
        <ReactTableBase
          columns={header}
          data={data}
          tableConfig={tableConfig}
        />
      </Panel>
      <div>
        <Modal       
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered 
          show={show} 
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center" style={{ fontSize: 'large' }}>Track Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedOrder && (
            <div className="container padding-bottom-3x mb-1 p-0">
              <div className="card mb-3 mt-0" style={{ boxShadow: 'none', fontFamily: 'sans-serif' }}>
                <div className="row steps flex-wrap flex-sm-nowrap justify-content-between py-2 mb-3 px-2 bg-secondary">
                  <div className="col-lg-12 col-md-12 col-sm-12 d-flex">
                    <span>
                      <div className="step completed mb-0">
                        <div className="step-icon-wrap bg-transparent" style={{ border: 'none', height: '60px' }}>
                          <div className="step-icon bg-transparent" style={{ color: 'black', border: 'none' }}><i className="pe-7s-box2" /></div>
                        </div>
                      </div>
                    </span>
                    <span>
                      <div className="py-1 px-0">
                        {selectedOrderQty > 1 ? (
                          <span className="mb-2"> {selectedOrderQty} Items </span>
                        ) : (
                          <span className="mb-2"> {selectedOrderQty} Item </span>
                        )}
                        <br />
                        {/* <p className="mb-0" style={{ color: '#575757', fontSize: 'small' }}>Deliver on April 27, 2023 </p>  */}
                        <p className="mb-0" style={{ color: '#575757', fontSize: 'small' }}>{selectedOrder.delivery_address} <i className="mr-1 fa fa-map-marker" /></p> 
                      </div>    
                    </span>               
                  </div>                 
                </div>
                <div className="card-body">
                  <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                    <div className="step completed mb-2">
                      <div className="step-icon-wrap">
                        {selectedOrder.order_status === 'pending' || selectedOrder.order_status === 'Pending' ? (
                          <div className="step-icon" style={{ borderColor: '#007bff', backgroundColor: '#007bff', color: '#fff' }}><i className="pe-7s-config" /></div>
                      ) : (
                        <div className="step-icon"><i className="pe-7s-config" /></div>
                      )}
                      </div>
                      <h4 className="step-title mt-0"> Pending</h4>
                    </div>
                    <div className="step completed mb-2">
                      <div className="step-icon-wrap">
                        {selectedOrder.order_status === 'waiting' || selectedOrder.order_status === 'Waiting' ? (
                          <div className="step-icon" style={{ borderColor: '#ffc107', backgroundColor: '#ffc107', color: '#fff' }}><i className="pe-7s-hourglass" /></div>
                      ) : (
                        <div className="step-icon"><i className="pe-7s-hourglass" /></div>
                      )}
                      </div>
                      <h4 className="step-title mt-0">Waiting</h4>
                    </div>
                    <div className="step completed mb-2">
                      <div className="step-icon-wrap">
                        {selectedOrder.order_status === 'shipped' || selectedOrder.order_status === 'Shipped' ? (
                          <div className="step-icon" style={{ borderColor: '#6c757d', backgroundColor: '#6c757d', color: '#fff' }}><i className="pe-7s-car" /></div>
                      ) : (
                        <div className="step-icon"><i className="pe-7s-car" /></div>
                      )}
                      </div>
                      <h4 className="step-title mt-0">Shipped</h4>
                    </div>
                    <div className="step completed mb-2">
                      <div className="step-icon-wrap">
                        {selectedOrder.order_status === 'delivered' || selectedOrder.order_status === 'Delivered' ? (
                          <div className="step-icon" style={{ borderColor: '#17a2b8', backgroundColor: '#17a2b8', color: '#fff' }}><i className="pe-7s-home" /></div>
                      ) : (
                        <div className="step-icon"><i className="pe-7s-home" /></div>
                      )}
                      </div>
                      <h4 className="step-title mt-0">Delivered</h4>
                    </div>
                    <div className="step completed mb-2">
                      <div className="step-icon-wrap">
                        {selectedOrder.order_status === 'completed' || selectedOrder.order_status === 'Completed' ? (
                          <div className="step-icon" style={{ borderColor: '#28a745', backgroundColor: '#28a745', color: '#fff' }}><i className="pe-7s-check" /></div>
                      ) : (
                        <div className="step-icon"><i className="pe-7s-check" /></div>
                      )}
                      </div>
                      <h4 className="step-title mt-0">Completed</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )}
          </Modal.Body>
        </Modal>
        <Modal       
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered 
          show={show1} 
          onHide={handleClose1}
        >
          <Modal.Header closeButton>
            {selectedOrder && (
              <Modal.Title className="text-center" style={{ fontSize: 'large' }}>Order No: <span style={{ fontWeight: 400 }}> {selectedOrder._id}</span></Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            {selectedOrder && (
            <>
              <div className="container mt-0 containerDiv pt-0" style={{ overflowX: 'hidden' }}>
                {/* <div className="row">
                <div className="col-12 text-center">
                  <h3 style={{ color: '#7b7b7b' }} className="mb-2"> Order Details</h3>
                </div>
              </div> */}
                <div className="row mt-2 mb-1">
                  <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                    <b>Order Detail</b>
                  </p> 
                </div>
                <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1 mt-1"
                    ><b>Quantity</b>
                    </p> 
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1"
                    >{selectedOrder.product_details.length}
                    </p> 
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1 mt-1"
                    ><b>Shipping</b>
                    </p> 
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1"
                    >{selectedOrder.total_delivery_charges} {selectedOrder.symbol}
                    </p> 
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1 mt-1"
                    ><b>Grand Total</b>
                    </p> 
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1"
                    >{selectedOrder.total_amount} {selectedOrder.symbol}
                    </p> 
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1 mt-1"
                    ><b>Payment Hash</b>
                    </p> 
                    {selectedOrder.chain_id === REACT_APP_SMART_CHAIN && (
                    <a 
                  // eslint-disable-next-line max-len
                      href={`${REACT_APP_BSC_TRANSACTION_EXPLORER_BASE_URL}/${selectedOrder.payment_transaction_hash}`}
                      target="_blank" 
                      rel="noreferrer" 
                      className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                    >
                      View
                    </a>
                  )}
                    {selectedOrder.chain_id === REACT_APP_POLYGON_MATIC && (
                    <a 
                      // eslint-disable-next-line max-len
                      href={`${REACT_APP_MATIC_TRANSACTION_EXPLORER_BASE_URL }/${selectedOrder.payment_transaction_hash}`}
                      target="_blank" 
                      rel="noreferrer" 
                      className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                    >
                      View
                    </a>
                  )}
                    {selectedOrder.chain_id !== REACT_APP_SMART_CHAIN && selectedOrder.chain_id !== REACT_APP_POLYGON_MATIC && (
                    <a 
                    // eslint-disable-next-line max-len
                      href={`${REACT_APP_ETH_TRANSACTION_EXPLORER_BASE_URL }/${selectedOrder.payment_transaction_hash}`}
                      target="_blank" 
                      rel="noreferrer" 
                      className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white"
                    >
                      View
                    </a>
                  )}
                  </div>                
                </div>
                <div className="row mt-4 mb-1">
                  <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0">
                    <b>Buyer Detail</b>
                  </p> 
                </div>
                <div className="row mt-0 mb-2 mx-1" style={{ border: '2px solid #7b7b7b', borderRadius: '5px' }}>
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1"
                    ><b>Buyer Name</b> <FontAwesomeIcon icon={faUser} />
                    </p> 
                    {selectedOrder.buyer_detail[0] ? (
                      <p  
                        style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                        className="py-1"
                      >{selectedOrder.buyer_detail[0].name}
                      </p> 
                  ) : (
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1"
                    >-
                    </p> 
                  )}
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 text-center">
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1"
                    ><b>Buyer Email</b> <FontAwesomeIcon icon={faEnvelope} />
                    </p> 
                    {selectedOrder.buyer_detail[0] ? (
                      <p  
                        style={{ textTransform: 'inherit', color: '#7b7b7b' }}   
                        className="py-1"
                      >{selectedOrder.buyer_detail[0].email}
                      </p> 
                  ) : (
                    <p  
                      style={{ textTransform: 'Capitalize', color: '#7b7b7b' }}   
                      className="py-1"
                    >-
                    </p> 
                  )}
                  </div>
                </div>
                <hr hidden style={{ border: '1px solid #b3b3b3', opacity: '1.1' }} />
                <p style={{ textTransform: 'Capitalize', color: '#7b7b7b' }} className="py-0 mt-4">
                  <b>Invoice</b>
                </p> 
                <div>
                  <div className="row mt-2 mb-2">
                    <div className="col-12">
                      <div style={{ overflowY: 'auto' }}>                      
                        <table className="table table-sm" style={{ textTransform: 'Capitalize' }}>
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">Product</th>
                              <th scope="col">Qty</th>
                              <th scope="col">Color</th>
                              <th scope="col">Discount</th>
                              <th scope="col">Price</th>
                            </tr>
                          </thead>
                          <tbody style={{ borderBottom: '2px solid #7b7b7b' }}>
                            {selectedOrder.product_details.map(product => (
                              <tr key={product._id}>
                                <td>{product.title ? product.title : '-'}</td>
                                <td>{product.quantity}</td>
                                <td>{product.color}</td>
                                <td>{product.discount} %</td>
                                <td>{product.price} {selectedOrder.symbol}</td>
                              </tr>
                          ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td style={{ borderTop: '2px solid #7b7b7b', borderBottom: '2px solid #7b7b7b' }}>-</td>
                              <td style={{ borderTop: '2px solid #7b7b7b', borderBottom: '2px solid #7b7b7b' }}><b style={{ fontWeight: '600' }}> {gettotalQtyTable} </b> </td>
                              <td style={{ borderTop: '2px solid #7b7b7b', borderBottom: '2px solid #7b7b7b' }}>-</td>
                              <td style={{ borderTop: '2px solid #7b7b7b', borderBottom: '2px solid #7b7b7b' }}>-</td>
                              <td style={{ borderTop: '2px solid #7b7b7b', borderBottom: '2px solid #7b7b7b' }}> <b style={{ fontWeight: '600' }}> {gettotalChargesTable} {cartSybmol}</b></td>
                            </tr>
                          </tfoot>                      
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" hidden>
                Submit Ratings
              </button>
            </>
        )}
          </Modal.Body>
        </Modal>
        <Modal
          isOpen={modalIsOpen1}
          onRequestClose={closeTrackDetails}
          contentLabel="Track Details"
          style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
          },
          content: {
            background: 'white',
            width: '750px',
            margin: 'auto',
            height: '400px',
          },
        }}
        >
          {selectedOrder && (
          <div className="container padding-bottom-3x mb-1 p-0">
            <div className="card mb-3" style={{ boxShadow: 'none', fontFamily: 'sans-serif' }}>
              <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between py-2 mb-3 px-2 bg-secondary">
                <div className="w-30 text-center py-1 px-2">
                  <div className="step completed mb-0">
                    <div className="step-icon-wrap bg-transparent" style={{ border: 'none' }}>
                      <div className="step-icon bg-transparent" style={{ color: 'black', border: 'none' }}><i className="pe-7s-box2" /></div>
                    </div>
                  </div>
                </div>
                <div className="w-100 py-1 px-0"><span className="mb-2"> 2 Items + Delivery</span>
                  <br />
                  <p className="mb-0" style={{ color: '#575757', fontSize: 'small' }}>Deliver on April 27, at 16:00</p> 
                  <p className="mb-0" style={{ color: '#575757', fontSize: 'small' }}>Mascow, Mira st. house 21, apt. 14</p> 
                </div>
              </div>
              <div className="card-body">
                <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                  <div className="step completed mb-2">
                    <div className="step-icon-wrap">
                      {selectedOrder.order_status === 'pending' || selectedOrder.order_status === 'Pending' ? (
                        <div className="step-icon" style={{ borderColor: '#007bff', backgroundColor: '#007bff', color: '#fff' }}><i className="pe-7s-config" /></div>
                      ) : (
                        <div className="step-icon"><i className="pe-7s-config" /></div>
                      )}
                    </div>
                    <h4 className="step-title mt-0"> Pending</h4>
                  </div>
                  <div className="step completed mb-2">
                    <div className="step-icon-wrap">
                      {selectedOrder.order_status === 'waiting' || selectedOrder.order_status === 'Waiting' ? (
                        <div className="step-icon" style={{ borderColor: '#ffc107', backgroundColor: '#ffc107', color: '#fff' }}><i className="pe-7s-hourglass" /></div>
                      ) : (
                        <div className="step-icon"><i className="pe-7s-hourglass" /></div>
                      )}
                    </div>
                    <h4 className="step-title mt-0">Waiting</h4>
                  </div>
                  <div className="step completed mb-2">
                    <div className="step-icon-wrap">
                      {selectedOrder.order_status === 'shipped' || selectedOrder.order_status === 'Shipped' ? (
                        <div className="step-icon" style={{ borderColor: '#6c757d', backgroundColor: '#6c757d', color: '#fff' }}><i className="pe-7s-car" /></div>
                      ) : (
                        <div className="step-icon"><i className="pe-7s-car" /></div>
                      )}
                    </div>
                    <h4 className="step-title mt-0">Shipped</h4>
                  </div>
                  <div className="step completed mb-2">
                    <div className="step-icon-wrap">
                      {selectedOrder.order_status === 'delivered' || selectedOrder.order_status === 'Delivered' ? (
                        <div className="step-icon" style={{ borderColor: '#17a2b8', backgroundColor: '#17a2b8', color: '#fff' }}><i className="pe-7s-home" /></div>
                      ) : (
                        <div className="step-icon"><i className="pe-7s-home" /></div>
                      )}
                    </div>
                    <h4 className="step-title mt-0">Delivered</h4>
                  </div>
                  <div className="step completed mb-2">
                    <div className="step-icon-wrap">
                      {selectedOrder.order_status === 'completed' || selectedOrder.order_status === 'Completed' ? (
                        <div className="step-icon" style={{ borderColor: '#28a745', backgroundColor: '#28a745', color: '#fff' }}><i className="pe-7s-check" /></div>
                      ) : (
                        <div className="step-icon"><i className="pe-7s-check" /></div>
                      )}
                    </div>
                    <h4 className="step-title mt-0">Completed</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        </Modal>
      </div>
    </>
  );
};

export default GetMarketplaceContracts;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const HashLink = styled.button`
  border: none; 
  background: transparent; 
  color: #4ce1b6;
  display: flex;  
  margin-left: auto;
  margin-right: auto;
`;
