import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import { CRYPTOCURRENCIES_NAMES_COINCAP } from '@/shared/constants/cryptocurrencies';
import { fetchTopTenCurrencies } from '@/redux/actions/topTenActions';
import CryptoRow from './components/CryptoRow';
import CryptoRowNew from './components/CryptoRowNew';
import TradeHistory from './components/TradeHistory';
import BtcEth from './components/BtcEth';
import CryptotrendsToday from './components/CryptotrendsToday';
import TopTen from './components/TopTen';
import TopActivies from './components/TopActivies';

const FinanceDashboard = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const { rtl, topTen } = useSelector(state => ({
    rtl: state.rtl,
    topTen: state.topTen.data,
  }));

  useEffect(() => {
    dispatch(fetchTopTenCurrencies());
  }, [dispatch]);


  return (
    <Container>
      {/* <Row>
        <Col md={12}>
          <h2 className="mx-3 mb-2 text-center">Wallet Activities</h2>
        </Col>
      </Row> */}
      {/* <CryptoRow style={{ background: 'red' }} /> */}
      {/* <CryptoRowNew />    */}
      <Row>        
        {/* <BtcEth dir={rtl.direction} /> */}
        {/* <TradeHistory /> */}
        {/* <TopTen /> */}
        <TopActivies />
      </Row>
    </Container>
  );
};

export default FinanceDashboard;
