import {
  CHANGE_SIDEBAR_VISIBILITY,
  CHANGE_MOBILE_SIDEBAR_VISIBILITY,
  TOTAL_BALANCE,
  FAIL_BALANCE,
} from '../actions/sidebarActions';

const initialState = {
  show: false,
  collapse: false,
  totalBalance: 'load',
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SIDEBAR_VISIBILITY:
      return { ...state, collapse: !state.collapse };
    case CHANGE_MOBILE_SIDEBAR_VISIBILITY:
      return { ...state, show: !state.show };
      case TOTAL_BALANCE:
        return { ...state, totalBalance: action.payload };
        case FAIL_BALANCE:
          return { ...state, totalBalance: null };
    default:
      return state;
  }
};

export default sidebarReducer;
