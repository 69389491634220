import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { colorBackgroundBody } from '@/utils/palette';
import { paddingLeft } from '@/utils/directions';
import Layout from '../Layout';
import { getSubAccountType } from '../../utils/services/user.service';
import { toast } from 'react-toastify';

function ProtectedRoute({ children, isAuthenticated, type, path, ...rest }) {
  const { isLoggedIn } = useSelector(state => state.user);
  const [accountType, setAccountType] = useState('');
  const user = JSON.parse(localStorage.getItem('user'));
  const history = useHistory();

  const fetchAccountType = async () => {
    const payload = {
      user_id: user?.data?.user?.user_id
    };
    const res = await getSubAccountType(payload);

    setAccountType(res?.data?.data.type);
  };

  useEffect(() => {
    fetchAccountType();
  }, [path]);

  // If account type is undefined, render the protected route without any restrictions
  if (accountType === undefined) {
    return (
      <div>
        <Layout />
        <ContainerWrap>
          <Route
            {...rest}
            render={({ location }) => (
              children
            )}
          />
        </ContainerWrap>
      </div>
    );
  }

  const isTransferAccount = accountType === 'transfer';
  const isPurchaseAccount = accountType === 'purchase';
  const restrictedPaths = ['/otcmarketplacedetail', '/safebox', '/otcmarketplacelists', '/livechat', '/support', '/chat', '/new-contract', '/contractslist', '/marketplace', '/homeproducts', '/home', '/storedetail', '/getmyorders', '/getmyapproval', '/getmyorderasbuyer', '/customoffers'];
  const restrictedPathsPurchase = ['/livechat', '/support', '/safebox', '/chat', '/new-contract', '/contractslist', '/marketplace', '/homeproducts', '/home', '/storedetail', '/getmyorders', '/getmyapproval', '/getmyorderasbuyer', '/customoffers'];

  // If not logged in, redirect to login page
  if (!isLoggedIn) {
    return <Route {...rest} render={() => history.push('/login')} />;
  }

  // If account type is transfer and accessing restricted paths, show toast error
  if (isTransferAccount && restrictedPaths.includes(path)) {
    history.push('/finance'); // Redirect back
    toast.error('You cannot access from sub account');
    return null; // Don't render anything
  }
  if (isPurchaseAccount && restrictedPathsPurchase.includes(path)) {
    history.push('/finance') // Redirect back
    toast.error('You cannot access from sub account');
    return null; // Don't render anything
  }

  // Render the protected route
  return (
    <div>
      <Layout />
      <ContainerWrap>
        <Route
          {...rest}
          render={({ location }) => (
            children
          )}
        />
      </ContainerWrap>
    </div>
  );
}

export default ProtectedRoute;

const ContainerWrap = styled.div`
  padding-top: 90px;
  min-height: 100vh;
  transition: padding-left 0.3s;
  ${paddingLeft}: 0;
  background: ${colorBackgroundBody};

  @media screen and (min-width: 576px) {
    ${paddingLeft}: 250px;
  }

  @media screen and (max-width: 576px) {
    padding-top: 150px;
  }
`;