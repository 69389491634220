/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Ring } from "@uiball/loaders";
import Badge from "react-bootstrap/Badge";
// import { Form, Field } from 'react-final-form';
import { Field, Formik, Form } from "formik";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Button } from "@/shared/components/Button";

import {
  MaterialFormContainer,


  MaterialTextField,
  MaterialFormLabel,
} from "@/shared/components/form/MaterialFormElements";
import { colorAccent } from "@/utils/palette";

import {
  getUserInfo,
  getMarketPlaceStatus,
  startKYC,
  getResultKYC,
  uploadProfileDocuments,
} from "../../../../utils/services/user.service";

import { useHistory } from "react-router-dom";
import moment from "moment/moment";

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  initialValue,
  disabled,
  type,
}) => (
  <MaterialTextField
    label={label}
    error={touched && error}
    value={input.value || initialValue}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
    disabled={disabled}
    type={type}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
};

renderTextField.defaultProps = {
  meta: null,
  label: "",
  initialValue: "",
  disabled: false,
  type: "text",
};

const KYC = ({ onSubmit }) => {
  const history = useHistory();
  const [userInfo, setUserInfo] = useState([]);
  const [twoFactors, setTwoFactor] = useState(false);
  const [twoFactors2, setTwoFactor2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [otcRequestStatus, setOtcRequestStatus] = useState("");
  const [kycDetail, setKYCDetail] = useState("");
  const [kycStatus, setStatus] = useState("");
  const [file, setFile] = useState([]);
  const fileInputRef = useRef(null);

  const [becomeApproverRequest, setBecomeApproverRequest] = useState(
    sessionStorage.getItem("approverStatus") || ""
  );
  const getUsers = () => {
    setIsLoading(true);
    getUserInfo()
      .then((res) => {
        // console.log('getUsers >>>>>', res);
        if (res.status === 200) {
          // console.log('res', res);
          setIsLoading(false);
          setUserInfo(res?.data?.User);
          setTwoFactor(res.data.User?.status_2fa);
          setTwoFactor2(res.data.User?.TFA === true);
        } else {
          setIsLoading(false);
          setUserInfo(JSON.parse(localStorage.getItem("user")));
          setTwoFactor(userInfo?.data?.user?.status_2fa);
        }
      })
      .catch(() => {
        setUserInfo(JSON.parse(localStorage.getItem("user")));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const status = JSON.parse(localStorage.getItem("user"));

  const getProfileStatus = async () => {
    const res = await getMarketPlaceStatus();

    setOtcRequestStatus(res?.data?.data?.status);
  };

  useEffect(() => {
    getProfileStatus();
    setBecomeApproverRequest(
      status?.data?.user?.approver_status
        ? status?.data?.user?.approver_status
        : becomeApproverRequest
    );
  }, [status]);

  const CreateResultKYC = async () => {
    try {
      setIsLoading(true);
      const res = await startKYC();
      setKYCDetail(res?.data?.verification?.url);
      if (res?.data?.verification?.url) {
        window.open(res.data.verification.url, "_blank");
      }
      //   toast.success(res?.data?.message || res?.data?.Message)
      getUsers();
      setIsLoading(false);
    } catch (error) {

      toast.error(error?.response?.data.Message);
      setIsLoading(false);
    }
  };

  const fetchGetResultKYC = async () => {
    try {
      setIsLoading(true);
      const res = await getResultKYC();
      setStatus(res?.data?.verifications[0]);

      toast.success(res?.data?.message || res?.data?.Message);
      getUsers();
      setIsLoading(false);
    } catch (error) {

      // toast.error(error?.response?.data.Message);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchGetResultKYC();
  }, [userInfo.kyc_status]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length + file.length > 5) {
      toast.error('You can only upload up to 5 files.')
      return;
    }
    setFile(prevFiles => [...prevFiles, ...selectedFiles]);
  };

  const handleButtonClickClick = () => {
    fileInputRef.current.click();
  };

  const handleFileRemove = (index) => {
    setFile(prevFiles => prevFiles.filter((_, i) => i !== index));
    toast.success("Document Removed", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleDocumentUpload = async () => {
    try {
      const formData = new FormData();
      file.forEach((file, index) => {
        formData.append('File', file);
      });

      const res = await uploadProfileDocuments(formData);
      toast.success(res?.data?.message)
      setFile(null)

    } catch (error) {

    }
  };
  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <div
        className="row mt-0 mb-2 mx-auto "
        style={{ border: "2px solid #7b7b7b", borderRadius: "5px" }}
      >
        {userInfo && (
          <>
            <Formik
              initialValues={{
                comments: "",
                File: null,
              }}
              onSubmit={(values, { resetForm }) => { }}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                } = props;
                return (
                  <><div>
                    <div className="row justify-content-center">
                      <div className="col-lg-4 col-md-6 col-sm-12 text-center">
                        <label
                          htmlFor="title"
                          style={{ fontSize: "small" }}
                          className="form-label text-white mb-0"
                        >
                          Status updated at
                        </label>
                        <br />
                        {userInfo?.kyc_status ||
                          (kycStatus && <p style={{
                            fontSize: '12px'
                          }}>
                            {moment(kycStatus?.createdTime).format('MMMM Do YYYY, h:mm:ss a')}
                          </p>)}
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 text-center">
                        <label
                          htmlFor="title"
                          style={{ fontSize: "small" }}
                          className="form-label text-white mb-0"
                        >
                          KYC Status
                        </label>
                        <br />
                        {userInfo?.kyc_status ||
                          (kycStatus && (
                            <>
                              <Badge
                                // bg="success"
                                className="ml-2"
                                style={{
                                  backgroundColor: "#4ce1b6",
                                  paddingBottom: "6px",
                                  fontSize: "small",
                                  fontWeight: "400",
                                  textTransform: "Capitalize",
                                }}
                              >
                                {kycStatus?.status}
                              </Badge>
                            </>
                          ))}
                      </div>
                      {kycStatus.status !== "approved" && (
                        <div className="col-lg-4 col-md-6 col-sm-12 text-center mx-auto mt-3 mt-md-0">
                          <label
                            htmlFor="title"
                            style={{ fontSize: "small" }}
                            className="form-label text-white mb-0"
                          >
                            Complete KYC
                          </label>
                          <br />
                          <Button
                            onClick={CreateResultKYC}
                            size="md"
                            className=" w-100 py-2 mt-2 transform-lowercase"
                            variant="outline-success"
                          >
                            Complete KYC
                          </Button>
                          <div className="w-100">
                            <label
                              htmlFor="title"
                              style={{ fontSize: "small" }}
                              className="form-label text-white mb-0"
                            >
                              Upload Document
                            </label>
                            <input
                              hidden
                              type="file"
                              ref={fileInputRef}
                              className="form-control"
                              onChange={handleFileChange}
                              id="File2"
                              name="File2"
                              multiple
                              accept=".pdf" />
                            <Button
                              onClick={file.length > 0 ? handleDocumentUpload : handleButtonClickClick}
                              className="w-100 py-2 btn-block mb-0"
                              variant="outline-success"
                            >
                              {file.length > 0 ? "Confirm Upload" : 'Upload Document'}
                            </Button>

                          </div>

                          <br />
                        </div>
                      )}

                    </div>
                  </div><div>
                      {file.length > 0 && file.map((data, index) => (
                        <div className="col-12" key={index}>
                          <label htmlFor="features" style={{ fontSize: "small" }} className="form-label text-white my-2">
                            Uploaded Document
                          </label>
                          <br />
                          <div className="pt-1" style={{ border: "1px solid white", borderRadius: "4px" }}>
                            <label htmlFor="features" className="form-label text-white mt-1 mx-2">
                              {data.name}
                            </label>
                            <button
                              type="button"
                              style={{ marginTop: "2px" }}
                              className="btn btn-danger py-0 float-right mr-1 mb-2"
                              onClick={() => handleFileRemove(index)}
                            >
                              Remove <i className="fa fa-file-pdf-o" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div></>
                );
              }}
            </Formik>
          </>
        )}
      </div>
    </>
  );
};

KYC.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default KYC;
export const ModalDiv = styled.div`
  height: 16rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TwoFactorTitle = styled.h4`
  padding: 5px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: ${colorAccent};
`;

export const TwoFactorSubTitle = styled.h6`
  padding: 5px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1rem;
`;
