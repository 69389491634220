/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import { CustomSelect } from "../../../shared/components/CustomSelect";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Select from "react-select";
import { Field, Formik } from "formik";
import { Ring } from "@uiball/loaders";
import { FormLabel } from "@mui/material";
import { Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeAnimated from "react-select/animated";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  validate,
  getAddressInfo,
  Network,
  AddressInfo,
} from "bitcoin-address-validation";
// import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faxmark } from "@fortawesome/free-solid-svg-icons";
import config from "@/config/walletConnect";
import Panel from "@/shared/components/Panel";
import {
  // createMarketPlace,
  getCategory,
  transferStoreFee,
  checkStore,
  createProduct,
  getMyStore,
  getApprovers,
  getBeneficiaryName,
} from "../../../utils/services/user.service";
import { Button } from "../../../shared/components/Button";
import CountryJson from "../createstore/country.json";
// import { CustomSelect, CustomMultiSelect } from '../../../shared/components/CustomSelect';

import { clearMsg, clearSendMsg } from "../../../redux/actions/authActions";
import "../style/createproduct.css";
import Webcam from "react-webcam";
import WebCam from "../../../shared/components/WebCam/WebCam";

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;

const CreateProduct = () => {
  const [msg, setMsg] = useState("");
  const [loading, setloading] = useState(false);
  const [setstatusCode] = useState(400);
  const [status, setStatus] = useState(200);
  let countriesArray = [];
  const RegionsArray = [];
  let subCategoryArray = [];
  let categoryArrayDetail = [];
  let categoryArrayDetail2 = [];

  const [chainDetails, setChainDetails] = useState({
    ETH: { chainId: 11155, symbol: "USDT" },
    BNB: { chainId: 97, symbol: "USDT" },
    MATIC: { chainId: 80001, symbol: "USDT" },
    BTC: { chainId: "BTC", symbol: "USDT" },
  });
  const {
    projectId,
    REACT_APP_ETHERIUM_CHAIN,
    REACT_APP_SMART_CHAIN,
    REACT_APP_POLYGON_MATIC,
    errorMsg,
  } = config;
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [Countries, setCountries] = useState([]);
  const [Regions, setRegion] = useState([]);
  const [Cites, setCites] = useState([]);

  const [provinces, setProvinces] = useState([]);
  const { statusCode } = useSelector((state) => state.user);
  const [categories, setCategories] = useState([]);
  const [getresultObject1, setresultObject1] = useState([]);
  const [selectedSymbol, SetSelectedSymbol] = useState();
  const [selectedChainDetails, setSelectedChainDetails] = useState({}); // Initialize an empty object
  const [selectedCountryDetails, setCountryChainDetails] = useState({}); // Initialize an empty object
  const [selectedColorDetails, setColorDetails] = useState({}); // Initialize an empty object
  const [selectedSizeDetails, setSizeDetails] = useState({}); // Initialize an empty object
  const [selectedFileDetails, setFilesDetails] = useState({}); // Initialize an empty object
  const [selectedChain, setSelectedChain] = useState(REACT_APP_SMART_CHAIN);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedColor, setSelectedColor] = useState("Red");
  const [selectedSize, setSelectedSize] = useState("Small");
  const [selectedPropertyType, setSelectedPropertyType] = useState("Rental");
  const [SelectedEngineType, setSelectedEngineType] = useState("Diesel");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [setDeliveryChargesval, setDeliveryCharges] = useState(0);
  const [setDeliveryChargesvalNew, setDeliveryChargesNew] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImages1, setSelectedImages1] = useState([]);
  const [selectedImages3, setSelectedImages3] = useState([]);
  const [fields, setFields] = useState([{ id: 1, value: "" }]);
  const [showTokenDropdown, setShowTokenDropdown] = useState(false);
  const [features, setFeatures] = useState([]);
  const [featureName, setFeatureName] = useState("");
  const [fieldsNew, setFieldsNew] = useState("");
  const [StoreID, setStoreID] = useState("");
  const [StoreChainId, setStoreChainId] = useState("");
  const [StoreSymbol, setStoreSymbol] = useState("");
  const [StoreApprover, setStoreApprover] = useState("");
  const [StoreCountry, setStoreCountry] = useState("");
  const [StoreAvailable, setStoreAvailable] = useState("");
  const [toggle, setToggle] = useState(false);
  const [searchLoad, setsearchLoad] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [fieldValuesArrayVal, setfieldValuesArray] = useState([]);
  const [getcategoryArrayDetailKeys2, setcategoryArrayDetailKeys2] = useState(
    []
  );
  const [TableData, StoreForTable] = useState("");
  const [image1, setImage1] = useState(null);
  const [imageSelfie, setImageSelfie] = useState(null);

  const [show1, setShow1] = useState(false);
  const [showSelfie, setShowSelfie] = useState(false);
  const [cropper, setCropper] = useState();
  const handleClose1 = () => setShow1(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [storeCategoryID, setStoreCategoryID] = useState(null);
  const [subCategory, setSubCategory] = useState([]);
  const [CategoryDetail, setCategoryDetail] = useState([]);
  const [CategoryDetail2, setCategoryDetail2] = useState([]);
  const [sCategoryDetailkeys, setCategoryDetailkeys] = useState([]);
  const [Approvers, setApprovers] = useState([]);
  const [storeCategoryName, setstoreCategoryName] = useState("");
  const [selectedSubcategoryID, setSelectedSubcategoryID] = useState("");
  const [SelectedCondition, setSelectedCondition] = useState("New");
  const [offerStatus, setOfferStatus] = useState(false);
  const [selectedApproverID, setSelectedApproverID] = useState(Approvers.id);
  const [deliveryAvailableCountries, setDeliveryAvailableCountries] = useState(
    []
  );
  const [SelectedQuantity, setSelectedQuantity] = useState(1);
  const [SelectedPrice, setSelectedPrice] = useState(1);
  const [selectedTransmission, setSelectedTransmission] = useState("Manual");
  const [SelectedStorage, setSelectedStorage] = useState("16 GB");
  const [SelectedRegistration, setSelectedRegistration] =
    useState("United States");
  const [SelectedRegion, setSelectedRegion] = useState("USA");
  const [SelectedCity, setSelectedCity] = useState("America");
  const [SelectedModel, setSelectedModel] = useState("0");
  const [SelectedHorsePower, setSelectedHorsePower] = useState("600 CC");
  const [SelectedDiscount, setSelectedDiscount] = useState(0);
  const [SelectedWarranty, setSelectedWarranty] = useState(0);
  const [SelectedSquareSize, setSelectedSquareSize] = useState("");
  const [SelectedMq, setSelectedMq] = useState(1);
  const [SelectedFloor, setSelectedFloor] = useState(1);
  const [showError, setShowError] = useState(false);
  const [showError2, setShowError2] = useState(false);
  const [showError3, setShowError3] = useState(false);
  const [showError4, setShowError4] = useState(false);
  const [showError5, setShowError5] = useState(false);
  const [showError6, setShowError6] = useState(false);
  const [showError7, setShowError7] = useState(false);
  const [valueCheckVal, valueCheck] = useState(0);
  const [valuePriceVal, valuePrice] = useState("");
  const [valueQuantityVal, valueQuantity] = useState("");
  const [valueDiscountVal, valueDiscount] = useState("");
  const [valueColorVal, valueColor] = useState("");
  const [valueSizeVal, valueSize] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [file2, setFile] = useState([]);
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileSelfieUpload = useRef(null);
  const dispatch = useDispatch();
  const [bnNames, setbnNames] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedWallet, setSelectedWallet] = useState("");
  const [selectedAgencyPercentage, setSelectedAgencyPercentage] = useState("");
  const [showWebCam, setShowWebCap] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const user = JSON.parse(localStorage.getItem('user'))
  const handleCaptureImage = (imageSrc) => {

    // Handle the captured image here (e.g., upload it to a server)
    setCapturedImage(imageSrc);
    setShowWebCap(false); // Hide the webcam modal after capturing the image
  };
  const optionType = [
    { value: "seller", label: "Seller" },
    { value: "agency", label: "Agency" },
  ];

  const optionSellerWallet = [
    { value: "bsc", label: "Bitcoin" },
    { value: "eth", label: "Ethereum" },
    { value: "sol", label: "Solana" },
  ];

  const handleSellerWalletSelect = (value) => {
    setSelectedWallet(value);

    if (value.value === "seller") {
      setShowTokenDropdown(true);
    } else {
      setShowTokenDropdown(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const handleAddField = () => {
    const newId = new Date().getTime(); // Generating a unique id
    setFields([...fields, { id: newId, value: "" }]);
  };

  const handleChangeFeature = (id, value) => {
    const featureValues = fields.map((field) => field.value);
    setFieldsNew(featureValues);
    const updatedFields = fields.map((field) => {
      if (field.id === id) {
        return { ...field, value };
      }
      return field;
    });
    setFields(updatedFields);
  };

  const addFeature = () => {
    if (featureName.trim() !== "") {
      setFeatures([...features, featureName]);
      setFeatureName("");
    }
  };

  const handleRemoveField = (id) => {
    const updatedFields = fields.filter((field) => field.id !== id);
    setFields(updatedFields);
  };

  const arrayOfArrays9 = [];
  const arrayOfArrays10 = [];

  let newField1 = {}; // Declare newField as let
  if (storeCategoryName === "Vehicle") {
    newField1 = sCategoryDetailkeys;
  } else {
    newField1 = {
      id: 1,
      value: "",
      color: "",
      size: "",
      price: "",
      quantity: "",
      discount: "",
    };
  }

  const [fields2, setFields2] = useState([
    {
      newField1,
    }, // Initial field
  ]);

  const handleAddField2 = () => {
    let newField = {}; // Declare newField as let

    const updatedArray = sCategoryDetailkeys.map((obj) => {
      if (obj.id === 1) {
        // Replace 1 with the specific id you want to update
        return { ...obj, id: fields2.length + 1 };
      }
      return obj;
    });

    // console.log('updatedArray <><><><><><><><><><><><><><><><><>', updatedArray);

    if (storeCategoryName === "Vehicle") {
      newField = updatedArray;
    } else {
      newField = {
        id: fields2.length + 1,
        value: "",
        color: "",
        size: "",
        price: "",
        quantity: "",
        discount: "",
      };
    }
    setFields2([...fields2, newField]);
  };

  const handleRemoveField2 = (id) => {
    const updatedFields = fields2.filter((field) => field.id !== id);
    setFields2(updatedFields);
  };

  const removeFeature = (index) => {
    const updatedFeatures = [...features];
    updatedFeatures.splice(index, 1);
    setFeatures(updatedFeatures);
  };

  const handleFileRemove = (index) => {
    setFile((prevFiles) => prevFiles.filter((_, i) => i !== index));
    toast.success("Document Removed", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleProductSubmit = () => {
    // Add any logic you need before redirecting
    // For now, let's assume you want to redirect to the first product's details
    if (products.length > 0) {
      history.push(`/productdetails/${products[0].id}`);
    }
  };
  const animatedComponents = makeAnimated();

  useEffect(() => {
    const fetchCategories = async () => {
      fetch("https://restcountries.com/v3.1/all")
        .then((response) => response.json())
        .then((data) => {
          // Extract specific data and store it in the countriesArray
          // eslint-disable-next-line react-hooks/exhaustive-deps
          countriesArray = data.map((country) => ({
            label: country.name.common,
            value: country.name.common,
          }));

          // Now you have the data in the countriesArray
          setCountries(countriesArray);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      try {
        const response = await getCategory();
        if (response.data.status) {
          const categoryData = response.data.category;
          setCategories(categoryData);
        } else {
          console.error("Error fetching categories:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchRegion = async (countryName) => {
      const apiUrl = "https://restcountries.com/v2/name/Pakistan?fullText=true";
      // Make an HTTP GET request to the API
      axios
        .get(apiUrl)
        .then((response) => {
          // Extract the provinces from the API response
          const subdivisions = response.data[0]?.subregion || [];
          setProvinces(subdivisions);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    fetchCategories();
    fetchRegion(SelectedRegistration);
  }, []);

  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        const response = await getMyStore(); // Replace with your API call
        if (response.data && response.data.result.length > 0) {
          const storeInfo = response.data.result[0]; // Extract the first store
          setStoreData(storeInfo);
          // eslint-disable-next-line no-underscore-dangle
          setStoreID(storeInfo._id);
          setSelectedType(storeInfo.store_type);
          setStoreChainId(storeInfo.chainId);
          setStoreSymbol(storeInfo.symbol);
          setStoreApprover(storeInfo.approver_wallet_address);
          setStoreCountry(storeInfo.delivery_available_countries);
          setStoreAvailable(storeInfo.online_delivery_available);
          // eslint-disable-next-line no-underscore-dangle
          const categoryID = storeInfo.categories[0]._id;
          setStoreCategoryID(categoryID);
          const categoryName = storeInfo.categories[0].category_name;
          setstoreCategoryName(categoryName);
          const subcategoryID = storeInfo.categories[0].sub_categories;
          const categoryDetail = storeInfo.categories[0].custom_detail;

          categoryDetail.sort((a, b) => a.value - b.value);

          // maphere
          // eslint-disable-next-line react-hooks/exhaustive-deps
          subCategoryArray = subcategoryID.map((data) => ({
            label: data.sub_category_name,
            // eslint-disable-next-line no-underscore-dangle
            value: data._id,
          }));
          // eslint-disable-next-line react-hooks/exhaustive-deps

          // eslint-disable-next-line no-inner-declarations
          function formatLabel(key) {
            return key
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");
          }

          const categoryArrayDetailKeys = categoryDetail.map(
            (data) => Object.keys(data)[0]
          );
          const categoryArrayDetailKeys1 = categoryDetail.map(
            (data) => Object.keys(data)[0]
          );
          const categoryArrayDetailKeys2 = categoryDetail.map(
            (data) => Object.keys(data)[0]
          );
          categoryArrayDetailKeys.push("id", "value");
          categoryArrayDetailKeys2.sort();

          // eslint-disable-next-line react-hooks/exhaustive-deps
          categoryArrayDetail = categoryArrayDetailKeys2.map((data) => ({
            label: formatLabel(data), // Transform the key
            // eslint-disable-next-line no-underscore-dangle
            value: data,
            // eslint-disable-next-line no-nested-ternary
            inputType:
              data === "color"
                ? "dropdown"
                : data === "size"
                  ? "dropdown"
                  : data === "engine_type"
                    ? "dropdown"
                    : data === "transmission"
                      ? "dropdown"
                      : data === "horse_power"
                        ? "dropdown"
                        : data === "storage"
                          ? "dropdown"
                          : data === "registration"
                            ? "dropdown"
                            : data === "region"
                              ? "dropdown"
                              : data === "city"
                                ? "dropdown"
                                : data === "property_type"
                                  ? "dropdown"
                                  : "input",
          }));

          // eslint-disable-next-line react-hooks/exhaustive-deps
          categoryArrayDetail2 = categoryArrayDetailKeys2.map((data) => ({
            label: formatLabel(data), // Transform the key
          }));

          const resultObject = categoryArrayDetailKeys.reduce((object, key) => {
            // eslint-disable-next-line no-param-reassign
            object[key] = key === "id" ? 1 : "";
            return object;
          }, {});

          const resultObject1 = categoryArrayDetailKeys1.reduce(
            (object, key) => {
              // eslint-disable-next-line no-param-reassign
              object[key] = " ";
              return object;
            },
            {}
          );

          setSubCategory(subCategoryArray);
          setCategoryDetail(categoryArrayDetail);
          setCategoryDetail2(categoryArrayDetail2);
          setCategoryDetailkeys(resultObject);
          setresultObject1(categoryDetail);
          setcategoryArrayDetailKeys2(categoryArrayDetailKeys2);
        } else {
          console.error("Error fetching store data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching store data:", error);
      }
    };
    const fetchApproverData = async () => {
      try {
        const response = await getApprovers(); // Replace with your API call
        if (response.data && response.data.user.length > 0) {
          const storeInfo = response.data.user; // Extract the first store
          setApprovers(storeInfo);
        } else {
          console.error("Error fetching store data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching store data:", error);
      }
    };
    fetchStoreData();
    fetchApproverData();
  }, []);

  const handleSubcategoryChange = (event) => {
    const selectedID = event.value;
    setSelectedSubcategoryID(selectedID);
  };

  const handleConditionChange = (event) => {
    const selectedCondition = event.value;
    setSelectedCondition(selectedCondition);
  };

  const handleOfferStatus = (event) => {
    const selectedStatus = event.value;
    setOfferStatus(selectedStatus);
  };

  const handleApproverChange = (event) => {
    const selectedID = event.target.value;
    setSelectedApproverID(selectedID);
  };

  const fetchCountriesData = async () => {
    try {
      const response = await fetch("https://restcountries.com/v3.1/all");
      if (!response.ok) {
        console.error("Error fetching countries:", response.status);
      }
      const countriesData = await response.json();
      return countriesData;
    } catch (error) {
      console.error("Error fetching countries:", error);
      return [];
    }
  };
  useEffect(() => {
    fetchCountriesData().then((data) => {
      setDeliveryAvailableCountries(data);
    });
  }, []);

  // const chainDetails = {
  //   ETH: { chainId: 11155, symbol: 'ETH' },
  //   BNB: { chainId: 97, symbol: 'BNB' },
  //   MATIC: { chainId: 80001, symbol: 'MATIC' },
  //   BTC: { chainId: 'BTC', symbol: 'BTC' },
  // };
  const [fieldCount, setFieldCount] = useState(1);

  const addField = () => {
    setFieldCount(fieldCount + 1);
  };

  let fieldValuesArray = [];

  const optionChain = [
    { value: "ETH", label: "ETH" },
    { value: "BNB", label: "BNB" },
    { value: "MATIC", label: "MATIC" },
  ];

  const productCondition = [
    { value: "New", label: "New" },
    { value: "Used", label: "Used" },
    { value: "Never worn", label: "Never worn" },
    { value: "Very good condition ", label: "Very good condition " },
    { value: "Good condition ", label: "Good condition " },
    { value: "Fair condition", label: "Fair condition" },
  ];
  const customOfferStatus = [
    { value: true, label: "True" },
    { value: false, label: "False" },
  ];

  const RegistrationArray = [
    { value: "United States", label: "United States" },
    { value: "United Kingdom", label: "United Kingdom" },
  ];

  const RegionArray = [
    { value: "UK", label: "UK" },
    { value: "USA", label: "USA" },
  ];

  const CityArray = [
    { value: "America", label: "America" },
    { value: "London", label: "London" },
  ];

  const PropertyTypes = [
    { value: "Rental", label: "Rental" },
    { value: "Plot", label: "Plot" },
    { value: "For Sale", label: "For Sale" },
    { value: "Commercial", label: "Commercial" },
  ];

  const optionColors = [
    { value: "Red", label: "Red" },
    { value: "Blue", label: "Blue" },
    { value: "Green", label: "Green" },
    { value: "Yellow", label: "Yellow" },
    { value: "Purple", label: "Purple" },
    { value: "Orange", label: "Orange" },
    { value: "Pink", label: "Pink" },
    { value: "Brown", label: "Brown" },
    { value: "Cyan", label: "Cyan" },
    { value: "Magenta", label: "Magenta" },
    { value: "Teal", label: "Teal" },
    { value: "Indigo", label: "Indigo" },
    { value: "Black", label: "Black" },
    { value: "White", label: "White" },
    { value: "Gold", label: "Gold" },
    { value: "Space Gray", label: "Space Gray" },
    { value: "Silver", label: "Silver" },
  ];

  const optionSizes = [
    { value: "Small", label: "Small" },
    { value: "Medium", label: "Medium" },
    { value: "Large", label: "Large" },
  ];

  const optionTransmission = [
    { value: "Manual", label: "Manual" },
    { value: "Auto", label: "Auto" },
  ];

  const optionHoursePower = [
    { value: "600 CC", label: "600 CC" },
    { value: "1000 CC", label: "1000 CC" },
  ];

  const engineTypes = [
    { value: "Diesel", label: "Diesel" },
    { value: "Petrol", label: "Petrol" },
    { value: "Electric", label: "Electric" },
    { value: "Hybrid ", label: "Hybrid " },
    { value: "Gas", label: "Gas" },
  ];

  const optionStorages = [
    { value: "16 GB", label: "16 GB" },
    { value: "32 GB", label: "32 GB" },
    { value: "64 GB", label: "64 GB" },
    { value: "128 GB ", label: "128 GB " },
    { value: "512 GB", label: "512 GB" },
    { value: "1 TB", label: "1 TB" },
  ];

  const optionWarranty = [
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
  ];

  const handleChainChange = (value) => {
    setSelectedChain(value.value);
    const details = chainDetails[value.value];
    setSelectedChainDetails(details);
  };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files.length;
    const file = e.target.files;
    for (let i = 0; i < selectedFiles; ++i) {
      setFile((prevFiles) => [...prevFiles, file[i]]);
    }
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value.value);
    const countryDetails = value.value;
    const selectedCountryValues = value.map((option) => option.value);
    const selectedCountryJson = JSON.stringify(selectedCountryValues);
    setCountryChainDetails(selectedCountryJson);
  };

  // const handlePriceChange = (value) => {
  //   // console.log('handlePriceChange', value.target.value);
  //   setSelectedPrice(value.target.value);
  // };

  const handleDeliveryChargesChange = (e) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    // Check if the parsed value is negative or greater than 100
    if (parsedValue < 0) {
      setShowError4(true);

      // Set a timer to hide the error message after 5 seconds
      setTimeout(() => {
        setShowError4(false);
      }, 5000);
    } else {
      setShowError4(false);
      setDeliveryChargesNew(newValue);
    }
  };

  const handlePriceChange = (e) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    // Check if the parsed value is negative or greater than 100
    if (parsedValue < 0) {
      setShowError3(true);

      // Set a timer to hide the error message after 5 seconds
      setTimeout(() => {
        setShowError3(false);
      }, 5000);
    } else {
      setShowError3(false);
      setSelectedPrice(newValue);
    }
  };

  const handleModelChange = (e) => {
    const newValue = e.target.value;
    setSelectedModel(newValue);
  };

  // const handleQuantityChange = (value) => {
  //   // console.log('handleQuantityChange', value.target.value);
  //   setSelectedQuantity(value.target.value);
  // };

  const handleQuantityChange = (e) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    // Check if the parsed value is negative or greater than 100
    if (parsedValue < 0) {
      setShowError2(true);

      // Set a timer to hide the error message after 5 seconds
      setTimeout(() => {
        setShowError2(false);
      }, 5000);
    } else {
      setShowError2(false);
      setSelectedQuantity(newValue);
    }
  };

  const handleMqChange = (e) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    // Check if the parsed value is negative or greater than 100
    if (parsedValue < 0) {
      setShowError2(true);

      // Set a timer to hide the error message after 5 seconds
      setTimeout(() => {
        setShowError2(false);
      }, 5000);
    } else {
      setShowError2(false);
      setSelectedMq(newValue);
    }
  };

  const handleDiscountChange = (e) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    // Check if the parsed value is negative or greater than 100
    if (parsedValue < 0 || parsedValue > 100) {
      setShowError(true);

      // Set a timer to hide the error message after 5 seconds
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    } else {
      setShowError(false);
      setSelectedDiscount(newValue);
    }
  };

  const handleSquareSizeChange = (e) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    // Check if the parsed value is negative or greater than 100
    if (parsedValue < 0 || parsedValue > 100) {
      setShowError(true);

      // Set a timer to hide the error message after 5 seconds
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    } else {
      setShowError(false);
      setSelectedSquareSize(newValue);
    }
  };

  const handleFloorChange = (e) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    // Check if the parsed value is negative or greater than 100
    if (parsedValue < 0 || parsedValue > 100) {
      setShowError(true);

      // Set a timer to hide the error message after 5 seconds
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    } else {
      setShowError(false);
      setSelectedFloor(newValue);
    }
  };

  const [arrayOfArraysNew, setArrayOfArraysNew] = useState([]);

  function Removeeee(indexToRemove) {
    const updatedArray = [...arrayOfArraysNew];
    updatedArray.splice(indexToRemove, 1); // Remove the element at indexToRemove
    setArrayOfArraysNew(updatedArray); // Update the state
  }

  const handleButtonClickClick = () => {
    fileInputRef.current.click();
  };

  const handleButtonClickClick1 = () => {
    fileInputRef1.current.click();
  };
  const handleButtonSelfieClick = () => {
    fileSelfieUpload.current.click();
  };
  const handleSelfie = () => {
    fileInputRef1.current.click();
  };
  const handleUploadSelfie = () => {
    fileInputRef1.current.click();
  };
  const handleButtonClickClick2 = () => {
    fileInputRef2.current.click();
  };

  function handleButtonClick() {
    const arrayOfArrays = [];
    fieldValuesArray = fields2.map(() => ({
      color: selectedColor,
      size: selectedSize,
      price: SelectedPrice,
      quantity: SelectedQuantity,
      discount_percentage: SelectedDiscount,
      sold_quantity: 0,
    }));

    const DataArray = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const carName of getresultObject1) {
      //  if (storeCategoryName === 'vehicle') {
      DataArray[Object.keys(carName)[0]] =
        Object.keys(carName)[0] === "model"
          ? SelectedModel
          : Object.keys(carName)[0] === "color"
            ? selectedColor
            : Object.keys(carName)[0] === "horse_power"
              ? SelectedHorsePower
              : Object.keys(carName)[0] === "transmission"
                ? selectedTransmission
                : Object.keys(carName)[0] === "engine_type"
                  ? SelectedEngineType
                  : Object.keys(carName)[0] === "quantity"
                    ? SelectedQuantity
                    : Object.keys(carName)[0] === "registration"
                      ? SelectedRegistration
                      : Object.keys(carName)[0] === "region"
                        ? SelectedRegion
                        : Object.keys(carName)[0] === "city"
                          ? SelectedCity
                          : Object.keys(carName)[0] === "discount"
                            ? SelectedDiscount
                            : Object.keys(carName)[0] === "storage"
                              ? SelectedStorage
                              : Object.keys(carName)[0] === "size"
                                ? selectedSize
                                : Object.keys(carName)[0] === "property_type"
                                  ? selectedPropertyType
                                  : Object.keys(carName)[0] === "square_size"
                                    ? SelectedSquareSize
                                    : Object.keys(carName)[0] === "mq"
                                      ? SelectedMq
                                      : Object.keys(carName)[0] === "floor"
                                        ? SelectedFloor
                                        : Object.keys(carName)[0] === "price"
                                          ? SelectedPrice
                                          : 0;
      //  }
      //   if (storeCategoryName === 'Mobile') {
      //   DataArray[Object.keys(carName)[0]] = Object.keys(carName)[0] === 'model' ? SelectedModel
      //   : Object.keys(carName)[0] === 'color' ? selectedColor
      //   : Object.keys(carName)[0] === 'price' ? SelectedPrice
      //   : Object.keys(carName)[0] === 'quantity' ? SelectedQuantity
      //   : Object.keys(carName)[0] === 'storage' ? SelectedStorage : 0;
      //  }
    }

    const DataArray1 = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const carName of getresultObject1) {
      //  if (storeCategoryName === 'vehicle') {
      DataArray1[Object.keys(carName)[0]] =
        Object.keys(carName)[0] === "model"
          ? SelectedModel
          : Object.keys(carName)[0] === "color"
            ? selectedColor
            : Object.keys(carName)[0] === "horse_power"
              ? SelectedHorsePower
              : Object.keys(carName)[0] === "transmission"
                ? selectedTransmission
                : Object.keys(carName)[0] === "engine_type"
                  ? SelectedEngineType
                  : Object.keys(carName)[0] === "quantity"
                    ? parseInt(SelectedQuantity, 10)
                    : Object.keys(carName)[0] === "registration"
                      ? SelectedRegistration
                      : Object.keys(carName)[0] === "region"
                        ? SelectedRegion
                        : Object.keys(carName)[0] === "city"
                          ? SelectedCity
                          : Object.keys(carName)[0] === "discount"
                            ? parseInt(SelectedDiscount, 10)
                            : Object.keys(carName)[0] === "storage"
                              ? SelectedStorage
                              : Object.keys(carName)[0] === "size"
                                ? selectedSize
                                : Object.keys(carName)[0] === "property_type"
                                  ? selectedPropertyType
                                  : Object.keys(carName)[0] === "square_size"
                                    ? SelectedSquareSize
                                    : Object.keys(carName)[0] === "mq"
                                      ? SelectedMq
                                      : Object.keys(carName)[0] === "floor"
                                        ? parseInt(SelectedFloor, 10)
                                        : Object.keys(carName)[0] === "price"
                                          ? parseInt(SelectedPrice, 10)
                                          : 0;
      //   }
      //    if (storeCategoryName === 'Mobile') {
      //    DataArray1[Object.keys(carName)[0]] = Object.keys(carName)[0] === 'model' ? SelectedModel
      //   : Object.keys(carName)[0] === 'color' ? selectedColor
      //    : Object.keys(carName)[0] === 'price' ? parseInt(SelectedPrice, 10)
      //    : Object.keys(carName)[0] === 'quantity' ? parseInt(SelectedQuantity, 10)
      //     : Object.keys(carName)[0] === 'storage' ? SelectedStorage : 0;
      //  }
    }

    const fieldValuesArrayNew = {
      color: selectedColor,
      size: selectedSize,
      price: parseInt(SelectedPrice, 10),
      quantity: parseInt(SelectedQuantity, 10),
      discount_percentage: parseInt(SelectedDiscount, 10),
      sold_quantity: 0,
    };

    setArrayOfArraysNew([...arrayOfArraysNew, DataArray1]);

    arrayOfArrays.push(DataArray);
    // console.log('arrayOfArrays JSON >>', JSON.stringify(arrayOfArrays[0]));
    // console.log('arrayOfArrays >>', arrayOfArrays);
    setfieldValuesArray(JSON.stringify(arrayOfArrays[0]));
    // console.log('fieldValuesArray >>>>>>> ', fieldValuesArray[0]);
    StoreForTable(DataArray);
    if (fieldValuesArray.length > 0) {
      valueCheck(0);
      valuePrice(fieldValuesArray[0].price);
      valueQuantity(fieldValuesArray[0].quantity);
      valueDiscount(fieldValuesArray[0].discount_percentage);
      valueColor(fieldValuesArray[0].color);
      valueSize(fieldValuesArray[0].size);
      setSelectedSize("Small");
      setSelectedColor("Red");
      setSelectedQuantity(1);
      setSelectedPrice(1);
      setSelectedDiscount(0);
      setSelectedTransmission("Manual");
      setSelectedStorage("16 GB");
      setSelectedPropertyType("Rental");
      setSelectedModel("0");
      setSelectedHorsePower("600 CC");
      setSelectedRegistration("United States");
      setSelectedRegion("USA");
      setSelectedCity("America");
      setSelectedFloor(1);
      setSelectedSquareSize("");
    } else {
      console.log("Else");
    }
    // Call your desired function here
    // Example: yourFunctionToCall();
  }

  const handleColorChange = (value) => {
    setSelectedColor(value.value);
  };

  const handleTransmissionChange = (value) => {
    setSelectedTransmission(value.value);
  };

  const handleStorageChange = (value) => {
    setSelectedStorage(value.value);
  };

  const handleRegionChange = (value) => {
    setSelectedRegion(value.value);
  };

  const handleCityChange = (value) => {
    setSelectedCity(value.value);
  };

  const handlePropertyTypeChange = (value) => {
    setSelectedPropertyType(value.value);
  };

  const handlePowerChange = (value) => {
    setSelectedHorsePower(value.value);
  };

  const handleRegistrationChange = (value) => {
    setSelectedRegistration(value.value);
  };

  const handleSizeChange = (value) => {
    setSelectedSize(value.value);
  };

  const handleEngineTypeChange = (value) => {
    setSelectedEngineType(value.value);
  };

  const handleWarrantyChange = (value) => {
    setSelectedWarranty(value.value);
  };

  const handleInputChange = (name) => {
    setsearchLoad(true);
    if (name.length >= 2) {
      getBeneficiaryName({ name })
        .then((res) => {
          setsearchLoad(false);
          const arr = res.data.user.map((i) => ({
            // eslint-disable-next-line no-underscore-dangle
            id: i._id,
            address: i.address,
            value: i.name,
            label: i.name,
          }));
          setsearchLoad(false);
          return setbnNames(arr);
        })
        .catch((error) => {
          setsearchLoad(false);
          setstatusCode(400);
          if (error.message === "Network Error") {
            // setMsg(`Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`);
          } else {
            setMsg(`CatchError:${error}${error?.message}`);
          }
          setTimeout(() => {
            setMsg("");
          }, 5000);
        });
    }
    setsearchLoad(false);
  };

  const handleImageUpload = (e) => {
    const selectedImagesArray = e.target.files;
    const selectedImage = e.target.files[0];
    setImage1(URL.createObjectURL(selectedImage));
    // setSelectedImages(Array.from(selectedImagesArray));
    setShow1(true);
    // setSelectedImages1(prevImages => [...prevImages, ...selectedImagesArray]);
  };
  const handleSelfieUpload = (e) => {
    const selectedImagesArray = e.target.files;
    const selectedImage = e.target.files[0];
    setImageSelfie(selectedImage);
    // setSelectedImages(Array.from(selectedImagesArray));
    // setShow1(true);
    // setSelectedImages1(prevImages => [...prevImages, ...selectedImagesArray]);
  };

  const dataURLtoBlob = (dataURL) => {
    const parts = dataURL.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  };

  const handleCrop = () => {
    setShow1(false);
    setIsLoading(true);
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();
      const dataUrl = croppedCanvas.toDataURL();

      // Create a Blob from the data URL
      const blob = dataURLtoBlob(dataUrl);
      const croppedFile = new File([blob], "cropped-image.jpg", {
        type: "image/jpeg",
      });

      // Create a Blob URL
      const blobUrl = URL.createObjectURL(blob);

      // Check if the Blob URL is not already in the array before pushing
      if (!selectedImages1.includes(blobUrl)) {
        // Update the state with the new Blob URL
        setSelectedImages(Array.from(croppedFile));
        setSelectedImages1((prevImages) => [...prevImages, croppedFile]);
        setSelectedImages3((prevImages) => [...prevImages, blobUrl]);
      }
      setIsLoading(false);
    }
  };

  const [valuesNew, setValues] = useState({
    isDeliveryServiceActive: false,
    // availability_status: false,
    deliveryCharges: 0,
  });

  function removeImage2(indexToRemove) {
    const updatedImages = [...selectedImages1];
    updatedImages.splice(indexToRemove, 1);
    setSelectedImages1(updatedImages);

    const updatedImages3 = [...selectedImages3];
    updatedImages3.splice(indexToRemove, 1);
    setSelectedImages3(updatedImages3);

    toast.success("Image Removed", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  const handleChangecheckbox = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: newValue,
    }));
  };

  const handleCreateProduct = async (value) => {
    const details = chainDetails[selectedChain];
    // const fieldValuesArrayVal1 = JSON.stringify(fieldValuesArrayVal);

    const colorDetailsVal = selectedColor;
    const formData = new FormData();
    const selectedFeatureValues = fieldsNew.map((option) => option);
    const selectedFeatureJson = JSON.stringify(selectedFeatureValues);

    if (document.getElementById("deliveryCharges") == null) {
      // eslint-disable-next-line no-const-assign
      setDeliveryCharges(0);
    } else {
      // eslint-disable-next-line no-const-assign
      setDeliveryCharges(document.getElementById("deliveryCharges").value);
    }
    // console.log('selectedImages', selectedImages);
    // console.log('JSON', JSON.stringify(selectedImages));

    // eslint-disable-next-line no-restricted-syntax
    for (const file of selectedImages1) {
      formData.append("File", file);
    }
    for (const file1 of file2) {
      formData.append("File2", file1);
    }
    // formData.append('File', selectedImages1);
    formData.append(
      "category_id",
      document.getElementById("category_id").value
    );
    // formData.append('subcategory_id', document.getElementById('subcategory_id').value);
    formData.append("approver_wallet_Address", StoreApprover);
    formData.append(
      "pickupAddress",
      document.getElementById("pickupAddress").value
    );
    formData.append("warrantyInMonths", parseInt(SelectedWarranty, 10));
    formData.append("title", document.getElementById("title").value);
    formData.append("brand", document.getElementById("brand").value);
    formData.append("allowedCountries", StoreCountry);
    // formData.append('File2', file2);
    formData.append("deliveryCharges", parseInt(setDeliveryChargesvalNew, 10));
    formData.append("isDeliveryServiceActive", StoreAvailable);
    // formData.append('File', selectedImages);
    formData.append("chainId", StoreChainId);
    formData.append("symbol", StoreSymbol);
    formData.append("store_id", StoreID);
    formData.append("seller_wallet", selectedType == "seller"  ?  user?.data?.user?.address : selectedWallet.value.address );
    formData.append("agency_percentage", selectedAgencyPercentage || 0);
    formData.append(
      "description",
      document.getElementById("description").value
    );
    // formData.append('price', document.getElementById('price').value);
    // formData.append('color', selectedColorDetails);
    // formData.append('quantity', document.getElementById('quantity').value);
    // formData.append('discount', document.getElementById('discount').value);
    formData.append("product_sizes_quantity", JSON.stringify(arrayOfArraysNew));
    formData.append("features", selectedFeatureJson);
    formData.append("store_category_id", storeCategoryID);
    formData.append("subcategory_id", selectedSubcategoryID);
    formData.append("product_condition", SelectedCondition);
    formData.append("custom_offer_status", offerStatus);

    // setStatus(400);
    // setloading(true);
    // setMsg("Catch Error: message");
    // console.log('Catch Error: message');
    setIsLoading(true);
    try {
      setIsLoading(true);
      const response = await createProduct(formData);
      if (response.data) {
        toast.success("Product Create Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        history.push(`/storedetail/${StoreID}`);
        setIsLoading(false);
      } else {
        toast.error(response.status, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
        console.error("Not Found", response.status);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Create Store Error: ", error.response);
      console.error(
        "Create Store Error Response: ",
        error.response.data.Message
      );
      const errorMessages = error.response.data.Message;
      // console.log('errorMessages', errorMessages);
      toast.error(errorMessages, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Panel lg={12} title="Create Product" closeBtn minusBtn refreshLoad>
        <Formik
          initialValues={{
            title: "",
            category_id: storeCategoryID,
            subcategory_id: selectedSubcategoryID,
            brand: "",
            description: "",
            features: "",
            pickupAddress: "",
            warrantyInMonths: 0,
            seller: "",
            agencyPer: "",
            price: "",
            color: "",
            File: "",
            File2: "",
            quantity: "",
            deliveryCharges: 0,
            discount: 0,
            product_sizes_quantity: "",
            product_condition: SelectedCondition,
            custom_offer_status: offerStatus,
          }}
          onSubmit={(values, { resetForm }) => {
            // console.log('values');
            // console.log(values);
            handleCreateProduct(values);
          }}
          validate={(values) => {
            const errors = {};
            setShowAlert(false);

            if (!values.title) {
              errors.title = "Title is Required!";
            }
            if (!selectedSubcategoryID) {
              errors.subcategory_id = "Sub Category is Required!";
            }
            if (!SelectedCondition) {
              errors.product_condition = "Condition is Required!";
            }
            if (!values.brand) {
              errors.brand = "Brand is Required!";
            }
            if (!values.description) {
              errors.description = "Description is Required!";
            }
            if (fieldsNew.length === 0) {
              errors.features = "Features is Required!";
            }
            if (selectedType === "") {
              errors.store_type = "Store Type is Required!";
            }
            // if (selectedWallet.value === "") {
            //   errors.seller = "Seller is Required!";
            // }
            // if (selectedAgencyPercentage === "") {
            //   errors.agencyPer = "Agency Percentage is Required!";
            // }
            if (arrayOfArraysNew.length === 0) {
              errors.product_sizes_quantity =
                "You Need to add Product Specifications";
            }
            if (selectedImages1.length === 0) {
              errors.File = "Attachment is Required!";
            }
            if (file2 === null) {
              errors.File2 = "Product Document is Required!";
            }
            if (!values.pickupAddress) {
              errors.pickupAddress = "Pickup Address is Required!";
            }
            // if (!values.price) {
            //   errors.price = 'Price is Required!';
            // }
            // if (Object.keys(selectedColorDetails).length === 0 || selectedColorDetails.length === 2) {
            //   errors.color = 'Color is Required!';
            // }
            // if (!values.quantity) {
            //   errors.quantity = 'Quantity is Required!';
            // }
            if (!values.deliveryCharges) {
              // errors.deliveryCharges = 'deliveryCharges is Required!!';
            }
            // if (Object.keys(selectedCountryDetails).length === 0 || selectedCountryDetails.length === 2) {
            //   errors.allowedCountries = 'Countries is Required!';
            // }
            if (!selectedWallet.value && selectedType == "agency") {
              errors.seller = 'Sellet Wallet  is Required!';
            }


            return errors;
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <form onSubmit={handleSubmit}>
                <div className="row mb-3">
                  <div className="col-md-12">
                    {showAlert && (
                      <div
                        className="alert alert-danger text-center"
                        role="alert"
                      >
                        {errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  {selectedImages3.map((index, image) => (
                    <div className="col-lg-2 col-md-4 col-sm-6 text-center">
                      <span>
                        <button
                          className="btn btn-danger btn-sm py-0 px-1 mb-3 shadow-none text-capitalize"
                          type="button"
                          onClick={() => removeImage2(image)}
                        >
                          {" "}
                          Remove
                        </button>
                        <img
                          key={image.id}
                          // src={URL.createObjectURL(index)}
                          src={index}
                          alt={`Uploaded ${index + 1}`}
                          className="uploaded-image createImg mx-2"
                        />
                      </span>
                    </div>
                  ))}
                </div>
                <div className="row mb-3">
                  {capturedImage && (
                    <div className="col-lg-2 col-md-4 col-sm-6 text-center">
                      <span>
                        <button
                          className="btn btn-danger btn-sm py-0 px-1 mb-3 shadow-none text-capitalize"
                          type="button"
                          onClick={() => setCapturedImage(null)}
                        >
                          {" "}
                          Remove
                        </button>
                        <img
                          key={capturedImage}
                          src={capturedImage}
                          className="uploaded-image createImg mx-2"
                          alt="imageselfie"
                        />
                      </span>
                    </div>
                  )}
                  {imageSelfie && !capturedImage && (
                    <div className="col-lg-2 col-md-4 col-sm-6 text-center">
                      <span>
                        <button
                          className="btn btn-danger btn-sm py-0 px-1 mb-3 shadow-none text-capitalize"
                          type="button"
                          onClick={() => setCapturedImage(null)}
                        >
                          {" "}
                          Remove
                        </button>
                        <img
                          src={URL.createObjectURL(imageSelfie)}
                          className="uploaded-image createImg mx-2"
                          alt="imageselfie"
                        />
                      </span>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <InputDiv>
                      <label
                        htmlFor="title"
                        style={{ fontSize: "small" }}
                        className="form-label text-white"
                      >
                        Title
                      </label>
                      <div>
                        <ChatSearchInput
                          type="text"
                          value={values.title}
                          name="title"
                          id="title"
                          placeholder="Enter Title"
                          onChange={handleChange}
                          className={`form-control mt-2 ${errors.title && touched.title && "error"
                            }`}
                        />
                        {errors.title && touched.title && (
                          <Error className="input feedback">
                            {errors.title}
                          </Error>
                        )}
                      </div>
                    </InputDiv>
                  </div>
                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <InputDiv>
                      <label
                        htmlFor="category_id"
                        style={{ fontSize: "small" }}
                        className="form-label text-white"
                      >
                        Category
                      </label>
                      <div>
                        <ChatSearchInput
                          type="text"
                          value={storeCategoryName}
                          name="category_name"
                          id="category_name"
                          placeholder="Enter Category"
                          onChange={handleChange}
                          className="form-control mt-2"
                          style={{ textTransform: "capitalize" }}
                        />
                        <ChatSearchInput
                          type="hidden"
                          name="category_id"
                          id="category_id"
                          value={storeCategoryID}
                        />
                        {errors.category_id && touched.category_id && (
                          <Error className="input feedback">
                            {errors.category_id}
                          </Error>
                        )}
                      </div>
                    </InputDiv>
                  </div>
                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <InputDiv>
                      <DropdownDiv className="">
                        <label
                          htmlFor="subcategory_id"
                          style={{ fontSize: "small" }}
                          className="form-label text-white"
                        >
                          Sub Category *
                        </label>
                        <CustomSelectCustom
                          name="subcategory_id"
                          id="subcategory_id"
                          className="w-25"
                          value={selectedSubcategoryID}
                          options={subCategory}
                          onChange={handleSubcategoryChange}
                        />
                        {errors.subcategory_id && touched.subcategory_id && (
                          <Error className="input feedback">
                            {errors.subcategory_id}
                          </Error>
                        )}
                      </DropdownDiv>
                    </InputDiv>
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <InputDiv>
                      <label
                        htmlFor="brand"
                        style={{ fontSize: "small" }}
                        className="form-label text-white"
                      >
                        Brand / Company *
                      </label>
                      <div>
                        <ChatSearchInput
                          type="text"
                          value={values.brand}
                          name="brand"
                          id="brand"
                          placeholder="Enter Brand / Company"
                          onChange={handleChange}
                          className={`form-control mt-2 ${errors.brand && touched.brand && "error"
                            }`}
                        />
                        {errors.brand && touched.brand && (
                          <Error className="input feedback">
                            {errors.brand}
                          </Error>
                        )}
                      </div>
                    </InputDiv>
                  </div>
                  <div className="col-md-12 col-lg-8 col-sm-12">
                    <InputDiv>
                      <label
                        htmlFor="description"
                        style={{ fontSize: "small" }}
                        className="form-label text-white"
                      >
                        Description *
                      </label>
                      <div>
                        <ChatSearchInput
                          type="text"
                          value={values.description}
                          name="description"
                          id="description"
                          placeholder="Enter Description"
                          onChange={handleChange}
                          className={`form-control mt-2 ${errors.description && touched.description && "error"
                            }`}
                        />
                        {errors.description && touched.description && (
                          <Error className="input feedback">
                            {errors.description}
                          </Error>
                        )}
                      </div>
                    </InputDiv>
                  </div>
                </div>

                <div className="row my-2">
                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <InputDiv>
                      <label
                        htmlFor="pickupAddress"
                        style={{ fontSize: "small" }}
                        className="form-label text-white"
                      >
                        Pickup Address *
                      </label>
                      <div>
                        <ChatSearchInput
                          type="text"
                          value={values.pickupAddress}
                          name="pickupAddress"
                          id="pickupAddress"
                          placeholder="Enter Pickup Address"
                          onChange={handleChange}
                          className={`form-control mt-2 ${errors.pickupAddress &&
                            touched.pickupAddress &&
                            "error"
                            }`}
                        />
                        {errors.pickupAddress && touched.pickupAddress && (
                          <Error className="input feedback">
                            {errors.pickupAddress}
                          </Error>
                        )}
                      </div>
                    </InputDiv>
                  </div>
                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <InputDiv>
                      <label
                        htmlFor="warrantyInMonths"
                        style={{ fontSize: "small" }}
                        className="form-label text-white"
                      >
                        Warranty In Months *
                      </label>
                      <DropdownDiv
                        className="mb-0"
                        style={{ textTransform: "Capitalize" }}
                      >
                        <CustomSelectCustom
                          name="size"
                          id="size"
                          className="w-25 px-0"
                          value={SelectedWarranty}
                          onChange={handleWarrantyChange}
                          options={optionWarranty}
                        />
                      </DropdownDiv>
                    </InputDiv>
                  </div>
                  {true && (
                    <div className="col-md-12 col-lg-4 col-sm-12">
                      <InputDiv>
                        <label
                          htmlFor="deliveryCharges"
                          style={{ fontSize: "small" }}
                          className="form-label text-white"
                        >
                          Delivery Charges *
                        </label>
                        <div>
                          <ChatSearchInput
                            type="number"
                            value={setDeliveryChargesvalNew}
                            name="deliveryCharges"
                            id="deliveryCharges"
                            placeholder="Enter Delivery Charges"
                            onChange={handleDeliveryChargesChange}
                            min={0}
                            className={`form-control mt-2 ${errors.deliveryCharges &&
                              touched.deliveryCharges &&
                              "error"
                              }`}
                          />
                          {showError4 && (
                            <Error className="input feedback">
                              Delivery Charges must be 1 or greater
                            </Error>
                          )}
                        </div>
                      </InputDiv>
                    </div>
                  )}
                </div>
                <div className="row my-2">
                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <label
                      htmlFor="File"
                      style={{ fontSize: "small" }}
                      className="form-label text-white"
                    >
                      Product Images *
                    </label>
                    <input
                      hidden
                      type="file"
                      ref={fileInputRef1}
                      value={values.File}
                      name="File[]"
                      id="File"
                      // multiple
                      onChange={handleImageUpload}
                      className={`form-control ${errors.File && touched.File && "error"
                        }`}
                      accept="image/*"
                    />
                    <br />
                    <Button
                      size="md"
                      onClick={handleButtonClickClick1}
                      className="py-2 btn-block mb-0"
                      variant="outline-success"
                    >
                      Upload Images
                    </Button>
                    {errors.File && touched.File && (
                      <Error className="input feedback">{errors.File}</Error>
                    )}
                  </div>
                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <label
                      htmlFor="File2"
                      style={{ fontSize: "small" }}
                      className="form-label text-white"
                    >
                      Product Document
                    </label>
                    <input
                      hidden
                      type="file"
                      ref={fileInputRef}
                      className="form-control"
                      onChange={handleFileChange}
                      id="File2"
                      name="File2"
                      multiple
                      accept=".pdf"
                    />
                    <br />
                    <Button
                      size="md"
                      onClick={handleButtonClickClick}
                      className="py-2 btn-block mb-0"
                      variant="outline-success"
                    >
                      Upload Document
                    </Button>
                    {/* <ErrorMessage name="File" component="div" className="invalid-feedback" /> */}
                    {errors.File2 && touched.File2 && (
                      <Error className="input feedback">{errors.File2}</Error>
                    )}
                  </div>
                </div>
                {file2.length > 0 && (
                  <div className="row mb-4">
                    <div className="col-md-12 col-lg-4 col-sm-12">
                      <label
                        htmlFor="features"
                        style={{ fontSize: "small" }}
                        className="form-label text-white mt-3"
                      >
                        Uploaded Documents{" "}
                      </label>
                      {file2.map((file, index) => (
                        <div
                          className="py-0 my-1"
                          style={{
                            border: "1px solid white",
                            borderRadius: "4px",
                          }}
                        >
                          <label
                            htmlFor="features"
                            className="form-label text-white mt-1 mx-2"
                          >
                            {file.name && file.name.length > 20
                              ? `${file.name.slice(0, 20)}...`
                              : file.name}
                          </label>
                          <i
                            className="fa fa-trash-o float-right mr-2 mt-2"
                            onClick={() => handleFileRemove(index)}
                            style={{
                              fontSize: "22px",
                              color: "rgb(255, 72, 97)",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <div className="row mb-4">
                  <div className="col-md-12 col-lg-8 col-sm-12">
                    <label
                      htmlFor="features"
                      style={{ fontSize: "small" }}
                      className="form-label text-white mt-3 mb-0"
                    >
                      Features *
                    </label>
                    {fields.map((field) => (
                      <div className="d-flex my-1" key={field.id}>
                        <ChatSearchInput
                          type="text"
                          value={field.value}
                          name="features[]"
                          id={`features_${field.id}`}
                          placeholder="Enter Features"
                          onChange={(e) =>
                            handleChangeFeature(field.id, e.target.value)
                          }
                          className="form-control mr-1 mt-2"
                        />
                        {field.id !== 1 ? (
                          <button
                            type="button"
                            className="btn btn-danger btn-sm shadow-none text-capitalize"
                            onClick={() => handleRemoveField(field.id)}
                          >
                            <i className="fa fa-minus" />{" "}
                          </button>
                        ) : (
                          <button
                            type="button"
                            style={{ background: "#4bdfb3" }}
                            className="btn btn-success btn-sm float-right shadow-none text-capitalize"
                            onClick={handleAddField}
                          >
                            <i className="fa fa-plus" />{" "}
                          </button>
                        )}
                      </div>
                    ))}
                    {errors.features && touched.features && (
                      <Error className="input feedback">
                        {errors.features}
                      </Error>
                    )}
                  </div>
                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <InputDiv>
                      <DropdownDiv className="">
                        <label
                          htmlFor="product_condition"
                          style={{ fontSize: "small" }}
                          className="form-label text-white mt-0"
                        >
                          Condition *
                        </label>
                        <CustomSelectCustom
                          name="product_condition"
                          id="product_condition"
                          className="w-25"
                          value={SelectedCondition}
                          options={productCondition}
                          onChange={handleConditionChange}
                        />
                        {errors.product_condition &&
                          touched.product_condition && (
                            <Error className="input feedback">
                              {errors.product_condition}
                            </Error>
                          )}
                      </DropdownDiv>
                    </InputDiv>
                  </div>
                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <InputDiv>
                      <DropdownDiv className="">
                        <label
                          htmlFor="custom_offer_status"
                          style={{ fontSize: "small" }}
                          className="form-label text-white mt-0"
                        >
                          {" "}
                          Custom Offer Status
                        </label>
                        <CustomSelectCustom
                          name="custom_offer_status"
                          id="custom_offer_status"
                          className="w-25"
                          value={offerStatus}
                          options={customOfferStatus}
                          onChange={handleOfferStatus}
                        />
                      </DropdownDiv>
                    </InputDiv>
                  </div>

                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <>
                      <label
                        htmlFor="storeDescription"
                        className="form-label text-white"
                      >
                        Store Type
                      </label>
                      <DropdownDiv className="mb-2">
                        <CustomSelect
                          name="storeDescription"
                          className="w-25"
                          placeholder={selectedType}
                          value={selectedType}
                          // isDisabled
                        />
                      </DropdownDiv>
                      {errors.store_type && touched.store_type && (
                        <ErrorMsg className="input feedback">
                          {errors.store_type}
                        </ErrorMsg>
                      )}
                    </>
                  </div>

                  {selectedType === "agency" && (
                    <>
                      <div className="col-md-12 col-lg-4 col-sm-12">
                        <>
                          <label
                            htmlFor="storeDescription"
                            className="form-label text-white"
                          >
                            Select Seller
                          </label>
                          <DropdownDiv className="mb-2">
                            <CustomSelect
                              name="chain"
                              className="w-25"
                              value={selectedWallet.value} 
                              placeholder={selectedWallet.label}
                              onInputChange={handleInputChange}
                              onChange={(value) => {

                                setSelectedWallet("selectedWallet", value);
                                handleSellerWalletSelect(value);
                              }}
                              options={bnNames}
                            />
                          </DropdownDiv>
                          {errors.seller && (
                            <ErrorMsg className="input feedback">
                              {errors.seller}
                            </ErrorMsg>
                          )}
                        </>
                      </div>
                      <div className="col-md-12 col-lg-4 col-sm-12">
                        <InputDiv>
                          <label
                            htmlFor="agencypercentage"
                            style={{ fontSize: "small" }}
                            className="form-label text-white"
                          >
                            Agency Percentage *
                          </label>
                          <div>
                            <ChatSearchInput
                              type="number"
                              value={selectedAgencyPercentage}
                              name="agencypercentage"
                              id="agencypercentage"
                              placeholder="Enter Agency Percentage"
                              onChange={(e) =>
                                setSelectedAgencyPercentage(e.target.value)
                              }
                              min={0}
                            />
                            {errors.agencyPer && (
                              <ErrorMsg className="input feedback">
                                {errors.agencyPer}
                              </ErrorMsg>
                            )}
                          </div>
                        </InputDiv>
                      </div>

                      <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center col-md-12 col-lg-4 col-sm-12">
                        <Button
                          onClick={() => setShowWebCap(true)}
                          size="md"
                          // className="py-2 btn-block align-self-center mt-2 "
                          variant="outline-success"
                          disabled={imageSelfie}
                        >
                          Verify Selfie
                        </Button>

                        <div className="d-flex mt-2 justify-content-center align-items-center">
                          <input
                            hidden
                            type="file"
                            ref={fileSelfieUpload}
                            value={values.File}
                            name="File[]"
                            id="File"
                            // multiple
                            onChange={handleSelfieUpload}

                            accept="image/*"
                          />
                          <br />
                          <Button
                            onClick={handleButtonSelfieClick}
                            size="md"
                            // className="py-2 btn-block mb-0 w-100 align-self-center  "
                            variant="outline-success"
                            disabled={capturedImage}

                          >
                            Upload Selfie
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <hr />

                <div className="row mb-4">
                  {CategoryDetail.map((field, index) => (
                    <div className="col-lg-2 col-md-3 col-sm-6">
                      {field.inputType === "dropdown" ? (
                        <InputDiv>
                          <DropdownDiv
                            className="mb-0"
                            style={{ textTransform: "Capitalize" }}
                          >
                            <label
                              htmlFor="color"
                              style={{ fontSize: "small" }}
                              className="form-label text-white mb-2"
                            >
                              {field.label}
                            </label>
                            <CustomSelectCustom
                              className="w-25 px-0"
                              value={
                                // eslint-disable-next-line no-nested-ternary
                                field.value === "engine_type"
                                  ? SelectedEngineType
                                  : // eslint-disable-next-line no-nested-ternary
                                  field.value === "color"
                                    ? selectedColor
                                    : field.value === "transmission"
                                      ? selectedTransmission
                                      : field.value === "storage"
                                        ? SelectedStorage
                                        : field.value === "registration"
                                          ? SelectedRegistration
                                          : field.value === "region"
                                            ? SelectedRegion
                                            : field.value === "city"
                                              ? SelectedCity
                                              : field.value === "property_type"
                                                ? selectedPropertyType
                                                : field.value === "horse_power"
                                                  ? SelectedHorsePower
                                                  : selectedSize
                              }
                              onChange={
                                // eslint-disable-next-line no-nested-ternary
                                field.value === "engine_type"
                                  ? handleEngineTypeChange
                                  : field.value === "color"
                                    ? handleColorChange
                                    : field.value === "transmission"
                                      ? handleTransmissionChange
                                      : field.value === "storage"
                                        ? handleStorageChange
                                        : field.value === "registration"
                                          ? handleRegistrationChange
                                          : field.value === "region"
                                            ? handleRegionChange
                                            : field.value === "city"
                                              ? handleCityChange
                                              : field.value === "property_type"
                                                ? handlePropertyTypeChange
                                                : field.value === "horse_power"
                                                  ? handlePowerChange
                                                  : handleSizeChange
                              }
                              options={
                                // eslint-disable-next-line no-nested-ternary
                                field.value === "engine_type"
                                  ? engineTypes
                                  : field.value === "color"
                                    ? optionColors
                                    : field.value === "transmission"
                                      ? optionTransmission
                                      : field.value === "storage"
                                        ? optionStorages
                                        : field.value === "registration"
                                          ? RegistrationArray
                                          : field.value === "region"
                                            ? RegionArray
                                            : field.value === "city"
                                              ? CityArray
                                              : field.value === "property_type"
                                                ? PropertyTypes
                                                : field.value === "horse_power"
                                                  ? optionHoursePower
                                                  : optionSizes
                              }
                            />
                          </DropdownDiv>
                        </InputDiv>
                      ) : (
                        <InputDiv>
                          <label
                            htmlFor="title"
                            style={{ fontSize: "small" }}
                            className="form-label text-white"
                          >
                            {field.label}
                          </label>
                          <div>
                            <ChatSearchInput
                              type={
                                // eslint-disable-next-line no-nested-ternary
                                field.value === "model"
                                  ? "text"
                                  : field.value === "square_size"
                                    ? "text"
                                    : field.value === "horse_power"
                                      ? "text"
                                      : "number"
                              }
                              value={
                                // eslint-disable-next-line no-nested-ternary
                                field.value === "model"
                                  ? SelectedModel
                                  : field.value === "quantity"
                                    ? SelectedQuantity
                                    : field.value === "discount"
                                      ? SelectedDiscount
                                      : field.value === "square_size"
                                        ? SelectedSquareSize
                                        : field.value === "floor"
                                          ? SelectedFloor
                                          : field.value === "mq"
                                            ? SelectedMq
                                            : SelectedPrice
                              }
                              name="price[]"
                              id={`features_${field.id}`}
                              placeholder={
                                // eslint-disable-next-line no-nested-ternary
                                field.value === "model"
                                  ? "Enter Model"
                                  : field.value === "quantity"
                                    ? "Enter Quantity"
                                    : field.value === "discount"
                                      ? "Enter Discount"
                                      : field.value === "square_size"
                                        ? "Enter Sqaure Size"
                                        : field.value === "floor"
                                          ? "Enter Floor"
                                          : field.value === "mq"
                                            ? "Enter MQ"
                                            : "Enter Price"
                              }
                              onChange={
                                // eslint-disable-next-line no-nested-ternary
                                field.value === "model"
                                  ? handleModelChange
                                  : field.value === "quantity"
                                    ? handleQuantityChange
                                    : field.value === "discount"
                                      ? handleDiscountChange
                                      : field.value === "square_size"
                                        ? handleSquareSizeChange
                                        : field.value === "floor"
                                          ? handleFloorChange
                                          : field.value === "mq"
                                            ? handleMqChange
                                            : handlePriceChange
                              }
                              className={`form-control mt-2 ${errors.title && touched.title && "error"
                                }`}
                              min={0}
                            />
                          </div>
                        </InputDiv>
                      )}
                    </div>
                  ))}
                  <div className="col-lg-2 col-md-3 col-sm-6">
                    <Button
                      onClick={handleButtonClick}
                      style={{ marginTop: "30px" }}
                      size="md"
                      className="py-2 btn-block mb-0"
                      variant="outline-success"
                    >
                      Add
                    </Button>
                    {valueCheckVal === 0 &&
                      (errors.product_sizes_quantity &&
                        touched.product_sizes_quantity ? (
                        <Error className="input feedback">
                          {errors.product_sizes_quantity}
                        </Error>
                      ) : null)}
                  </div>
                </div>
                {valueCheckVal === 0 && (
                  <div className="row mb-4">
                    <div className="col-md-12 col-lg-12 col-sm-12">
                      <div style={{ overflowY: "auto" }}>
                        <table className="table text-center table-hover">
                          <thead className="thead-light">
                            <tr>
                              {CategoryDetail2.map((field, index) => (
                                <th style={{ fontWeight: "bold" }}>
                                  {field.label}
                                </th>
                              ))}
                              <th style={{ fontWeight: "bold" }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {arrayOfArraysNew.map((array, index) => (
                              <tr>
                                {getcategoryArrayDetailKeys2.includes(
                                  "city"
                                ) && <td>{array.city}</td>}
                                {getcategoryArrayDetailKeys2.includes(
                                  "color"
                                ) && <td>{array.color}</td>}
                                {getcategoryArrayDetailKeys2.includes(
                                  "discount"
                                ) && (
                                    <td>
                                      {array.discount} <b>%</b>
                                    </td>
                                  )}
                                {getcategoryArrayDetailKeys2.includes(
                                  "floor"
                                ) && <td>{array.floor}</td>}
                                {getcategoryArrayDetailKeys2.includes("mq") && (
                                  <td>{array.mq}</td>
                                )}
                                {getcategoryArrayDetailKeys2.includes(
                                  "horse_power"
                                ) && <td>{array.horse_power}</td>}
                                {getcategoryArrayDetailKeys2.includes(
                                  "model"
                                ) && <td>{array.model}</td>}
                                {getcategoryArrayDetailKeys2.includes(
                                  "price"
                                ) && (
                                    <td>
                                      {array.price} <b>{StoreSymbol}</b>
                                    </td>
                                  )}
                                {getcategoryArrayDetailKeys2.includes(
                                  "property_type"
                                ) && <td>{array.property_type}</td>}
                                {getcategoryArrayDetailKeys2.includes(
                                  "quantity"
                                ) && <td>{array.quantity}</td>}
                                {getcategoryArrayDetailKeys2.includes(
                                  "size"
                                ) && <td>{array.size}</td>}
                                {getcategoryArrayDetailKeys2.includes(
                                  "square_size"
                                ) && <td>{array.square_size}</td>}
                                {getcategoryArrayDetailKeys2.includes(
                                  "region"
                                ) && <td>{array.region}</td>}
                                {getcategoryArrayDetailKeys2.includes(
                                  "registration"
                                ) && <td>{array.registration}</td>}
                                {getcategoryArrayDetailKeys2.includes(
                                  "storage"
                                ) && <td>{array.storage}</td>}
                                {getcategoryArrayDetailKeys2.includes(
                                  "transmission"
                                ) && <td>{array.transmission}</td>}
                                <td style={{ verticalAlign: "middle" }}>
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm mt-0 shadow-none text-capitalize rounded-circle"
                                    onClick={() => Removeeee(index)}
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row mb-4">
                  <div className="col-md-12 col-lg-4 col-sm-12" />
                  <div className="col-md-12 col-lg-4 col-sm-12">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <FormButton type="submit" variant="success" className="w-100 shadow-none text-capitalize">
                      Create
                    </FormButton> */}
                      <Button
                        type="submit"
                        size="md"
                        className="py-2 btn-block"
                        variant="outline-success"
                      >
                        Create
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4 col-sm-12" />
                </div>
              </form>
            );
          }}
        </Formik>
      </Panel>
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        show={show1}
        onHide={handleClose1}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: "large" }}>
            Crop Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container padding-bottom-3x mb-1 p-0">
            <div
              className="card mt-0"
              style={{ boxShadow: "none", fontFamily: "sans-serif" }}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <Cropper
                      src={image1}
                      aspectRatio={12 / 12}
                      guides
                      onInitialized={(instance) => {
                        setCropper(instance);
                      }}
                    />
                    <Button
                      onClick={handleCrop}
                      size="md"
                      className="py-2 btn-block mt-2"
                      variant="success"
                    >
                      Crop Image
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {showWebCam && (
        <WebCam
          show={showWebCam}
          hide={() => setShowWebCap(false)}
          onCapture={handleCaptureImage}
        />
      )}
    </>
  );
};

export default CreateProduct;

const CustomSelectCustom = ({
  isLoading,
  onChange,
  options,
  value,
  name,
  onClick,
  handleBlur,
  isDisabled,
  isSearchable,
  onInputChange,
  placeholder,
}) => {
  const style = {
    option: (provided, state) => {
      const styles = {
        ...provided,
        cursor: "pointer",
      };

      if (state.isSelected) {
        styles.color = "#fff";
        styles.backgroundColor = "#4ce1b6";
      } else if (state.isDisabled) {
        styles.color = "#A9A9A9";
        styles.backgroundColor = "#857f7f";
      } else {
        styles.color = "black";
        styles.backgroundColor = "#fff";
      }

      styles["&:hover"] = {
        backgroundColor: "#4ce1b6",
      };

      return styles;
    },
    control: (base) => ({
      ...base,
      width: "100%",
      border: "1px solid #b1b1b1 !important",
      backgroundColor: "transparent !important",
      "&:hover": {
        border: "1px solid #4ce1b6 !important",
        backgroundColor: "transparent !important",
      },
      ".isDisabled": {
        border: "1px solid #4ce1b6 !important",
        backgroundColor: "transparent !important",
      },
    }),
    singleValue: (provided, state) => {
      const styles = {
        ...provided,
        color: "#b1b1b1", // Change this to the color you want
      };

      return styles;
    },
  };
  // eslint-disable-next-line max-len
  const defaultValue = (selectOptions, selectValue) =>
    selectOptions
      ? selectOptions?.find((option) => option.value === selectValue)
      : null;
  return (
    <Select
      key={`my_unique_select_key__${value}`}
      isLoading={isLoading}
      value={defaultValue(options, value) || ""}
      // eslint-disable-next-line no-shadow
      onChange={onChange}
      name={name}
      options={options}
      className="selectDropdpenReact"
      onClick={onClick}
      width="100%"
      styles={style}
      minMenuHeight={100}
      maxMenuHeight={150}
      menuPlacement="bottom"
      onBlur={handleBlur}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      onInputChange={onInputChange}
      placeholder={placeholder}
    />
  );
};

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
const Error = styled.div`
  color: #e66e6e;
  padding: 2px 0px;
  font-size: 12px;
  cursor: none;
`;

const Label = styled.label`
        @media screen and (max-width: 876px) {
          margin - bottom: 0px;
        margin-top: 8px;
}
`;
const Grid = styled.div`
  display: grid;
  grid-gap: 1rem;
  width: 100%;
  @media screen and (max-width: 876px) {
    grid-template: none;
  }
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;

const ErrorMsg = styled.div`
  color: #e66e6e;
  padding: 2px 0px;
  font-size: 12px;
  cursor: none;
`;
