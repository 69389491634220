import styled from "styled-components";
import {
  getUser,
  getUserInfo,
  verifyEmailAddress,
  verifyKYC,
  verifyMobile,
} from "../../../utils/services/user.service";
import { useEffect, useState } from "react";
import { Button } from "@/shared/components/Button";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import CreateStrigaAccountModal from "./CreateStrigaAccountModal";

const StrigaDetailPage = ({ strigaUser, userinfo }) => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [kycLink, setKYClink] = useState("");
  const [strigaUserData, setStrigaUserData] = useState(null);

  const [show, setShow] = useState(false);
  const [reFetch, setRefetch] = useState(false);

  const fetchUserInfo = async () => {
    setIsLoading(true);
    try {
      const res = await getUserInfo();
      setUserData(res?.data?.User);
      setIsLoading(false);
    } catch (error) {

      setIsLoading(false);
    }
  };
  const handleEmailVerify = async () => {
    setIsLoading(true);
    try {
      const res = await verifyEmailAddress();
      toast.success(res?.data?.Message);
      fetchStrigaUser();
      setIsLoading(false);
    } catch (error) {

      setIsLoading(false);
    }
  };
  const handleMobileVerify = async () => {
    setIsLoading(true);
    try {
      const res = await verifyMobile();
      fetchStrigaUser();
      setIsLoading(false);
      toast.success(res?.data?.Message);
    } catch (error) {

      toast.error(error.response.data.Message);
      setIsLoading(false);
    }
  };
  const handleKYCVerify = async () => {
    setIsLoading(true);
    try {
      const res = await verifyKYC();
      fetchStrigaUser();
      setIsLoading(false);
      if (res?.data.verificationLink) {
        setKYClink(res?.data.verificationLink);

        toast.success("Please Visit the link below");
      }
    } catch (error) {

      toast.error(error.response.data.Message);
      setIsLoading(false);
    }
  };
  const fetchStrigaUser = async () => {
    setIsLoading(true);
    try {
      const res = await getUser();
      setStrigaUserData(res?.data);
      setIsLoading(false);
    } catch (error) {

      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserInfo();
    fetchStrigaUser();
  }, [reFetch]);

  return (
    <div className="d-flex flex-column d-md-flex flex-md-row   w-100 gap-4  align-self-center m-auto justify-content-between">
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>

      {userData?.striga_user_id ? (
        <>
          <div className="d-flex w-100  flex-column gap-2 ">
            <h4 className="d-flex align-self-start">User Details</h4>

            <StyledDivInfo>
              <p>First Name</p>
              <p>{strigaUserData?.firstName} </p>
            </StyledDivInfo>
            <StyledDivInfo>
              <p>Last Name</p>
              <p>{strigaUserData?.lastName} </p>
            </StyledDivInfo>
            <StyledDivInfo>
              <p>Email</p>
              <p>{strigaUserData?.email} </p>
            </StyledDivInfo>
            <StyledDivInfo>
              <p>UserId</p>
              <p>{strigaUserData?.userId} </p>
            </StyledDivInfo>
            <StyledDivInfo>
              <p>Phone</p>
              <p>
                {strigaUserData?.mobile?.countryCode +
                  strigaUserData?.mobile?.number}{" "}
              </p>
            </StyledDivInfo>
          </div>
          <div className="vr"></div>
          <div className="d-flex w-100  flex-column align-items-center gap-2 ">
            <h4 className="d-flex align-self-start ">Identity verification</h4>
            <StyledDivInfo>
              <div className="d-flex gap-2 align-items-center ">
                <i
                  className="fa fa-envelope-o "
                  aria-hidden="true"
                  style={{
                    color: `${
                      strigaUserData?.KYC?.emailVerified ? "green" : "red"
                    }`,
                  }}
                ></i>
                <p>Email Verified </p>
              </div>

              {userData?.striga_user_id &&
                !strigaUserData?.KYC?.emailVerified && (
                  <FormButton
                    variant="outline-primary"
                    type="submit"
                    className={"d-flex col-12 align-self-center"}
                    onClick={handleEmailVerify}
                  >
                    Verify Email
                  </FormButton>
                )}
            </StyledDivInfo>
            <StyledDivInfo>
              <div className="d-flex gap-2 align-items-center ">
                <i
                  className="fa fa-mobile"
                  aria-hidden="true"
                  style={{
                    color: `${
                      strigaUserData?.KYC?.mobileVerified ? "green" : "red"
                    }`,
                  }}
                ></i>
                <p>Phone Verified </p>
              </div>

              {userData?.striga_user_id &&
                strigaUserData?.KYC?.mobileVerified == false && (
                  <FormButton
                    variant="outline-primary"
                    type="submit"
                    className={"d-flex col-12 align-self-center"}
                    onClick={handleMobileVerify}
                  >
                    Verify Mobile
                  </FormButton>
                )}
            </StyledDivInfo>

            {userinfo?.striga_iban_no && (
              <StyledDivInfo>
                <p>IBAN</p>
                <p>{userinfo?.striga_iban_no} </p>
              </StyledDivInfo>
            )}

            {userinfo?.striga_wallet_id && (
              <StyledDivInfo>
                <p>Wallet</p>
                <p>{userinfo?.striga_wallet_id} </p>
              </StyledDivInfo>
            )}

            {userData?.striga_user_id &&
              strigaUserData?.KYC?.mobileVerified &&
              strigaUserData?.KYC?.emailVerified &&
              !kycLink &&
              strigaUserData?.KYC?.tier1?.status !== "APPROVED" && (
                <FormButton
                  variant="outline-primary"
                  type="submit"
                  className={"d-flex col-12 align-self-center"}
                  onClick={handleKYCVerify}
                  disabled={
                    strigaUserData?.KYC?.tier1?.status === "PENDING_REVIEW"
                  }
                >
                  {strigaUserData?.KYC?.tier1?.status === "PENDING_REVIEW"
                    ? "PENDING_REVIEW"
                    : " Verify KYC"}
                </FormButton>
              )}
            {userData?.striga_user_id &&
              strigaUserData?.KYC?.mobileVerified &&
              strigaUserData?.KYC?.emailVerified &&
              kycLink && (
                <a
                  href={kycLink}
                  target="_blank"
                  className={"d-flex w-100 align-self-center "}
                >
                  <FormButton
                    variant="outline-primary"
                    type="submit"
                    className={"d-flex col-12 align-self-center"}
                    onClick={handleKYCVerify}
                  >
                    Visit KYC Link
                  </FormButton>
                </a>
              )}
          </div>
        </>
      ) : (
        <div className="d-flex w-100 justify-content-center align-items-center ">
          {!userData?.striga_user_id && (
            <FormButton
              variant="outline-primary"
              type="submit"
              className={"d-flex col-12 col-md-4 col-lg-3 align-self-center"}
              onClick={() => setShow(true)}
            >
              Create Bank Account
            </FormButton>
          )}
        </div>
      )}

      {show && (
        <CreateStrigaAccountModal
          show={show}
          hide={() => setShow(false)}
          reFetch={() => setRefetch(!reFetch)}
        />
      )}
    </div>
  );
};
export default StrigaDetailPage;
const StyledDivInfo = styled.div`
  background-color: rgb(27, 27, 27);
  border-radius: 18px;
  color: rgb(155, 155, 155);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 16px;
  position: relative;
  width: 100%;
  @media only screen and (max-width: 768px) {
    width: 95%;
    padding: 12px;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  margin-top: 1rem;
  width: 45%;
  align-self: center;

  @media screen and (max-width: 876px) {
    width: 95%;
    align-self: center;
  }
`;
