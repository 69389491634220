import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import RegisterForm from '@/shared/components/account/login_register/LoginRegisterForm';
import {
  AccountCard,
  AccountContent,
  AccountHaveAccount,
  AccountHead,
  AccountLogo,
  AccountTitle,
  AccountWrap,
} from '@/shared/components/account/AccountElements';

import Logo from '../../../utils/img/custodiy.png';
import { clearMsg, register } from '../../../redux/actions/authActions';
import Loading from '../../../shared/components/Loading';
import { useChainContext } from '../../context/context';

const Register = ({ history }) => {
  const { message } = useSelector(state => state.message);
  const { isRegister } = useSelector(state => state.user);
  const {selectrole}=useChainContext()


  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const dispatch = useDispatch();

  const registerFireBase = (user) => {
    localStorage.setItem('newUser', JSON.stringify(user.email));
    setIsLoad(true);
    dispatch(register(user)).then(() => {
      setIsLoad(false);
      setTimeout(() => {
        setIsLoad(false);
        dispatch(clearMsg());
      }, 3000);
      history.push('/Webapistore');
    })
      .catch(() => {
        setIsLoad(false);
        setTimeout(() => {
          dispatch(clearMsg());
        }, 3000);
      });
  };
  // if (message === 'Email verification otp sent') {
  //   history.push('/Webapistore');
  // }
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        isLoading ? (
          <Loading loading={isLoading} />
        ) : (
          <AccountWrap>
            <AccountContent>
              <AccountCard>
                <AccountHead>
                  <AccountTitle>Welcome to
                    <AccountLogo>
                      <img style={{ width: '68%' }} src={Logo} alt="logo" />
                    </AccountLogo>
                  </AccountTitle>
                </AccountHead>
                <h4
                  className="subhead"
                  style={{ fontSize: '18px', fontWeight: '700', textAlign: 'center' }}
                >Sign Up
                </h4>
                <RegisterForm
                  onSubmit={registerFireBase}
                  errorMsg={message}
                  isRegister={isRegister}
                  isLoading={isLoad}
                />
                <AccountHaveAccount>
                  <p>Already have an account? <NavLink to="/login">Login</NavLink></p>
                </AccountHaveAccount>
              </AccountCard>
            </AccountContent>
          </AccountWrap>
        )
      }

    </>

  );
};

Register.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(Register);
