/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { Button } from '@/shared/components/Button';
import ProductSearch from './ProductSearch';
import {
  getSingleProductDetail,
  getProductData,
} from '../../../utils/services/user.service';
import Product from './Product';

const Products = () => {
  const [matchingProducts, setMatchingProducts] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const handleInputChange = async (name) => {
    setSearchInput(name);
    if (name.length >= 2) {
      try {
        const payload = { name };
        const response = await getSingleProductDetail(payload);
        if (response.data && response.data.products) {
          setMatchingProducts(response.data.products);
        }
      } catch (error) {
        // console.log('error', error);
          setMatchingProducts([]);
      }
    } else {
      setMatchingProducts([]);
    }
    // console.log('matchingProducts:', matchingProducts);
  };
  return (
    <div className="container mt-4">
      <div className="row row-cols-1 row-cols-md-4 g-4">
        <div className="col-md-8" />
        <div className="col-md-4">
          <ProductSearch
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            handleInputChange={handleInputChange}
          />
        </div>
      </div>
      <h2 className="text-center mb-4">Products</h2>
      <div className="row row-cols-1 row-cols-md-4 g-4">
        {searchInput.length >= 2 && matchingProducts.length > 0 ? (
          matchingProducts.map(product => (
            <div className="col" key={product.id}>
              <div className="row">
          
                <div className="col-md-4" key={product.id}>
                  <Card style={{ width: '18rem' }} className="mx-3">
                    <Card.Img variant="top" src={product.pictures} className="mx-3" />
                    <Card.Body>
                      <Card.Title>{product.title}</Card.Title>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                      <ListGroup.Item>Cras justo odio</ListGroup.Item>
                      <ListGroup.Item>{product.brand}</ListGroup.Item>
                      <ListGroup.Item>{product.price}   {product.symbol}</ListGroup.Item>
                    </ListGroup>
                    <Card.Body>
                      <Card.Link>   
                        <Link to={`/ProductEdit/${product.title}`} hidden>
                          <button type="button" className="btn btn-primary btn-block w-100 purple-button">
                            <FontAwesomeIcon icon={faPenToSquare} />Edit
                          </button>
                        </Link>
                      </Card.Link>
                      <Card.Link>  
                        <Link to={`/ProductEdit/${product._id}`}>
                          <div className="overlay-text">
                            <p><span>&#9998;</span></p>
                          </div>
                        </Link>
                      </Card.Link>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          ))
        ) : (
          <Product />
        )}
      </div>
      <div className="text-center mt-4">
        <Link to="/createproduct" className="btn btn-primary purple-button">
          Create Product
        </Link>
      </div>
    </div>
  );
};
export default Products;
