import React from 'react';

const NotFoundPage = () => (
  <h1 style={{
            textAlign: 'center',
            color: '#4ce1b6',
            backgroundColor: '#232329',
            fontFamily: 'Times New Roman',
            height: '40rem',
    overflowY: 'hidden !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
        }}
  >404! Page Not Found 
  </h1>
    );

export default NotFoundPage;
