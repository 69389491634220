import { getPrivacyOrCookies } from "../../../utils/services/user.service";
import ReactQuill from 'react-quill';
import parse from 'html-react-parser';
import { useEffect, useState } from "react";
import { Ring } from "@uiball/loaders";

const PrivacyNotice = () => {
    const [content, setContent] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchContent = async () => {
            setIsLoading(true)
            try {
                const payload = {
                    type: 'policy'
                }
                const response = await getPrivacyOrCookies(payload)
                setContent(response?.data?.result[0].content)

                setIsLoading(false)


            } catch (error) {
                console.error('Error fetching content:', error);
                setIsLoading(false)
            }
        };

        fetchContent();
    }, []);
    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none", // Use flex display when loading
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <div className="d-flex justify-content-center">
                <h3>Privacy Notice</h3>
            </div>
            <div className="d-flex flex-column p-3" >
                <ReactQuill value={parse(content)} readOnly={true} theme="bubble" />
            </div>
        </>
    )
}
export default PrivacyNotice;