import { useEffect, useState } from "react";
import { getMyOtcPackageTrx } from "../../../utils/services/user.service";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@/shared/components/Button";
import { Pie, Tooltip } from "recharts";
import getTooltipStyles from "@/shared/helpers";
import { colorDarkText } from "@/utils/palette";

import {
  DashboardPieChartContainer,
  DashboardPieChartFlex,
  DashboardChartLegendTable,
  Bullet,
} from "../../../containers/Dashboards/BasicDashboardComponents";
import VirtualBalanceModal from "./VirtualBalanceModal";
import { useSelector } from "react-redux";
import { optionColors } from "../../../utils/constants/contants";
import SwapModal from "./SwapModal";
import numeral from "numeral";

const VirtualBalaceTable = ({ marketplaceId, swapPair, marketplaceData }) => {
  const [myOtcPackageTrx, setMyOtcPackageTrx] = useState([]);


  const [show, setShow] = useState(false);
  const [showSwapModal, setShowSwapModal] = useState(false);
  const [modalId, setModalId] = useState("");
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const themeName = useSelector((state) => state.theme.className);
  const [swapData, setSwapData] = useState({});
  const [reFetch, setReFetch] = useState(false);

  const fetchTransection = async () => {
    const payload = {
      marketplace_id: marketplaceId,
    };
    const res = await getMyOtcPackageTrx(payload);
    setMyOtcPackageTrx(res?.data?.data);
  };
  useEffect(() => {
    fetchTransection();
  }, [marketplaceId, reFetch]);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const getStatusColor = (status1) => {
    switch (status1.toLowerCase()) {
      case "completed":
        return "#4ce1b6";
      case "pending":
        return "#f6da6e";
      case "rejected":
        return "#ff4861";
      case "waiting":
        return "#f6da6e";
      case "declined":
        return "#ff4861";
      case "failed":
        return "#ff4861";
      case "success":
        return "green";
      case "paid":
        return "green";
      default:
        return "#70bbfd";
    }
  };
  const header = [
    // { accessor: "package_Id", Header: "Package Id" },
    { accessor: "buy_date", Header: "Buy Date" },
    { accessor: "endDate", Header: "End Date" },
    { accessor: "teansferDate", Header: "Transfer Date" },
    { accessor: "pool", Header: "Pool" },
    { accessor: "buy_hash", Header: "Buy Hash" },
    { accessor: "buy_status", Header: "Buy Status" },
    { accessor: "status", Header: "Status" },
    { accessor: "quantity", Header: "Quantity" },
    { accessor: "balance", Header: " Balance(USDT)" },
    { accessor: "transfer_status", Header: "Transfer Status" },
    { accessor: "action", Header: "Action" },
  ];
  const tableConfi2 = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    isPagination: false,
    withSearchEngine: false,
    manualPageSize: [40, 20, 30, 40],
    placeholder: "Search...",
    withPagination: true,
  };

  const data = [];
  myOtcPackageTrx.map((item) =>
    data.push({
      package_Id: item.package_id,

      buy_date: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {moment(item?.createdAt).format("L")}
        </h6>
      ),

      endDate: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {moment(item?.unlock_time).format("L")}
        </h6>
      ),
      teansferDate: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.transfer_status !== "pending"
            ? moment(item?.updatedAt).format("L")
            : "-"}
        </h6>
      ),
      pool: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.pool}
        </h6>
      ),
      quantity: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {numeral(item?.receive_amount || 0).format("0,0.00")}
        </h6>
      ),
      balance: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {numeral(item?.receive_amount * item?.price[0]?.priceUSD || 0).format(
            "0,0.000000"
          )}
        </h6>
      ),
      action: (
        <div className="d-flex mt-3 ">
          {marketplaceData?.otc_type != "general" && <FormButton
            type="submit"
            variant="outline-success"
            className=" py-1 w-80"
            disabled={
              item.transfer_status !== "pending" ||
              moment(item.unlock_time).unix() >= moment().unix() ||
              item.status == "swap" ||
              item.status == "pending" ||
              marketplaceData?.swapping_status == "inactive"
            }
            onClick={() => {
              setShowSwapModal(true);
              setSwapData(item);
            }}
          >
            Swap
          </FormButton>}
          <FormButton
            type="submit"
            variant="contain-success"
            className=" py-1 w-80"
            disabled={
              item.transfer_status !== "pending" ||
              item.status == "pending" ||
              marketplaceData?.transfer_status == "inactive"
            }
            onClick={() => {
              setModalId(item);
              setShow(true);
            }}
          >
            Transfer
          </FormButton>
        </div>
      ),

      buy_hash: (
        <HashLink
          role="link"
          onClick={() => {
            let baseUrl;
            switch (item?.chain_id) {
              case 1:
                baseUrl = "https://etherscan.io/tx/";
                break;
              case 137:
                baseUrl = "https://polygonscan.com/tx/";
                break;
              case 56:
              default:
                baseUrl = "https://bscscan.com/tx/";
                break;
            }
            openInNewTab(`${baseUrl}${item?.buy_tax_hash}`);
          }}
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </HashLink>
      ),
      buy_status: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{ backgroundColor: getStatusColor(item.buy_status) }}
        >
          <h6 className="text-white">
            <b>{item.buy_status}</b>
          </h6>
        </span>
      ),
      status: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{ backgroundColor: getStatusColor(item.status) }}
        >
          <h6 className="text-white">
            <b>{item.status}</b>
          </h6>
        </span>
      ),
      transfer_status: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{ backgroundColor: getStatusColor(item.transfer_status) }}
        >
          <h6 className="text-white">
            <b>{item.transfer_status}</b>
          </h6>
        </span>
      ),
      contract_hash: (
        <HashLink
          role="link"
          onClick={() => {
            let baseUrl;
            switch (item?.chain_id) {
              case 1:
                baseUrl = "https://etherscan.io/tx/";
                break;
              case 137:
                baseUrl = "https://polygonscan.com/tx/";
                break;
              case 56:
              default:
                baseUrl = "https://bscscan.com/tx/";
                break;
            }
            openInNewTab(`${baseUrl}${item?.contract_hash}`);
          }}
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </HashLink>
      ),
      receive_amount: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.receive_amount}
        </h6>
      ),
      buy_amount: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.buy_amount}
        </h6>
      ),
    })
  );

  const tooltipFormatter = (value, name, entry) => {
    return [
      <span key="custom-label">{`${entry?.payload?.symbol} : ${(
        entry?.payload?.graphVal || 0
      ).toFixed(2)}
      `}</span>,
    ];
  };

  let colorIndex = 0; // Track the current index of the color array
  const newArray = myOtcPackageTrx
    .filter(
      (item) =>
        item.transfer_status !== "success" && item.transfer_status !== "swap"
    )
    .reduce((acc, item) => {
      const symbol = item.pool.split("-")[1].trim();
      const fill = optionColors[colorIndex].value;
      const newVal = Number(item?.receive_amount);
      const priceValue = newVal?.toFixed(2) || 0;
      const graphVal = parseFloat(priceValue);
      const userName = item?.user_details[0].name;
      const endDate = item?.unlock_time;

      colorIndex = (colorIndex + 1) % optionColors.length;

      const existingIndex = acc.findIndex((el) => el.symbol === symbol);
      if (existingIndex !== -1) {
        acc[existingIndex].graphVal += graphVal;
      } else {
        acc.push({
          fill,
          symbol,
          graphVal,
          userName,
          endDate,
        });
      }

      return acc;
    }, []);
  const newArray2 = myOtcPackageTrx
    .filter(
      (item) =>
        item.transfer_status !== "success" && item.transfer_status !== "swap"
    )
    .reduce((acc, item) => {
      const symbol = item.pool.split("-")[1].trim();
      const fill = optionColors[colorIndex].value;
      const newVal = Number(item?.receive_amount);
      const priceValue = newVal?.toFixed(2) || 0;
      const graphVal = parseFloat(priceValue);
      const userName = item?.user_details[0].name;
      const endDate = item?.unlock_time;
      const priceUSD = item?.price[0]?.priceUSD; // Get priceUSD from the item

      const totalPrice = priceUSD * newVal; // Calculate total price for the item
      acc.push({
        fill,
        symbol,
        graphVal,
        userName,
        endDate,
        totalPrice, // Add totalPrice to the object
      });

      return acc;
    }, []);

  // Calculate total sum
  const totalSum = newArray2.reduce((sum, item) => sum + item.totalPrice, 0);

  const onMouseMove = (e) => {
    if (e.tooltipPosition) {
      setCoordinates({ x: e.tooltipPosition.x, y: e.tooltipPosition.y });
    }
  };
  return (
    <>
      {data.length > 0 ? (
        <>
          <div className="d-flex flex-column flex-lg-row py-5">
            <div className="col-12 col-md-6 py-4">
              <DashboardPieChartContainer>
                <DashboardPieChartFlex>
                  <Tooltip
                    position={coordinates}
                    {...getTooltipStyles(themeName)}
                    formatter={tooltipFormatter}
                  />
                  <Pie
                    data={newArray}
                    dataKey="graphVal"
                    cy={130}
                    innerRadius={80}
                    outerRadius={100}
                    label
                    onMouseMove={onMouseMove}
                    minAngle={20}
                  />
                </DashboardPieChartFlex>
              </DashboardPieChartContainer>
            </div>

            <DashboardChartLegendTable>
              <LegendCol>
                <LegendColTitle>Coin List</LegendColTitle>
                <LegendColContent>
                  {newArray2.map((entry, index) => (
                    <li
                      key={index}
                      style={{
                        width: "max-content",
                        display: "flex",
                        flexWrap: "nowrap",
                        alignItems: "center",
                      }}
                    >
                      <Bullet color={optionColors[index]?.value} />

                      {entry?.symbol}
                    </li>
                    //  )
                  ))}

                  <hr />
                  <p className="d-flex text-nowrap ">Total balance(USDT)</p>
                </LegendColContent>
              </LegendCol>
              <LegendCol>
                <LegendColTitle>
                  <p
                    style={{
                      width: "max-content",
                      display: "flex",
                      flexWrap: "nowrap",
                      alignItems: "center",
                    }}
                  >
                    User Name
                  </p>
                </LegendColTitle>
                <LegendColContent>
                  {newArray2?.map((entry,index) => (
                    <li key={index} className="flex text-nowrap ">{entry?.userName}</li>
                  ))}
                  <hr />
                  --
                </LegendColContent>
              </LegendCol>
              <LegendCol>
                <LegendColTitle>Quantity</LegendColTitle>
                <LegendColContent>
                  {newArray2.map((entry ,index) => (
                    <li key={index}>{(entry?.graphVal || 0).toFixed(5)}</li>
                  ))}

                  <hr />

                  <p>{numeral(totalSum)?.format("0,0.00000")}</p>
                </LegendColContent>
              </LegendCol>
              <LegendCol>
                <LegendColTitle>End Date</LegendColTitle>
                <LegendColContent>
                  {newArray2.map((entry ,index) => (
                    <li key={index} > {moment(entry?.unlock_time).format("L")}</li>
                  ))}
                  <hr />
                  --
                </LegendColContent>
              </LegendCol>
            </DashboardChartLegendTable>
          </div>
          <ReactTableBase
            columns={header}
            data={data}
            tableConfig={tableConfi2}
          />
        </>
      ) : (
        <h3>No Data found</h3>
      )}

      {show && (
        <VirtualBalanceModal
          show={show}
          hide={() => setShow(false)}
          marketplaceData={marketplaceData}
          modalId={modalId}
          reFetch={() => setReFetch(!reFetch)}
        />
      )}
      {showSwapModal && (
        <SwapModal
        marketplaceData={marketplaceData}
          show={showSwapModal}
          hide={() => setShowSwapModal(false)}
          swapData={swapData}
          swapPair={swapPair}
          reFetch={() => setReFetch(!reFetch)}
        />
      )}
    </>
  );
};
export default VirtualBalaceTable;
const HashLink = styled.button`
  border: none;
  background: transparent;
  color: #4ce1b6;
`;
const LegendCol = styled("div")`
  flex-shrink: 2;
  flex-grow: 0;

  :first-child {
    flex-shrink: 0;
    flex-basis: 100px;
  }

  :last-child {
    flex-grow: 1;
  }
`;

const LegendColTitle = styled("div")`
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.1;
  color: ${colorDarkText};
`;

const LegendColContent = styled("ul")`
  padding: 0;
  list-style: none;
  color: ${colorDarkText};

  li {
    margin-top: 15px;
    font-size: 14px;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
