/* eslint-disable max-len */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import {
  configureChains, createConfig,
} from 'wagmi';
import { arbitrum, mainnet, polygon } from 'wagmi/chains';
import config from '@/config/walletConnect';
import Panel from '@/shared/components/Panel';
import Tooltip from '@/shared/components/Tooltip';
import { getShortCryptoAddress } from '@/utils/helpers';
import {
  WalletConnection,
  WalletInfoWrapper,
  WalletStatRowTitle,
  WalletStatRowValue,
  WalletStatRowWrapper,
} from '../styled';
import { SidebarLinkIcon } from '../../../Layout/components/sidebar/SidebarLink';
import { colorAccent } from '../../../../utils/palette';
import { getTotalBalance, userBalanceInternalWalletSubWallet } from '../../../../utils/services/user.service';
import numeral from 'numeral';
import { Button } from "@/shared/components/Button";
import { useChainContext } from '../../../context/context';
import QRCode from 'qrcode.react';
import { useAccount } from 'wagmi';
import { toast } from 'react-toastify';

const chains = [arbitrum, mainnet, polygon];
const {
  projectId, REACT_APP_ETHERIUM_CHAIN, REACT_APP_SMART_CHAIN, REACT_APP_POLYGON_MATIC,
} = config;

const WalletInfo = ({ dir, subWallet, strigaWallet }) => {
  const { t } = useTranslation('common');
  const onCopyWalletAddress = (item) => {
    navigator.clipboard.writeText(item);
    toast.success('Address Copied !')
  };

  const [isLoading, setIsLoading] = useState(false);
  const [sendAmount, setsendAmount] = useState([]);
  const [receiveAmount, setreceiveAmount] = useState([]);
  const [totalBalance, setTotalBalance] = useState('');
  const { setSelectedDashboardCoin, selectedDashboardCoin } = useChainContext();
  const { reFethData, setReFetchData } = useChainContext()

  var externalBalance = localStorage?.getItem('internalCoins');
  var subwalletBalance = localStorage?.getItem('balance_sub_wallet');

  var totalExternalBalance = externalBalance?.replace(/"/g, '');

  var numberValue = parseFloat(totalExternalBalance);


  var subwalletBalance = subwalletBalance?.replace(/"/g, '');
  const [filterData, setFilterData] = useState([])

  useEffect(() => {
    let isMounted = true;

    const getContractDetails = async () => {
      setIsLoading(true);

      try {
        const res = await getTotalBalance();
        if (isMounted) {
          setTotalBalance(res.data.balance);
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setTotalBalance(null);
          setIsLoading(false);
        }
      }
    };

    getContractDetails();

    return () => {
      // Cleanup function to cancel ongoing tasks or subscriptions
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchSubWallet = async () => {
      try {
        const res = await userBalanceInternalWalletSubWallet();
        if (isMounted) {
          setFilterData(res?.data);
        }
      } catch (error) {
        console.log("🚀 ~ fetchSubWallet ~ error:", error);
      }
    };

    fetchSubWallet();

    return () => {
      // Cleanup function to cancel ongoing tasks or subscriptions
      isMounted = false;
    };
  }, []);

  return (
    <Panel xs={12} lg={6} xxl={3} title={t('wallet.page_title')} >
      <WalletInfoWrapper>
        <WalletStatRowWrapper>
          <h4> <b> {!subWallet ? 'Virtual Wallet' : "Onchain Wallet"}</b></h4>
          {!subWallet && <>
            <WalletStatRowTitle>{t('wallet.address')}</WalletStatRowTitle><WalletStatRowValue variant="small">
              <span>{getShortCryptoAddress(JSON.parse(localStorage.getItem('user'))?.data?.user?.address) || "--"}</span>
              <Tooltip text="Copy wallet address" dir={dir} placement="top">
                <SidebarLinkIcon className="lnr lnr-book" onClick={() => onCopyWalletAddress(JSON.parse(localStorage.getItem('user')).data.user.address)} />
              </Tooltip>
            </WalletStatRowValue>
            <WalletStatRowTitle>{'TRON Wallet'}</WalletStatRowTitle><WalletStatRowValue variant="small">
              <span>{getShortCryptoAddress(JSON.parse(localStorage.getItem('user'))?.data?.user?.trc_address) || "--"}</span>
              <Tooltip text="Copy wallet address" dir={dir} placement="top">
                <SidebarLinkIcon className="lnr lnr-book" onClick={() => onCopyWalletAddress(JSON.parse(localStorage.getItem('user')).data.user.trc_address)} />
              </Tooltip>
            </WalletStatRowValue>
            <WalletStatRowTitle>{'Sol Wallet'}</WalletStatRowTitle><WalletStatRowValue variant="small">
              <span>{getShortCryptoAddress(JSON.parse(localStorage.getItem('user'))?.data?.user?.sol_address) || "--"}</span>
              <Tooltip text="Copy wallet address" dir={dir} placement="top">
                <SidebarLinkIcon className="lnr lnr-book" onClick={() => onCopyWalletAddress(JSON.parse(localStorage.getItem('user')).data.user.sol_address)} />
              </Tooltip>
            </WalletStatRowValue>
          </>}

          {subWallet &&
            <><WalletStatRowTitle>Subwallet Address</WalletStatRowTitle><WalletStatRowValue variant="small">
              <span>{subWallet?.slice(0, 15)}...{subWallet?.slice(-3)}</span>
              <Tooltip text="Copy wallet address" dir={dir} placement="top">
                <SidebarLinkIcon className="lnr lnr-book" onClick={() => onCopyWalletAddress(subWallet)} />
              </Tooltip>
            </WalletStatRowValue></>}
        </WalletStatRowWrapper>

        {!subWallet && <WalletStatRowWrapper>
          <WalletStatRowTitle>Total Balance</WalletStatRowTitle>
          <WalletStatRowValue>
            {typeof numberValue === 'undefined' ? (
              <Spinner style={{ color: colorAccent }} />
            ) : (
              `${numeral(numberValue).format('0,0.00')} $`
            )}
          </WalletStatRowValue>
        </WalletStatRowWrapper>}
        {subWallet && <WalletStatRowWrapper>
          <WalletStatRowTitle>Onchain Wallet Balance</WalletStatRowTitle>
          <WalletStatRowValue>
            {typeof filterData?.total_balanceUSDT === 'undefined' ? (
              <Spinner style={{ color: colorAccent }} />
            ) : (
              `${numeral(filterData?.total_balanceUSDT).format('0,0.00')} $`
            )}
          </WalletStatRowValue>
        </WalletStatRowWrapper>}

        <Button
          onClick={() => setSelectedDashboardCoin('external')}
          size="md"
          className="py-2 mt-2 transform-lowercase"
          variant="outline-primary"

        >
          External Wallet
        </Button>
        <Button
          onClick={() => {

             setReFetchData(!reFethData);
            toast.success('Balance Updated Successfully')

          }}
          size="md"
          className="py-2 mt-2 transform-lowercase"
          variant="outline-primary"
        >
          Update Balance
        </Button>


        {/* <WalletStatRowWrapper className="mt-5" hidden>
          <h4> <b> External Wallet</b></h4>
          <W3ButtonPageNew />
          <InputDiv>
            <DropdownDiv className="">
              <label style={{ fontSize: 'small' }} htmlFor="subcategory_id" className="form-label text-white mt-0">Select Chain</label>
              <CustomSelect
                name="chain"
                id="chain"
                              // eslint-disable-next-line max-len
                className="form-control w-100"
                styles={{
                                menu: provided => ({
                                  ...provided,
                                  textTransform: 'capitalize',
                                }),
                              }}
                value={ChainValue}
                options={optionChain}
                onChange={(
                  value,
                ) => {
                  setChainValue(
                    value.value,
                  );
                }}
              />
            </DropdownDiv>
          </InputDiv>
          <>           
            <Web3Modal
              projectId={projectId}
              ethereumClient={ethereumClient}
              themeVariables={{
               '--w3m-accent-color': '#4ce1b6',
               '--w3m-z-index': 127,
               '--w3m-overlay-backdrop-filter': 'blur(6px)',
             }}
            />
            <WagmiConfig config={wagmiConfig}>
              <WalletResponseNew setstatusCode={setstatusCode} chain={ChainValue} selectedValue="external" />
            </WagmiConfig>
          </>
        </WalletStatRowWrapper> */}
      </WalletInfoWrapper>
      <Container hidden>
        <Row xs={12} md={6} lg={3} style={{ textTransform: 'Capitalize' }}>
          <Col sm={12} md={12} lg={12} xl={12}>
            <WalletInfoWrapper>
              {/* <WalletStatRowWrapper>
                <WalletStatRowTitle>{t('wallet.address')}</WalletStatRowTitle>
                <WalletStatRowValue variant="small">
                  <span>{getShortCryptoAddress(WALLET_ADDRESS)}</span>
                  <Tooltip text="Copy wallet address" dir={dir} placement="top">
                    <SidebarLinkIcon className="lnr lnr-book" onClick={onCopyWalletAddress} />
                  </Tooltip>
                </WalletStatRowValue>
              </WalletStatRowWrapper> */}
              <WalletStatRowWrapper>
                <WalletStatRowTitle>Send</WalletStatRowTitle>
                <WalletStatRowValue>
                  {isLoading ? <Spinner style={{ color: colorAccent }} />
                    : (

                      `${sendAmount} $`
                    )}
                </WalletStatRowValue>
              </WalletStatRowWrapper>
              <WalletStatRowWrapper>
                <WalletStatRowTitle>Receive</WalletStatRowTitle>
                <WalletStatRowValue>
                  {isLoading ? <Spinner style={{ color: colorAccent }} />
                    : (

                      `${receiveAmount} $`
                    )}
                </WalletStatRowValue>
              </WalletStatRowWrapper>
            </WalletInfoWrapper>
          </Col>
        </Row>
      </Container>
    </Panel>
  );
};

WalletInfo.propTypes = {
  dir: PropTypes.string.isRequired,
};

export default WalletInfo;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const DropdownDiv = styled.div`
  display: block;
  float: right;
  width: 100%;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;