import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../style/product.css';


const Services = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch('https://jsonplaceholder.typicode.com/photos?_limit=12') // Replace this with your fake API URL
      .then(response => response.json())
      .then(data => setProducts(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4">Products</h2>
      <div className="row row-cols-1 row-cols-md-4 g-4">
        {products.map(product => (
          <div className="col" key={product.id}>
            <div className="card">
              <img src={product.thumbnailUrl} className="card-img-top" alt={product.title} />
              <div className="card-body">
                <Link to={`/servicedetails/${product.id} `}>
                  <button type="button" className="btn btn-primary btn-block w-100 purple-button">View Details</button>
                </Link>
                <h5 className="card-title mt-2">{product.title}</h5>
                {/* <p className="card-text">ID: {product.id}</p> */}
                <div className="col">
                  <p className="card-text text-dark">Property 2: {product.property2}</p>
                </div>
                <div className="col">
                  <p className="card-text text-dark">Property 2: {product.property2}</p>
                </div>
                <div className="col">
                  <p className="card-text text-dark">Property 2: {product.property2}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
