import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Web3 from "web3";
import { Col, Spinner, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Switch, Stack } from "@mui/material";
import { Legend, Pie, Tooltip } from "recharts";
import { fetchAllCurrencies1 } from "@/redux/actions/FetchCoinLists";
import Panel from "@/shared/components/Panel";

import {
  colorAdditional,
  colorDarkText,
  scrollbarStyles,
  colorAccent,
} from "@/utils/palette";

import { toast } from "react-toastify";
import {
  Bullet,
  DashboardChartLegendTable,
  DashboardPieChartFlex,
  DashboardPieChartContainer,
} from "../BasicDashboardComponents";
import useMatchMedia from "@/shared/hooks/useMatchMedia";
import {
  getUserPendingBalance,
  getUserPendingBalanceWebapp,
} from "../../../utils/services/user.service";
import { Ring } from "@uiball/loaders";
import { optionColors } from "../../../utils/constants/contants";

const BalanceInfo = ({ dir }) => {
  const { t } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [loggedInUserData, setLoggedInUserData] = useState([]);
  const [pendingBalance, setPendingBalance] = useState([]);
  const [reFetch, setRefetch] = useState(false);
  const isMdBreakpoint = useMatchMedia(1199);

  const [isLoading1, setIsLoading1] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));

  const fetchBalanceInfo = async () => {
    setIsLoading(true);
    const res = await getUserPendingBalanceWebapp();
    setPendingBalance(res?.data?.data);
    setIsLoading(false);
  };
  useEffect(() => {
    setLoggedInUserData(userData.data.user);
    fetchBalanceInfo();
  }, [reFetch]);

  const handleRefresh = async () => {
    setRefetch(!reFetch);
  };

  return (
    <Panel
      lg={12}
      xxl={12}
      isLoading={isLoading}
      refreshRequest={handleRefresh}
    >
      {isLoading1 ? (
        <Spinner style={{ color: colorAccent }} />
      ) : (
        <>
          <div dir="ltr" className="">
            <h5 className="d-flex text-danger fw-bold">
              The balance transfer is slated for completion within the upcoming
              five business days
            </h5>

            <br />
            <div dir="ltr" className="d-flex ">
              {pendingBalance.length > 0 ? (
                <div className="col-lg-2">
                  <DashboardCoinsChartContainer width="100%">
                    <DashboardPieChartFlex>
                      <Legend
                        layout="vertical"
                        verticalAlign={isMdBreakpoint ? "bottom" : "top"}
                        content={
                          <DashboardChartLegendTable>
                            <LegendCol>
                              <LegendColTitle>Coin List</LegendColTitle>
                              <LegendColContent>
                                {pendingBalance.map((entry, index) => (
                                  <li
                                    key={index}
                                    style={{
                                      width: "max-content",
                                      display: "flex",
                                      flexWrap: "nowrap",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Bullet
                                      color={optionColors[index]?.value}
                                    />
                                    {entry?.symbol}
                                  </li>
                                ))}
                              </LegendColContent>
                            </LegendCol>
                            <LegendCol>
                              <LegendColTitle>Quantity</LegendColTitle>
                              <LegendColContent>
                                {pendingBalance?.map((entry) => (
                                  <li
                                  // key={5}
                                  >
                                    {entry?.quantity || 0.0}
                                  </li>
                                ))}
                              </LegendColContent>
                            </LegendCol>
                          </DashboardChartLegendTable>
                        }
                        align={isMdBreakpoint ? "center" : "right"}
                      />
                    </DashboardPieChartFlex>
                  </DashboardCoinsChartContainer>
                </div>
              ) : (
                <h3>No data found!</h3>
              )}
            </div>
          </div>
        </>
      )}
    </Panel>
  );
};

export default BalanceInfo;

// region
const DashboardCoinsChartContainer = styled(DashboardPieChartContainer)`
  min-height: 360px;
  height: 100% !important;

  svg {
    min-height: 360px;
    position: absolute;
    left: 0;
    top: 0;

    @media (max-width: 1640px) {
      max-width: 90%;
    }

    @media (max-width: 1199px) {
      max-width: 100%;
    }

    @media (max-width: 768px) {
      height: 80%;
    }

    @media (max-width: 400px) {
      height: 60%;
    }

    .recharts-pie {
      transform: translateX(-60px);

      @media (max-width: 1919px) {
        transform: translateX(0);
      }
    }
  }

  .recharts-legend-wrapper {
    position: relative !important;
    max-width: 55%;
    width: 100% !important;
    z-index: 5;

    @media (max-width: 1919px) {
      max-width: 50%;
    }
    @media (max-width: 1199px) {
      max-width: 100%;
    }
    @media (max-width: 768px) {
      overflow-x: auto;

      ${scrollbarStyles};
    }
  }
`;
const LegendCol = styled("div")`
  flex-shrink: 2;
  flex-grow: 0;

  :first-child {
    flex-shrink: 0;
    flex-basis: 100px;
  }

  :last-child {
    flex-grow: 1;
  }
`;

const LegendColTitle = styled("div")`
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.1;
  color: ${colorDarkText};
`;

const LegendColContent = styled("ul")`
  padding: 0;
  list-style: none;
  color: ${colorDarkText};

  li {
    margin-top: 15px;
    font-size: 14px;
  }
`;

const LegendSubtext = styled("span")`
  color: ${colorAdditional};
`;
