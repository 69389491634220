/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import StarRating from '../home/StarRating';

import {
  getMyStore,
} from '../../../utils/services/user.service';
import '../style/storecard.css';
import '../style/Rating.css';

const StoreCard = () => {
    const [storeData, setStoreData] = useState(null);

    useEffect(() => {
      const fetchStoreData = async () => {
        try {
          const response = await getMyStore(); // Replace with your API call
          if (response.data && response.data.result.length > 0) {
            const storeInfo = response.data.result[0]; // Extract the first store
            setStoreData(storeInfo);
            // console.log('storeInfo >>>>>>>>>>>>>>>>>>>', storeInfo);
          } else {
            console.error('Error fetching store data:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching store data:', error);
        }
      };
  
      fetchStoreData();
    }, []);
  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">My Store </h2>
      {storeData ? (
        <div
          className="col-lg-4 col-md-6 col-sm-12"
          style={{ textTransform: 'capitalize' }}
          key={storeData._id}
        >
          <Link to={`/storedetail/${storeData._id}`}>
            <div className="card productCard" style={{ background: '#232329' }}>
              <div className="square-img-wrapper mt-4">
                <img
                  src={storeData.storeLogo}
                  className="card-img-top square-img"
                  alt={storeData.storeName}
                />
              </div>
              <div className="card-body">
                <span className="d-flex">
                  <span style={{ width: '100%' }}>
                    <h4 className="card-title producttitle mt-0 mb-1">{storeData.storeName}</h4>       
                  </span>
                </span> 
                         
                <div className="Rating mb-4" style={{ float: 'right', marginTop: '7%' }}>
                  {storeData.ProductRatting?.length > 0 ? (
                    <StarRating rating={storeData.Store_avg_ratting} />
      ) : (
        <StarRating rating={0} />
      )}
                </div> 
 
              </div>
            </div>
          </Link>
        </div>
) : (
  <div className="text-center">
    <p>No store available.</p>
    <Link to="/createstore" className="btn btn-primary my-2 w-25 shadow-none text-capitalize">
      Create Store
    </Link>
  </div>
)}
     
    </div>
  );
};

export default StoreCard;
