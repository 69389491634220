import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import MetaMaskForm from './component/MetaMaskForm';

const MetamaskPage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Wallets</h3>
      </Col>
    </Row>
    <Row>
      <MetaMaskForm />
    </Row>

  </Container>
  );

export default MetamaskPage;
