import { useEffect } from "react";

export const useGoogleAnalytics = () => {
  useEffect(() => {
    // Define dataLayer if it's not already defined
    window.dataLayer = window.dataLayer || [];

    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=UA-214514439-1";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag("js", new Date());

      gtag("config", "UA-214514439-1");
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};
