/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/no-extraneous-dependencies */
import { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const SignaturePad = ({ onEnd, getPDf1 }) => {
  const signatureRef = useRef(null);
  const clearSignature = () => {
    signatureRef.current.clear();
    getPDf1(false);
  };

  return (
    <>
      <SignatureCanvas
        ref={signatureRef}
        canvasProps={{ width: 500, height: 120, className: 'sigCanvas' }}
        onEnd={() => onEnd(signatureRef.current.toDataURL())}
      />
      <div className="row">
        <div className="col-12 my-1 px-3 text-center">
          <a 
            onClick={clearSignature}
            style={{ fontWeight: '500' }}
            className="btn btn-danger btn-sm text-white btn-block w-100 ms-auto shadow-none text-capitalize"
          >
            Clear Signature
          </a>
        </div>
      </div>
    </>
  );
};

export default SignaturePad;
