import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import BigCalendar from './components/BigCalendar';
import EventLabels from './components/EventLabels';
import { useState } from 'react';
import ItemEditModal from '../../Todo/components/form/ItemEditModal';

const Calendar = () => {
  const rtl = useSelector(state => state.rtl);
  const [showEditModal, setShowEditModal] = useState(false); // State to control the modal visibility

  const handleCreateEventClick = () => {
    // Open the modal when the "Create new event" button is clicked
    setShowEditModal(true);
  };
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Calendar</h3>
          <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
            information
          </h3>
        </Col>
      </Row>
      <Row>
        <BigCalendar dir={rtl.direction} />
        <EventLabels onClick={handleCreateEventClick} />
      </Row>

      <ItemEditModal
          // theme={theme}
          rtl={rtl}
          showEditModal={showEditModal}
          changeShowEditModal={()=>setShowEditModal(false)}
        />
    </Container>
  );
};

export default Calendar;
