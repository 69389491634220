import { Badge, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { colorAccent } from "../../../../../utils/palette";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { getContractDetail } from "../../../../../utils/services/user.service";
import { Button } from "../../../../../shared/components/Button";

const ContractDetailModal = ({ modalIsOpen, selectedId, closeContractsDetails1 }) => {
    console.log("🚀 ~ ContractDetailModal ~ modalIsOpen:", modalIsOpen)
    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };
    const Parcel = `${process.env.PUBLIC_URL}/img/remove-data.png`;
    const [selectedOrder, setSelectedOrder] = useState(null);
    console.log("🚀 ~ ContractDetailModal ~ selectedOrder:", selectedOrder)
    const [isLoad, setIsLoad] = useState(false);

    const getDetail = () => {
        setIsLoad(true);
        getContractDetail(selectedId)
            .then((res) => {
                setIsLoad(false);
                setSelectedOrder(res.data.result[0]);
            })
            .catch((error) => {

                setIsLoad(false);
                // setDetail(error.response.data.Message);
            });

    };
    useEffect(() => {
        getDetail()
    }, [])
    return (
        <>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalIsOpen}
                onHide={closeContractsDetails1}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-center" style={{ fontSize: "large" }}>
                        Contract Details
                    </Modal.Title>
                </Modal.Header>
                {isLoad ? (
                    <Modal.Body>
                        <SpinnerDiv>
                            <Spinner style={{ color: colorAccent }} />
                        </SpinnerDiv>
                    </Modal.Body>
                ) : (
                    <Modal.Body>
                        {selectedOrder ? (
                            <>
                                <div
                                    className="container mt-0 containerDiv pt-0"
                                    style={{ overflowX: "hidden" }}
                                >

                                    <div
                                        className="row mt-0 mb-2 mx-1"
                                        style={{ border: "2px solid #7b7b7b", borderRadius: "5px" }}
                                    >
                                        {selectedOrder.hash && selectedOrder?.hash?.length > 6 && (
                                            <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                                                <p
                                                    style={{
                                                        textTransform: "Capitalize",
                                                        color: "#7b7b7b",
                                                    }}
                                                    className="py-1"
                                                >
                                                    <b>Hash</b>
                                                </p>
                                                {selectedOrder.transfer_hash ? <a
                                                    style={{ color: "#70bbfd", cursor: "pointer" }}
                                                    onClick={() => {
                                                        let baseURL;
                                                        if (["ETH", "USDT_ERC20", "PAX", "GBPT", "USDT (ERC20)", "EUROC"].includes(selectedOrder.Symbol)) {
                                                            baseURL = "https://etherscan.io/tx/";
                                                        } else if (["BNB", "CTY", "FTY", "OPY", "BUSD", "USDT (BEP20)", "USDT", "MET$", "META"].includes(selectedOrder.Symbol)) {
                                                            baseURL = "https://bscscan.com/tx/";
                                                        }
                                                        else if (["SOLANA", "LTX", "WEN", "SoLpy"]) {
                                                            baseURL = "https://explorer.solana.com/tx/"
                                                        }
                                                        else {
                                                            baseURL = "https://polygonscan.com/tx/";
                                                        }

                                                        openInNewTab(`${baseURL}${selectedOrder.transfer_hash}`);
                                                    }}
                                                    rel="noreferrer"
                                                >
                                                    <span className="text-purple text-xs font-weight-bold">
                                                        {selectedOrder.transfer_hash.slice(0, 10) + "..." + selectedOrder.transfer_hash.slice(-10)}
                                                    </span>
                                                </a> : "--"}

                                            </div>
                                        )}
                                        <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                                            <p
                                                style={{
                                                    textTransform: "Capitalize",
                                                    color: "#7b7b7b",
                                                }}
                                                className="py-1"
                                            >
                                                <b>Status</b>
                                            </p>
                                            <Badge
                                                bg="dark"
                                                className="mb-1"
                                                style={{
                                                    paddingBottom: "6px",
                                                    fontSize: "small",
                                                    fontWeight: "400",
                                                }}
                                            >
                                                {selectedOrder.status}
                                            </Badge>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                                            <p
                                                style={{
                                                    textTransform: "Capitalize",
                                                    color: "#7b7b7b",
                                                }}
                                                className="py-1"
                                            >
                                                <b>Fee</b>
                                            </p>
                                            <p
                                                style={{
                                                    textTransform: "Capitalize",
                                                    color: "#7b7b7b",
                                                }}
                                                className="pb-1 mb-2"
                                            >
                                                {selectedOrder.adminFee || "--"} {selectedOrder.commission_symbol || "--"}
                                            </p>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                                            <p
                                                style={{
                                                    textTransform: "Capitalize",
                                                    color: "#7b7b7b",
                                                }}
                                                className="py-1"
                                            >
                                                <b>Amount</b>
                                            </p>
                                            <p
                                                style={{
                                                    textTransform: "Capitalize",
                                                    color: "#7b7b7b",
                                                }}
                                                className="pb-1 mb-2"
                                            >
                                                {selectedOrder.Amount} {selectedOrder.Symbol}
                                            </p>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                                            <p
                                                style={{
                                                    textTransform: "Capitalize",
                                                    color: "#7b7b7b",
                                                }}
                                                className="py-1"
                                            >
                                                <b>Coin</b>
                                            </p>
                                            <p
                                                style={{
                                                    textTransform: "Capitalize",
                                                    color: "#7b7b7b",
                                                }}
                                                className="pb-1 mb-2"
                                            >
                                                {selectedOrder.Symbol}
                                            </p>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                                            <p
                                                style={{
                                                    textTransform: "Capitalize",
                                                    color: "#7b7b7b",
                                                }}
                                                className="py-1"
                                            >
                                                <b>Date</b>
                                            </p>
                                            <p
                                                style={{
                                                    textTransform: "Capitalize",
                                                    color: "#7b7b7b",
                                                }}
                                                className="py-1"
                                            >
                                                {selectedOrder.Date}
                                            </p>
                                        </div>
                                        {selectedOrder.role && (
                                            <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                                                <p
                                                    style={{
                                                        textTransform: "Capitalize",
                                                        color: "#7b7b7b",
                                                    }}
                                                    className="py-1"
                                                >
                                                    <b>Role </b>
                                                </p>
                                                <p
                                                    style={{
                                                        textTransform: "Capitalize",
                                                        color: "#7b7b7b",
                                                    }}
                                                    className="py-1"
                                                >
                                                    {selectedOrder.role}
                                                </p>
                                            </div>
                                        )}
                                        <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                                            <p
                                                style={{
                                                    textTransform: "Capitalize",
                                                    color: "#7b7b7b",
                                                }}
                                                className="py-1"
                                            >
                                                <b>Type </b>
                                            </p>
                                            <p
                                                style={{
                                                    textTransform: "Capitalize",
                                                    color: "#7b7b7b",
                                                }}
                                                className="py-1"
                                            >
                                                {selectedOrder.type}
                                            </p>
                                        </div>
                                        <div className="d-flex flex-row   overflow-x-auto">
                                            {selectedOrder?.FileName ? (
                                                selectedOrder.FileName.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div key={index} className="col-4 col-md-2 d-flex text-center">
                                                                <p
                                                                    style={{
                                                                        textTransform: "capitalize",
                                                                        color: "#7b7b7b",
                                                                    }}
                                                                    className="py-1"
                                                                >
                                                                    {/* <b className="d-flex text-nowrap">File {index + 1} </b> */}
                                                                </p>
                                                                <a
                                                                    onClick={() => {
                                                                        openInNewTab(item);
                                                                    }}
                                                                    rel="noreferrer"
                                                                    className=" shadow-none text-white"
                                                                >
                                                                    <Button variant='outline-purple'>

                                                                        View
                                                                    </Button>
                                                                </a>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            ) : null}
                                        </div>
                                    </div>

                                    {typeof selectedOrder.Approver === "object" ? (
                                        <>
                                            <div className="row mt-4 mb-1">
                                                <p
                                                    style={{
                                                        textTransform: "capitalize",
                                                        color: "#7b7b7b",
                                                    }}
                                                    className="py-0"
                                                >
                                                    <b>Approver Detail</b>
                                                </p>
                                            </div>
                                            <div
                                                className="row mt-0 mb-2 mx-1"
                                                style={{
                                                    border: "2px solid #7b7b7b",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                                                    <p
                                                        style={{
                                                            textTransform: "capitalize",
                                                            color: "#7b7b7b",
                                                        }}
                                                        className="py-1"
                                                    >
                                                        <b>Approver Name </b>
                                                    </p>
                                                    {selectedOrder.Approver ? (
                                                        <p
                                                            style={{
                                                                textTransform: "capitalize",
                                                                color: "#7b7b7b",
                                                            }}
                                                            className="py-1"
                                                        >
                                                            {selectedOrder.Approver.name}
                                                        </p>
                                                    ) : (
                                                        <p
                                                            style={{
                                                                textTransform: "capitalize",
                                                                color: "#7b7b7b",
                                                            }}
                                                            className="py-1"
                                                        >
                                                            -
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                                                    <p style={{ color: "#7b7b7b" }} className="py-1">
                                                        <b>Approver Email </b>
                                                    </p>
                                                    {selectedOrder.Approver ? (
                                                        <p style={{ color: "#7b7b7b" }} className="py-1">
                                                            {selectedOrder.Approver.email}
                                                        </p>
                                                    ) : (
                                                        <p
                                                            style={{
                                                                textTransform: "capitalize",
                                                                color: "#7b7b7b",
                                                            }}
                                                            className="py-1"
                                                        >
                                                            -
                                                        </p>
                                                    )}
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                                                    <p
                                                        style={{
                                                            textTransform: "capitalize",
                                                            color: "#7b7b7b",
                                                        }}
                                                        className="py-1"
                                                    >
                                                        <b>Approver Address </b>
                                                    </p>
                                                    {selectedOrder.Approver ? (
                                                        <p
                                                            style={{
                                                                textTransform: "capitalize",
                                                                color: "#7b7b7b",
                                                            }}
                                                            className="py-1"
                                                        >
                                                            {selectedOrder.Approver?.address}
                                                        </p>
                                                    ) : (
                                                        <p
                                                            style={{
                                                                textTransform: "capitalize",
                                                                color: "#7b7b7b",
                                                            }}
                                                            className="py-1"
                                                        >
                                                            -
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="d-flex flex-row overflow-x-auto">
                                                    {selectedOrder?.Approver_docs?.length > 0 ? (
                                                        selectedOrder?.Approver_docs?.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <div key={index} className="col-4 col-md-2 text-center">
                                                                        <p
                                                                            style={{
                                                                                textTransform: "capitalize",
                                                                                color: "#7b7b7b",
                                                                            }}
                                                                            className="py-1"
                                                                        >
                                                                            {/* <b className="d-flex text-nowrap">File {index + 1} </b> */}
                                                                        </p>
                                                                        <a
                                                                            onClick={() => {
                                                                                openInNewTab(item);
                                                                            }}
                                                                            rel="noreferrer"
                                                                            className=" shadow-none  text-white"
                                                                        >
                                                                            <Button variant={'outline-purple'}>

                                                                                View
                                                                            </Button>
                                                                        </a>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    ) : null}
                                                </div>

                                            </div>
                                        </>
                                    ) : null}
                                    <div className="row mt-4 mb-1">
                                        <p
                                            style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                                            className="py-0"
                                        >
                                            <b>Beneficiary Detail</b>
                                        </p>
                                    </div>
                                    {typeof selectedOrder.Beneficiary === "object" ? (
                                        <div
                                            className="row mt-0 mb-2 mx-1"
                                            style={{
                                                border: "2px solid #7b7b7b",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                                                <p
                                                    style={{
                                                        textTransform: "capitalize",
                                                        color: "#7b7b7b",
                                                    }}
                                                    className="py-1"
                                                >
                                                    <b>Beneficiary Name </b>
                                                </p>
                                                {selectedOrder.Beneficiary ? (
                                                    <p
                                                        style={{
                                                            textTransform: "capitalize",
                                                            color: "#7b7b7b",
                                                        }}
                                                        className="py-1"
                                                    >
                                                        {selectedOrder.Beneficiary.name}
                                                    </p>
                                                ) : (
                                                    <p
                                                        style={{
                                                            textTransform: "capitalize",
                                                            color: "#7b7b7b",
                                                        }}
                                                        className="py-1"
                                                    >
                                                        -
                                                    </p>
                                                )}
                                            </div>

                                            <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                                                <p style={{ color: "#7b7b7b" }} className="py-1">
                                                    <b>Beneficiary Email </b>
                                                </p>
                                                {selectedOrder.Beneficiary ? (
                                                    <p style={{ color: "#7b7b7b" }} className="py-1">
                                                        {selectedOrder.Beneficiary.email}
                                                    </p>
                                                ) : (
                                                    <p
                                                        style={{
                                                            textTransform: "capitalize",
                                                            color: "#7b7b7b",
                                                        }}
                                                        className="py-1"
                                                    >
                                                        -
                                                    </p>
                                                )}
                                            </div>

                                            <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                                                <p
                                                    style={{
                                                        textTransform: "capitalize",
                                                        color: "#7b7b7b",
                                                    }}
                                                    className="py-1"
                                                >
                                                    <b>Beneficiary Address </b>
                                                </p>
                                                {selectedOrder.Beneficiary ? (
                                                    <p
                                                        style={{
                                                            textTransform: "capitalize",
                                                            color: "#7b7b7b",
                                                        }}
                                                        className="py-1"
                                                    >
                                                        {selectedOrder.Beneficiary?.address}
                                                    </p>
                                                ) : (
                                                    <p
                                                        style={{
                                                            textTransform: "capitalize",
                                                            color: "#7b7b7b",
                                                        }}
                                                        className="py-1"
                                                    >
                                                        -
                                                    </p>
                                                )}



                                            </div>
                                            <div className="d-flex flex-row overflow-x-auto">
                                                {selectedOrder?.Beneficiary_docs?.length > 0 ? (
                                                    selectedOrder?.Beneficiary_docs?.map((item, index) => {
                                                        return (
                                                            <>
                                                                <div key={index} className="col-4 col-md-2 text-center">
                                                                    <p
                                                                        style={{
                                                                            textTransform: "capitalize",
                                                                            color: "#7b7b7b",
                                                                        }}
                                                                        className="py-1"
                                                                    >
                                                                        {/* <b className="d-flex text-nowrap">File {index + 1} </b> */}
                                                                    </p>
                                                                    <a
                                                                        onClick={() => {
                                                                            openInNewTab(item);
                                                                        }}
                                                                        rel="noreferrer"
                                                                        className=" shadow-none  text-white"
                                                                    >
                                                                        <Button variant='outline-purple'>

                                                                            View
                                                                        </Button>
                                                                    </a>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                ) : null}
                                            </div>

                                        </div>
                                    ) : (
                                        <div
                                            className="row mt-0 mb-2 mx-1"
                                            style={{
                                                border: "2px solid #7b7b7b",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                                                <p
                                                    style={{
                                                        textTransform: "capitalize",
                                                        color: "#7b7b7b",
                                                    }}
                                                    className="py-1"
                                                >
                                                    <b>Beneficiary Address </b>
                                                </p>
                                                {selectedOrder.Beneficiary ? (
                                                    <p
                                                        style={{
                                                            textTransform: "capitalize",
                                                            color: "#7b7b7b",
                                                        }}
                                                        className="py-1"
                                                    >
                                                        {selectedOrder.Beneficiary}
                                                    </p>
                                                ) : (
                                                    <p
                                                        style={{
                                                            textTransform: "capitalize",
                                                            color: "#7b7b7b",
                                                        }}
                                                        className="py-1"
                                                    >
                                                        -
                                                    </p>
                                                )}
                                            </div>

                                        </div>

                                    )}

                                    <div className="row mt-4 mb-1">
                                        <p
                                            style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                                            className="py-0"
                                        >
                                            <b>Transfer Detail</b>
                                        </p>
                                    </div>
                                    {typeof selectedOrder.Transfer === "object" ? (
                                        <div
                                            className="row mt-0 mb-2 mx-1"
                                            style={{
                                                border: "2px solid #7b7b7b",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                                                <p
                                                    style={{
                                                        textTransform: "capitalize",
                                                        color: "#7b7b7b",
                                                    }}
                                                    className="py-1"
                                                >
                                                    <b>Transfer Name </b>
                                                </p>
                                                {selectedOrder.Transfer ? (
                                                    <p
                                                        style={{
                                                            textTransform: "capitalize",
                                                            color: "#7b7b7b",
                                                        }}
                                                        className="py-1"
                                                    >
                                                        {selectedOrder.Transfer.name}
                                                    </p>
                                                ) : (
                                                    <p
                                                        style={{
                                                            textTransform: "capitalize",
                                                            color: "#7b7b7b",
                                                        }}
                                                        className="py-1"
                                                    >
                                                        -
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                                                <p style={{ color: "#7b7b7b" }} className="py-1">
                                                    <b>Transfer Email </b>
                                                </p>
                                                {selectedOrder.Transfer ? (
                                                    <p style={{ color: "#7b7b7b" }} className="py-1">
                                                        {selectedOrder.Transfer.email}
                                                    </p>
                                                ) : (
                                                    <p
                                                        style={{
                                                            textTransform: "capitalize",
                                                            color: "#7b7b7b",
                                                        }}
                                                        className="py-1"
                                                    >
                                                        -
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                                                <p
                                                    style={{
                                                        textTransform: "capitalize",
                                                        color: "#7b7b7b",
                                                    }}
                                                    className="py-1"
                                                >
                                                    <b>Transfer Address </b>
                                                </p>
                                                {selectedOrder.Transfer ? (
                                                    <p
                                                        style={{
                                                            textTransform: "capitalize",
                                                            color: "#7b7b7b",
                                                        }}
                                                        className="py-1"
                                                    >
                                                        {selectedOrder.Transfer?.address}
                                                    </p>
                                                ) : (
                                                    <p
                                                        style={{
                                                            textTransform: "capitalize",
                                                            color: "#7b7b7b",
                                                        }}
                                                        className="py-1"
                                                    >
                                                        -
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="row mt-0 mb-2 mx-1"
                                            style={{
                                                border: "2px solid #7b7b7b",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                                                <p
                                                    style={{
                                                        textTransform: "capitalize",
                                                        color: "#7b7b7b",
                                                    }}
                                                    className="py-1"
                                                >
                                                    <b>Transfer Address </b>
                                                </p>
                                                {selectedOrder.Transfer ? (
                                                    <p
                                                        style={{
                                                            textTransform: "capitalize",
                                                            color: "#7b7b7b",
                                                        }}
                                                        className="py-1"
                                                    >
                                                        {selectedOrder.Transfer}
                                                    </p>
                                                ) : (
                                                    <p
                                                        style={{
                                                            textTransform: "capitalize",
                                                            color: "#7b7b7b",
                                                        }}
                                                        className="py-1"
                                                    >
                                                        -
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <hr
                                        hidden
                                        style={{ border: "1px solid #b3b3b3", opacity: "1.1" }}
                                    />
                                </div>
                                <button type="button" hidden>
                                    Submit Ratings
                                </button>
                            </>
                        ) : (
                            <div className="row mt-0 mb-2 mx-1">
                                <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                    <p
                                        style={{
                                            textTransform: "capitalize",
                                            color: "#7b7b7b",
                                            fontSize: "x-large",
                                            fontWeight: "500",
                                        }}
                                        className="py-1"
                                    >
                                        Detail Not Found
                                    </p>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 text-center" />
                                <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                                    <img
                                        src={Parcel}
                                        className="d-block w-100 card-img-top"
                                        alt="img"
                                    />
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 text-center" />
                            </div>
                        )}
                    </Modal.Body>
                )}
            </Modal>

        </>
    )
}
export default ContractDetailModal
export const SpinnerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margintop: 0.5rem;
  height: 9rem;
`;